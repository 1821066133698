import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { InputRadioOptionComponent } from 'src/app/components/forms/input-radio/input-radio-option.component';
import { InputRadioComponent } from 'src/app/components/forms/input-radio/input-radio.component';

@NgModule({
  declarations: [InputRadioOptionComponent, InputRadioComponent],
  exports: [InputRadioOptionComponent, InputRadioComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
  ],
})
export class InputRadioModule {}
