import { AuthenticationService } from 'src/app/services';
import { getFirstNonEmptyValueFrom, getPageRoute } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

/**
 * Lock out specific routes based on the current environments feature flags.
 *
 * @returns True if the user has access to the route and the environment flag
 * allows it if applicable, false otherwise.
 */
export function environmentFeaturesGuard(): CanActivateFn {
  return async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> => {
    const routeToCheck: PageRoute | null = getPageRoute(state.url);

    if (!routeToCheck) {
      throw new Error(
        'Route is not a SPA route. Unable to check facility features with a route guard.',
      );
    }

    const restrictedFallbackRoute: PageRoute = '/restricted-access';

    switch (routeToCheck) {
      case '/sandbox/buttons':
      case '/sandbox/editor':
      case '/sandbox/forms':
      case '/sandbox/icons':
      case '/sandbox/loading-indicators':
      case '/sandbox/messages':
      case '/sandbox/print':
      case '/sandbox/signatures':
      case '/sandbox/tables': {
        const authenticationService = inject(AuthenticationService);
        const router = inject(Router);

        const user = await getFirstNonEmptyValueFrom(
          authenticationService.userChanges,
        );
        const hasAccess = user.role.isAdmin && config.features.isSandboxEnabled;
        return hasAccess
          ? true
          : router.createUrlTree([restrictedFallbackRoute]);
      }
      default:
        return true;
    }
  };
}
