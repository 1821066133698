import { AllergyTypeApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<AllergyTypeApi>();

export class AllergyType {
  public constructor(props: ClassProperties<AllergyType>) {
    this.id = props.id;
    this.isActive = props.isActive;
    this.isDrugAllergen = props.isDrugAllergen;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Allergy Type API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      isActive: io.boolean,
      isDrugAllergen: io.boolean,
      name: io.string,
    },
    'AllergyTypeApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isDrugAllergen' }) public readonly isDrugAllergen: boolean;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Allergy Type object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Allergy Type object.
   * @throws An error if the value is not a valid Allergy Type object.
   */
  public static deserialize(value: NonNullable<AllergyTypeApi>): AllergyType {
    const decoded = decode(AllergyType.Codec, value);
    return new AllergyType(decoded);
  }

  /**
   * Deserializes a list of Allergy Type objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Allergy Type objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Allergy Type objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<AllergyTypeApi>>,
  ): readonly AllergyType[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Allergy Type objects.');
    }
    return values.map(AllergyType.deserialize);
  }
}
