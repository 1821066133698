import { CustomIconsRegistryService } from 'src/app/services';
import { isNumber } from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { customIconNames } from 'src/app/components/icon/icon-name';

/**
 * A component that displays an icon from the Material Design icon set.
 *
 * // Material Icon List (with search)
 * @see https://fonts.google.com/icons
 *
 * // Material Icon Documentation
 * @see https://developers.google.com/fonts/docs/material_icons
 *
 * // Material Icon Source Code
 * @see https://github.com/google/material-design-icons
 */
@Component({
  selector: 'alleva-icon[name]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  public constructor(
    private readonly customIconsRegistryService: CustomIconsRegistryService,
  ) {
    // Register the custom Alleva icons with Angular Material icons.
    this.customIconsRegistryService.initialize();
  }

  /**
   * The name of the icon to use.
   */
  @Input() public name!: IconName;

  /**
   * The color of the icon. Can be `primary`, `accent`, `warn`, or `success`.
   */
  @Input() public color: ThemePalette | 'success' = 'primary';

  /**
   * Override default icon color with a custom hex color.
   */
  @Input() public colorHex?: string;

  /**
   * Whether or not to flip the icon horizontally.
   */
  @Input() public flipHorizontal = false;

  /**
   * Whether or not the icon should be inlined.
   */
  @HostBinding('class.inline')
  @Input()
  public set inline(value: BooleanInput) {
    this.#inline = coerceBooleanProperty(value);
  }
  public get inline(): boolean {
    return this.#inline;
  }
  #inline = false;

  /**
   * Whether or not the icon is a custom icon.
   *
   * @param name The name of the icon to check.
   * @returns Whether or not the icon is a custom icon.
   */
  protected get isCustom(): boolean {
    return this.customIcons.includes(this.name);
  }

  private readonly customIcons: ReadonlyArray<this['name']> = customIconNames;

  /**
   * Override the default icon size.
   */
  @HostBinding('style.transform')
  @Input()
  public set scale(value: string | number | undefined) {
    const scale = isNumber(Number(value))
      ? `scale(${Number(value)})`
      : undefined;
    this.#scale = scale;
  }
  public get scale(): string | undefined {
    return this.#scale;
  }
  #scale: string | undefined;

  /**
   * Whether or not to animate and spin the icon clockwise.
   */
  @Input() public spin = false;

  /**
   * The type of icon to use. Can be `filled`, `outlined`, `rounded`, `sharp`, or `two-tone`.
   * Defaults to `filled`.
   */
  @Input() public type: IconType | undefined = 'filled';
  public get fontSet(): string {
    switch (this.type) {
      default:
      case 'filled':
        return 'material-icons';
      case 'outlined':
        return 'material-icons-outlined';
      case 'rounded':
        return 'material-icons-round';
      case 'sharp':
        return 'material-icons-sharp';
      case 'two-tone':
        return 'material-icons-two-tone';
    }
  }
}
