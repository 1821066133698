import * as localforage from 'localforage';
import { DateTime } from 'luxon';
import { config } from 'src/configs/config';

import { Injectable } from '@angular/core';

// |------------------------------------------------------------------|
// |  (λ)  | Cookies   | localStorage | sessionStorage  | localforage |
// |-------|-----------|--------------|-----------------|-------------|
// | Size  | 4 KB      | 5 MB         | 5 MB            | 80% of disk |
// | Life  | Specified | Until Delete | Until Tab Close | Until clear |
// |------------------------------------------------------------------|

/**
 * Q: What is `localforage`?
 * A: localForage is a client-side storage that provides an asynchronous non
 * thread blocking API that can store significantly more data than
 * `localStorage` or `sessionStorage`. It uses IndexedDB under the hood, which
 * can typically store up to ~80% of the disk space available to the browser.
 */

/**
 * Storage types supported by this service.
 */
export enum StorageTypeEnum {
  COOKIES,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  LOCAL_FORAGE,
}

/**
 * A service to store and retrieve data from local memory.
 */
@Injectable({ providedIn: 'root' })
export class StorageService {
  /**
   * Clear all of the data from the given storage type.
   *
   * @param type The storage type to clear.
   */
  public clearAll(type: StorageTypeEnum): void {
    switch (type) {
      case StorageTypeEnum.COOKIES:
        clearAllCookies();
        break;
      case StorageTypeEnum.LOCAL_STORAGE:
        localStorage.clear();
        break;
      case StorageTypeEnum.SESSION_STORAGE:
        sessionStorage.clear();
        break;
      case StorageTypeEnum.LOCAL_FORAGE:
        localforage.clear();
        break;
      default:
        throw new Error(`Unknown storage type: ${type}`);
    }
  }

  /**
   * Get the value from the given storage type.
   *
   * @param key The key to get the value for.
   * @param type The storage type to get the value from.
   * @returns The value for the given key.
   */
  public async get<T extends object>(
    key: string,
    type: StorageTypeEnum = StorageTypeEnum.SESSION_STORAGE,
  ): Promise<T | null> {
    switch (type) {
      case StorageTypeEnum.COOKIES:
        return getCookie<T>(key);
      case StorageTypeEnum.LOCAL_STORAGE:
        return getStorage<T>(key, StorageTypeEnum.LOCAL_STORAGE);
      case StorageTypeEnum.SESSION_STORAGE:
        return getStorage<T>(key, StorageTypeEnum.SESSION_STORAGE);
      case StorageTypeEnum.LOCAL_FORAGE:
        return await getForage<T>(key);
      default:
        throw new Error(`Unknown storage type: ${type}`);
    }
  }

  /**
   * Set the value for the given key in the given storage type.
   *
   * @param key The key to set the value for.
   * @param value The value to set.
   * @param type The storage type to set the value in.
   * @param expiration The expiration date for the value.
   */
  public async set<T extends object>(
    key: string,
    value: T | null,
    type: StorageTypeEnum = StorageTypeEnum.SESSION_STORAGE,
    expiration?: DateTime,
  ): Promise<void> {
    switch (type) {
      case StorageTypeEnum.COOKIES:
        setCookie(key, value, expiration);
        break;
      case StorageTypeEnum.LOCAL_STORAGE:
        setStorage<T>(key, value, StorageTypeEnum.LOCAL_STORAGE, expiration);
        break;
      case StorageTypeEnum.SESSION_STORAGE:
        setStorage<T>(key, value, StorageTypeEnum.SESSION_STORAGE, expiration);
        break;
      case StorageTypeEnum.LOCAL_FORAGE:
        setForage<T>(key, value, expiration);
        break;
      default:
        throw new Error(`Unknown storage type: ${type}`);
    }
  }
}

export interface StoredObject<T> {
  value: T;
  expiration: string | null;
}

function setStorage<T extends object>(
  key: string,
  value: T | null,
  type: StorageTypeEnum.LOCAL_STORAGE | StorageTypeEnum.SESSION_STORAGE,
  expiration?: DateTime,
): void {
  const obfuscate = config.features.isStorageObfuscationEnabled ? true : false;
  if (value === null) {
    if (type === StorageTypeEnum.LOCAL_STORAGE) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
    return;
  }

  const objToStore: StoredObject<T> = {
    expiration: expiration?.toISO() ?? null,
    value,
  };
  const json = JSON.stringify(objToStore);
  const obfuscatedJson = obfuscate ? encode(json) : json;

  const storage =
    type === StorageTypeEnum.LOCAL_STORAGE ? localStorage : sessionStorage;
  const storageUsed = JSON.stringify(storage).length;
  const storageLimit =
    type === StorageTypeEnum.LOCAL_STORAGE ? 5 * 1024 * 1024 : 5 * 1024 * 1024; // 5 MB

  if (storageUsed + obfuscatedJson.length > storageLimit) {
    throw new Error(
      `Storage size limit exceeded for ${
        type === StorageTypeEnum.LOCAL_STORAGE ? 'local' : 'session'
      } storage.`,
    );
  }

  storage.setItem(key, obfuscatedJson);
}

function getStorage<T extends object>(
  key: string,
  type: StorageTypeEnum.LOCAL_STORAGE | StorageTypeEnum.SESSION_STORAGE,
): T | null {
  const deobfuscate = config.features.isStorageObfuscationEnabled
    ? true
    : false;
  const storedData =
    type === StorageTypeEnum.LOCAL_STORAGE
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);

  if (!storedData) {
    return null;
  }

  try {
    const deobfuscatedData = deobfuscate ? decode(storedData) : storedData;
    const jsonObject: StoredObject<T> | null | undefined =
      JSON.parse(deobfuscatedData);
    if (jsonObject === null || jsonObject === undefined) {
      return null;
    } else if (
      jsonObject.expiration &&
      DateTime.fromISO(jsonObject.expiration) < DateTime.local()
    ) {
      return null;
    } else {
      return jsonObject.value;
    }
  } catch (parseIssue: unknown) {
    console.error(parseIssue);
    return null;
  }
}

async function setForage<T extends object>(
  key: string,
  value: T | null,
  expiration?: DateTime,
): Promise<void> {
  if (value === null) {
    await localforage.removeItem(key);
    return;
  }
  const objToStore: StoredObject<T> = {
    value,
    expiration: expiration?.toISO() ?? null,
  };
  await localforage.setItem(key, objToStore);
}

async function getForage<T extends object>(key: string): Promise<T | null> {
  const storedObject = await localforage.getItem<StoredObject<T>>(key);

  if (!storedObject) {
    return null;
  }

  if (
    storedObject.expiration &&
    DateTime.fromISO(storedObject.expiration) < DateTime.local()
  ) {
    await localforage.removeItem(key);
    return null;
  }

  return storedObject.value;
}

// @todo: Add cookie support when needed.
function clearAllCookies(): void {
  throw new Error('Not implemented.');
}

// @todo: Add cookie support when needed.
function getCookie<T extends object>(_name: string): T | null {
  throw new Error('Not implemented.');
}

// @todo: Add cookie support when needed.
function setCookie<T extends object>(
  _name: string,
  _value: T | null,
  _exp?: DateTime,
): void {
  throw new Error('Not implemented.');
}

/**
 * Decode a string obfuscated by the encode function.
 *
 * @param input The string to decode.
 * @returns The decoded string.
 */
function decode(input: string): string {
  // Replace characters that are not base64-safe with corresponding characters.
  const base64 = input.replace(/-/g, '+').replace(/_/g, '/');
  // Calculate the padding needed and add it to the base64 string if necessary.
  // Accounts for: "In Base64 encoding, the length of an output-encoded String
  // must be a multiple of four. If necessary, the encoder adds one or two
  // padding characters (=) at the end of the output as needed in order to meet
  // this requirement. Upon decoding, the decoder discards these extra padding
  // characters."
  const paddingLength = 4 - (base64.length % 4);
  const paddedBase64 =
    paddingLength === 4 ? base64 : base64 + '='.repeat(paddingLength);

  // Decode the base64 data.
  const decodedData = atob(paddedBase64);

  // Convert the decoded data from binary to a UTF-8 string.
  const decoder = new TextDecoder();
  return decoder.decode(
    new Uint8Array(Array.from(decodedData, (char) => char.charCodeAt(0))),
  );
}

/**
 * Encode a string obfuscating it from the user.
 *
 * @param input The string to encode.
 * @returns The encoded string.
 */
function encode(input: string): string {
  // Convert the input string to binary data.
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  // Convert the binary data to a string of characters.
  const charArray = Array.from(data, (byte) => String.fromCharCode(byte));
  const charString = charArray.join('');

  // Encode the character string as base64.
  const base64 = btoa(charString);

  // Replace characters that were not base64-safe with corresponding characters.
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
