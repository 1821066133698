import { NoteTypeApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<NoteTypeApi>();

export class NoteType {
  public constructor(props: ClassProperties<NoteType>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Note Type API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'NoteTypeApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Note Type object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Note Type object.
   * @throws An error if the value is not a valid Note Type object.
   */
  public static deserialize(value: NonNullable<NoteTypeApi>): NoteType {
    const decoded = decode(NoteType.Codec, value);
    return new NoteType({
      ...decoded,
    });
  }

  /**
   * Deserializes a list of Note Type objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Note Type objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Note Type objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<NoteTypeApi>>,
  ): readonly NoteType[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Note Type objects.');
    }
    return values.map(NoteType.deserialize);
  }
}
