import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { InputModule } from 'src/app/components/forms/input/input.module';
import { ScreenLockDialogComponent } from 'src/app/components/screen-lock/screen-lock-dialog.component';
import { ScreenLockComponent } from 'src/app/components/screen-lock/screen-lock.component';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [ScreenLockComponent, ScreenLockDialogComponent],
  exports: [ScreenLockComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    PipesModule,
    InputModule,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class ScreenLockModule {}
