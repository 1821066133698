import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { BasicDialogComponent } from 'src/app/components/dialogs/basic-dialog.component';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [BasicDialogComponent],
  imports: [ButtonComponent, CommonModule, PipesModule],
})
export class DialogsModule {}
