import { ClientCIWABCreatorApi, ClientCIWABCreatorUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientCIWABCreatorApi>();

export class ClientCIWABCreator extends UserBase {
  public constructor(props: ClassProperties<ClientCIWABCreator>) {
    super(props);

    this.doctor = props.doctor;
    this.guid = props.guid;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA-B Creator
   * API model.
   */
  public static readonly Codec = io.intersection([
    UserBase.BaseCodec,
    io.type(
      {
        doctor: io.boolean,
        guid: io.string,
        role: io.union([NameId.Codec, io.null]),
      },
      'ClientCIWABCreatorApi',
    ),
  ]);

  @api({ key: 'doctor' }) public readonly doctor: boolean;
  @api({ key: 'guid' }) public readonly guid: string;
  @api({ key: 'role' }) public readonly role: NameId | null;

  /**
   * Deserializes a Client CIWA-B Creator object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B Creator object.
   * @throws An error if the value is not a valid Client CIWA-B Creator
   * object.
   */
  public static override deserialize(
    value: NonNullable<ClientCIWABCreatorApi>,
    deserializationArgs: ClientCiwaBCreatorDeserializationArgs,
  ): ClientCIWABCreator {
    const decoded = decode(ClientCIWABCreator.Codec, value);
    const userBase = UserBase.deserialize(decoded, deserializationArgs);
    return new ClientCIWABCreator({
      ...decoded,
      ...userBase,
      role: decoded.role ? NameId.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA-B Creator objects from an API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B Creator objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA-B Creator
   * objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWABCreatorApi>>,
    deserializationArgs: ClientCiwaBCreatorDeserializationArgs,
  ): readonly ClientCIWABCreator[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA-B Creator objects.');
    }
    return values.map((clientCIWABCreator) =>
      ClientCIWABCreator.deserialize(clientCIWABCreator, deserializationArgs),
    );
  }
}

export class ClientCIWABCreatorUpdate {
  public constructor(props: ClassProperties<ClientCIWABCreatorUpdate>) {
    this.guid = props.guid;
  }

  @api({ key: 'guid' }) public readonly guid: ClientCIWABCreator['guid'];

  /**
   * Serializes a Client CIWA-B Creator object to an API model.
   *
   * @returns The serialized Client CIWA-B Creator object.
   */
  public serialize(): ClientCIWABCreatorUpdateApi {
    return this;
  }
}

export interface ClientCiwaBCreatorDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
