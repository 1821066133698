/**
 * All custom enum values for the `customType` attribute of an `input` element.
 *
 * These custom input type are not part of the HTML specifications but carry
 * logic specific to the application.
 */
export enum CustomInputTypeEnum {
  /** For entering a password that can be shown to the user. */
  PASSWORD_SHOWABLE = 'password-showable',
}

/**
 * All possible enum values for the `type` attribute of an `input` element.
 */
export enum InputTypeEnum {
  /**
   * A button that can be pressed or activated by the user.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/button
   */
  BUTTON = 'button',
  /**
   * A basic checkbox that can be either checked or unchecked.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox
   */
  CHECKBOX = 'checkbox',
  /**
   * A color, either by using a visual color picker interface or by entering
   * the color into a text field in #rrggbb hexadecimal format.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/color
   */
  COLOR = 'color',
  /**
   * A date, either with a textbox that validates the input or a special date
   * picker interface.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
   */
  DATE = 'date',
  /**
   * Both a date and a time, including the year, month, and day as well as the
   * time in hours and minutes.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
   */
  DATETIME_LOCAL = 'datetime-local',
  /**
   * For letting the user enter and edit an email address, or, if the multiple
   * attribute is specified, a list of email addresses.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
   */
  EMAIL = 'email',
  /**
   * For choosing one or more files from device storage to be used or uploaded.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
   */
  FILE = 'file',
  /**
   * For including data that cannot be seen or modified by users when a form is
   * submitted.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/hidden
   */
  HIDDEN = 'hidden',
  /**
   * Used to create graphical submit buttons, i.e. submit buttons that take the
   * form of an image rather than text.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/image
   */
  IMAGE = 'image',
  /**
   * For entering a month and year, with no time.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/month
   */
  MONTH = 'month',
  /**
   * For entering a number.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number
   */
  NUMBER = 'number',
  /**
   * For entering a password.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password
   */
  PASSWORD = 'password',
  /**
   * For selecting one of a limited number of choices, such as a set of radio
   * buttons.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio
   */
  RADIO = 'radio',
  /**
   * For entering a number whose exact value is not important (like a slider,
   * or a dial).
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range
   */
  RANGE = 'range',
  /**
   * For resetting the contents of a form to their initial values.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/reset
   */
  RESET = 'reset',
  /**
   * For entering a search string.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/search
   */
  SEARCH = 'search',
  /**
   * For submitting the contents of a form.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/submit
   */
  SUBMIT = 'submit',
  /**
   * For entering a telephone number.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel
   */
  TEL = 'tel',
  /**
   * For entering a short text string.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text
   */
  TEXT = 'text',
  /**
   * For entering a time value with no time zone.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
   */
  TIME = 'time',
  /**
   * For entering a URL.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/url
   */
  URL = 'url',
  /**
   * For entering a week and year, with no time.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/week
   */
  WEEK = 'week',
}
