export const CLIENT_COWS_SCORES_DROPDOWN_OPTIONS: ClientCOWSScoreDropdownOptions =
  {
    anxiety: [
      { name: 'None.', value: 0 },
      {
        name: 'Patient reports increasing irritability or anxiousness.',
        value: 1,
      },
      { name: 'Patient obviously irritable/anxious.', value: 2 },
      // No value for `3`.
      {
        name:
          'Patient so irritable or anxious that participation in the ' +
          'assessment is difficult.',
        value: 4,
      },
      // No value for `5`.
    ],
    bone: [
      { name: 'Not present', value: 0 },
      { name: 'Mild diffuse discomfort.', value: 1 },
      {
        name: 'Patient reports severe diffuse aching of joints/muscles.',
        value: 2,
      },
      // No value for `3`.
      {
        name:
          'Patient is rubbing joints or muscles and is unable to sit ' +
          'still because of discomfort.',
        value: 4,
      },
      // No value for `5`.
    ],
    giUpset: [
      { name: 'No GI symptoms.', value: 0 },
      { name: 'Stomach cramps.', value: 1 },
      { name: 'Nausea or loose stool.', value: 2 },
      { name: 'Vomiting or diarrhea.', value: 3 },
      // No value for `4`.
      { name: 'Multiple episodes of vomiting or diarrhea.', value: 5 },
    ],
    gooseFlesh: [
      { name: 'Skin is smooth.', value: 0 },
      // No value for `1`.
      // No value for `2`.
      {
        name: 'Piloerection of skin can be felt or hairs standing up on arms.',
        value: 3,
      },
      // No value for `4`.
      { name: 'Prominent piloerection.', value: 5 },
    ],
    pupilSize: [
      { name: 'Pupils pinned or normal for room light.', value: 0 },
      { name: 'Pupils possibly larger than normal for room light.', value: 1 },
      { name: 'Pupils moderately dilated.', value: 2 },
      // No value for `3`.
      // No value for `4`.
      {
        name: 'Pupils so dilated that only rim of the iris is visible.',
        value: 5,
      },
    ],
    restlessness: [
      { name: 'Able to sit still.', value: 0 },
      { name: 'Reports of difficulty sitting still.', value: 1 },
      // No value for `2`.
      {
        name: 'Frequent shifting or extraneous movements of legs/arms.',
        value: 3,
      },
      // No value for `4`.
      { name: 'Unable to sit still for more than a few seconds.', value: 5 },
    ],
    runnyNose: [
      { name: 'Not present.', value: 0 },
      { name: 'Nasal stuffiness or unusually moist eyes.', value: 1 },
      { name: 'Nose running or tearing.', value: 2 },
      // No value for `3`.
      {
        name: 'Nose constantly running or tears streaming down cheeks.',
        value: 4,
      },
      // No value for `5`.
    ],
    sweating: [
      { name: 'No report of chills or flushing.', value: 0 },
      { name: 'Subjective report of chills or flushing.', value: 1 },
      { name: 'Flushed or observable moisture on face.', value: 2 },
      { name: 'Beads of sweat on brow or face.', value: 3 },
      { name: 'Sweat streaming off face.', value: 4 },
    ],
    tremors: [
      { name: 'No tremor.', value: 0 },
      { name: 'Tremor can be felt, but not observed.', value: 1 },
      { name: 'Slight tremor observable.', value: 2 },
      // No value for `3`.
      { name: 'Gross tremor or muscle twitching.', value: 4 },
      // No value for `5`.
    ],
    yawning: [
      { name: 'No yawning.', value: 0 },
      { name: 'Yawning 1-2x during the assessment.', value: 1 },
      { name: 'Yawning 3+ times during the assessment.', value: 2 },
      // No value for `3`.
      { name: 'Yawning several times per minute.', value: 4 },
      // No value for `5`.
    ],
  };

interface ClientCOWSScoreDropdownOptions {
  anxiety: readonly ClientCOWSScoreDropdownOption[];
  bone: readonly ClientCOWSScoreDropdownOption[];
  giUpset: readonly ClientCOWSScoreDropdownOption[];
  gooseFlesh: readonly ClientCOWSScoreDropdownOption[];
  pupilSize: readonly ClientCOWSScoreDropdownOption[];
  restlessness: readonly ClientCOWSScoreDropdownOption[];
  runnyNose: readonly ClientCOWSScoreDropdownOption[];
  sweating: readonly ClientCOWSScoreDropdownOption[];
  tremors: readonly ClientCOWSScoreDropdownOption[];
  yawning: readonly ClientCOWSScoreDropdownOption[];
}

interface ClientCOWSScoreDropdownOption {
  name: string;
  value: number;
}
