<ng-container *ngIf="userChanges | async as user">
  <div
    [matTooltip]="
      isCollapsed ? 'Go to the &#34;User Management&#34; page.' : ''
    "
    matTooltipPosition="right"
  >
    <img
      [class.collapsed-image]="isCollapsed"
      routerLink="/settings/users"
      [src]="
        (user.image | authBlob | async) || 'assets/images/clients/temp.png'
      "
    />
  </div>
  <div class="account-actions-container">
    <div
      *ngIf="!isCollapsed"
      class="user-name"
      [matTooltip]="
        user.shortName +
        (user.activeLicenseAcronyms ? ', ' + user.activeLicenseAcronyms : '')
      "
    >
      {{
        user.shortName +
          (user.activeLicenseAcronyms ? ', ' + user.activeLicenseAcronyms : '')
      }}
    </div>
    <div *ngIf="!isCollapsed" class="user-role" [title]="user.role.name">
      ({{ user.role.name }})
    </div>
    <div class="button-actions">
      <div class="button-container">
        <alleva-button
          (click)="logout()"
          [disabled]="isLoggingOut"
          [icon]="isCollapsed ? 'logout' : undefined"
          [matTooltip]="isCollapsed ? 'Log out of Alleva.' : ''"
          [styling]="isCollapsed ? 'mini-fab' : 'flat'"
          color="accent"
          label="Log out of Alleva."
          matTooltipPosition="right"
        >
          Log Out
        </alleva-button>
      </div>
      <div class="button-container">
        <alleva-button
          [disabled]="isLoggingOut"
          [icon]="{
            colorHex: 'var(--white)',
            name: 'user_settings',
            inline: true
          }"
          [matMenuTriggerFor]="userMenu"
          [matTooltip]="isCollapsed ? 'Quick settings.' : ''"
          class="settings-button"
          disableRipple
          label="Open User Settings"
          matTooltipPosition="right"
          styling="icon"
        ></alleva-button>
      </div>
    </div>
  </div>
</ng-container>

<mat-menu #userMenu="matMenu">
  <button mat-menu-item [routerLink]="settingsProfileAccountUrlChanges | async">
    My Profile & Settings
  </button>
  <button *ngIf="isScreenAutoLockEnabled" mat-menu-item (click)="lockScreen()">
    Lock Screen
  </button>
  <button [matMenuTriggerFor]="facilityMenu" mat-menu-item>
    Change Facility
  </button>
</mat-menu>

<mat-menu #facilityMenu="matMenu">
  <button
    (click)="facilityBaseControl.setValue(facilityBase)"
    *ngFor="let facilityBase of availableFacilityBaseChanges | async"
    [class.active]="facilityBase.id === facilityBaseControl.value?.id"
    class="facility-menu-item"
    mat-menu-item
  >
    <span class="facility-name">{{ facilityBase.name }}</span>
    <alleva-icon
      *ngIf="facilityBase.id === facilityBaseControl.value?.id"
      color="primary"
      name="check"
    ></alleva-icon>
  </button>
</mat-menu>
