import { ClientMedicationCreatedByApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientMedicationCreatedByApi>();

export class ClientMedicationCreatedBy extends UserBase {
  public constructor(props: ClassProperties<ClientMedicationCreatedBy>) {
    super(props);

    this.doctor = props.doctor;
    this.guid = props.guid;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Medication Created
   * By API model.
   */
  public static readonly Codec = io.intersection([
    UserBase.BaseCodec,
    io.type(
      {
        doctor: io.boolean,
        guid: io.string,
        role: io.union([NameId.Codec, io.null]),
      },
      'ClientMedicationCreatedByApi',
    ),
  ]);

  @api({ key: 'doctor' }) public readonly doctor: boolean;
  @api({ key: 'guid' }) public readonly guid: string;
  @api({ key: 'role' }) public readonly role: NameId | null;

  /**
   * Deserializes a Client Medication Created By object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication Created By object.
   * @throws An error if the value is not a valid Client Medication Created By
   * object.
   */
  public static override deserialize(
    value: NonNullable<ClientMedicationCreatedByApi>,
    deserializationArgs: ClientMedicationCreatedByDeserializationArgs,
  ): ClientMedicationCreatedBy {
    const decoded = decode(ClientMedicationCreatedBy.Codec, value);
    const userBase = UserBase.deserialize(decoded, deserializationArgs);
    return new ClientMedicationCreatedBy({
      ...decoded,
      ...userBase,
      role: decoded.role ? NameId.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Client Medication Created By objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication Created By objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Medication
   * Created By objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ClientMedicationCreatedByApi>>,
    deserializationArgs: ClientMedicationCreatedByDeserializationArgs,
  ): readonly ClientMedicationCreatedBy[] {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Client Medication Created By objects.',
      );
    }
    return values.map((clientMedicationCreatedBy) =>
      ClientMedicationCreatedBy.deserialize(
        clientMedicationCreatedBy,
        deserializationArgs,
      ),
    );
  }
}

export interface ClientMedicationCreatedByDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
