import { CountryApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<CountryApi>();

export class Country {
  public constructor(props: ClassProperties<Country>) {
    this.abbreviation = props.abbreviation;
    this.active = props.active;
    this.callingCode = props.callingCode;
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Country API model.
   */
  public static readonly Codec = io.type(
    {
      abbreviation: io.union([io.string, io.null]),
      active: io.boolean,
      callingCode: io.number,
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'CountryApi',
  );

  @api({ key: 'abbreviation' }) public readonly abbreviation: string | null;
  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'callingCode' }) public readonly callingCode: number;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Country object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Country object.
   * @throws An error if the value is not a valid Country object.
   */
  public static deserialize(value: NonNullable<CountryApi>): Country {
    const decoded = decode(Country.Codec, value);
    return new Country(decoded);
  }

  /**
   * Deserializes a list of Country objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Country objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Country objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<CountryApi>>,
  ): readonly Country[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Country objects.');
    }
    return values.map(Country.deserialize);
  }
}
