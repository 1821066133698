import { LabResultObservationApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<LabResultObservationApi>();

export class LabResultObservation {
  public constructor(props: ClassProperties<LabResultObservation>) {
    this.date = props.date;
    this.id = props.id;
    this.physicianName = props.physicianName;
    this.placerOrderNumber = props.placerOrderNumber;
    this.resultId = props.resultId;
    this.resultStatus = props.resultStatus;
    this.specimenSource = props.specimenSource;
    this.testDesc = props.testDesc;
  }

  /**
   * The io-ts codec for runtime type checking of the Lab Result Observation
   * Api model.
   */
  public static readonly Codec = io.type(
    {
      date: io.union([io.null, io.string]),
      id: io.number,
      physicianName: io.string,
      placerOrderNumber: io.string,
      resultId: io.number,
      resultStatus: io.string,
      specimenSource: io.string,
      testDesc: io.string,
    },
    'LabResultObservationApi',
  );

  @api({ key: 'date' }) public readonly date: DateTime | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'physicianName' }) public readonly physicianName: string;
  @api({ key: 'placerOrderNumber' }) public readonly placerOrderNumber: string;
  @api({ key: 'resultId' }) public readonly resultId: number;
  @api({ key: 'resultStatus' }) public readonly resultStatus: string;
  @api({ key: 'specimenSource' }) public readonly specimenSource: string;
  @api({ key: 'testDesc' }) public readonly testDesc: string;

  /**
   * Deserializes a Lab Result Observation object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Lab Result Observation object.
   * @throws An error if the value is not a valid Lab Result Observation
   * object.
   */
  public static deserialize(
    value: NonNullable<LabResultObservationApi>,
    deserializationArgs: LabResultObservationDeserializationArgs,
  ): LabResultObservation {
    const decoded = decode(LabResultObservation.Codec, value);
    return new LabResultObservation({
      ...decoded,
      date: decoded.date
        ? DateTime.fromISO(decoded.date, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
    });
  }

  /**
   * Deserializes a list of Lab Result Observation objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Lab Result Observation objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Lab Result
   * Observation objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<LabResultObservationApi>>,
    deserializationArgs: LabResultObservationDeserializationArgs,
  ): readonly LabResultObservation[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Lab Result Observation objects.');
    }
    return values.map((labResultObservation) =>
      LabResultObservation.deserialize(
        labResultObservation,
        deserializationArgs,
      ),
    );
  }
}

export interface LabResultObservationDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
