import { Component, HostBinding, Input } from '@angular/core';

/**
 * A component that displays content in a custom, themed Alleva container.
 */
@Component({
  selector: 'alleva-container',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  @Input() @HostBinding('class') public theme?: 'light' | 'dark' | 'shadow';
}
