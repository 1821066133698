import { InsurancePlanApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<InsurancePlanApi>();

export class InsurancePlan {
  public constructor(props: ClassProperties<InsurancePlan>) {
    this.active = props.active;
    this.description = props.description;
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Plan API
   * model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      description: io.union([io.string, io.null]),
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'InsurancePlanApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Insurance Plan object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Plan object.
   * @throws An error if the value is not a valid Insurance Plan object.
   */
  public static deserialize(
    value: NonNullable<InsurancePlanApi>,
  ): InsurancePlan {
    const decoded = decode(InsurancePlan.Codec, value);
    return new InsurancePlan(decoded);
  }

  /**
   * Deserializes a list of Insurance Plan objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Plan objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Plan
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsurancePlanApi>>,
  ): readonly InsurancePlan[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Plan objects.');
    }
    return values.map(InsurancePlan.deserialize);
  }
}
