import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import {
  HoverPreviewComponent,
  HoverPreviewConfig,
  HoverPreviewRef,
} from './hover-preview.component';

@Injectable()
export class HoverPreviewService {
  public constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
  ) {}

  public overlayRef?: OverlayRef;

  public open<T>(options: HoverPreviewConfig<T>): void {
    this.closePreview();
    const { content, data, height, maxHeight, maxWidth, origin, width } =
      options;

    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      height,
      maxHeight,
      maxWidth,
      positionStrategy: this.createPositionStrategy(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      width,
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    const hoverPreviewReference = new HoverPreviewRef<T>(
      this.overlayRef,
      content,
      data,
    );

    const hoverPreviewInjector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: HoverPreviewRef, useValue: hoverPreviewReference },
      ],
    });

    this.overlayRef.attach(
      new ComponentPortal(HoverPreviewComponent, null, hoverPreviewInjector),
    );
  }

  public closePreview(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
  }

  private createPositionStrategy(
    flexibleConnectedPositionStrategyOrigin: FlexibleConnectedPositionStrategyOrigin,
  ): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(flexibleConnectedPositionStrategyOrigin)
      .withPositions(connectionPositionsPair)
      .withFlexibleDimensions(false)
      .withPush(false);
  }
}

/** Positioning of hover preview relative to the origin element. */
const connectionPositionsPair: ConnectionPositionPair[] = [
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
];
