import { CountryApi, StateApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Country, State } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the locations API.
 */
@Injectable()
export class LocationsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Get the list of available countries.
   *
   * @returns The countries list on success, undefined on error.
   */
  public getCountriesList(): Observable<readonly Country[] | undefined> {
    return this.httpClient
      .get<readonly CountryApi[]>(`${config.api}/countries`)
      .pipe(
        map((response) => (response ? Country.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Get the list of available states.
   *
   * @returns The states list on success, undefined on error.
   */
  public getStatesList(
    countryId: Country['id'],
  ): Observable<readonly State[] | undefined> {
    return this.httpClient
      .get<readonly StateApi[]>(`${config.api}/countries/${countryId}/states`)
      .pipe(
        map((response) => (response ? State.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
