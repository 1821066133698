import { TreatmentPlanReviewApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  TreatmentPlanReview,
  TreatmentPlanReviewDeserializationArgs,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Treatment Plan Reviews API.
 */
@Injectable()
export class TreatmentPlanReviewsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all client treatment plan reviews data for the given
   * client.
   *
   * @param clientId The client id to get the treatment plan reviews for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The client treatment plan reviews on success, undefined on error.
   */
  public getByClientId(
    clientId: Client['id'],
    deserializationArgs: TreatmentPlanReviewDeserializationArgs,
  ): Observable<readonly TreatmentPlanReview[] | undefined> {
    return this.httpClient
      .get<
        readonly TreatmentPlanReviewApi[] | undefined
      >(`${config.api}/clients/${clientId}/treatment-plan-reviews`, { params: { clientId } })
      .pipe(
        map((response) =>
          response
            ? TreatmentPlanReview.deserializeList(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
