import { InsurancePlanPolicyApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<InsurancePlanPolicyApi>();

export class InsurancePlanPolicy {
  public constructor(props: ClassProperties<InsurancePlanPolicy>) {
    this.endDate = props.endDate;
    this.policyNumber = props.policyNumber;
    this.startDate = props.startDate;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Plan Policy API
   * model.
   */
  public static readonly Codec = io.type(
    {
      endDate: io.union([io.string, io.null]),
      policyNumber: io.union([io.string, io.null]),
      startDate: io.union([io.string, io.null]),
    },
    'InsurancePlanPolicyApi',
  );

  @api({ key: 'endDate' }) public readonly endDate: DateTime | null;
  @api({ key: 'policyNumber' }) public readonly policyNumber: string | null;
  @api({ key: 'startDate' }) public readonly startDate: DateTime | null;

  /**
   * Deserializes a Insurance Plan Policy object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Plan Policy object.
   * @throws An error if the value is not a valid Insurance Plan Policy object.
   */
  public static deserialize(
    value: NonNullable<InsurancePlanPolicyApi>,
    deserializationArgs: InsurancePlanPolicyDeserializationArgs,
  ): InsurancePlanPolicy {
    const decoded = decode(InsurancePlanPolicy.Codec, value);
    return new InsurancePlanPolicy({
      ...decoded,
      endDate: decoded.endDate
        ? DateTime.fromISO(decoded.endDate, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
      startDate: decoded.startDate
        ? DateTime.fromISO(decoded.startDate, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
    });
  }

  /**
   * Deserializes a list of Insurance Plan Policy objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Plan Policy objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Plan Policy
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsurancePlanPolicyApi>>,
    deserializationArgs: InsurancePlanPolicyDeserializationArgs,
  ): readonly InsurancePlanPolicy[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Plan Policy objects.');
    }
    return values.map((insurancePlanPolicy) =>
      InsurancePlanPolicy.deserialize(insurancePlanPolicy, deserializationArgs),
    );
  }
}

export class InsurancePlanPolicyUpdate extends InsurancePlanPolicy {
  public serialize(): InsurancePlanPolicyApi {
    return {
      endDate: this.endDate ? this.endDate.toISO() : null,
      policyNumber: this.policyNumber,
      startDate: this.startDate ? this.startDate.toISO() : null,
    };
  }
}

export interface InsurancePlanPolicyDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
