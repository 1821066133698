import {
  AutoCompleteEnum,
  CustomInputTypeEnum,
  InputTypeEnum,
} from 'src/app/enumerators';
import { isNonEmptyString, isNonEmptyValue } from 'src/app/utilities';

import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * A custom form field component that renders an `input` or `textarea` element
 * which extends upon Angular Reactive Forms and Angular Material Forms style
 * and functionality. Supports a wide variety of validation error messages by
 * default, can display hint messages for the user, and much more.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@Component({
  selector: 'alleva-input[formControl][label]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent<T>
  extends CustomInputDirective<T>
  implements OnInit
{
  /**
   * The autocomplete attribute for the input.
   *
   * @default 'off' Defaults to 'off' to prevent browser autocomplete.
   * @note Also supports 'on' to enable automatic browser autocomplete.
   */
  @Input() public autocomplete: AutoComplete = 'off';

  /**
   * The id of the form control, automatically generated if not provided.
   */
  @Input() public id = `alleva-input-${++uniqueFormId}`;

  /**
   * The _required_ label for this input.
   */
  @Input() public override label!: string;

  /**
   * Name of the form control. Submitted with the form as part of a
   * name/value pair.
   *
   * @default '' Defaults to an empty string.
   */
  @Input() public name?: string;

  /**
   * The prefix icon to display on the input.
   */
  @Input() public prefixIcon?: Icon;

  /**
   * The step size to use for the input. The step _must_ be a positive
   * number. We can add onto this in the future to support other types.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#step
   */
  @Input() public step: number | null = null;

  /**
   * The suffix icon to display on the input.
   */
  @Input() public suffixIcon?: Icon;

  /**
   * The suffix text to display on the input.
   */
  @Input() public suffixText?: string;

  /**
   * The type of input this component should render and use.
   *
   * @default 'text' Defaults to a text input.
   * @note Also supports `textarea` for a textarea input.
   */
  @Input() public type: SupportedInputType | 'textarea' = 'text';

  /** The custom type of input this component should render and use. */
  @Input() public typeCustom: SupportedCustomInputType | null = null;

  @Input() public isClearable?: boolean;

  /** The color palette to use for the suffix icon. */
  public get suffixIconColor(): ThemePalette {
    return this.suffixIcon?.color;
  }

  /** The name of the suffix icon to display on the input. */
  public get suffixIconName(): IconName {
    if (this.isClearable) {
      return 'clear';
    } else if (!this.suffixIcon) {
      throw new Error(
        'Suffix icon name must be provided when passing a suffix icon.',
      );
    }

    return this.suffixIcon.name;
  }

  /** Perform a specific action when the suffix icon is clicked. */
  protected onSuffixIconClick(): void {
    if (this.typeCustom === CustomInputTypeEnum.PASSWORD_SHOWABLE) {
      const isPasswordType = this.type === InputTypeEnum.PASSWORD;
      this.type = isPasswordType ? 'text' : 'password';
      this.suffixIcon = {
        name: isPasswordType ? 'visibility_off' : 'visibility',
        color: 'primary',
      };
    } else if (this.isClearable) {
      this.baseControl.setValue(null);
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.typeCustom === CustomInputTypeEnum.PASSWORD_SHOWABLE) {
      this.type = InputTypeEnum.PASSWORD;
      // Is showable password input, let's make sure there's no suffix text, we'll
      // use the suffix icon to toggle the password visibility.
      this.suffixText = undefined;
      // Set the suffix icon to the default visibility icon.
      this.suffixIcon = { name: 'visibility', color: 'primary' };
    } else if (this.suffixIcon && this.suffixText) {
      throw new Error('Cannot have both a suffix icon and suffix text.');
    }

    this.validatePlaceholder();
    this.validateStep();
  }

  /**
   * Validates the `placeholder` attribute based on the `type` attribute.
   */
  private validatePlaceholder(): void {
    if (!isNonEmptyString(this.placeholder)) {
      // No placeholder to validate. All is good.
      return;
    } else if (
      this.type === InputTypeEnum.EMAIL ||
      this.type === InputTypeEnum.NUMBER ||
      this.type === InputTypeEnum.PASSWORD ||
      this.type === InputTypeEnum.SEARCH ||
      this.type === InputTypeEnum.TEL ||
      this.type === InputTypeEnum.TEXT ||
      this.type === InputTypeEnum.URL ||
      this.typeCustom === CustomInputTypeEnum.PASSWORD_SHOWABLE ||
      // Text area also supports a placeholder.
      this.type === 'textarea'
    ) {
      // The placeholder is supported by the input type. All is good.
      return;
    } else {
      // The placeholder is not supported by the input type. Throw an error.
      throw new Error(
        `The "placeholder" attribute is not supported by the "${this.type}" ` +
          'input type.',
      );
    }
  }

  /**
   * Validates the `step` attribute based on the `type` attribute.
   */
  private validateStep(): void {
    if (!isNonEmptyValue(this.step)) {
      // No step to validate. All is good.
      return;
    } else if (
      this.type === InputTypeEnum.DATE ||
      this.type === InputTypeEnum.DATETIME_LOCAL ||
      this.type === InputTypeEnum.MONTH ||
      this.type === InputTypeEnum.NUMBER ||
      this.type === InputTypeEnum.TIME ||
      this.type === InputTypeEnum.WEEK
    ) {
      // The step is supported by the input type. All is good.
      return;
    } else {
      // The step is not supported by the input type. Throw an error.
      throw new Error(
        `The "step" attribute is not supported by the "${this.type}" ` +
          'input type.',
      );
    }
  }
}

/**
 * A unique ID for each form field component. Increments each time a new `input`
 * component is used during the lifetime of the application (e.g. page refresh).
 */
let uniqueFormId = 0;

/**
 * The possible string literal values for the `customType` attribute of the
 * `alleva-input` component.
 */
export type SupportedCustomInputType =
  `${CustomInputTypeEnum.PASSWORD_SHOWABLE}`;

/**
 * The possible string literal values for the `type` attribute of the
 * `alleva-input` component.
 *
 * @note Only supports the following input types supported by Angular Material:
 * - `color`
 * - `date`
 * - `datetime-local`
 * - `email`
 * - `month`
 * - `number`
 * - `password`
 * - `search`
 * - `tel`
 * - `text`
 * - `time`
 * - `url`
 * - `week`
 * @see https://material.angular.io/components/input/overview
 * @note See enum `InputTypeEnum` in `input-type.enum.ts` for more details.
 */
export type SupportedInputType =
  | `${InputTypeEnum.COLOR}`
  | `${InputTypeEnum.DATETIME_LOCAL}`
  | `${InputTypeEnum.DATE}`
  | `${InputTypeEnum.EMAIL}`
  | `${InputTypeEnum.MONTH}`
  | `${InputTypeEnum.NUMBER}`
  | `${InputTypeEnum.PASSWORD}`
  | `${InputTypeEnum.SEARCH}`
  | `${InputTypeEnum.TEL}`
  | `${InputTypeEnum.TEXT}`
  | `${InputTypeEnum.TIME}`
  | `${InputTypeEnum.URL}`
  | `${InputTypeEnum.WEEK}`;

/**
 * The possible string literal values for the `autocomplete` attribute of the
 * `alleva-input` component.
 *
 * @note Supports all `autocomplete` attribute values, including `off` and `on`.
 * @note See enum `AutoCompleteEnum` in `autocomplete.enum.ts` for more details.
 */
export type AutoComplete =
  | `${AutoCompleteEnum.ADDITIONAL_NAME}`
  | `${AutoCompleteEnum.ADDRESS_LEVEL1}`
  | `${AutoCompleteEnum.ADDRESS_LEVEL2}`
  | `${AutoCompleteEnum.ADDRESS_LEVEL3}`
  | `${AutoCompleteEnum.ADDRESS_LEVEL4}`
  | `${AutoCompleteEnum.ADDRESS_LINE1}`
  | `${AutoCompleteEnum.ADDRESS_LINE2}`
  | `${AutoCompleteEnum.ADDRESS_LINE3}`
  | `${AutoCompleteEnum.CC_ADDITIONAL_NAME}`
  | `${AutoCompleteEnum.CC_CSC}`
  | `${AutoCompleteEnum.CC_EXP_MONTH}`
  | `${AutoCompleteEnum.CC_EXP_YEAR}`
  | `${AutoCompleteEnum.CC_EXP}`
  | `${AutoCompleteEnum.CC_FAMILY_NAME}`
  | `${AutoCompleteEnum.CC_GIVEN_NAME}`
  | `${AutoCompleteEnum.CC_GIVEN_NAME}`
  | `${AutoCompleteEnum.CC_NAME}`
  | `${AutoCompleteEnum.CC_NAME}`
  | `${AutoCompleteEnum.CC_NUMBER}`
  | `${AutoCompleteEnum.CC_NUMBER}`
  | `${AutoCompleteEnum.CC_TYPE}`
  | `${AutoCompleteEnum.CC_TYPE}`
  | `${AutoCompleteEnum.COUNTRY_NAME}`
  | `${AutoCompleteEnum.COUNTRY_NAME}`
  | `${AutoCompleteEnum.COUNTRY}`
  | `${AutoCompleteEnum.COUNTRY}`
  | `${AutoCompleteEnum.CURRENT_PASSWORD}`
  | `${AutoCompleteEnum.CURRENT_PASSWORD}`
  | `${AutoCompleteEnum.DOB_DAY}`
  | `${AutoCompleteEnum.DOB_MONTH}`
  | `${AutoCompleteEnum.DOB_YEAR}`
  | `${AutoCompleteEnum.DOB}`
  | `${AutoCompleteEnum.EMAIL}`
  | `${AutoCompleteEnum.EMAIL}`
  | `${AutoCompleteEnum.FAMILY_NAME}`
  | `${AutoCompleteEnum.FAMILY_NAME}`
  | `${AutoCompleteEnum.GIVEN_NAME}`
  | `${AutoCompleteEnum.HONORIFIC_PREFIX}`
  | `${AutoCompleteEnum.HONORIFIC_SUFFIX}`
  | `${AutoCompleteEnum.IMPP}`
  | `${AutoCompleteEnum.LANGUAGE}`
  | `${AutoCompleteEnum.NAME}`
  | `${AutoCompleteEnum.NEW_PASSWORD}`
  | `${AutoCompleteEnum.NICKNAME}`
  | `${AutoCompleteEnum.OFF}`
  | `${AutoCompleteEnum.ONE_TIME_CODE}`
  | `${AutoCompleteEnum.ON}`
  | `${AutoCompleteEnum.ORGANIZATION_TITLE}`
  | `${AutoCompleteEnum.ORGANIZATION}`
  | `${AutoCompleteEnum.PHOTO}`
  | `${AutoCompleteEnum.POSTAL_CODE}`
  | `${AutoCompleteEnum.SEX}`
  | `${AutoCompleteEnum.STREET_ADDRESS}`
  | `${AutoCompleteEnum.TEL_AREA_CODE}`
  | `${AutoCompleteEnum.TEL_COUNTRY_CODE}`
  | `${AutoCompleteEnum.TEL_EXTENSION}`
  | `${AutoCompleteEnum.TEL_LOCAL_PREFIX}`
  | `${AutoCompleteEnum.TEL_LOCAL_SUFFIX}`
  | `${AutoCompleteEnum.TEL_LOCAL}`
  | `${AutoCompleteEnum.TEL_NATIONAL}`
  | `${AutoCompleteEnum.TEL}`
  | `${AutoCompleteEnum.TRANSACTION_AMOUNT}`
  | `${AutoCompleteEnum.TRANSACTION_CURRENCY}`
  | `${AutoCompleteEnum.URL}`
  | `${AutoCompleteEnum.USERNAME}`;
