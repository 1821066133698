import { StateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<StateApi>();

export class State {
  public constructor(props: ClassProperties<State>) {
    this.abbreviation = props.abbreviation;
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the State API model.
   */
  public static readonly Codec = io.type(
    {
      abbreviation: io.union([io.string, io.null]),
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'StateApi',
  );

  @api({ key: 'abbreviation' }) public readonly abbreviation: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a State object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized State object.
   * @throws An error if the value is not a valid State object.
   */
  public static deserialize(value: NonNullable<StateApi>): State {
    const decoded = decode(State.Codec, value);
    return new State(decoded);
  }

  /**
   * Deserializes a list of State objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized State objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid State objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<StateApi>>,
  ): readonly State[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of State objects.');
    }
    return values.map(State.deserialize);
  }
}
