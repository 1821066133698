<alleva-screen-lock *ngIf="isScreenLockedChanges | async"></alleva-screen-lock>

<ng-container *ngIf="isAuthenticatedChanges | async; else loggedOutTemplate">
  <alleva-layout-header></alleva-layout-header>
  <alleva-layout-navbar></alleva-layout-navbar>
  <alleva-layout-page-content [isLoading]="isLoadingChanges | async">
    <ng-container [ngTemplateOutlet]="pageContentTemplate"></ng-container>
  </alleva-layout-page-content>
  <alleva-layout-quick-launch-menu
    *ngIf="isQuickLaunchMenuEnabled"
  ></alleva-layout-quick-launch-menu>
  <alleva-layout-footer></alleva-layout-footer>
</ng-container>

<ng-template #loggedOutTemplate>
  <ng-container [ngTemplateOutlet]="pageContentTemplate"></ng-container>
</ng-template>

<ng-template #pageContentTemplate>
  <ng-container
    *ngIf="(isLoggingOutChanges | async) === false; else loadingTemplate"
  >
    <router-outlet></router-outlet>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <alleva-loading-indicator [isCentered]="true"></alleva-loading-indicator>
  </div>
</ng-template>
