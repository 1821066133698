import { firstValueFrom } from 'rxjs';
import { Client } from 'src/app/models';
import { getFirstNonEmptyValueFrom } from 'src/app/utilities';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AlertService } from '../root/alert.service';
import { CurrentClientService } from '../root/current-client.service';
import { ClientsService } from './clients.service';

@Injectable()
export class ClientResolverService {
  public constructor(
    private readonly alert: AlertService,
    private readonly clientsService: ClientsService,
    private readonly currentClientService: CurrentClientService,
    private readonly router: Router,
  ) {}

  public async resolve(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<Client | null> {
    const id = Number(next.params['id']);
    if (isNaN(id)) {
      return this.handleNotFound();
    }

    const client = await firstValueFrom(this.clientsService.get(id));
    if (!client) {
      return this.handleNotFound();
    }

    // Set the currently selected client.
    this.currentClientService.selected = client;
    await getFirstNonEmptyValueFrom(this.currentClientService.selectedChanges);

    return client;
  }

  private handleNotFound(): null {
    this.alert.error({ message: `Invalid client, please try again.` });
    const clientsListRoute: PageRoute = '/clients/list';
    this.router.navigate([clientsListRoute]);
    return null;
  }
}
