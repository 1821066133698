import { AuthResponseApi, AuthResponseUserDataApi } from 'api/models';
import * as io from 'io-ts';
import { Permission, Permissions } from 'src/app/constants';
import { apiDecorator } from 'src/app/decorators';
import { decode, getFullName, isNumber } from 'src/app/utilities';

import { Facility, FacilityBase } from 'src/app/models/facility/facility.model';
import { User } from 'src/app/models/user/user.model';

const authResponseApi = apiDecorator<AuthResponseApi>();
const authResponseUserDataApi = apiDecorator<AuthResponseUserDataApi>();

export class AuthenticatedUser {
  public constructor(props: ClassProperties<AuthenticatedUserArgs>) {
    // User values and information provided by the auth server.
    this.accessToken = props.accessToken;
    this.applicationUserId = props.applicationUserId;
    this.email = props.email;
    this.firstName = props.firstName;
    this.id = props.id;
    this.idToken = props.idToken;
    this.lastName = props.lastName;
    this.userName = props.userName;

    // User values and information supplied by the EMR server.
    this.active = props.active;
    this.defaultFacility = props.defaultFacility;
    this.facilities = props.facilities;
    this.hasPin = props.hasPin;
    this.image = props.image;
    this.isSupervisor = props.isSupervisor;
    this.licenses = props.licenses;
    this.npi = props.npi;
    this.permissions = props.permissions;
    this.phone = props.phone;
    this.role = props.role;
    this.settings = props.settings;
    this.userGuid = props.guid;

    // Properties set from the Alleva auth token.
    this.clientTenantId = props.clientTenantId;

    // Calculated properties.
    this.fullName =
      getFullName({ first: this.firstName, last: this.lastName }) ?? 'N/A';

    this.shortName = this.firstName?.charAt(0)
      ? `${this.firstName.charAt(0)}. ${this.lastName ?? ''}`
      : this.lastName ?? 'N/A';

    const activeLicenses = this.licenses
      ?.filter((license) => license.active && license.isCurrent)
      // Sort by `order` key of type `number` ascending.
      ?.sort((prev, next) => prev.order - next.order);

    this.activeLicenseAcronyms =
      activeLicenses?.map((license) => license.acronym).join(', ') ?? null;
  }

  // User values and information provided by the auth server.

  @authResponseApi({ key: 'accessToken' }) public readonly accessToken: string;
  @authResponseUserDataApi({ key: 'applicationUserId' })
  public readonly applicationUserId: string;
  @authResponseUserDataApi({ key: 'client_tenant_id' })
  public readonly clientTenantId: string;
  @authResponseUserDataApi({ key: 'email' }) public readonly email: string;
  @authResponseUserDataApi({ key: 'firstName' }) public readonly firstName:
    | string
    | null;
  @authResponseUserDataApi({ key: 'RehabUserId' }) public readonly id: number;
  @authResponseApi({ key: 'idToken' }) public readonly idToken: string;
  @authResponseUserDataApi({ key: 'lastName' }) public readonly lastName:
    | string
    | null;
  @authResponseUserDataApi({
    key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  })
  public readonly userName: string;

  // User values and information supplied by the EMR server.

  @User.api({ key: 'active' }) public readonly active: User['active'];
  @User.api({ key: 'defaultFacility' })
  public readonly defaultFacility: Facility;
  @User.api({ key: 'facilities' })
  public readonly facilities: readonly FacilityBase[];
  @User.api({ key: 'hasPin' }) public readonly hasPin: User['hasPin'];
  @User.api({ key: 'image' }) public readonly image: User['image'];
  @User.api({ key: 'supervisor' })
  public readonly isSupervisor: User['isSupervisor'];
  @User.api({ key: 'licenses' }) public readonly licenses: User['licenses'];
  @User.api({ key: 'npi' }) public readonly npi: User['npi'];
  @User.api({ key: 'permissions' })
  public readonly permissions: User['permissions'];
  @User.api({ key: 'phone' }) public readonly phone: User['phone'];
  @User.api({ key: 'role' }) public readonly role: User['role'];
  @User.api({ key: 'settings' }) public readonly settings: User['settings'];
  @User.api({ key: 'guid' }) public readonly userGuid: string;

  // Calculated properties.
  public readonly activeLicenseAcronyms: string | null;
  public readonly fullName: string;
  public readonly shortName: string;

  /**
   * Deserializes a Authenticated User object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Authenticated User object.
   * @throws An error if the value is not a valid Authenticated User object.
   */
  public static deserialize(
    authResponseApi: NonNullable<AuthResponseApi>,
    emrUser: UserArgs,
  ): AuthenticatedUser {
    const authResponseDecoded = decode(authResponseCodec, authResponseApi);
    const userId: number | undefined = isNumber(
      authResponseApi.userData.RehabUserId,
    )
      ? Number(authResponseApi.userData.RehabUserId)
      : undefined;

    if (!userId) {
      throw new Error('Invalid user id provided by the auth server.');
    }

    return new AuthenticatedUser({
      accessToken: authResponseDecoded.accessToken,
      active: emrUser.active,
      applicationUserId: authResponseDecoded.userData.applicationUserId,
      clientTenantId: authResponseDecoded.userData.client_tenant_id,
      defaultFacility: Facility.deserialize({
        ...emrUser.defaultFacility,
        settings: {
          defaultUnits: emrUser.defaultFacility.settings.measurementSystem,
        },
      }),
      email: authResponseDecoded.userData.email,
      facilities: FacilityBase.deserializeList(emrUser.facilities),
      firstName: emrUser.firstName ?? authResponseDecoded.userData.firstName,
      guid: emrUser.guid,
      hasPin: emrUser.hasPin,
      id: userId,
      idToken: authResponseDecoded.idToken,
      image: emrUser.image,
      isSupervisor: emrUser.isSupervisor,
      lastName: emrUser.lastName ?? authResponseDecoded.userData.lastName,
      licenses: emrUser.licenses,
      npi: emrUser.npi,
      phone: emrUser.phone,
      role: emrUser.role,
      permissions: emrUser.permissions,
      settings: emrUser.settings,
      userName:
        authResponseDecoded.userData[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
        ],
      userGuid: emrUser.guid,
    });
  }

  /**
   * Check whether the user has access to the given permission.
   *
   * @param permission The permission to check for.
   * @returns Whether the user has the associated permission.
   */
  public hasPermission(permissions: Permission | Permissions): boolean {
    if (this.role.isAdmin) {
      // User is admin, allow all permissions.
      return true;
    }

    if (Array.isArray(permissions) && permissions.length === 0) {
      // No permissions to check, return true.
      return true;
    }

    if (typeof permissions === 'string') {
      // If the user has the permission, return true.
      return this.permissions?.includes(permissions) ?? false;
    }

    // If the user has at least one permission in the array, return true.
    return permissions.some((permission) => this.hasPermission(permission));
  }
}

type UserArgs = Omit<
  User,
  // We already have these values in the Authenticated User response, so we
  // can omit them as arguments.
  'id' | 'fullName' | 'name'
>;

type AuthenticatedUserArgs = Omit<
  ClassProperties<AuthenticatedUser>,
  // Omit computed properties set on construct from outside data.
  'activeLicenseAcronyms' | 'fullName' | 'shortName'
> &
  UserArgs;

/**
 * The io-ts codec for runtime type checking of the Auth Response User Data
 * model.
 */
const authResponseUserDataCodec = io.type(
  {
    applicationUserId: io.string,
    /** @todo - Update to camel case. Pending auth team changes. */
    client_tenant_id: io.string,
    email: io.string,
    firstName: io.string,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': io.string,
    lastName: io.string,
    /**
     * @todo - Update to a number type. Pending auth team changes.
     * @todo - Update to camel case. Pending auth team changes.
     */
    RehabUserId: io.string,
    sub: io.string,
  },
  'AuthResponseUserDataApi',
);

/**
 * The io-ts codec for runtime type checking of the Auth Response model.
 */
export const authResponseCodec = io.type(
  {
    accessToken: io.string,
    configId: io.string,
    idToken: io.string,
    isAuthenticated: io.boolean,
    userData: authResponseUserDataCodec,
  },
  'AuthResponseApi',
);
