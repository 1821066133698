import * as io from 'io-ts';

/**
 * A dynamic, reusable codec for paged list data with API results of type `T`.
 *
 * @param resultsArrayCodec The codec for the expected paged "results" typing.
 * @param typeName String representation of `T`.
 * @returns The codec for the expected paged list typing.
 */
export const PagedListCodec = <T>(
  resultsArrayCodec: io.Type<T>,
  typeName: string,
): PagedListCodecType<T> =>
  io.type(
    {
      currentCount: io.number,
      currentPage: io.number,
      pageSize: io.number,
      results: io.readonlyArray(resultsArrayCodec),
      totalCount: io.number,
      totalPages: io.number,
    },
    typeName,
  );
