import { NgModule } from '@angular/core';

import { AuthBlobPipe } from 'src/app/pipes/auth-blob.pipe';
import { DateTimePipe } from 'src/app/pipes/date-time.pipe';
import { FilterAllPipe } from 'src/app/pipes/filter-all.pipe';
import { FilterStringsPipe } from 'src/app/pipes/filter-string.pipe';
import { FirstLetterEveryWordPipe } from 'src/app/pipes/first-letter-every-word.pipe';
import { MaskPipe } from 'src/app/pipes/mask.pipe';
import { TrueFalsePipe } from 'src/app/pipes/true-false.pipe';
import { YesNoPipe } from 'src/app/pipes/yes-no.pipe';

@NgModule({
  declarations: [
    AuthBlobPipe,
    DateTimePipe,
    FilterAllPipe,
    FilterStringsPipe,
    FirstLetterEveryWordPipe,
    MaskPipe,
    TrueFalsePipe,
    YesNoPipe,
  ],
  exports: [
    AuthBlobPipe,
    DateTimePipe,
    FilterAllPipe,
    FilterStringsPipe,
    FirstLetterEveryWordPipe,
    MaskPipe,
    TrueFalsePipe,
    YesNoPipe,
  ],
})
export class PipesModule {}
