import { ClientCOWSApi, ClientCOWSUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import {
  ClientCOWSAssessment,
  ClientCOWSAssessmentUpdate,
} from 'src/app/models/client/client-cows-assessment.model';
import {
  ClientCOWSCreator,
  ClientCOWSCreatorUpdate,
} from 'src/app/models/client/client-cows-creator.model';
import {
  ClientCOWSReviewer,
  ClientCOWSReviewerUpdate,
} from 'src/app/models/client/client-cows-reviewer.model';
import {
  ClientCOWSVitals,
  ClientCOWSVitalsUpdate,
} from 'src/app/models/client/client-cows-vitals.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientCOWSApi>();
const apiUpdate = apiDecorator<ClientCOWSUpdateApi>();

abstract class ClientCOWSBase {
  public constructor(props: ClassProperties<ClientCOWSBase>) {
    this.date = props.date;
    this.detoxDate = props.detoxDate;
    this.notes = props.notes;
  }

  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'detoxDate' }) public readonly detoxDate: DateTime | null;
  @api({ key: 'notes' }) public readonly notes: string | null;
}

/**
 * The Client COWS ("Clinical Opiates Withdrawals Scale") model.
 */
export class ClientCOWS extends ClientCOWSBase {
  public constructor(props: ClassProperties<ClientCOWS>) {
    super(props);

    this.administered = props.administered;
    this.assessment = props.assessment;
    this.createdBy = props.createdBy;
    this.id = props.id;
    this.reviewers = props.reviewers;
    this.scale = props.scale;
    this.score = props.score;
    this.vitals = props.vitals;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA-B API model.
   */
  public static readonly Codec = io.type(
    {
      administered: io.union([ClientCOWSReviewer.Codec, io.undefined]),
      assessment: ClientCOWSAssessment.Codec,
      createdBy: ClientCOWSCreator.Codec,
      date: io.string,
      detoxDate: io.union([io.string, io.null]),
      id: io.number,
      notes: io.union([io.string, io.null]),
      reviewers: io.union([io.array(ClientCOWSReviewer.Codec), io.null]),
      scale: io.union([io.string, io.null]),
      score: io.number,
      vitals: io.union([ClientCOWSVitals.Codec, io.null]),
    },
    'ClientCOWSApi',
  );

  @api({ key: 'administered' })
  public readonly administered: ClientCOWSReviewer | null;
  @api({ key: 'assessment' })
  public readonly assessment: ClientCOWSAssessment;
  @api({ key: 'createdBy' })
  public readonly createdBy: ClientCOWSCreator;
  @api({ key: 'id' })
  public readonly id: number;
  @api({ key: 'reviewers' })
  public readonly reviewers: readonly ClientCOWSReviewer[] | null;
  @api({ key: 'scale' })
  public readonly scale: string | null;
  @api({ key: 'score' })
  public readonly score: number;
  @api({ key: 'vitals' })
  public readonly vitals: ClientCOWSVitals | null;

  /**
   * Deserializes a Client CIWA-B object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B object.
   * @throws An error if the value is not a valid Client CIWA-B object.
   */
  public static deserialize(
    value: NonNullable<ClientCOWSApi>,
    deserializationArgs: ClientCOWSDeserializationArgs,
  ): ClientCOWS {
    const decoded = decode(ClientCOWS.Codec, value);
    return new ClientCOWS({
      administered: decoded.administered
        ? ClientCOWSReviewer.deserialize(
            decoded.administered,
            deserializationArgs,
          )
        : null,
      assessment: ClientCOWSAssessment.deserialize({
        ...decoded.assessment,
        pulse: decoded.vitals?.pulse ?? null,
      }),
      createdBy: ClientCOWSCreator.deserialize(
        decoded.createdBy,
        deserializationArgs,
      ),
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      detoxDate: decoded.detoxDate
        ? DateTime.fromISO(decoded.detoxDate, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
      id: decoded.id,
      notes: decoded.notes ?? null,
      reviewers: decoded.reviewers
        ? decoded.reviewers.map((reviewer) =>
            ClientCOWSReviewer.deserialize(reviewer, deserializationArgs),
          )
        : null,
      scale: decoded.scale ?? null,
      score: decoded.score,
      vitals: decoded.vitals
        ? ClientCOWSVitals.deserialize(decoded.vitals, deserializationArgs)
        : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA-B objects from an API/unknown model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA-B objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<unknown>>,
    deserializationArgs: ClientCOWSDeserializationArgs,
  ): readonly ClientCOWS[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA-B objects.');
    }
    return values.map((clientCOWS) =>
      ClientCOWS.deserialize(clientCOWS, deserializationArgs),
    );
  }
}

export class ClientCOWSUpdate extends ClientCOWSBase {
  public constructor(props: ClassProperties<ClientCOWSUpdate>) {
    super(props);

    this.administered = props.administered;
    this.createdBy = props.createdBy;
    this.reviewers = props.reviewers;
    this.assessment = props.assessment;
    this.vitals = props.vitals;
  }

  @apiUpdate({ key: 'administered' })
  public readonly administered: ClientCOWSReviewerUpdate;
  @apiUpdate({ key: 'assessment' })
  public readonly assessment: ClientCOWSAssessmentUpdate;
  @apiUpdate({ key: 'createdBy' })
  public readonly createdBy: ClientCOWSCreatorUpdate;
  @apiUpdate({ key: 'reviewers' }) public readonly reviewers:
    | readonly ClientCOWSReviewerUpdate[]
    | null;
  @apiUpdate({ key: 'vitals' }) public readonly vitals: ClientCOWSVitalsUpdate;

  public serialize(): ClientCOWSUpdateApi {
    return {
      administered: this.administered.serialize(),
      assessment: this.assessment.serialize(),
      createdBy: this.createdBy.serialize(),
      date: this.date.toISO()!,
      detoxDate: this.detoxDate?.toISO() ?? null,
      notes: this.notes,
      reviewers:
        this.reviewers?.map((reviewer) => reviewer.serialize()) ?? null,
      scale: this.assessment.scale,
      score: this.assessment.score,
      vitals: this.vitals.serialize(),
    };
  }
}

export interface ClientCOWSDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
