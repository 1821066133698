import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuService } from 'src/app/services';

import { Component, HostBinding, OnInit } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'alleva-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss'],
})
export class LayoutFooterComponent implements OnInit {
  public constructor(private readonly menuService: MenuService) {}

  @HostBinding('class.menu-collapsed') public isCollapsed = false;

  public readonly currentYear = new Date().getFullYear();

  public ngOnInit(): void {
    this.menuService.isCollapsedChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isCollapsed = value;
      });
  }
}
