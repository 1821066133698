<p *ngIf="data.message as message" [innerHTML]="message"></p>

<div class="actions">
  <alleva-button
    (click)="onClose()"
    *ngIf="data.display?.cancel"
    [label]="data.labels?.cancel || 'Cancel'"
    styling="stroked"
    >{{ data.labels?.cancel || 'Cancel' }}</alleva-button
  >

  <alleva-button
    (click)="onClose(false)"
    *ngIf="data.display?.reject"
    [label]="data.labels?.reject || 'No'"
    color="warn"
    styling="flat"
    >{{ data.labels?.reject || 'No' }}</alleva-button
  >

  <alleva-button
    (click)="onClose(true)"
    *ngIf="data.display?.confirm"
    [label]="data.labels?.confirm || 'Yes'"
    color="accent"
    styling="flat"
    >{{ data.labels?.confirm || 'Yes' }}</alleva-button
  >
</div>
