<div class="inputs-container">
  <mat-form-field [appearance]="'outline'" class="arm">
    <mat-label>Arm</mat-label>
    <div class="prefix-icon-container">
      <alleva-icon
        class="prefix-icon"
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="hasErrors ? 'warn' : 'primary'"
        name="arm_position"
      ></alleva-icon>
    </div>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['arm']"
        [id]="armId"
        [multiple]="false"
        [required]="required"
        name="Arm"
      >
        <mat-option [value]="BloodPressureArmEnum.LEFT">
          {{ BloodPressureArmEnum.LEFT }}
        </mat-option>
        <mat-option [value]="BloodPressureArmEnum.RIGHT">
          {{ BloodPressureArmEnum.RIGHT }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" class="position">
    <mat-label>Position</mat-label>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['position']"
        [id]="positionId"
        [multiple]="false"
        [required]="required"
        name="Position"
      >
        <mat-option [value]="BloodPressurePositionEnum.LYING">
          {{ BloodPressurePositionEnum.LYING }}
        </mat-option>
        <mat-option [value]="BloodPressurePositionEnum.SITTING">
          {{ BloodPressurePositionEnum.SITTING }}
        </mat-option>
        <mat-option [value]="BloodPressurePositionEnum.STANDING">
          {{ BloodPressurePositionEnum.STANDING }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>
<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div class="errors-container">
    <!-- ARM INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlArm.invalid && ctrlArm.touched">
      <mat-error>
        <ng-container *ngIf="ctrlArm.hasError('required')">
          Arm is required.
        </ng-container>
      </mat-error>
    </ng-container>
    <!-- POSITION INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlPosition.invalid && ctrlPosition.touched">
      <mat-error *ngIf="ctrlArm.valid || ctrlArm.untouched">
        <ng-container *ngIf="ctrlPosition.hasError('required')">
          Position is required.
        </ng-container>
      </mat-error>
    </ng-container>
  </div>
</ng-template>
