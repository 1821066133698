import { NgModule } from '@angular/core';

import { AttributesDirective } from 'src/app/directives/attributes.directive';
import { HasPermissionDirective } from 'src/app/directives/has-permission.directive';

@NgModule({
  declarations: [AttributesDirective, HasPermissionDirective],
  exports: [AttributesDirective, HasPermissionDirective],
})
export class DirectivesModule {}
