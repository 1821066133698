import { ALLEVA_PAGES } from 'src/app/constants/alleva-pages';

/**
 * The name of an Alleva page based on the route. This is essentially a
 * reversed Record of the "ALLEVA_PAGES" constant for retrieving the page name
 * based on the route.
 */
export const PAGE_NAMES_BY_ROUTE: Record<PageRoute, PageName> = Object.entries(
  ALLEVA_PAGES,
).reduce<Record<PageRoute, PageName>>(
  (acc, [pageName, pageRoute]) => {
    acc[pageRoute] = pageName as PageName;
    return acc;
  },
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as Record<PageRoute, PageName>,
);
