import { PronounsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<PronounsApi>();

export class Pronouns {
  public constructor(props: ClassProperties<Pronouns>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Pronouns Api model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'PronounsApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Pronouns object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Pronouns object.
   * @throws An error if the value is not a valid Pronouns object.
   */
  public static deserialize(value: NonNullable<PronounsApi>): Pronouns {
    const decoded = decode(Pronouns.Codec, value);
    return new Pronouns({
      ...decoded,
    });
  }

  /**
   * Deserializes a list of Pronouns objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Pronouns objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Pronouns objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<PronounsApi>>,
  ): readonly Pronouns[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Pronouns objects.');
    }
    return values.map(Pronouns.deserialize);
  }
}
