import { UserApi, UserBaseApi } from 'api/models';
import * as io from 'io-ts';
import { Permission, decodePermissions } from 'src/app/constants';
import { apiDecorator } from 'src/app/decorators';
import { decode, getFullName } from 'src/app/utilities';

import { Facility, FacilityBase } from 'src/app/models/facility/facility.model';
import { Name } from 'src/app/models/user/name.model';
import { Role } from 'src/app/models/user/role.model';
import { UserLicense } from 'src/app/models/user/user-license.model';

const api = apiDecorator<UserBaseApi>();

type UserArgs = Omit<
  User,
  // Omit computed properties set on construct from outside data.
  'firstName' | 'lastName' | 'fullName'
>;

type UserBaseArgs = Omit<
  UserBase,
  // Omit computed properties set on construct from outside data.
  'firstName' | 'lastName' | 'fullName'
>;

export class UserBase {
  public constructor(props: ClassProperties<UserBaseArgs>) {
    this.active = props.active;
    this.id = props.id;
    this.image = props.image;
    this.isSupervisor = props.isSupervisor;
    this.licenses = props.licenses;
    this.name = props.name;
    this.npi = props.npi;
    this.phone = props.phone;

    // Computed properties.
    this.firstName = props.name?.first ?? null;
    this.lastName = props.name?.last ?? null;
    this.fullName = this.name ? getFullName(this.name) ?? 'N/A' : 'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the User model.
   */
  public static readonly BaseCodec = io.type(
    {
      active: io.boolean,
      id: io.number,
      image: io.union([io.string, io.null]),
      licenses: io.union([io.array(UserLicense.Codec), io.null]),
      name: io.union([Name.Codec, io.null]),
      npi: io.union([io.string, io.null]),
      phone: io.union([io.string, io.null]),
      supervisor: io.boolean,
    },
    'UserBaseApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'supervisor' }) public readonly isSupervisor: boolean;
  @api({ key: 'licenses' }) public readonly licenses:
    | readonly UserLicense[]
    | null;
  @api({ key: 'name' }) public readonly name: Name | null;
  @api({ key: 'npi' }) public readonly npi: string | null;
  @api({ key: 'phone' }) public readonly phone: string | null;

  // Computed properties.
  public readonly firstName: string | null;
  public readonly fullName: string;
  public readonly lastName: string | null;

  /**
   * Deserializes a Client User Base object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client User Base object.
   * @throws An error if the value is not a valid Client User Base object.
   */
  public static deserialize(
    value: NonNullable<UserBaseApi>,
    deserializationArgs?: UserBaseDeserializationArgs,
  ): UserBase {
    const decoded = decode(UserBase.BaseCodec, value);
    return new UserBase({
      ...decoded,
      isSupervisor: decoded.supervisor,
      licenses: decoded.licenses
        ? UserLicense.deserializeList(decoded.licenses, {
            facilityTimeZone: deserializationArgs?.facilityTimeZone ?? null,
          })
        : null,
      name: decoded.name ? Name.deserialize(decoded.name) : null,
      npi: decoded.npi,
    });
  }

  /**
   * Deserializes a list of Client User Base objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client User Base objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client User Base
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<UserBaseApi>>,
    deserializationArgs?: UserBaseDeserializationArgs,
  ): readonly UserBase[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of User Base objects.');
    }
    return values.map((userBase) =>
      UserBase.deserialize(userBase, deserializationArgs),
    );
  }

  /**
   * Determine if the item is an instance of the model.
   *
   * @param item The item to check the instance of.
   * @returns True if the item is an instance of the model, otherwise false.
   */
  public static isInstanceFrom(item: unknown): item is UserBase {
    if (item instanceof UserBase) {
      // Item is an instance of the model.
      return true;
    } else if (!item || !(typeof item === 'object')) {
      // Is not an object or is null.
      return false;
    }
    // Check if all keys are present in the item.
    return baseModelPropertyKeys.every((property) => property in item);
  }

  /**
   * A pure function that returns the unique identifier for a UserBase. This is
   * specifically used for the `trackBy` function in Angular's `ngFor` directive
   * to improve rendering performance when a list of UserBase's is rendered.
   */
  public static trackBy(_index: number, userBase: UserBase): number {
    return userBase.id;
  }
}

export class User extends UserBase {
  public constructor(props: ClassProperties<UserArgs>) {
    super(props);

    this.defaultFacility = props.defaultFacility;
    this.facilities = props.facilities;
    this.guid = props.guid;
    this.hasPin = props.hasPin;
    this.permissions = props.permissions;
    this.role = props.role;
    this.settings = props.settings;
  }

  public static readonly api = apiDecorator<UserApi>();

  /**
   * The io-ts codec for runtime type checking of the User model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      defaultFacility: Facility.Codec,
      facilities: io.array(FacilityBase.BaseCodec),
      guid: io.string,
      hasPin: io.boolean,
      id: io.number,
      image: io.union([io.string, io.null]),
      licenses: io.union([io.array(UserLicense.Codec), io.null]),
      name: io.union([Name.Codec, io.null]),
      npi: io.union([io.string, io.null]),
      phone: io.union([io.string, io.null]),
      supervisor: io.boolean,
      role: Role.Codec,
      permissions: io.union([
        // Less restrictive codec for permissions.
        // We'll validate the permissions on deserialize.
        io.array(io.string),
        io.null,
      ]),
      settings: io.type({
        isInsightsEnabled: io.boolean,
      }),
    },
    'UserApi',
  );

  @User.api({ key: 'defaultFacility' })
  public readonly defaultFacility: Facility;
  @User.api({ key: 'facilities' })
  public readonly facilities: readonly FacilityBase[];
  @User.api({ key: 'guid' }) public readonly guid: string;
  @User.api({ key: 'hasPin' }) public readonly hasPin: boolean;
  @User.api({ key: 'permissions' }) public readonly permissions:
    | readonly Permission[]
    | null;
  @User.api({ key: 'role' }) public readonly role: Role;
  @User.api({ key: 'settings' }) public readonly settings: UserSettings;

  /**
   * Deserializes a User object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized User object.
   * @throws An error if the value is not a valid User object.
   */
  public static override deserialize(value: NonNullable<UserApi>): User {
    const decoded = decode(User.Codec, value);
    const defaultFacility = Facility.deserialize(decoded.defaultFacility);
    const permissions = decodePermissions(decoded.permissions);
    return new User({
      active: decoded.active,
      defaultFacility,
      facilities: FacilityBase.deserializeList(decoded.facilities),
      guid: decoded.guid,
      hasPin: decoded.hasPin,
      id: decoded.id,
      image: decoded.image,
      isSupervisor: decoded.supervisor,
      licenses: decoded.licenses
        ? UserLicense.deserializeList(decoded.licenses, {
            facilityTimeZone: defaultFacility.timeZone,
          })
        : null,
      name: decoded.name ? Name.deserialize(decoded.name) : null,
      npi: decoded.npi ?? null,
      permissions,
      phone: decoded.phone,
      role: Role.deserialize(decoded.role),
      settings: { isInsightsEnabled: decoded.settings.isInsightsEnabled },
    });
  }

  /**
   * Deserializes a list of User objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized User objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid User objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<UserApi>>,
  ): readonly User[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of User objects.');
    }
    return values.map(User.deserialize);
  }
}

abstract class UserSettings {
  public constructor(props: ClassProperties<UserSettings>) {
    this.isInsightsEnabled = props.isInsightsEnabled;
  }

  public readonly isInsightsEnabled: boolean;
}

const baseModelPropertyKeys: ReadonlyArray<keyof UserBase> = [
  'active',
  'firstName',
  'fullName',
  'id',
  'image',
  'isSupervisor',
  'lastName',
  'licenses',
  'name',
  'npi',
  'phone',
];

export interface UserBaseDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
