<ng-container *ngIf="isDisplayed">
  <alleva-menu-category-item
    *ngIf="categoryMenuItem as item"
    [class.collapsed]="isCollapsedChanges | async"
    [currentFacility]="currentFacilityChanges | async"
    [currentUser]="currentUserChanges | async"
    [categoryMenuItem]="item"
    [isCollapsed]="isCollapsedChanges | async"
    [selectedClient]="selectedClientChanges | async"
  ></alleva-menu-category-item>
  <alleva-menu-top-level-item
    *ngIf="topLevelMenuItem as item"
    [class.collapsed]="isCollapsedChanges | async"
    [currentFacility]="currentFacilityChanges | async"
    [currentUser]="currentUserChanges | async"
    [isCollapsed]="isCollapsedChanges | async"
    [selectedClient]="selectedClientChanges | async"
    [topLevelMenuItem]="item"
  ></alleva-menu-top-level-item>
</ng-container>
