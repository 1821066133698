import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClientStatusBadgeKeyDisplayComponent } from 'src/app/components/client/status/badge-key-display/client-status-badge-key-display.component';
import { IconModule } from 'src/app/components/icon/icon.module';

@NgModule({
  declarations: [ClientStatusBadgeKeyDisplayComponent],
  exports: [ClientStatusBadgeKeyDisplayComponent],
  imports: [CommonModule, IconModule],
  providers: [],
})
export class ClientStatusBadgeKeyDisplayModule {}
