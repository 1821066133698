import { ClientContactReferralApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientContactReferralContact } from 'src/app/models/client/client-contact-referral-contact.model';
import { NameId } from 'src/app/models/core/name-id.model';

const api = apiDecorator<ClientContactReferralApi>();

type ClientContactReferralArgs = Omit<
  ClassProperties<ClientContactReferral>,
  // Omit computed properties set on construct from outside data.
  'companyName' | 'email' | 'fullName' | 'phoneNumber'
>;

/**
 * The Client Contact referral model.
 */
export class ClientContactReferral {
  public constructor(props: ClassProperties<ClientContactReferralArgs>) {
    this.company = props.company;
    this.contact = props.contact;

    // Computed values.
    this.companyName = props.company?.name ?? 'N/A';
    this.email = props.contact?.email ?? 'N/A';
    this.fullName = props.contact?.name ?? 'N/A';
    this.phoneNumber =
      props.contact?.phone.mobile ??
      props.contact?.phone.office ??
      props.contact?.phone.other ??
      'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the Client Contact referral
   * API model.
   */
  public static readonly Codec = io.type(
    {
      company: io.union([NameId.Codec, io.null]),
      contact: io.union([ClientContactReferralContact.Codec, io.null]),
    },
    'ClientContactReferralApi',
  );

  @api({ key: 'company' })
  public readonly company: NameId | null;
  @api({ key: 'contact' })
  public readonly contact: ClientContactReferralContact | null;

  // Computed values.
  public readonly companyName: string;
  public readonly email: string;
  public readonly fullName: string;
  public readonly phoneNumber: string;

  /**
   * Deserializes a Client Contact Referral object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Contact Referral object.
   * @throws An error if the value is not a valid Client Contact Referral
   * object.
   */
  public static deserialize(
    value: NonNullable<ClientContactReferralApi>,
  ): ClientContactReferral {
    const decoded = decode(ClientContactReferral.Codec, value);
    return new ClientContactReferral({
      company: decoded.company ? NameId.deserialize(decoded.company) : null,
      contact: decoded.contact
        ? ClientContactReferralContact.deserialize(decoded.contact)
        : null,
    });
  }

  /**
   * Deserializes a list of Client Contact Referral objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Contact Referral objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Contact
   * Referral objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientContactReferralApi>>,
  ): readonly ClientContactReferral[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Contact Referral objects.');
    }
    return values.map(ClientContactReferral.deserialize);
  }
}
