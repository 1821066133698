export function getFahrenheitFrom({
  celsius,
  kelvin,
}: {
  celsius?: number;
  kelvin?: number;
} = {}): number {
  if (celsius !== undefined) {
    return (celsius * 9) / 5 + 32;
  }
  if (kelvin !== undefined) {
    return ((kelvin - 273.15) * 9) / 5 + 32;
  }
  throw new Error('No temperature value provided.');
}

export function getCelsiusFrom({
  fahrenheit,
  kelvin,
}: {
  fahrenheit?: number;
  kelvin?: number;
} = {}): number {
  if (fahrenheit !== undefined) {
    return ((fahrenheit - 32) * 5) / 9;
  }
  if (kelvin !== undefined) {
    return kelvin - 273.15;
  }
  throw new Error('No temperature value provided.');
}

export function getKelvinFrom({
  celsius,
  fahrenheit,
}: {
  celsius?: number;
  fahrenheit?: number;
} = {}): number {
  if (celsius !== undefined) {
    return celsius + 273.15;
  }
  if (fahrenheit !== undefined) {
    return ((fahrenheit - 32) * 5) / 9 + 273.15;
  }
  throw new Error('No temperature value provided.');
}
