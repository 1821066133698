import { AuthenticationService, FacilityService } from 'src/app/services';
import { getFirstNonEmptyValueFrom, isPageRoute } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

export function facilityRedirectGuard(): CanActivateFn {
  return async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> => {
    const authenticationService = inject(AuthenticationService);
    const facilityService = inject(FacilityService);
    const router = inject(Router);

    const user = await getFirstNonEmptyValueFrom(
      authenticationService.userChanges,
    );

    if (!facilityService.initialized) {
      facilityService.currentFacility = user.defaultFacility;
    }

    const activeFacility = await getFirstNonEmptyValueFrom(
      facilityService.currentFacilityChanges,
    );

    const facilityRoute = `/facility/${activeFacility.id.toString()}`;

    if (isPageRoute(state.url)) {
      const pageRoute = `${facilityRoute}${state.url}`;
      return router.parseUrl(pageRoute);
    }

    if (state.url === '/') {
      const homeRoute: PageRoute = config.isLegacyRoutingEnabled
        ? '/clients/list'
        : '/dashboard';
      const pageRoute = `${facilityRoute}${homeRoute}`;
      return router.parseUrl(pageRoute);
    }

    return router.parseUrl('/not-found' satisfies PageRoute);
  };
}
