import { FileAttachmentApi, FileAttachmentUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';
import { config } from 'src/configs/config';

const api = apiDecorator<FileAttachmentApi>();

abstract class FileAttachmentBase {
  public constructor(props: ClassProperties<FileAttachmentBase>) {
    this.fileName = props.fileName;
    this.path = config.isProduction
      ? `${config.apiDocuments}/attachments/${props.path}`
      : props.path;
  }

  @api({ key: 'fileName' }) public readonly fileName: string;
  @api({ key: 'path' }) public readonly path: string;
}

export class FileAttachment extends FileAttachmentBase {
  public constructor(props: ClassProperties<FileAttachment>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the File Attachment API
   * model.
   */
  public static readonly Codec = io.type(
    {
      clientDocumentId: io.number,
      fileName: io.string,
      path: io.string,
    },
    'FileAttachmentApi',
  );

  @api({ key: 'clientDocumentId' }) public readonly id: number;

  /**
   * Deserializes a File Attachment object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized File Attachment object.
   * @throws An error if the value is not a valid File Attachment object.
   */
  public static deserialize(
    value: NonNullable<FileAttachmentApi>,
  ): FileAttachment {
    const decoded = decode(FileAttachment.Codec, value);
    return new FileAttachment({
      ...decoded,
      id: decoded.clientDocumentId,
    });
  }

  /**
   * Deserializes a list of File Attachment objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized File Attachment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid File Attachment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<FileAttachmentApi>>,
  ): readonly FileAttachment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of File Attachment objects.');
    }
    return values.map(FileAttachment.deserialize);
  }
}

export class FileAttachmentUpdate extends FileAttachmentBase {
  public serialize(): FileAttachmentUpdateApi {
    return {
      fileName: this.fileName,
      path: this.path,
    };
  }
}
