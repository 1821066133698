<mat-form-field [appearance]="'outline'" (click)="onContainerClick($event)">
  <mat-label *ngIf="label as inputLabel">{{ inputLabel }}</mat-label>
  <input
    *ngIf="baseControl as formControl; else loadingControl"
    [formControl]="formControl"
    [matDatepicker]="picker"
    [max]="max"
    [min]="min"
    [placeholder]="placeholder"
    autocomplete="off"
    matInput
  />
  <ng-template #loadingControl>
    Loading{{ label ? ' ' + label + ' ' : '' }}...
  </ng-template>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('min')">
      {{ label }} must be at least
      {{ baseControl.errors?.['min'].min | dateTime }}.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('max')">
      {{ label }} must be at most
      {{ baseControl.errors?.['max'].max | dateTime }}.
    </ng-container>
  </mat-error>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    color="accent"
    [startAt]="startAt || baseControl.value || null"
  ></mat-datepicker>
</mat-form-field>
