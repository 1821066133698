import {
  ClientCIWABAssessmentApi,
  ClientCIWABAssessmentUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientCIWABAssessmentApi>();

type ClientCIWABAssessmentArgs = Omit<
  ClassProperties<ClientCIWABAssessment>,
  // Omit computed properties set on construct from outside data.
  'score'
>;

export class ClientCIWABAssessment {
  public constructor(props: ClassProperties<ClientCIWABAssessmentArgs>) {
    this.anxiety = props.anxiety;
    this.appetite = props.appetite;
    this.concentration = props.concentration;
    this.fatigue = props.fatigue;
    this.fear = props.fear;
    this.headache = props.headache;
    this.insomnia = props.insomnia;
    this.irritability = props.irritability;
    this.mood = props.mood;
    this.muscle = props.muscle;
    this.palpitations = props.palpitations;
    this.restfulness = props.restfulness;
    this.restlessness = props.restlessness;
    this.sweats = props.sweats;
    this.tactileDisturbances = props.tactileDisturbances;
    this.tense = props.tense;
    this.tremors = props.tremors;
    this.visualDisturbances = props.visualDisturbances;
    this.weakness = props.weakness;
    this.worry = props.worry;
  }

  /**
   * Union of possible 0-4 literal values, nullable. For use in runtime type
   * checking of the number literals in the Client CIWA-B Assessment API model.
   */
  private static readonly ZERO_TO_FOUR_NULLABLE_UNION = io.union([
    io.literal(0),
    io.literal(1),
    io.literal(2),
    io.literal(3),
    io.literal(4),
    io.null,
  ]);

  /**
   * The io-ts codec for runtime type checking of the Client CIWA-B Score API
   * model.
   */
  public static readonly Codec = io.type(
    {
      anxiety: this.ZERO_TO_FOUR_NULLABLE_UNION,
      appetite: this.ZERO_TO_FOUR_NULLABLE_UNION,
      concentration: this.ZERO_TO_FOUR_NULLABLE_UNION,
      fatigue: this.ZERO_TO_FOUR_NULLABLE_UNION,
      fear: this.ZERO_TO_FOUR_NULLABLE_UNION,
      headache: this.ZERO_TO_FOUR_NULLABLE_UNION,
      insomnia: this.ZERO_TO_FOUR_NULLABLE_UNION,
      irritability: this.ZERO_TO_FOUR_NULLABLE_UNION,
      mood: this.ZERO_TO_FOUR_NULLABLE_UNION,
      muscle: this.ZERO_TO_FOUR_NULLABLE_UNION,
      palpitations: this.ZERO_TO_FOUR_NULLABLE_UNION,
      restfulness: this.ZERO_TO_FOUR_NULLABLE_UNION,
      restlessness: this.ZERO_TO_FOUR_NULLABLE_UNION,
      sweats: this.ZERO_TO_FOUR_NULLABLE_UNION,
      tactileDisturbances: this.ZERO_TO_FOUR_NULLABLE_UNION,
      tense: this.ZERO_TO_FOUR_NULLABLE_UNION,
      tremors: this.ZERO_TO_FOUR_NULLABLE_UNION,
      visualDisturbances: this.ZERO_TO_FOUR_NULLABLE_UNION,
      weakness: this.ZERO_TO_FOUR_NULLABLE_UNION,
      worry: this.ZERO_TO_FOUR_NULLABLE_UNION,
    },
    'ClientCIWABAssessmentApi',
  );

  @api({ key: 'anxiety' })
  public readonly anxiety: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'appetite' })
  public readonly appetite: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'concentration' })
  public readonly concentration: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'fatigue' })
  public readonly fatigue: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'fear' })
  public readonly fear: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'headache' })
  public readonly headache: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'insomnia' })
  public readonly insomnia: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'irritability' })
  public readonly irritability: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'mood' })
  public readonly mood: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'muscle' })
  public readonly muscle: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'palpitations' })
  public readonly palpitations: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'restfulness' })
  public readonly restfulness: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'restlessness' })
  public readonly restlessness: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'sweats' })
  public readonly sweats: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'tactileDisturbances' })
  public readonly tactileDisturbances: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'tense' })
  public readonly tense: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'tremors' })
  public readonly tremors: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'visualDisturbances' })
  public readonly visualDisturbances: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'weakness' })
  public readonly weakness: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'worry' })
  public readonly worry: 0 | 1 | 2 | 3 | 4;

  /**
   * Get the total CIWA-B assessment score.
   *
   * @returns The total CIWA-B assessment score.
   */
  public get score(): number {
    return (
      this.anxiety +
      this.appetite +
      this.concentration +
      this.fatigue +
      this.fear +
      this.headache +
      this.insomnia +
      this.irritability +
      this.mood +
      this.muscle +
      this.palpitations +
      this.restfulness +
      this.restlessness +
      this.sweats +
      this.tactileDisturbances +
      this.tense +
      this.tremors +
      this.visualDisturbances +
      this.weakness +
      this.worry
    );
  }

  /**
   * Deserializes a Client CIWA-B Score object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client CIWA-B Score object.
   * @throws An error if the value is not a valid Client CIWA-B Score object.
   */
  public static deserialize(
    value: NonNullable<unknown>,
  ): ClientCIWABAssessment {
    const decoded = decode(ClientCIWABAssessment.Codec, value);
    return new ClientCIWABAssessment({
      anxiety: decoded.anxiety ?? 0,
      appetite: decoded.appetite ?? 0,
      concentration: decoded.concentration ?? 0,
      fatigue: decoded.fatigue ?? 0,
      fear: decoded.fear ?? 0,
      headache: decoded.headache ?? 0,
      insomnia: decoded.insomnia ?? 0,
      irritability: decoded.irritability ?? 0,
      mood: decoded.mood ?? 0,
      muscle: decoded.muscle ?? 0,
      palpitations: decoded.palpitations ?? 0,
      restfulness: decoded.restfulness ?? 0,
      restlessness: decoded.restlessness ?? 0,
      sweats: decoded.sweats ?? 0,
      tactileDisturbances: decoded.tactileDisturbances ?? 0,
      tense: decoded.tense ?? 0,
      tremors: decoded.tremors ?? 0,
      visualDisturbances: decoded.visualDisturbances ?? 0,
      weakness: decoded.weakness ?? 0,
      worry: decoded.worry ?? 0,
    });
  }

  /**
   * Deserializes a list of Client CIWA-B Score objects from an API/unknown
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client CIWA-B Score objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA-B Score
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<unknown>>,
  ): readonly ClientCIWABAssessment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA-B Score objects.');
    }
    return values.map(ClientCIWABAssessment.deserialize);
  }
}

export class ClientCIWABAssessmentUpdate extends ClientCIWABAssessment {
  public constructor(props: ClassProperties<ClientCIWABAssessmentUpdate>) {
    super(props);
  }

  /**
   * Serializes a Client CIWA-B Score object to an API model.
   *
   * @returns The serialized Client CIWA-B Score object.
   */
  public serialize(): ClientCIWABAssessmentUpdateApi {
    return this;
  }
}
