<alleva-button
  (click)="openPersonSearchDialog()"
  [icon]="{
    color: 'primary',
    name: 'person_search',
    scale: 1.15,
    type: 'filled'
  }"
  colorHex="var(--white)"
  label="Person Search Toggle"
  name="Person Search Toggle"
  styling="mini-fab"
></alleva-button>
