import { ClientNameApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { NamePrefixEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientNameApi>();

export class ClientName {
  public constructor(props: ClassProperties<ClientName>) {
    this.first = props.first;
    this.former = props.former;
    this.isLegalName = props.isLegalName;
    this.last = props.last;
    this.middle = props.middle;
    this.preferred = props.preferred;
    this.prefix = props.prefix;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Name API model.
   */
  public static readonly Codec = io.type(
    {
      first: io.union([io.string, io.null]),
      former: io.union([io.string, io.null]),
      isLegalName: io.union([io.boolean, io.null]),
      last: io.union([io.string, io.null]),
      middle: io.union([io.string, io.null]),
      preferred: io.union([io.string, io.null]),
      prefix: io.union([
        io.literal(NamePrefixEnum.DR),
        io.literal(NamePrefixEnum.MISS),
        io.literal(NamePrefixEnum.MISSES),
        io.literal(NamePrefixEnum.MR),
        io.literal(NamePrefixEnum.MRS),
        io.literal(NamePrefixEnum.MS),
        io.literal(NamePrefixEnum.MX),
        io.null,
      ]),
    },
    'ClientNameApi',
  );

  @api({ key: 'first' }) public readonly first: string | null;
  @api({ key: 'former' }) public readonly former: string | null;
  @api({ key: 'isLegalName' }) public readonly isLegalName: boolean | null;
  @api({ key: 'last' }) public readonly last: string | null;
  @api({ key: 'middle' }) public readonly middle: string | null;
  @api({ key: 'preferred' }) public readonly preferred: string | null;
  @api({ key: 'prefix' }) public readonly prefix: NamePrefixEnum | null;

  /**
   * Deserializes a Client Name object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Name object.
   * @throws An error if the value is not a valid Client Name object.
   */
  public static deserialize(value: NonNullable<ClientNameApi>): ClientName {
    const decoded = decode(ClientName.Codec, value);
    return new ClientName(decoded);
  }

  /**
   * Deserializes a list of Client Name objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Name objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Name objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientNameApi>>,
  ): readonly ClientName[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Name objects.');
    }
    return values.map(ClientName.deserialize);
  }
}
