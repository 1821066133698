import { ClientVitalsApi } from 'api/models';
import { Observable, catchError, map, of, withLatestFrom } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import { Client, ClientVitals, ClientVitalsUpdate } from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityService } from '../root/facility.service';

@Injectable()
export class ClientVitalsService {
  public constructor(
    private readonly facilityService: FacilityService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Delete the client vitals for the given client ID and client vitals ID.
   *
   * @param clientId The client ID to delete the client vitals for.
   * @param clientVitalsId The ID of the client vitals to delete.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientVitalsId: ClientVitals['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${config.api}/clients/${clientId}/vitals/${clientVitalsId}`,
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return the latest Client Vitals data for the given Client.
   *
   * @returns Client Vitals data on success, undefined on error.
   */
  public get(
    clientId: Client['id'],
    requestParameters: readonly ClientVitalsRequestParam[],
  ): Observable<readonly ClientVitals[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<
        readonly ClientVitalsApi[] | undefined
      >(`${config.api}/clients/${clientId}/vitals`, { params })
      .pipe(
        withLatestFrom(this.facilityService.currentFacilityChanges),
        map(([response, currentFacility]) =>
          response && response.length > 0
            ? ClientVitals.deserializeList(response, currentFacility)
            : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new client vitals for the given client ID.
   *
   * @param clientId The client ID to create the vitals for.
   * @param clientVitalsUpdate The client vitals update data.
   * @returns The created client vitals on success, undefined on error.
   */
  public post(
    clientId: Client['id'],
    clientVitalsUpdate: ClientVitalsUpdate,
  ): Observable<ClientVitals | undefined> {
    return this.httpClient
      .post<ClientVitalsApi>(
        `${config.api}/clients/${clientId}/vitals`,
        clientVitalsUpdate.serialize(),
      )
      .pipe(
        withLatestFrom(this.facilityService.currentFacilityChanges),
        map(([response, currentFacility]) =>
          ClientVitals.deserialize(response, currentFacility),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Client Vitals API.
 */
export interface ClientVitalsRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'order' | 'limit' | 'sort';
  /** The values to use for the query parameters. */
  value: SortOrderEnum | number | keyof ClientVitals;
}
