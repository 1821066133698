import { BillingCodeApi, BillingCodePagedListApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  BillingCode,
  BillingCodeCreate,
  BillingCodeUpdate,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BillingCodesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete a billing code by id.
   *
   * @param billingCodeId The id of the billing code to delete.
   * @returns True if the billing code was deleted, false on fail, and
   * undefined on error.
   */
  public delete(billingCodeId: BillingCode['id']): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api}/billing/billing-codes/${billingCodeId}`, { observe: 'response' })
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetches a list of billing codes for a facility, or all billing codes if no
   * facility id is provided.
   *
   * @param requestParameters The list of query parameters to use for the paged
   * request.
   * @returns A list of billing codes for the facility, or all billing codes if
   * no facility id is provided. Undefined on error.
   */
  public getPagedList(
    requestParameters: readonly BillingCodesPagedListParam[],
  ): Observable<PagedList<BillingCode> | undefined> {
    // If both 'active' true and false are provided, remove the 'active' key.
    if (
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === true,
      ) &&
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === false,
      )
    ) {
      requestParameters = requestParameters.filter(
        (param) => param.key !== 'active',
      );
    }
    return this.httpClient
      .get<BillingCodePagedListApi | undefined>(
        `${config.api}/billing/billing-codes`,
        {
          params: parseHttpParams(requestParameters),
        },
      )
      .pipe(
        map((response) =>
          response
            ? {
                ...decode(BillingCodesPagedListCodec, response),
                results: BillingCode.deserializeList(response.results),
              }
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a billing code resource.
   *
   * @param billingCodeId The id of the billing code to patch.
   * @param billingCodeUpdate The values to patch.
   * @returns The patched billing code, or undefined on error.
   */
  public put(
    billingCodeId: BillingCode['id'],
    billingCodeUpdate: BillingCodeUpdate,
  ): Observable<BillingCode | HttpErrorResponseApi> {
    return this.httpClient
      .put<
        BillingCodeApi | undefined
      >(`${config.api}/billing/billing-codes/${billingCodeId}`, billingCodeUpdate.serialize(), { observe: 'response' })
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Billing Code PUT response from API is empty!');
          }
          return BillingCode.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }

  /**
   * Create a new Billing Code resource.
   *
   * @param billingCodeUpdate The billing code values to post.
   * @returns The new billing code record, or undefined on error.
   */
  public post(
    billingCodeUpdate: BillingCodeCreate,
  ): Observable<BillingCode | HttpErrorResponseApi> {
    return this.httpClient
      .post<
        BillingCodeApi | undefined
      >(`${config.api}/billing/billing-codes`, billingCodeUpdate.serialize(), { observe: 'response' })
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Billing Code POST response from API is empty!');
          }
          return BillingCode.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const BillingCodesPagedListCodec = PagedListCodec(
  BillingCode.Codec,
  'BillingCodePagedListApi',
);

/** Request parameter interface for use with the Billing Code API. */
export interface BillingCodesPagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'facilityId' | 'order' | 'pageNumber' | 'pageSize' | 'search';
  /** The value to use for the query parameter. */
  value: boolean | number | string | keyof BillingCode;
}
