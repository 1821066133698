import { ClientRecentAppointmentsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientAppointment } from 'src/app/models/client/client-appointment.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientRecentAppointmentsApi>();

export class ClientRecentAppointments {
  public constructor(props: ClassProperties<ClientRecentAppointments>) {
    this.last = props.last;
    this.upcoming = props.upcoming;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Recent
   * Appointments API model.
   */
  public static readonly Codec = io.type(
    {
      last: io.union([ClientAppointment.Codec, io.null]),
      upcoming: io.union([ClientAppointment.Codec, io.null]),
    },
    'ClientRecentAppointmentsApi',
  );

  @api({ key: 'last' }) public readonly last: ClientAppointment | null;
  @api({ key: 'upcoming' }) public readonly upcoming: ClientAppointment | null;

  /**
   * Deserializes a Client Recent Appointments object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Recent Appointments object.
   * @throws An error if the value is not a valid Client Recent Appointments
   * object.
   */
  public static deserialize(
    value: NonNullable<ClientRecentAppointmentsApi>,
    deserializationArgs: ClientRecentAppointmentDeserializationArgs,
  ): ClientRecentAppointments {
    const decoded = decode(ClientRecentAppointments.Codec, value);

    return new ClientRecentAppointments({
      last: decoded.last
        ? ClientAppointment.deserialize(decoded.last, deserializationArgs)
        : null,
      upcoming: decoded.upcoming
        ? ClientAppointment.deserialize(decoded.upcoming, deserializationArgs)
        : null,
    });
  }
}

export interface ClientRecentAppointmentDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
