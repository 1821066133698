import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  interval,
  map,
  startWith,
  switchMap,
  timer,
} from 'rxjs';
import { ALLEVA_ROUTES } from 'src/app/constants';
import {
  AUTO_LOGOUT_TIMEOUT_KEY,
  AUTO_LOGOUT_TIMEOUT_MINUTES,
  AlertService,
  AuthenticationService,
  AutoLogoutTimeout,
  ScreenLockService,
  StorageService,
  StorageTypeEnum,
  clearAutoLogoutTimeout,
  getStoredAutoLogoutTimeout,
} from 'src/app/services';
import { getFirstNonEmptyValueFrom } from 'src/app/utilities';

import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DialogRef } from 'src/app/components/dialogs/dialog-ref';

export interface LockScreenDialogOutput {
  // Whether or not the dialog was closed nefariously (removing the dialog from
  // the DOM without using the close method for instance).
  wasClosedNefariously: boolean;
}

/**
 * A dialog component for the lock screen user prompt.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-screen-lock-dialog',
  templateUrl: './screen-lock-dialog.component.html',
  styleUrls: ['./screen-lock-dialog.component.scss'],
})
export class ScreenLockDialogComponent implements OnInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly dialogRef: DialogRef<LockScreenDialogOutput>,
    private readonly elementRef: ElementRef,
    private readonly router: Router,
    private readonly screenLockService: ScreenLockService,
    private readonly storageService: StorageService,
  ) {}

  private logoutTime: DateTime = DateTime.local().plus({
    minutes: AUTO_LOGOUT_TIMEOUT_MINUTES,
  });

  protected isSubmitting = false;

  /**
   * Observable that emits the time remaining until the user is logged out
   * automatically.
   */
  private readonly timeUntilLogoutChanges: Observable<AutoLogoutTimeout> =
    this.screenLockService.isLockedChanges.pipe(
      switchMap((isLocked) => {
        if (!isLocked) {
          return timer(0, 1000).pipe(
            startWith({ minutes: 0, seconds: 0, updated: null }),
            map(() => ({ minutes: 0, seconds: 0, updated: null })),
            distinctUntilChanged(),
          );
        } else {
          return timer(0, 1000).pipe(
            startWith(
              getStoredAutoLogoutTimeout(this.storageService) ??
                this.timeUntilAutoLogout(),
            ),
            map(() => this.timeUntilAutoLogout()),
            distinctUntilChanged(),
          );
        }
      }),
    );

  protected readonly timeUntilLogoutLabelChanges: Observable<string> =
    this.timeUntilLogoutChanges.pipe(
      map((remainingTime) => {
        if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
          return '00:00';
        }
        return `${remainingTime.minutes
          .toString()
          .padStart(2, '0')}:${remainingTime.seconds
          .toString()
          .padStart(2, '0')}`;
      }),
      distinctUntilChanged(),
    );

  protected readonly formGroup: FormGroup<LockScreenFormGroup> = new FormGroup({
    password: new FormControl<string | null>(null, Validators.required),
  });

  public async ngOnInit(): Promise<void> {
    const now = DateTime.local();

    // Perform all neccessary checks for the the auto logout timer.
    const storedAutoLogoutTimeout = await getStoredAutoLogoutTimeout(
      this.storageService,
    );
    if (storedAutoLogoutTimeout && storedAutoLogoutTimeout.updated) {
      const updated = DateTime.fromISO(storedAutoLogoutTimeout.updated);
      // Check the time difference from between when the last time the auto
      // logout timer was updated and now. This is used to update the logout
      // time timer relative to the time difference.
      const diff = now.diff(updated);
      // The difference in minutes between the last time the auto logout timer
      // was updated and now. This includes fractionals for seconds.
      const diffMinutes = diff.as('minutes');
      this.logoutTime = now.plus({
        minutes: storedAutoLogoutTimeout.minutes - diffMinutes,
        seconds:
          storedAutoLogoutTimeout.seconds - diff.seconds < 0
            ? 0
            : storedAutoLogoutTimeout.seconds - diff.seconds,
      });
    } else {
      // Start with a fresh timer from the top.
      this.logoutTime = DateTime.local().plus({
        minutes: AUTO_LOGOUT_TIMEOUT_MINUTES,
      });
    }

    // Every 100ms check if the element still exists in the DOM and not
    // forcefully removed by the client. If it was removed, close the dialog
    // with the nefarious flag set to true. This will cause the parent
    // component to re-open this dialog with a fresh instance.
    interval(100)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!document.body.contains(this.elementRef.nativeElement)) {
          this.dialogRef.close({ wasClosedNefariously: true });
        }
      });

    const unauthenticatedRoutes = Object.keys(ALLEVA_ROUTES.unauthenticated);
    // Perform various actions when the screen is locked, or when the time
    // remaining until the user is logged out automatically changes.
    combineLatest([
      this.screenLockService.isLockedChanges,
      this.timeUntilLogoutChanges,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(async ([isLocked, timeUntilAutoLogout]) => {
        // Reactively close this dialog if the screen lock is disabled elsewhere.
        if (!isLocked) {
          clearAutoLogoutTimeout(this.storageService);
          this.dialogRef.close({ wasClosedNefariously: false });
          return;
        }

        // If the user attempts to navigates to an unauthenticated route while the
        // screen is locked, log them out and send them to the login screen.
        if (
          unauthenticatedRoutes.includes(location.pathname) ||
          unauthenticatedRoutes.includes(this.router.url)
        ) {
          this.logout();
          return;
        }

        // Check if the logout time has been met.
        if (
          timeUntilAutoLogout.minutes === 0 &&
          timeUntilAutoLogout.seconds === 0
        ) {
          this.screenLockService.isLocked = false;
          this.alertService.error({
            message: `Logged out due to inactivity.`,
            keepOpen: true,
          });
          this.authenticationService.logout();
          return;
        }

        // Retrieve stored auto logout timeout data (if it exists).
        const storedAutoLogoutTimeout = await getStoredAutoLogoutTimeout(
          this.storageService,
        );

        // If this is now locked but wasn't before, set the logout time with
        // a fresh new timer set to X minutes.
        if (isLocked && !storedAutoLogoutTimeout) {
          // Reset the logout time timer.
          this.logoutTime = DateTime.local().plus({
            minutes: AUTO_LOGOUT_TIMEOUT_MINUTES,
          });
          // Store the auto logout timeout data.
          this.setAutoLogoutTimeout(this.timeUntilAutoLogout());
          return;
        }

        // Update the stored auto logout timeout data.
        this.setAutoLogoutTimeout(timeUntilAutoLogout);
      });
  }

  /** Logs the user out and closes the dialog. */
  protected logout(): void {
    this.dialogRef.close({ wasClosedNefariously: false });
    this.authenticationService.logout();
  }

  /**
   * Store the auto logout timeout data in local storage.
   *
   * @param value The auto logout timeout data to store.
   */
  private setAutoLogoutTimeout(value: AutoLogoutTimeout): void {
    this.storageService.set(
      AUTO_LOGOUT_TIMEOUT_KEY,
      value,
      StorageTypeEnum.LOCAL_STORAGE,
    );
  }

  /**
   * Submits the users password for validation. On success, close the dialog,
   * on failure, display an error message and keep the dialog open.
   */
  protected async submit(): Promise<void> {
    const { password } = this.formGroup.value;
    const user = await getFirstNonEmptyValueFrom(
      this.authenticationService.userChanges,
    );

    if (this.formGroup.invalid || !password) {
      // Form is invalid, do nothing.
      return;
    }

    // Disable the form while we're submitting.
    this.formGroup.disable();
    this.isSubmitting = true;

    // Check if the provided password is valid.
    const isPasswordValid: boolean = await firstValueFrom(
      this.authenticationService.verifyUserPassword(user, password),
    );

    // Re-enable the form now that we're done submitting.
    this.formGroup.enable();
    this.isSubmitting = false;

    // Check if the password is invalid. If so display an error message and
    // keep the dialog open.
    if (!isPasswordValid) {
      this.alertService.error({
        message: 'Password is invalid. Please try again or contact support.',
      });
      return;
    }

    clearAutoLogoutTimeout(this.storageService);
    this.dialogRef.close({ wasClosedNefariously: false });
  }

  /**
   * The time remaining until the user is logged out automatically.
   *
   * @returns The time remaining until the user is logged out automatically.
   */
  private timeUntilAutoLogout(): AutoLogoutTimeout {
    const now = DateTime.local();
    const updated = now.toISO();
    if (!updated) {
      throw new Error('Unable to get current time.');
    }

    const remainingTime = this.logoutTime.diff(now);
    const remainingMinutes = Math.max(
      0,
      Math.floor(remainingTime.as('minutes')),
    );
    const remainingSeconds = Math.floor(remainingTime.as('seconds')) % 60;

    const autoLogoutTimeout: AutoLogoutTimeout = {
      minutes: remainingMinutes,
      seconds: remainingSeconds,
      updated,
    };

    return autoLogoutTimeout;
  }
}

interface LockScreenFormGroup {
  password: FormControl<string | null>;
}
