import { ALLEVA_ROUTE_URLS } from 'src/app/constants';

import { replaceNumberGroups } from 'src/app/utilities/strings';

/**
 * Validate that the value is an empty value.
 *
 * @param value The value to check.
 * @returns True if the value is an empty value, false otherwise.
 */
export function isEmptyValue(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}

/**
 * Validate that the value is a non-empty primitive type.
 *
 * @param value The value to check.
 * @returns True if the value is a non-empty primitive type, false otherwise.
 */
export function isNonEmptyPrimitive(
  value: unknown,
): value is NonNullable<Primitive> {
  return isPrimitive(value) && value !== null && value !== undefined;
}

/**
 * Validate that the value is a string that is non-empty.
 *
 * @param value The value to check.
 * @returns True if the value is a string that is non-empty, false otherwise.
 */
export function isNonEmptyString(value: unknown): value is string {
  return isString(value) && value.length > 0 && value.trim().length > 0;
}

/**
 * Validate that the value is a non-empty value.
 *
 * @param value The value to check.
 * @returns True if the value is a non-empty value, false otherwise.
 */
export function isNonEmptyValue<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

/**
 * Validate that the value is a number.
 *
 * @param value The value to check.
 * @returns True if the value is a number, false otherwise.
 */
export function isNumber(value: unknown): value is number {
  return value !== null && value !== '' && !isNaN(Number(String(value)));
}

/**
 * A function that returns whether or not the value matches one of the string
 * literals defined in the `PageRoute` type. Also works with SPA route URL's
 * that contain the base facility relative URL.
 */
export function isPageRoute(value: string): value is PageRoute {
  const id: RouteResourceIdentifier = ':id';
  // Replace all numbers with `:id` to match the pre-defined page routes.
  const valueWithId = replaceNumberGroups(value, id);
  return (
    Object.values(ALLEVA_ROUTE_URLS).includes(value as PageRoute) ||
    Object.values(ALLEVA_ROUTE_URLS).includes(valueWithId as PageRoute) ||
    // If the value with id is preceeded by a facility id (ie `/facility/1`),
    // then remove it and check again to see if it matches a page route.
    Object.values(ALLEVA_ROUTE_URLS).includes(
      valueWithId.replace(`/facility/:id`, '') as PageRoute,
    )
  );
}

/**
 * Validate that the value is a primitive type.
 *
 * @param value The value to check.
 * @returns True if the value is a primitive type, false otherwise.
 */
export function isPrimitive(value: unknown): value is Primitive {
  return (
    value === null ||
    value === undefined ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol' ||
    typeof value === 'bigint'
  );
}

/**
 * Validate that the value is route data.
 *
 * @param value The value to check.
 * @returns True if the value is route data, false otherwise.
 */
export function isRouteData(value: unknown): value is RouteData {
  return (value as RouteData).meta !== undefined;
}

/**
 * Validate that the value is a string.
 *
 * @param value The value to check.
 * @returns True if the value is a string, false otherwise.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string' || value instanceof String;
}

export function isCategoryMenuItem(
  menuItem: MenuItem,
): menuItem is CategoryMenuItem {
  return 'topLevelMenuItems' in menuItem;
}
