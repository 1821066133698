import {
  BmiCategoryEnum,
  HeightUnitsEnum,
  WeightUnitsEnum,
} from 'src/app/enumerators';
import { getKilogramsFrom, getMetersFrom } from 'src/app/utilities';

/**
 * Get the BMI category from a BMI value.
 *
 * @param { height, weight } - The height and weight to calculate the BMI from.
 * @returns The BMI value.
 */
export function getBMICalculationFrom({
  height,
  heightUnits,
  weight,
  weightUnits,
}: {
  height: number;
  heightUnits: HeightUnitsEnum;
  weight: number;
  weightUnits: WeightUnitsEnum;
}): number {
  let scaledWeight: number;
  let scaledHeight: number;

  // Convert height to meters
  if (heightUnits === HeightUnitsEnum.CENTIMETERS) {
    scaledHeight = getMetersFrom({ centimeters: height });
  } else if (heightUnits === HeightUnitsEnum.INCHES) {
    scaledHeight = getMetersFrom({ inches: height });
  } else {
    throw new Error(`Invalid height units: ${heightUnits}`);
  }

  // Convert weight to kilograms
  if (weightUnits === WeightUnitsEnum.KILOGRAMS) {
    scaledWeight = weight;
  } else if (weightUnits === WeightUnitsEnum.POUNDS) {
    scaledWeight = getKilogramsFrom({ pounds: weight });
  } else {
    throw new Error(`Invalid weight units: ${weightUnits}`);
  }

  const bmi = scaledWeight / (scaledHeight * scaledHeight);

  return bmi;
}

/**
 * Get the BMI category from a BMI value. Copies the logic from the API backend.
 *
 * @see https://github.com/AllevaSoft/rehabweb/blob/b4117d0245030a040f3da95a266933f2688fb82d/AllevaApi/Repositories/v2/ClientVitalsRepository.cs#L417
 * @param bmi The bmi value to calculate the category from.
 * @returns The category of the bmi value.
 */
export function getBMICategoryFrom(bmi: number): BmiCategoryEnum {
  if (bmi < 18.5) {
    return BmiCategoryEnum.UNDERWEIGHT;
  } else if (bmi >= 18.5 && bmi < 25) {
    return BmiCategoryEnum.NORMAL;
  } else if (bmi >= 25 && bmi < 30) {
    return BmiCategoryEnum.OVERWEIGHT;
  } else if (bmi >= 30 && bmi < 35) {
    return BmiCategoryEnum.OBESE_CLASS_1;
  } else if (bmi >= 35 && bmi < 40) {
    return BmiCategoryEnum.OBESE_CLASS_2;
  } else {
    return BmiCategoryEnum.OBESE_CLASS_3;
  }
}
