export const CLIENT_CIWA_SCORES_DROPDOWN_OPTIONS: ClientCIWAScoreDropdownOptions =
  {
    agitation: [
      { label: 'Normal activity.', value: 0 },
      { label: 'Somewhat more activity than normal activity.', value: 1 },
      { label: null, value: 2 },
      { label: null, value: 3 },
      { label: 'Moderately fidgety and restless.', value: 4 },
      { label: null, value: 5 },
      { label: null, value: 6 },
      {
        label:
          'Paces back and forth during most of the interview, or constantly thrashes about.',
        value: 7,
      },
    ],
    anxiety: [
      { label: 'No anxiety.', value: 0 },
      { label: 'Mildly anxious.', value: 1 },
      { label: null, value: 2 },
      { label: null, value: 3 },
      {
        label: 'Moderately anxious, or guarded, so anxiety is inferred.',
        value: 4,
      },
      { label: null, value: 5 },
      { label: null, value: 6 },
      {
        label:
          'Equivalent to acute panic states, as seen in severe delirium or acute schizophrenic reactions.',
        value: 7,
      },
    ],
    auditoryDisturbances: [
      { label: 'Not present.', value: 0 },
      { label: 'Very mild harshness or ability to frighten.', value: 1 },
      { label: 'Mild harshness or ability to frighten.', value: 2 },
      { label: 'Moderate harshness or ability to frighten.', value: 3 },
      { label: 'Moderately severe hallucinations.', value: 4 },
      { label: 'Severe hallucinations.', value: 5 },
      { label: 'Extremely severe hallucinations.', value: 6 },
      { label: 'Continuous hallucinations.', value: 7 },
    ],
    headache: [
      { label: 'Not present.', value: 0 },
      { label: 'Very mild.', value: 1 },
      { label: 'Mild.', value: 2 },
      { label: 'Moderate.', value: 3 },
      { label: 'Moderately severe.', value: 4 },
      { label: 'Severe.', value: 5 },
      { label: 'Very severe.', value: 6 },
      { label: 'Extremely severe.', value: 7 },
    ],
    nausea: [
      { label: 'No nausea and no vomiting.', value: 0 },
      { label: 'Mild nausea with no vomiting.', value: 1 },
      { label: null, value: 2 },
      { label: null, value: 3 },
      { label: 'Intermittent nausea with dry heaves & vomiting.', value: 4 },
      { label: null, value: 5 },
      { label: null, value: 6 },
      { label: 'Constant nausea, frequent dry heaves & vomiting.', value: 7 },
    ],
    orientation: [
      { label: 'Oriented and can do serial additions.', value: 0 },
      {
        label: 'Cannot do serial additions and is uncertain about date.',
        value: 1,
      },
      {
        label: 'Disoriented for date by no more than 2 calendar days.',
        value: 2,
      },
      { label: 'Disoriented for date by more than 2 calendar days.', value: 3 },
      { label: 'Disoriented for place and/or person.', value: 4 },
    ],
    sweats: [
      { label: 'No sweat visible.', value: 0 },
      { label: 'Barely perceptible sweating, palms moist.', value: 1 },
      { label: null, value: 2 },
      { label: null, value: 3 },
      { label: 'Beads of sweat obvious on forehead.', value: 4 },
      { label: null, value: 5 },
      { label: null, value: 6 },
      { label: 'Drenching sweat.', value: 7 },
    ],
    tactileDisturbances: [
      { label: 'None.', value: 0 },
      {
        label: 'Very mild itching, pins & needles, buring or numbness.',
        value: 1,
      },
      { label: 'Mild itching, pins & needles, buring or numbness.', value: 2 },
      {
        label: 'Moderate itching, pins & needles, buring or numbness.',
        value: 3,
      },
      { label: 'Moderately severe hallucinations.', value: 4 },
      { label: 'Severe hallucinations.', value: 5 },
      { label: 'Extremely severe hallucinations.', value: 6 },
      { label: 'Continuous hallucinations.', value: 7 },
    ],
    tremors: [
      { label: 'No Tremor.', value: 0 },
      {
        label: 'Not visible but can be felt fingertip to fingertip.',
        value: 1,
      },
      { label: null, value: 2 },
      { label: null, value: 3 },
      { label: "Moderate, with patient's arm extended.", value: 4 },
      { label: null, value: 5 },
      { label: null, value: 6 },
      { label: 'Severe, even with arms not extended.', value: 7 },
    ],
    visualDisturbances: [
      { label: 'Not present.', value: 0 },
      { label: 'Very mild sensitivity.', value: 1 },
      { label: 'Mild sensitivity.', value: 2 },
      { label: 'Moderate sensitivity.', value: 3 },
      { label: 'Moderately severe hallucinations.', value: 4 },
      { label: 'Severe hallucinations.', value: 5 },
      { label: 'Extremely severe hallucinations.', value: 6 },
      { label: 'Continuous hallucinations.', value: 7 },
    ],
  };

interface ClientCIWAScoreDropdownOptions {
  agitation: readonly ClientCIWAScoreDropdownOption[];
  anxiety: readonly ClientCIWAScoreDropdownOption[];
  auditoryDisturbances: readonly ClientCIWAScoreDropdownOption[];
  headache: readonly ClientCIWAScoreDropdownOption[];
  nausea: readonly ClientCIWAScoreDropdownOption[];
  orientation: readonly ClientCIWAScoreDropdownOption[];
  sweats: readonly ClientCIWAScoreDropdownOption[];
  tactileDisturbances: readonly ClientCIWAScoreDropdownOption[];
  tremors: readonly ClientCIWAScoreDropdownOption[];
  visualDisturbances: readonly ClientCIWAScoreDropdownOption[];
}

interface ClientCIWAScoreDropdownOption {
  label: string | null;
  value: number;
}
