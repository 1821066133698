import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { ALLEVA_ROUTES } from 'src/app/constants';
import { config } from 'src/configs/config';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { StorageService, StorageTypeEnum } from './storage.service';

/**
 * The key used to store and retrieve the auto logout timeout data in local
 * storage. **Warning** Changing this value is a breaking change. Clear your
 * local storage if you change this value and re-login.
 */
export const AUTO_LOGOUT_TIMEOUT_KEY = 'autoLogoutTimeout';

/**
 * The number of minutes the lock screen is open before the user is logged
 * out of the application automatically.
 *
 * Note: Set to low value for testing, such as `0.1` for six seconds (0.1*60).
 *
 * @default 30 Minutes.
 */
export const AUTO_LOGOUT_TIMEOUT_MINUTES = 30;

/**
 * The key used to store and retrieve the inactive timeout data in local
 * storage. **Warning** Changing this value is a breaking change. Clear your
 * local storage if you change this value and re-login.
 */
const INACTIVE_TIMEOUT_KEY = 'inactiveTimeout';

/**
 * The number of minutes of inactivity before the screen automatically locks
 * with a dialog that requires the user to enter their password to continue.
 *
 * Note: Set to low value for testing, such as `0.1` for six seconds (0.1*60).
 *
 * @default 15 Minutes.
 */
const INACTIVITY_TIMEOUT_MINUTES = 15;

/**
 * The source of truth for the screen lock state app-wide is stored in this
 * service. This service also handles the logic for automatically locking the
 * screen after X minutes of inactivity, and logging the user out after X
 * minutes of inactivity.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScreenLockService {
  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  private inititialized = false;

  /** Set the single source of truth for the screen lock state app-wide. */
  public set isLocked(value: boolean) {
    this.isLockedSubject.next(value);
  }

  /** The current screen lock state subject. */
  private readonly isLockedSubject = new BehaviorSubject<boolean>(false);

  /**
   * Observable that emits the current screen lock state. This is an emission
   * of the single source of truth for the screen lock state app-wide.
   */
  public readonly isLockedChanges = this.isLockedSubject
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  /**
   * An rxjs observable timer that emits every second that the user is inactive
   * and away from the screen (no mouse or keyboard events firing) counting up
   * from zero and resetting back to zero when the user becomes active again.
   */
  private readonly inactivityTimerChanges = merge(
    fromEvent<MouseEvent>(document, 'mousemove').pipe(debounceTime(100)),
    fromEvent<KeyboardEvent>(document, 'keydown'),
  ).pipe(
    // Emit null initially to start the timer immediately.
    startWith(null),
    // Start the timer when there's user activity. Emits an incurring number
    // every second. Will reset to zero when the user becomes active again.
    switchMap(() => timer(0, 1000)),
    // If the value is over the timeout, emit the timeout value instead.
    map((value) => Math.min(value, INACTIVITY_TIMEOUT_MINUTES * 60)),
    // Share the observable with all subscribers.
    shareReplay(1),
  );

  public async initialize(): Promise<void> {
    // Ensure we only initialize once (this is done in the root component).
    // This service runs throughout the lifetime of the application and should
    // never need to be re-initialized.
    if (this.inititialized) {
      throw new Error('The screen lock service is already initialized!');
    } else if (config.features.isScreenAutoLockEnabled === false) {
      // The screen auto lock feature is disabled, do not initialize.
      // eslint-disable-next-line no-console
      return;
    }
    this.inititialized = true;

    // Get the current time for use in various places.
    const now = DateTime.local();

    // Perform all neccessary checks for the the inactivity timer.
    const storedInactiveTimer = await this.storageService.get<InactiveTimeout>(
      INACTIVE_TIMEOUT_KEY,
      StorageTypeEnum.LOCAL_STORAGE,
    );
    if (storedInactiveTimer) {
      const lastActive = DateTime.fromISO(storedInactiveTimer.lastActive);
      const diff = now.diff(lastActive);
      const diffMinutes = diff.as('minutes');
      if (diffMinutes > INACTIVITY_TIMEOUT_MINUTES) {
        // The difference between the last time the user was active and now
        // is greater than the inactivity timeout. This means the user was
        // inactive for longer than the inactivity timeout, so we should lock
        // the screen.
        this.isLocked = true;
      }
    }

    // Perform all neccessary checks for the the auto logout timer.
    const storedAutoLogoutTimeout = await getStoredAutoLogoutTimeout(
      this.storageService,
    );
    if (storedAutoLogoutTimeout) {
      if (storedAutoLogoutTimeout.updated === null) {
        // The updated date is somehow empty. Clear the stored auto logout timer
        // and continue. This should never happen since we don't store it null,
        // however it's possible to be null for when the application uses it and
        // both functionalities use the same typing for brevity.
        clearAutoLogoutTimeout(this.storageService);
        this.isLocked = false;
      } else {
        const updated = DateTime.fromISO(storedAutoLogoutTimeout.updated);
        // Check the time difference from between when the last time the auto
        // logout timer was updated and now. This is used to update the logout
        // time timer relative to the time difference.
        const diff = now.diff(updated);
        // The difference in minutes between the last time the auto logout timer
        // was updated and now. This includes fractionals for seconds.
        const diffMinutes = diff.as('minutes');
        if (diffMinutes > storedAutoLogoutTimeout.minutes) {
          // The difference between the last time the auto logout timer was
          // updated and now is greater than the minutes remaining on the
          // auto logout timer. This means the user was inactive for longer
          // than the auto logout timer, so we should log them out.
          clearAutoLogoutTimeout(this.storageService);
          this.isLocked = false;
          this.authenticationService.logout();
        } else {
          this.isLocked = true;
        }
      }
    }

    const unauthenticatedRoutes = Object.keys(ALLEVA_ROUTES.unauthenticated);
    // When the user is inactive for X minutes, lock the screen.
    this.inactivityTimerChanges
      .pipe(withLatestFrom(this.isLockedChanges), untilDestroyed(this))
      .subscribe(([secondsTick, isScreenLocked]) => {
        // If the screen is already locked or we're on an unauthenticated page,
        // do not lock the screen.
        if (
          isScreenLocked ||
          unauthenticatedRoutes.includes(location.pathname) ||
          unauthenticatedRoutes.includes(this.router.url)
        ) {
          clearInactiveTimeout(this.storageService);
          return;
        }

        // If the user is active, reset the inactive timeout "last active" data
        // in memory.
        if (secondsTick === 0) {
          const currentTimeIso = DateTime.local().toISO();
          if (!currentTimeIso) {
            throw new Error('Unable to get current time.');
          }

          const inactiveTimeout: InactiveTimeout = {
            lastActive: currentTimeIso,
          };
          // Store the inactive timeout data.
          this.storageService.set(
            INACTIVE_TIMEOUT_KEY,
            inactiveTimeout,
            StorageTypeEnum.LOCAL_STORAGE,
          );
          return;
        }

        // How long the user has been inactive for, in seconds.
        const inactivityTimerSeconds = INACTIVITY_TIMEOUT_MINUTES * 60;

        // If the user has been inactive for X seconds, lock the screen.
        if (secondsTick === inactivityTimerSeconds) {
          this.isLocked = true;
        }
      });

    // Perform various actions when the screen is locked or unlocked.
    this.isLockedChanges.pipe(untilDestroyed(this)).subscribe((isLocked) => {
      if (!isLocked) {
        clearInactiveTimeout(this.storageService);
        return;
      }
    });
  }
}

interface InactiveTimeout {
  lastActive: string;
}

/**
 * Clear the stored auto logout timeout data from local storage.
 *
 * @param storageService The storage service to use.
 */
export function clearAutoLogoutTimeout(storageService: StorageService): void {
  storageService.set(
    AUTO_LOGOUT_TIMEOUT_KEY,
    null,
    StorageTypeEnum.LOCAL_STORAGE,
  );
}

/**
 * Clear the stored inactive timeout data from local storage.
 *
 * @param storageService The storage service to use.
 */
function clearInactiveTimeout(storageService: StorageService): void {
  storageService.set(INACTIVE_TIMEOUT_KEY, null, StorageTypeEnum.LOCAL_STORAGE);
}

export interface AutoLogoutTimeout {
  /**
   * The number of minutes remaining until the user is logged out automatically.
   */
  minutes: number;
  /**
   * The number of seconds remaining until the user is logged out automatically.
   */
  seconds: number;
  /**
   * The ISO string of the last time the auto logout timeout data was updated.
   */
  updated: string | null;
}

function isAutoLogoutTimeout(value: unknown): value is AutoLogoutTimeout {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const minutes = (value as AutoLogoutTimeout).minutes;
  const seconds = (value as AutoLogoutTimeout).seconds;
  const updated = (value as AutoLogoutTimeout).updated;
  return (
    typeof minutes === 'number' &&
    typeof seconds === 'number' &&
    typeof updated === 'string'
  );
}

/**
 * Retrieve the stored auto logout timer, if it exists.
 *
 * @param storageService The storage service to use.
 * @returns The stored auto logout timer, or null if empty.
 */
export async function getStoredAutoLogoutTimeout(
  storageService: StorageService,
): Promise<AutoLogoutTimeout | null> {
  const storedAutoLogoutTimeout = await storageService.get<AutoLogoutTimeout>(
    AUTO_LOGOUT_TIMEOUT_KEY,
    StorageTypeEnum.LOCAL_STORAGE,
  );
  if (storedAutoLogoutTimeout) {
    if (isAutoLogoutTimeout(storedAutoLogoutTimeout)) {
      return storedAutoLogoutTimeout;
    } else {
      // The stored time is not a valid AutoLogoutTimeout object, clear it.
      clearAutoLogoutTimeout(storageService);
    }
  }
  return null;
}
