import { BillingRuleClaimValueCodeApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ValueCode } from 'src/app/models/billing/value-code.model';

const api = apiDecorator<BillingRuleClaimValueCodeApi>();

export class BillingRuleClaimValueCode {
  public constructor(props: ClassProperties<BillingRuleClaimValueCode>) {
    this.amount = props.amount;
    this.code = props.code;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Claim
   * Value Code API model.
   */
  public static readonly Codec = io.type(
    {
      amount: io.number,
      code: ValueCode.Codec,
    },
    'BillingRuleClaimValueCodeApi',
  );

  @api({ key: 'amount' }) public readonly amount: number;
  @api({ key: 'code' }) public readonly code: ValueCode;

  /**
   * Deserializes a Billing Payor Rule Claim Value Code object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Claim Value Code object.
   * @throws An error if the value is not a valid Billing Payor Rule Claim
   * Value Code object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleClaimValueCodeApi>,
  ): BillingRuleClaimValueCode {
    const decoded = decode(BillingRuleClaimValueCode.Codec, value);
    return {
      amount: decoded.amount,
      code: ValueCode.deserialize(decoded.code),
    };
  }

  /**
   * Deserializes a list of Billing Payor Rule Claim Value Code objects from
   * the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized list of Billing Payor Rule Claim Value Code
   * objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not Billing Payor Rule Claim
   * Value Code objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<BillingRuleClaimValueCodeApi>>,
  ): readonly BillingRuleClaimValueCode[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected an array of Billing Payor Rules objects');
    }
    return values.map(BillingRuleClaimValueCode.deserialize);
  }
}
