import * as io from 'io-ts';
import { decode, isNonEmptyString } from 'src/app/utilities';

export type Permission = Action | Add | Delete | Edit | View;

export type Permissions = readonly Permission[];

export const ACTION_PERMISSIONS = [
  // Whether or not the user can undo the discharge status for clients.
  'action_undischarge_clients',
] as const;
type Action = (typeof ACTION_PERMISSIONS)[number];

export const ADD_PERMISSIONS = [] as const;
type Add = (typeof ADD_PERMISSIONS)[number];

export const DELETE_PERMISSIONS = [] as const;
type Delete = (typeof DELETE_PERMISSIONS)[number];

export const EDIT_PERMISSIONS = [
  // Whether or not the user can edit the client.
  'edit_admission_intake_screen',
  'edit_alumni_screen',
  'edit_audit_log_screen',
  'edit_bed_management_screen',
  'edit_billing_authorization_screen',
  'edit_billing_intake_screen',
  'edit_billing_settings',
  'edit_biopsychosocial_intake_screen',
  'edit_chef_dashboard_screen',
  'edit_client_chart_screen',
  'edit_client_discharge_screen',
  'edit_client_list_screen',
  'edit_custom_form_screen',
  'edit_custom_tp_template_screen',
  'edit_daily_questionnaire_screen',
  'edit_digital_dashboard_screen',
  'edit_doctors_orders_screen',
  'edit_encounter_transmission_screen',
  'edit_face_to_face_screen',
  'edit_forms_manager_3.0_screen',
  'edit_forms_manager_screen',
  'edit_group_therapy_screen',
  'edit_homework_screen',
  'edit_incident_report_screen',
  'edit_individual_session_screen',
  'edit_lab_orders_screen',
  'edit_lab_test_screen',
  'edit_lead_appointment_screen',
  'edit_licenses_&_certifications_screen',
  'edit_manage_admission_checklist_screen',
  'edit_manage_codes_screen',
  'edit_manage_discharge_checklist_screen',
  'edit_manage_discharge_screen',
  'edit_manage_groups_screen',
  'edit_manage_intake_screen',
  'edit_manage_labs_screen',
  'edit_manage_meals_screen',
  'edit_manage_rehab_screen',
  'edit_manage_roles_screen',
  'edit_manage_survey_screen',
  'edit_manage_tpr_screen',
  'edit_meal_plan_screen',
  'edit_medical_intake_screen',
  'edit_medical_provider_screen',
  'edit_my_calender_screen',
  'edit_notes_review_screen',
  'edit_notes_screen',
  'edit_occupancy_screen',
  'edit_pharmacy_screen',
  'edit_prescription_screen',
  'edit_prospective_client_list_screen',
  'edit_quick_intake_screen',
  'edit_re_admission_screen',
  'edit_referrals_screen',
  'edit_report_screen',
  'edit_review_screen_screen',
  'edit_scheduler_screen',
  'edit_shift_end_notes_screen',
  'edit_shift_rounds_screen',
  'edit_system_settings_screen',
  'edit_templates_screen',
  'edit_transferred_client_list_screen',
  'edit_transportation_screen',
  'edit_treatment_plan_screen',
  'edit_user_management_screen',
  'edit_utilization_review_screen',
] as const;
type Edit = (typeof EDIT_PERMISSIONS)[number];

export const VIEW_PERMISSIONS = [
  // Client data permissions:
  'view_client_id',
  'view_medicaid_number',
  'view_collaborate_md_provider',
  'view_social_security_number',
  // Navigation menu permissions:
  'view_admission_&_discharge_report_screen',
  'view_admission_intake_screen',
  'view_alerts_frequency_screen',
  'view_alerts_notification_sms_screen',
  'view_alerts_notifications_screen',
  'view_alerts_template_screen',
  'view_alerts_template_screen',
  'view_alleva_ai_menu',
  'view_alleva_billing_screen',
  'view_alleva_payment_screen',
  'view_alumni_menu',
  'view_alumni_screen',
  'view_audit_log_screen',
  'view_bed_management_screen',
  'view_billing_authorization_screen',
  'view_billing_intake_screen',
  'view_billing_menu',
  'view_billing_settings',
  'view_biopsychosocial_intake_screen',
  'view_calendar_menu',
  'view_chef_dashboard_menu',
  'view_chef_dashboard_screen',
  'view_client_chart_screen',
  'view_client_discharge_screen',
  'view_client_list_screen',
  'view_client_mar_screen',
  'view_client_prospects_menu',
  'view_client_reports_screen',
  'view_clients_menu',
  'view_company_settings_menu',
  'view_custom_form_screen',
  'view_custom_form_screen',
  'view_custom_forms_2_screen',
  'view_custom_tp_template_screen',
  'view_daily_questionnaire_screen',
  'view_dashboard_menu',
  'view_digital_dashboard_screen',
  'view_doctors_orders_menu',
  'view_doctors_orders_screen',
  'view_e-prescription_screen',
  'view_encounter_transmission_screen',
  'view_external_providers_screen',
  'view_face_to_face_screen',
  'view_family_portal_screen',
  'view_forms_manager_3.0_screen',
  'view_forms_manager_screen',
  'view_forms_manager_screen',
  'view_group_therapy_screen',
  'view_historical_events_screen',
  'view_homework_screen',
  'view_incident_report_screen',
  'view_incidents_menu',
  'view_individual_session_screen',
  'view_lab_orders_menu',
  'view_lab_orders_screen',
  'view_lab_test_screen',
  'view_lead_appointment_screen',
  'view_lead_report_screen',
  'view_licenses_&_certifications_screen',
  'view_manage_admission_checklist_screen',
  'view_manage_codes_screen',
  'view_manage_discharge_checklist_screen',
  'view_manage_discharge_screen',
  'view_manage_groups_screen',
  'view_manage_intake_screen',
  'view_manage_labs_screen',
  'view_manage_meals_screen',
  'view_manage_rehab_screen',
  'view_manage_roles_screen',
  'view_manage_survey_screen',
  'view_manage_tpr_screen',
  'view_meal_plan_screen',
  'view_medical_intake_screen',
  'view_medical_provider_screen',
  'view_medical_providers_menu',
  'view_medication_destruction_screen',
  'view_medication_scheduling_screen',
  'view_medication_screen',
  'view_medications_menu',
  'view_meds_count_report_screen',
  'view_meds_count_screen',
  'view_meds_management_screen',
  'view_my_calender_screen',
  'view_my_dashboard_menu',
  'view_notes_audit_screen',
  'view_notes_review_screen',
  'view_notes_screen',
  'view_occupancy_menu',
  'view_occupancy_report_screen',
  'view_occupancy_screen',
  'view_office_tp_template_screen',
  'view_order_settings_screen',
  'view_pharmacy_screen',
  'view_prospective_client_list_screen',
  'view_prospective_client_list_screen',
  'view_quick_intake_screen',
  'view_quick_intake_screen',
  'view_re_admission_menu',
  'view_re_admission_screen',
  'view_rec_therapy_assessment_screen',
  'view_referrals_menu',
  'view_referrals_screen',
  'view_reports_menu',
  'view_review_screen_menu',
  'view_review_screen_screen',
  'view_scheduler_screen',
  'view_scheduler_screen',
  'view_set_alerts_frequency_screen',
  'view_shift_end_notes_screen',
  'view_shift_notes_menu',
  'view_shift_rounds_menu',
  'view_shift_rounds_screen',
  'view_shifts_menu',
  'view_signature_review_screen',
  'view_survey_monkey_screen',
  'view_system_settings_menu',
  'view_system_settings_screen',
  'view_templates_screen',
  'view_transferred_client_list_screen',
  'view_transferred_client_list_screen',
  'view_transportation_screen',
  'view_treatment_plan_screen',
  'view_treatment_plan_v2_screen',
  'view_user_management_screen',
  'view_utilization_review_menu',
  'view_utilization_review_screen',
] as const;
type View = (typeof VIEW_PERMISSIONS)[number];

/**
 * The codec for runtime type checking of the action permissions string literals.
 *
 * Use `keyOf` instead of union when defining string literals.
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals
 */
export const ActionPermissionsCodec: io.Mixed = io.keyof(
  // Convert string literal to object with null values. For example
  // 'action_undischarge_clients' becomes { action_undischarge_clients: null }.
  ACTION_PERMISSIONS.reduce(
    (prev, current) => ({ ...prev, [current]: null }),
    {} as const,
  ),
) as unknown as io.Mixed;

/**
 * The codec for runtime type checking of the edit permissions string literals.
 *
 * Use `keyOf` instead of union when defining string literals.
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals
 */
export const EditPermissionsCodec: io.Mixed = io.keyof(
  // Convert string literal to object with null values. For example
  // 'edit_client_chart_screen' becomes { edit_client_chart_screen: null }.
  EDIT_PERMISSIONS.reduce(
    (prev, current) => ({ ...prev, [current]: null }),
    {} as const,
  ),
) as unknown as io.Mixed;

/**
 * The codec for runtime type checking of the view permissions string literals.
 *
 * Use `keyOf` instead of union when defining string literals.
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals
 */
export const ViewPermissionsCodec: io.Mixed = io.keyof(
  // Convert string literal to object with null values. For example
  // 'view_clients_menu' becomes { view_clients_menu: null }.
  VIEW_PERMISSIONS.reduce(
    (prev, current) => ({ ...prev, [current]: null }),
    {} as const,
  ),
) as unknown as io.Mixed;

export function decodePermissions(
  permissions?: readonly string[] | null,
): Permissions | null {
  return (
    permissions
      ?.map(
        (permission): Permission =>
          decode(
            io.union([
              ActionPermissionsCodec,
              EditPermissionsCodec,
              ViewPermissionsCodec,
            ]),
            permission,
            false,
          ),
      )
      .filter((permission) => isNonEmptyString(permission)) ?? null
  );
}
