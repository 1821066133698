import {
  ClientCOWSAssessmentApi,
  ClientCOWSAssessmentUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientCOWSAssessmentApi>();

type ClientCOWSAssessmentArgs = Omit<
  ClassProperties<ClientCOWSAssessment>,
  // Omit computed properties set on construct from outside data.
  'pulseScore' | 'scale' | 'score'
>;

export class ClientCOWSAssessment {
  public constructor(props: ClassProperties<ClientCOWSAssessmentArgs>) {
    this.anxiety = props.anxiety;
    this.bone = props.bone;
    this.giUpset = props.giUpset;
    this.gooseFlesh = props.gooseFlesh;
    this.pupilSize = props.pupilSize;
    this.pulse = props.pulse;
    this.restlessness = props.restlessness;
    this.runnyNose = props.runnyNose;
    this.sweating = props.sweating;
    this.tremors = props.tremors;
    this.yawning = props.yawning;
  }

  /**
   * The io-ts codec for runtime type checking of the Client COWS Assessment
   * API model.
   */
  public static readonly Codec = io.type(
    {
      anxiety: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(4),
        io.null,
      ]),
      bone: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(4),
        io.null,
      ]),
      giUpset: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(3),
        io.literal(5),
        io.null,
      ]),
      gooseFlesh: io.union([
        io.literal(0),
        io.literal(3),
        io.literal(5),
        io.null,
      ]),
      pupil: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(5),
        io.null,
      ]),
      restlessness: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(3),
        io.literal(5),
        io.null,
      ]),
      runnyNose: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(4),
        io.null,
      ]),
      sweating: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(3),
        io.literal(4),
        io.null,
      ]),
      tremors: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(4),
        io.null,
      ]),
      yawning: io.union([
        io.literal(0),
        io.literal(1),
        io.literal(2),
        io.literal(4),
        io.null,
      ]),
    },
    'ClientCOWSAssessmentApi',
  );

  @api({ key: 'anxiety' }) public readonly anxiety: 0 | 1 | 2 | 4;
  @api({ key: 'bone' }) public readonly bone: 0 | 1 | 2 | 4;
  @api({ key: 'giUpset' }) public readonly giUpset: 0 | 1 | 2 | 3 | 5;
  @api({ key: 'gooseFlesh' }) public readonly gooseFlesh: 0 | 3 | 5;
  @api({ key: 'pupil' }) public readonly pupilSize: 0 | 1 | 2 | 5;
  public readonly pulse: number | null;
  @api({ key: 'restlessness' }) public readonly restlessness: 0 | 1 | 3 | 5;
  @api({ key: 'runnyNose' }) public readonly runnyNose: 0 | 1 | 2 | 4;
  @api({ key: 'sweating' }) public readonly sweating: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'tremors' }) public readonly tremors: 0 | 1 | 2 | 4;
  @api({ key: 'yawning' }) public readonly yawning: 0 | 1 | 2 | 4;

  public get pulseScore(): number {
    if (this.pulse === null) {
      return 0;
    } else if (this.pulse > 120) {
      return 4;
    } else if (this.pulse >= 101) {
      return 2;
    } else if (this.pulse >= 81) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * Get the total COWS assessment score.
   *
   * @returns The total COWS assessment score along with the pulse score which
   * is factored in to the total score.
   */
  public get score(): number {
    const assessmentScore =
      this.anxiety +
      this.bone +
      this.giUpset +
      this.gooseFlesh +
      this.pupilSize +
      this.restlessness +
      this.runnyNose +
      this.sweating +
      this.tremors +
      this.yawning;
    return (
      assessmentScore +
      // The pulse assessment score is factored into the assessment score so we
      // must include it in the total score.
      this.pulseScore
    );
  }

  /**
   * Get the scales textual context of the assessment.
   *
   * @returns The scale of the assessment textual context.
   */
  public get scale(): string {
    if (this.score.isBetween(1, 4)) {
      return 'Absent or Minimal Withdrawal';
    } else if (this.score.isBetween(5, 12)) {
      return 'Mild Withdrawal';
    } else if (this.score.isBetween(13, 24)) {
      return 'Moderate Withdrawal';
    } else if (this.score.isBetween(25, 36)) {
      return 'Moderate Severe Withdrawal';
    } else if (this.score > 36) {
      return 'Severe Withdrawal';
    } else {
      return 'None';
    }
  }

  /**
   * Deserializes a Client COWS Assessment object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client COWS Assessment object.
   * @throws An error if the value is not a valid Client COWS Assessment
   * object.
   */
  public static deserialize(
    value: NonNullable<
      ClientCOWSAssessmentApi & {
        // Pulse is factored into the assessment so we must include it.
        pulse: ClientCOWSAssessment['pulse'];
      }
    >,
  ): ClientCOWSAssessment {
    const decoded = decode(ClientCOWSAssessment.Codec, value);
    return new ClientCOWSAssessment({
      anxiety: decoded.anxiety ?? 0,
      bone: decoded.bone ?? 0,
      giUpset: decoded.giUpset ?? 0,
      gooseFlesh: decoded.gooseFlesh ?? 0,
      pupilSize: decoded.pupil ?? 0,
      pulse: value.pulse,
      restlessness: decoded.restlessness ?? 0,
      runnyNose: decoded.runnyNose ?? 0,
      sweating: decoded.sweating ?? 0,
      tremors: decoded.tremors ?? 0,
      yawning: decoded.yawning ?? 0,
    });
  }

  /**
   * Deserializes a list of Client COWS Assessment objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client COWS Assessment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client COWS Assessment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientCOWSAssessmentApi>>,
  ): readonly ClientCOWSAssessment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client COWS Assessment objects.');
    }
    return values.map(ClientCOWSAssessment.deserialize);
  }
}

export class ClientCOWSAssessmentUpdate extends ClientCOWSAssessment {
  public constructor(props: ClassProperties<ClientCOWSAssessmentUpdate>) {
    super(props);
  }

  /**
   * Serializes a Client COWS Score object to an API model.
   *
   * @returns The serialized Client COWS Score object.
   */
  public serialize(): ClientCOWSAssessmentUpdateApi {
    return {
      ...this,
      pupil: this.pupilSize,
    };
  }
}
