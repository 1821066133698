import { delay, firstValueFrom, of } from 'rxjs';

import {
  AnimationEvent,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Event } from '@angular/router';

/** The height of the `alleva-loading-indicator` element in px. */
const loaderHeight = 120;
/** The width of the `alleva-loading-indicator` element in px. */
const loaderWidth = 500;

/** The number of Alleva animated "dots" to display on each side. */
const dotCount = 6;
/** The height of the "dot" element in px. */
const dotHeight = 80;
/** The width of the "dot" element in px. */
const dotWidth = 80;
/** The default animation delay of the Alleva "dots". */
const dotAnimationDelay = 0.25;
/** The animation duration for the Alleva "dots". */
const dotAnimationDuration = 1.25;

/** The global CSS variable for the Alleva Orange color. */
const allevaOrange = 'var(--alleva-orange)';
/** The global CSS variable for the Alleva Blue color. */
const allevaBlue = 'var(--alleva-blue)';

/**
 * A loader component that displays a loading animation.
 */
@Component({
  selector: 'alleva-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  animations: [
    // Fade in from opacity 0 to 1 on page load.
    trigger('fade-in', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
    // Animate the Alleva SVG logo color.
    trigger('logo', [
      transition(
        '* <=> *',
        [
          animate('3s linear', style({ color: '{{previous}}' })),
          animate('3s linear', style({ color: '{{current}}' })),
          animate('3s linear', style({ color: allevaOrange })),
        ],
        {
          params: { current: allevaOrange, previous: allevaOrange },
        },
      ),
    ]),
    // Animate the Alleva SVG logo background color.
    trigger('logo-back', [
      transition(
        '* <=> *',
        [
          animate('3s linear', style({ backgroundColor: '{{previous}}' })),
          animate('3s linear', style({ backgroundColor: '{{current}}' })),
          animate('3s linear', style({ backgroundColor: allevaBlue })),
        ],
        { params: { current: allevaBlue, previous: allevaBlue } },
      ),
    ]),
    // Animate the left sided dots on boolean transition.
    trigger('left', [
      state(
        'true',
        style({
          backgroundColor: '{{backgroundColor}}',
          transform: `translateX(${
            loaderWidth / 2 - (dotWidth + dotWidth / 4)
          }px) scaleX(0.15)`,
        }),
        { params: { backgroundColor: allevaBlue } },
      ),
      transition('false => true', animate('{{duration}}s {{delay}}s linear'), {
        params: { delay: 0, duration: 0 },
      }),
    ]),
    // Animate the right sided dots on boolean transition.
    trigger('right', [
      state(
        'true',
        style({
          backgroundColor: '{{backgroundColor}}',
          transform: `translateX(-${
            loaderWidth / 2 - (dotWidth + dotWidth / 4)
          }px) scaleX(0.15)`,
        }),
        { params: { backgroundColor: allevaBlue } },
      ),
      transition('false => true', animate('{{duration}}s {{delay}}s linear'), {
        params: { delay: 0, duration: 0 },
      }),
    ]),
  ],
  styleUrls: ['./loading-indicator.component.scss'],
  // Inline styles to use shared variables from the component.
  styles: [
    `
      .loading-container {
        height: ${loaderHeight}px;
        width: ${loaderWidth}px;
      }
      .dot {
        height: ${dotHeight}px;
        min-width: ${dotWidth}px;
        width: ${dotWidth}px;

        &.logo {
          left: calc((${loaderWidth}px / 2) - (${dotWidth}px / 2));
        }
      }
    `,
  ],
})
export class LoadingIndicatorComponent {
  /** The referenced states of all the animations. */
  protected animationStates: LoadingAnimationStates =
    getInitialLoadingAnimationStates();

  /** Set attribute to self trigger an ease-in animation on load. */
  @Input() @HostBinding('@fade-in') public fadeIn = true;

  /**
   * The maximum amount of time that passes before an error is displayed.
   *
   * @default 180000 Milliseconds (180 seconds, or 3 minutes).
   */
  @Input() public displayErrorAfterMs = 180000;

  /** When true, loading indicator will be centered in the viewport. */
  @Input() @HostBinding('class.centered') public isCentered = false;

  /** When true, loading indicator will be displayed in full screen mode. */
  @Input() @HostBinding('class.full-screen') public isFullscreen = false;

  /**
   * The label to display what is currently being loaded.
   *
   * @default 'Loading'.
   */
  @Input() public set labelHidden(value: BooleanInput) {
    this.#isLabelHidden = coerceBooleanProperty(value);
  }
  public get labelHidden(): boolean {
    return this.#isLabelHidden;
  }
  #isLabelHidden = false;

  /** Run state checks every time the window is focused or blurred. */
  @HostListener('window:focus', ['$event'])
  @HostListener('window:blur', ['$event'])
  protected onWindowBlurOrFocus(_event: Event): void {
    // Reset animation state on window focus or blur. Angular animations do not
    // play if the window isn't in focus, so our delays in state memory will
    // get out of sync if we don't do this.
    this.animationStates = getInitialLoadingAnimationStates(
      this.animationStates,
    );
  }

  /**
   * Handles the animation completion ("done") event for the animations.
   *
   * @param animationState The associated animation state to update.
   * @param stateKey The parent key where the `animationState` is stored.
   * @param event The animation event to handle the state change.
   */
  protected async onAnimationComplete(
    animationState: AnimationState,
    stateKey: keyof LoadingAnimationStates,
    event: AnimationEvent,
  ): Promise<void> {
    // Set the animation state to true to indicate the animation is complete.
    animationState.isAnimationComplete = true;
    // Verify event of state change is true, then reset the states for certain
    // animations.
    if (Boolean(event.toState) === true) {
      // Await a single tick for the animation to complete.
      await firstValueFrom(of(null).pipe(delay(0)));
      // Handle the animation state change.
      switch (stateKey) {
        case 'leftDots':
        case 'rightDots': {
          if (!animationState.backgroundColor) {
            throw new Error(
              'The "backgroundColor" property must be defined for "dots".',
            );
          }
          // Update the background color for the next animation cycle.
          animationState.backgroundColor.current = getRandomAllevaThemeColor();
          // After the initial loop completes, we reset the animation states to
          // use the default delay since we want them to re-loop immediately.
          animationState.delay = dotAnimationDelay;
          // Reset the animation state to false so it will loop once again.
          animationState.isAnimationComplete = false;
          break;
        }
        case 'logo': {
          if (!animationState.color) {
            throw new Error('The "color" property must be defined for logos.');
          }
          // Update the color for the next animation cycle.
          animationState.color = {
            current: getRandomAllevaLogoColor(
              // Exclude the previus color so we always get a new color.
              animationState.color.current,
            ),
            previous: animationState.color.current,
          };
          // Reset the animation state to false so it will loop once again.
          animationState.isAnimationComplete = false;
          break;
        }
        case 'backDot': {
          if (!animationState.backgroundColor) {
            throw new Error(
              'The "backgroundColor" property must be defined for "dots".',
            );
          }
          // Update the background color for the next animation cycle.
          animationState.backgroundColor.current = getRandomAllevaThemeColor();
          animationState.backgroundColor.previous =
            animationState.backgroundColor.current;
          // Reset the animation state to false so it will loop once again.
          animationState.isAnimationComplete = false;
          break;
        }
        default: {
          throw new Error('Unhandled stateKey.');
        }
      }
    }
  }

  /**
   * Handle mouse down events for certain images.
   *
   * @param event The mouse down event to act upon.
   */
  protected onImageMouseDown(event: MouseEvent): void {
    // Cancel the default behavior of the mouse down event. This prevents users
    // from accidentally dragging the image.
    event.preventDefault();
  }
}

/** Refrence of all possible animations states. */
interface LoadingAnimationStates {
  /** The dot "state" for the logo background. */
  backDot: AnimationState;
  /** An array of "state" for each left sided dot. */
  leftDots: AnimationState[];
  /** The dot "state" for the logo. */
  logo: AnimationState;
  /** An array of "state" for each right sided dot. */
  rightDots: AnimationState[];
}

/** The state of a single animation. */
interface AnimationState {
  /** The font or svg color of the element. */
  color?: {
    current: string;
    previous: string;
  };
  /** The background color of the element. */
  backgroundColor?: {
    current: string;
    previous: string;
  };
  /** The delay until the animation for the "dot" begins. */
  delay?: number;
  /** The duration that the "dot" is animated. */
  duration?: number;
  /** Whether or not the animation for this "dot" has completed its loop. */
  isAnimationComplete: boolean;
}

/** A list of all available Alleva global theme colors. */
const allevaThemeColors = [
  'var(--alleva-blue)',
  'var(--alleva-blue-dark)',
  'var(--alleva-blue-light)',
  'var(--alleva-orange)',
  'var(--alleva-orange-dark)',
  'var(--alleva-orange-light)',
  'var(--black)',
];

/** A list of all available Alleva logo colors. */
const allevaLogoColors = [
  'var(--white)',
  'var(--alleva-orange)',
  'var(--black)',
];

/**
 * Get a random Alleva logo color from a predefined string array containing
 * global css logo variables.
 *
 * @param excludedColor A color to exclude from the random selection.
 * @returns A random color from the `allevaLogoColors` array.
 */
function getRandomAllevaLogoColor(excludedColor?: string): string {
  const colors = excludedColor
    ? allevaLogoColors.filter((color) => color !== excludedColor)
    : allevaLogoColors;
  return colors[Math.floor(Math.random() * colors.length)];
}

/**
 * Get a random Alleva theme color from a predefined string array containing
 * global css theme variables.
 *
 * @param excludedBackgroundColor A background color to exclude from the random
 * selection.
 * @returns A random color from the `allevaThemeColors` array.
 */
function getRandomAllevaThemeColor(excludedBackgroundColor?: string): string {
  const colors = excludedBackgroundColor
    ? allevaThemeColors.filter((color) => color !== excludedBackgroundColor)
    : allevaThemeColors;
  return colors[Math.floor(Math.random() * colors.length)];
}

/**
 * Returns an object containing the initial state of all the animations.
 *
 * @param previousValues An object containing the previous values of the
 * animations. Because we don't need to "stagger" the delay of __all__
 * animations, we can provide previous values that we can re-use so we don't
 * have to reset the animation state for them.
 * @returns An object containing the initial state of all the animations.
 */
function getInitialLoadingAnimationStates(
  previousValues?: LoadingAnimationStates,
): LoadingAnimationStates {
  let delayLeft = -dotAnimationDelay;
  let delayRight = -dotAnimationDelay;
  /** An array of numbers from 0 to X, for a total count of X "dots". */
  const dotCounts = [...Array(dotCount).keys()];
  return {
    backDot: {
      backgroundColor: {
        current:
          previousValues?.backDot.backgroundColor?.current ??
          getRandomAllevaThemeColor(
            // Exclude the default logo background color as it will init with this
            // color.
            allevaBlue,
          ),
        previous:
          previousValues?.backDot.backgroundColor?.previous ?? allevaBlue,
      },
      isAnimationComplete: previousValues?.backDot.isAnimationComplete ?? false,
    },
    leftDots: dotCounts.map(
      (): AnimationState => ({
        backgroundColor: {
          current: getRandomAllevaThemeColor(allevaBlue),
          previous: allevaBlue,
        },
        delay: (delayLeft += dotAnimationDelay),
        duration: dotAnimationDuration,
        isAnimationComplete: false,
      }),
    ),
    logo: {
      color: {
        current:
          previousValues?.logo.color?.current ??
          getRandomAllevaLogoColor(
            // Exclude the default logo color as it will init with this color.
            allevaOrange,
          ),
        previous: previousValues?.logo.color?.previous ?? allevaOrange,
      },
      isAnimationComplete: previousValues?.logo.isAnimationComplete ?? false,
    },
    rightDots: dotCounts.map(
      (): AnimationState => ({
        backgroundColor: {
          current: getRandomAllevaThemeColor(),
          previous: allevaBlue,
        },
        delay: (delayRight += dotAnimationDelay),
        duration: dotAnimationDuration,
        isAnimationComplete: false,
      }),
    ),
  };
}
