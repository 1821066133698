<div class="grid-list">
  <ng-container *ngFor="let item of items; let i = index; trackBy: trackById">
    @if (Client.isInstanceFrom(item)) {
      <alleva-client-card
        (click)="click(item)"
        [class.clickable]="isClickable"
        [client]="item"
      ></alleva-client-card>
    } @else if (ReferralContact.isInstanceFrom(item)) {
      <alleva-referral-card
        (click)="click(item)"
        [class.clickable]="isClickable"
        [referral]="item"
      ></alleva-referral-card>
    } @else if (UserBase.isInstanceFrom(item)) {
      <alleva-user-card
        (click)="click(item)"
        [class.clickable]="isClickable"
        [user]="item"
      ></alleva-user-card>
    }
  </ng-container>
</div>
