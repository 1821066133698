import { RxApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<RxApi>();

export class Rx {
  public constructor(props: ClassProperties<Rx>) {
    this.bin = props.bin;
    this.group = props.group;
    this.name = props.name;
    this.pcn = props.pcn;
    this.phoneNumber = props.phoneNumber;
  }

  /**
   * The io-ts codec for runtime type checking of the Rx model.
   */
  public static readonly Codec = io.type(
    {
      bin: io.union([io.string, io.null]),
      group: io.union([io.string, io.null]),
      name: io.union([io.string, io.null]),
      pcn: io.union([io.string, io.null]),
      phoneNumber: io.union([io.string, io.null]),
    },
    'RxApi',
  );

  @api({ key: 'bin' }) public readonly bin: string | null;
  @api({ key: 'group' }) public readonly group: string | null;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'pcn' }) public readonly pcn: string | null;
  @api({ key: 'phoneNumber' }) public readonly phoneNumber: string | null;

  /**
   * Deserializes a Rx object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Rx object.
   * @throws An error if the value is not a valid Rx object.
   */
  public static deserialize(value: NonNullable<RxApi>): Rx {
    const decoded = decode(Rx.Codec, value);
    return new Rx(decoded);
  }

  /**
   * Deserializes a list of Rx objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Rx objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Rx objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<RxApi>>,
  ): readonly Rx[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Rx objects.');
    }
    return values.map(Rx.deserialize);
  }
}

export class RxUpdate extends Rx {
  /**
   * Serialize the model to an API object.
   *
   * @returns The serialized API object.
   */
  public serialize(): RxApi {
    return this;
  }
}
