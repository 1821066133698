import { BillingRuleRateApi, BillingRuleRateCreateApi } from 'api/models';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';

const api = apiDecorator<BillingRuleRateApi>();

export class BillingRuleRateCreate {
  public constructor(props: ClassProperties<BillingRuleRateCreate>) {
    this.contractRate = props.contractRate;
    this.endDateTime = props.endDateTime;
    this.startDate = props.startDate;
    this.unitRate = props.unitRate;
  }

  @api({ key: 'contractRate' }) public readonly contractRate: number | null;
  // It's not possible to add endDate via the UI for the "Create" model.
  @api({ key: 'endDate' }) public readonly endDateTime: null;
  @api({ key: 'startDate' }) public readonly startDate: DateTime;
  @api({ key: 'unitRate' }) public readonly unitRate: number;

  public serialize(): BillingRuleRateCreateApi {
    const startDate = this.startDate.toISO();
    if (!startDate) {
      throw new Error('Failed to serialize `startDate` in BillingRuleRate.');
    }

    return {
      contractRate: this.contractRate,
      endDate: this.endDateTime,
      startDate,
      unitRate: this.unitRate,
    };
  }
}
