import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { ContainerModule } from 'src/app/components/container/container.module';
import { InputDropdownModule } from 'src/app/components/forms/input-dropdown/input-dropdown.module';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingIndicatorModule } from 'src/app/components/loading-indicator/loading-indicator.module';
import { MenuCategoryItemComponent } from 'src/app/components/menu/menu-category-item.component';
import { MenuItemComponent } from 'src/app/components/menu/menu-item.component';
import { MenuSubLevelItemComponent } from 'src/app/components/menu/menu-sub-level-item.component';
import { MenuTopLevelItemComponent } from 'src/app/components/menu/menu-top-level-item.component';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { PersonSearchModule } from 'src/app/components/person-search/person-search.module';

import { DirectivesModule } from 'src/app/directives/directives.module';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    MenuCategoryItemComponent,
    MenuComponent,
    MenuItemComponent,
    MenuSubLevelItemComponent,
    MenuTopLevelItemComponent,
  ],
  exports: [MenuComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    ContainerModule,
    DirectivesModule,
    IconModule,
    InputDropdownModule,
    LoadingIndicatorModule,
    MatBadgeModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    PersonSearchModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [DatePipe],
})
export class MenuModule {}
