<a
  (click)="toggleSubMenu()"
  [class.collapsed]="isCollapsedChanges | async"
  [class.current-selected-client]="topLevelMenuItem.isClientMenu"
  [matTooltip]="(tooltipDisplayChanges | async) || ''"
  [routerLinkActiveOptions]="{ exact: true }"
  [routerLink]="menuItemLinkChanges | async"
  mat-list-item
  matTooltipPosition="right"
  routerLinkActive="active"
  type="button"
>
  <alleva-icon
    *ngIf="
      !topLevelMenuItem.isClientMenu && topLevelMenuItem.iconName as iconName
    "
    [class.center]="isCollapsedChanges | async"
    [name]="iconName"
    colorHex="#fff"
  ></alleva-icon>
  <ng-container *ngIf="topLevelMenuItem.isClientMenu">
    <img
      *ngIf="selectedClientChanges | async as client"
      [class.collapsed-image]="isCollapsedChanges | async"
      [src]="
        client.profileImage || 'assets/images/clients/temp.png'
          | authBlob
          | async
      "
    />
  </ng-container>
  <ng-container *ngIf="selectedClientChanges | async as selectedClient">
    <div *ngIf="topLevelMenuItem.isClientMenu" class="client-menu-item">
      <ng-container *ngIf="(isCollapsedChanges | async) === false">
        <span
          [innerText]="getClientName(selectedClient)"
          class="current-client"
        ></span>
        <span class="current-chart">(Current Chart)</span>
      </ng-container>
    </div>
  </ng-container>
  <span
    *ngIf="
      !topLevelMenuItem.isClientMenu && (isCollapsedChanges | async) === false
    "
    [innerText]="topLevelMenuItem.label"
  ></span>
  <ng-container *ngIf="(isCollapsedChanges | async) === false">
    <alleva-icon
      *ngIf="topLevelMenuItem.subLevelMenuItems"
      [class.expanded]="isSubMenuOpenChanges | async"
      class="sub-menu-arrow"
      colorHex="#fff"
      name="keyboard_arrow_right"
    ></alleva-icon>
  </ng-container>
</a>

<ng-container *ngIf="isSubMenuOpenChanges | async">
  <ng-container
    *ngFor="let subLevelMenuItem of subLevelMenuItemsChanges | async"
  >
    <alleva-menu-sub-level-item
      *ngIf="subLevelMenuItem.isDisplayed"
      [class.collapsed]="isCollapsedChanges | async"
      [isCollapsed]="isCollapsedChanges | async"
      [selectedClient]="selectedClientChanges | async"
      [subLevelMenuItem]="subLevelMenuItem"
    ></alleva-menu-sub-level-item>
  </ng-container>
</ng-container>
