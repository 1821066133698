import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, startWith } from 'rxjs';

import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * A directive for custom Angular Material form groups.
 *
 * @example `InputCustomFormGroupComponent extends CustomFormGroupDirective<FormGroupType>`
 */
@UntilDestroy()
@Directive()
export class CustomFormGroupDirective<T>
  extends CustomInputDirective<T>
  implements ControlValueAccessor, OnInit
{
  public constructor(
    @Optional() @Self() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  // Support for multi control form groups.
  protected formGroup!: FormGroup<{ [K in keyof T]: FormControl }>;

  public override ngOnInit(): void {
    super.ngOnInit();

    // Set the initial disabled state for form groups. This needs to be done
    // manually as form groups are treated differently than form controls and
    // the custom nested controls will update with this post-initialization.
    this.disabled = this.baseControl.disabled;

    // When the base control updates, update the form group with the new values.
    this.baseControl.valueChanges
      .pipe(startWith(this.baseControl.value), untilDestroyed(this))
      .subscribe((value) => {
        if (!this.formGroup) {
          return;
        }

        if (value !== null && typeof value === 'object') {
          this.formGroup.patchValue(value, { emitEvent: false });
          this.formGroup.markAsDirty();
        } else {
          // Update each control in the form group to null (reset).
          for (const controlName in this.formGroup.controls) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.formGroup.controls,
                controlName,
              )
            ) {
              const control = this.formGroup.controls[controlName];
              if (value === null) {
                control.reset(null, { emitEvent: false });
              }
            }
          }
          this.formGroup.markAsUntouched();
        }
      });

    // When the form group updates, update the validation states.
    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((formGroupValue) => {
        const isBaseRequired = this.baseControl.hasValidator(
          Validators.required,
        );
        const shouldHaveRequired =
          isBaseRequired ||
          Object.values(formGroupValue).some((value) => value !== null);

        Object.keys(this.formGroup.controls).forEach((controlName) => {
          const control =
            this.formGroup.controls[
              controlName as keyof typeof this.formGroup.controls
            ];

          const hasRequired = control.hasValidator(Validators.required);
          if (shouldHaveRequired && !hasRequired) {
            control.addValidators([Validators.required]);
            control.markAsTouched();
            control.updateValueAndValidity();
          } else if (!shouldHaveRequired && hasRequired) {
            control.removeValidators(Validators.required);
            control.markAsTouched();
            control.updateValueAndValidity();
          }
        });
      });

    // When the disabled state changes, update the form group controls.
    this.disabledChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((disabled) => {
        if (this.formGroup === undefined) {
          return;
        }

        for (const controlName in this.formGroup.controls) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.formGroup.controls,
              controlName,
            )
          ) {
            const control = this.formGroup.controls[controlName];
            if (disabled) {
              control.disable({ emitEvent: false });
            } else {
              control.enable({ emitEvent: false });
            }
          }
        }
      });
  }

  public markAllAsTouched(): void {
    this.formGroup.markAllAsTouched();
  }

  public get hasErrors(): boolean {
    for (const controlName in this.formGroup.controls) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.formGroup.controls,
          controlName,
        )
      ) {
        const control = this.formGroup.get(controlName);
        if (
          control?.invalid &&
          (control.dirty || control.touched || control.pending)
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

export function isCustomFormGroup<T>(
  value: unknown,
): value is CustomFormGroupDirective<T> {
  return (
    (value as CustomFormGroupDirective<T>).markAllAsTouched !== undefined &&
    (value as CustomFormGroupDirective<T>).hasErrors !== undefined
  );
}
