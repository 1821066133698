<div *ngIf="isFullscreen" class="backdrop"></div>
<div class="loading-container">
  <div
    (@left.done)="onAnimationComplete(dotState, 'leftDots', $event)"
    *ngFor="let dotState of animationStates.leftDots"
    [@left]="{
      value: dotState.isAnimationComplete,
      params: {
        backgroundColor: dotState.backgroundColor?.current,
        delay: dotState.delay,
        duration: dotState.duration
      }
    }"
    class="dot left"
  ></div>
  <div
    (@logo-back.done)="onAnimationComplete(state, 'backDot', $event)"
    *ngIf="animationStates.backDot as state"
    [@logo-back]="{
      value: state.isAnimationComplete,
      params: state.backgroundColor
    }"
    class="dot logo back"
  ></div>
  <div
    (@logo.done)="onAnimationComplete(state, 'logo', $event)"
    *ngIf="animationStates.logo as state"
    [@logo]="{ value: state.isAnimationComplete, params: state.color }"
    class="dot logo front"
  >
    <ng-container [ngTemplateOutlet]="allevaIcon"></ng-container>
  </div>
  <div
    (@right.done)="onAnimationComplete(dotState, 'rightDots', $event)"
    *ngFor="let dotState of animationStates.rightDots"
    [@right]="{
      value: dotState.isAnimationComplete,
      params: {
        backgroundColor: dotState.backgroundColor?.current,
        delay: dotState.delay,
        duration: dotState.duration
      }
    }"
    class="dot right"
  ></div>
  <div class="loading-text">
    <alleva-loading-text
      *ngIf="!labelHidden"
      [displayErrorAfterMs]="displayErrorAfterMs"
    ></alleva-loading-text>
  </div>
</div>

<ng-template #allevaIcon>
  <svg
    height="59"
    viewBox="0 0 62 59"
    width="62"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>ALLEVA</title>
    <path
      d="
        M0.823242 0.0585938H49.1762C55.8036 0.0585938 61.1762 5.43118 61.1762 
        12.0586V26.4268H12.8233C6.19583 26.4268 0.823242 21.0542 0.823242 
        14.4268V0.0585938Z
      "
      fill="currentColor"
    />
    <path
      d="
        M0.823242 31.8081H49.1762C55.8036 31.8081 61.1762 37.1807 61.1762 
        43.8081V58.1763H12.8233C6.19583 58.1763 0.823242 52.8037 0.823242 
        46.1763V31.8081Z
      "
      fill="currentColor"
    />
  </svg>
</ng-template>
