import {
  ClientMedicationDiscontinuedApi,
  ClientMedicationDiscontinuedUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientMedicationDiscontinuedBy } from 'src/app/models/client/client-medication-discontinued-by.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientMedicationDiscontinuedApi>();

export class ClientMedicationDiscontinued {
  public constructor(props: ClassProperties<ClientMedicationDiscontinued>) {
    this.by = props.by;
    this.date = props.date;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Medication Created
   * By API model.
   */
  public static readonly Codec = io.type(
    {
      by: ClientMedicationDiscontinuedBy.Codec,
      date: io.string,
    },
    'ClientMedicationDiscontinuedApi',
  );

  @api({ key: 'by' }) public readonly by: ClientMedicationDiscontinuedBy;
  @api({ key: 'date' }) public readonly date: DateTime;

  /**
   * Deserializes a Client Medication Discontinued object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication Discontinued object.
   * @throws An error if the value is not a valid Client Medication Discontinued
   * object.
   */
  public static deserialize(
    value: NonNullable<ClientMedicationDiscontinuedApi>,
    deserializationArgs: ClientMedicationDiscontinuedDeserializationArgs,
  ): ClientMedicationDiscontinued {
    const decoded = decode(ClientMedicationDiscontinued.Codec, value);
    return new ClientMedicationDiscontinued({
      by: ClientMedicationDiscontinuedBy.deserialize(
        decoded.by,
        deserializationArgs,
      ),
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
    });
  }

  /**
   * Deserializes a list of Client Medication Discontinued objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication Discontinued objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Medication
   * Discontinued objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientMedicationDiscontinuedApi>>,
    deserializationArgs: ClientMedicationDiscontinuedDeserializationArgs,
  ): readonly ClientMedicationDiscontinued[] {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Client Medication Discontinued objects.',
      );
    }
    return values.map((clientMedicationDiscontinued) =>
      ClientMedicationDiscontinued.deserialize(
        clientMedicationDiscontinued,
        deserializationArgs,
      ),
    );
  }
}

export class ClientMedicationDiscontinuedUpdate extends ClientMedicationDiscontinued {
  public constructor(
    props: ClassProperties<ClientMedicationDiscontinuedUpdate>,
  ) {
    super(props);
  }

  /**
   * Serializes a Client Medication Discontinued object to an API model.
   *
   * @returns The serialized Client Medication Discontinued object.
   */
  public serialize(): ClientMedicationDiscontinuedUpdateApi {
    return {
      by: { guid: this.by.guid },
      date: this.date.toISO()!,
    };
  }
}

export interface ClientMedicationDiscontinuedDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
