import { ClientCIWAApi, ClientCIWAUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import {
  ClientCIWAAssessment,
  ClientCIWAAssessmentUpdate,
} from 'src/app/models/client/client-ciwa-assessment.model';
import {
  ClientCIWACreator,
  ClientCIWACreatorUpdate,
} from 'src/app/models/client/client-ciwa-creator.model';
import {
  ClientCIWAReviewer,
  ClientCIWAReviewerUpdate,
} from 'src/app/models/client/client-ciwa-reviewer.model';
import {
  ClientCIWAVitals,
  ClientCIWAVitalsUpdate,
} from 'src/app/models/client/client-ciwa-vitals.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientCIWAApi>();
const apiUpdate = apiDecorator<ClientCIWAUpdateApi>();

abstract class ClientCIWABase {
  public constructor(props: ClassProperties<ClientCIWABase>) {
    this.date = props.date;
    this.notes = props.notes;
  }

  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'notes' }) public readonly notes: string | null;
}

/**
 * The Client CIWA ("Clinical Withdrawal Assessment") model.
 */
export class ClientCIWA extends ClientCIWABase {
  public constructor(props: ClassProperties<ClientCIWA>) {
    super(props);

    this.administered = props.administered;
    this.assessment = props.assessment;
    this.createdBy = props.createdBy;
    this.id = props.id;
    this.reviewers = props.reviewers;
    this.score = props.score;
    this.vitals = props.vitals;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA API model.
   */
  public static readonly Codec = io.type(
    {
      administered: io.union([ClientCIWAReviewer.Codec, io.undefined]),
      assessment: ClientCIWAAssessment.Codec,
      createdBy: ClientCIWACreator.Codec,
      date: io.string,
      id: io.number,
      notes: io.union([io.string, io.null]),
      reviewers: io.union([io.array(ClientCIWAReviewer.Codec), io.null]),
      score: io.number,
      vitals: io.union([ClientCIWAVitals.Codec, io.null]),
    },
    'ClientCIWAApi',
  );

  @api({ key: 'administered' })
  public readonly administered: ClientCIWAReviewer | null;
  @api({ key: 'assessment' })
  public readonly assessment: ClientCIWAAssessment;
  @api({ key: 'createdBy' }) public readonly createdBy: ClientCIWACreator;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'reviewers' }) public readonly reviewers:
    | readonly ClientCIWAReviewer[]
    | null;
  @api({ key: 'score' }) public readonly score: number;
  @api({ key: 'vitals' }) public readonly vitals: ClientCIWAVitals | null;

  /**
   * Deserializes a Client CIWA object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA object.
   * @throws An error if the value is not a valid Client CIWA object.
   */
  public static deserialize(
    value: NonNullable<ClientCIWAApi>,
    deserializationArgs: ClientCIWADeserializationArgs,
  ): ClientCIWA {
    const decoded = decode(ClientCIWA.Codec, value);
    return new ClientCIWA({
      administered: decoded.administered
        ? ClientCIWAReviewer.deserialize(
            decoded.administered,
            deserializationArgs,
          )
        : null,
      assessment: ClientCIWAAssessment.deserialize(decoded.assessment),
      createdBy: ClientCIWACreator.deserialize(
        decoded.createdBy,
        deserializationArgs,
      ),
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      id: decoded.id,
      notes: decoded.notes ?? null,
      reviewers: decoded.reviewers
        ? decoded.reviewers.map((reviewer) =>
            ClientCIWAReviewer.deserialize(reviewer, deserializationArgs),
          )
        : null,
      score: decoded.score,
      vitals: decoded.vitals
        ? ClientCIWAVitals.deserialize(decoded.vitals, deserializationArgs)
        : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA objects from an API/unknown model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<unknown>>,
    deserializationArgs: ClientCIWADeserializationArgs,
  ): readonly ClientCIWA[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA objects.');
    }
    return values.map((clientCIWA) =>
      ClientCIWA.deserialize(clientCIWA, deserializationArgs),
    );
  }
}

export class ClientCIWAUpdate extends ClientCIWABase {
  public constructor(props: ClassProperties<ClientCIWAUpdate>) {
    super(props);

    this.administered = props.administered;
    this.createdBy = props.createdBy;
    this.reviewers = props.reviewers;
    this.assessment = props.assessment;
    this.vitals = props.vitals;
  }

  @apiUpdate({ key: 'administered' })
  public readonly administered: ClientCIWAReviewerUpdate;
  @apiUpdate({ key: 'assessment' })
  public readonly assessment: ClientCIWAAssessmentUpdate;
  @apiUpdate({ key: 'createdBy' })
  public readonly createdBy: ClientCIWACreatorUpdate;
  @apiUpdate({ key: 'reviewers' }) public readonly reviewers:
    | readonly ClientCIWAReviewerUpdate[]
    | null;
  @apiUpdate({ key: 'vitals' }) public readonly vitals: ClientCIWAVitalsUpdate;

  public serialize(): ClientCIWAUpdateApi {
    return {
      administered: this.administered.serialize(),
      assessment: this.assessment.serialize(),
      createdBy: this.createdBy.serialize(),
      date: this.date.toISO()!,
      notes: this.notes,
      reviewers:
        this.reviewers?.map((reviewer) => reviewer.serialize()) ?? null,
      score: this.assessment.score,
      vitals: this.vitals.serialize(),
    };
  }
}

export interface ClientCIWADeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
