import { PronounsApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Facility, Pronouns } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PronounService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch the list of possible pronouns from the server.
   *
   * @param facilityId The facility ID to get the possible pronouns for.
   * @param isActive Whether to get only active pronouns.
   * @returns An observable of the possible pronouns for the given facility
   * ID, or undefined if there was an error.
   */
  public getList(
    facilityId: Facility['id'],
    isActive: boolean = true,
  ): Observable<readonly Pronouns[] | undefined> {
    return this.httpClient
      .get<readonly PronounsApi[] | undefined>(`${config.api}/pronouns`, {
        params: { active: isActive, facilityId },
      })
      .pipe(
        map((results) =>
          results ? Pronouns.deserializeList(results) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
