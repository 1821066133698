import { RoleApi, RoleUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<RoleApi>();

export class Role {
  public constructor(props: ClassProperties<Role>) {
    this.id = props.id;
    this.isAdmin = props.isAdmin;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Role API model.
   */
  public static readonly Codec = io.type(
    {
      admin: io.boolean,
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'RoleApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'admin' }) public readonly isAdmin: boolean;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Role object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Role object.
   * @throws An error if the value is not a valid Role object.
   */
  public static deserialize(value: NonNullable<RoleApi>): Role {
    const decoded = decode(Role.Codec, value);
    return new Role({
      id: decoded.id,
      isAdmin: decoded.admin,
      name: decoded.name,
    });
  }

  /**
   * Deserializes a list of Role objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Role objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Role objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<RoleApi>>,
  ): readonly Role[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Role objects.');
    }
    return values.map(Role.deserialize);
  }
}

export class RoleUpdate extends Role {
  public serialize(): RoleUpdateApi {
    return {
      admin: this.isAdmin,
      id: this.id,
      name: this.name,
    };
  }
}
