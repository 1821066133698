import { Client } from 'src/app/models';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ClientStatusBadgeModule } from 'src/app/components/client/status/badge/client-status-badge.module';

import { PipesModule } from 'src/app/pipes/pipes.module';

/**
 * A component that displays a client data card.
 *
 * @requires `client` attribute to be set.
 */
@Component({
  selector: 'alleva-client-card[client]',
  templateUrl: './client-card.component.html',
  styleUrls: ['../card.scss'],
  /** Standalone Options */
  standalone: true,
  imports: [CommonModule, ClientStatusBadgeModule, MatIconModule, PipesModule],
})
export class ClientCardComponent {
  public constructor(private readonly elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  public readonly nativeElement: HTMLElement;

  /**
   * The client data to display.
   */
  @Input() public client!: Client;
}
