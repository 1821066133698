import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, firstValueFrom } from 'rxjs';
import { VALIDATION } from 'src/app/constants';
import { MeasurementSystemEnum, WeightUnitsEnum } from 'src/app/enumerators';
import { FacilityService } from 'src/app/services';
import {
  getKilogramsFrom,
  getPoundsFrom,
  isNonEmptyValue,
} from 'src/app/utilities';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';

import { CustomFormGroupDirective } from 'src/app/components/forms/custom-form-group.directive';

/**
 * An input component for tracking weight.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-weight[formControl][label]',
  templateUrl: './input-weight.component.html',
  styleUrls: ['./input-weight.component.scss'],
})
export class InputWeightComponent
  extends CustomFormGroupDirective<Weight>
  implements OnInit
{
  public constructor(
    @Optional() @Self() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    private readonly facilityService: FacilityService,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  /**
   * The id of the units form control, automatically generated if not provided.
   */
  @Input()
  public unitsId = `alleva-input-weight-units${++uniqueWeightUnitsFormId}`;

  /**
   * The id of the value form control, automatically generated if not provided.
   */
  @Input()
  public valueId = `alleva-input-weight-value${++uniqueWeightValueFormId}`;

  // Override the following defaults to undefined to show they're unused here.
  public override placeholder = undefined;

  protected readonly VALIDATION = VALIDATION;
  protected readonly WeightUnitsEnum = WeightUnitsEnum;

  protected override readonly formGroup = new FormGroup<WeightFormGroup>({
    units: new FormControl<Weight['units'] | null>(null, Validators.required),
    value: new FormControl<Weight['value'] | null>(null, [
      Validators.required,
      Validators.min(VALIDATION.vitals.weight.min),
      Validators.max(VALIDATION.vitals.weight.max),
    ]),
  });

  protected readonly ctrlUnits = this.formGroup.controls.units;
  protected readonly ctrlValue = this.formGroup.controls.value;

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputWeightComponent requires a formControl attribute to be set.',
      );
    }

    const facility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );
    const facilityMeasurementSystem = facility.settings.measurementSystem;
    let facilityWeightUnits: WeightUnitsEnum | null = null;

    switch (facilityMeasurementSystem) {
      case MeasurementSystemEnum.AMERICAN:
      case MeasurementSystemEnum.IMPERIAL:
        facilityWeightUnits = WeightUnitsEnum.POUNDS;
        break;
      case MeasurementSystemEnum.METRIC:
        facilityWeightUnits = WeightUnitsEnum.KILOGRAMS;
        break;
      default:
        throw new Error(
          `Unhandled measurement system for weight: ${facilityMeasurementSystem}`,
        );
    }

    const currentWeight = this.baseControl.value;
    const currentWeightUnits = currentWeight?.units ?? facilityWeightUnits;
    let currentWeightValue = currentWeight?.value ?? null;

    // Convert the current value to the facility's measurement system.
    if (
      currentWeight &&
      currentWeightValue &&
      currentWeightUnits !== facilityWeightUnits
    ) {
      switch (facilityWeightUnits) {
        case WeightUnitsEnum.POUNDS: {
          switch (currentWeightUnits) {
            case WeightUnitsEnum.POUNDS: {
              break; // Do nothing, the value is already in pounds.
            }
            case WeightUnitsEnum.KILOGRAMS: {
              currentWeightValue = getPoundsFrom({
                kilograms: currentWeightValue,
              });
              break;
            }
            default: {
              throw new Error(
                `Unhandled conversion from: ${currentWeightUnits} to ${facilityWeightUnits}`,
              );
            }
          }
          break;
        }
        case WeightUnitsEnum.KILOGRAMS: {
          switch (currentWeightUnits) {
            case WeightUnitsEnum.KILOGRAMS: {
              break; // Do nothing, the value is already in kilograms.
            }
            case WeightUnitsEnum.POUNDS: {
              currentWeightValue = getKilogramsFrom({
                pounds: currentWeightValue,
              });
              break;
            }
            default: {
              throw new Error(
                `Unhandled conversion from: ${currentWeightUnits} to ${facilityWeightUnits}`,
              );
            }
          }
          break;
        }
        default: {
          throw new Error(
            'Unhandled facility measurement system' +
              `"${facilityMeasurementSystem}" for weight.`,
          );
        }
      }
    }

    // Set initial values.
    this.formGroup.setValue({
      units: facilityWeightUnits,
      value: currentWeightValue,
    });

    // If the base control is required, then the sub controls should be required.
    if (!this.baseControl.hasValidator(Validators.required)) {
      this.ctrlUnits.removeValidators(Validators.required);
      this.ctrlValue.removeValidators(Validators.required);
      this.ctrlUnits.markAsUntouched();
      this.ctrlValue.markAsUntouched();
    }

    // Update the validation after setting the initial values.
    this.formGroup.controls.value.updateValueAndValidity({
      emitEvent: false,
    });
    this.ctrlValue.markAsUntouched();

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(
          (
            { units: oldUnits, value: oldValue },
            { units: newUnits, value: newValue },
          ) => oldUnits === newUnits && oldValue === newValue,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ units, value }) => {
        if (
          isNonEmptyValue(units) &&
          isNonEmptyValue(value) &&
          this.formGroup.valid
        ) {
          this.baseControl.patchValue({ units, value });
          this.baseControl.markAsDirty();
        } else if (!isNonEmptyValue(units) && !isNonEmptyValue(value)) {
          this.baseControl.patchValue(null);
          this.baseControl.markAsPristine();
        } else {
          this.baseControl.patchValue(null, { emitEvent: false });
          this.baseControl.markAsPristine();
        }
      });
  }
}

interface WeightFormGroup {
  units: FormControl<Weight['units'] | null>;
  value: FormControl<Weight['value'] | null>;
}

/** A unique ID for each form field weight `units` input. */
let uniqueWeightUnitsFormId = 0;

/** A unique ID for each form field weight `value` input. */
let uniqueWeightValueFormId = 0;
