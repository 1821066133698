import { isNonEmptyString } from 'src/app/utilities/type-check';

/**
 * Get the full name of a persons name object.
 *
 * @param firstName The first name of the person.
 * @param middleName The middle name of the person.
 * @param lastName The last name of the person.
 * @returns The full name of the person.
 */
export function getFullName({
  first,
  middle,
  last,
  preferred,
}: {
  first?: string | null | undefined;
  middle?: string | null | undefined;
  last?: string | null | undefined;
  preferred?: string | null | undefined;
}): string | null {
  if (preferred) {
    const preferredQuoted = `"${preferred}"`;
    return (
      [first, preferredQuoted, middle, last]
        .filter(isNonEmptyString)
        .join(' ')
        .trim() || null
    );
  }
  return (
    [first, middle, last].filter(isNonEmptyString).join(' ').trim() || null
  );
}
