import { firstValueFrom } from 'rxjs';
import { AlertService, SignatureService } from 'src/app/services';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

enum SignMePinDigitEnum {
  DIGIT1 = 'digit1',
  DIGIT2 = 'digit2',
  DIGIT3 = 'digit3',
  DIGIT4 = 'digit4',
}

/**
 * A component that allows the user to enter a four digit PIN, which
 * is checked against the PIN stored on the server. If the PIN is correct,
 * the users signature is returned and is applied via this component's
 * `signature` output.
 */
@Component({
  selector: 'alleva-input-sign-me-pin',
  templateUrl: './input-sign-me-pin.component.html',
  styleUrls: ['./input-sign-me-pin.component.scss'],
})
export class InputSignMePinComponent {
  public constructor(
    private readonly alertService: AlertService,
    private readonly signatureService: SignatureService,
  ) {}

  protected isSumbitting = false;
  protected readonly SignMePinDigitEnum = SignMePinDigitEnum;

  @Input() public set disabled(value: boolean) {
    if (value) {
      this.signMePinForm.disable();
    } else {
      this.signMePinForm.enable();
    }
  }

  @ViewChild(`${SignMePinDigitEnum.DIGIT1}`)
  protected readonly digit1!: ElementRef<HTMLInputElement>;
  @ViewChild(`${SignMePinDigitEnum.DIGIT2}`)
  protected readonly digit2!: ElementRef<HTMLInputElement>;
  @ViewChild(`${SignMePinDigitEnum.DIGIT3}`)
  protected readonly digit3!: ElementRef<HTMLInputElement>;
  @ViewChild(`${SignMePinDigitEnum.DIGIT4}`)
  protected readonly digit4!: ElementRef<HTMLInputElement>;

  protected readonly signMePinForm = new FormGroup<SignMePinFormGroup>({
    [SignMePinDigitEnum.DIGIT1]: new FormControl<number | null>(null, [
      Validators.max(9),
      Validators.maxLength(1),
      Validators.min(0),
      Validators.minLength(1),
      Validators.required,
    ]),
    [SignMePinDigitEnum.DIGIT2]: new FormControl<number | null>(null, [
      Validators.max(9),
      Validators.maxLength(1),
      Validators.min(0),
      Validators.minLength(1),
      Validators.required,
    ]),
    [SignMePinDigitEnum.DIGIT3]: new FormControl<number | null>(null, [
      Validators.max(9),
      Validators.maxLength(1),
      Validators.min(0),
      Validators.minLength(1),
      Validators.required,
    ]),
    [SignMePinDigitEnum.DIGIT4]: new FormControl<number | null>(null, [
      Validators.max(9),
      Validators.maxLength(1),
      Validators.min(0),
      Validators.minLength(1),
      Validators.required,
    ]),
  });

  /**
   * The signature that is returned from the server. This is emitted when the
   * user enters a valid PIN. If the user cancels the input, this is set to
   * null.
   */
  @Output() public readonly signatureFromPINUpdate =
    new EventEmitter<Base64<'png'> | null>();

  /** Clear the form and cancel the input. */
  public clearAndCancel(): void {
    this.signMePinForm.reset();
    this.signatureFromPINUpdate.emit(null);
  }

  /**
   * Handles the keyup event from the pin input. When the user enters a digit
   * that is not 0-9, the input is cleared. When the user enters a digit that
   * is 0-9, the next mat-form-field is focused. When the user presses the
   * backspace/delete key, the previous mat-form-field is focused. When the
   * user presses enter, the form is submitted, but will do nothing if the
   * form isn't completely filled out and valid.
   *
   * @param event The keyup event from the pin input.
   * @param signMePinDigitEnum The enum value for the pin input.
   */
  protected async onPinKeyUp(
    event: KeyboardEvent,
    signMePinDigitEnum: SignMePinDigitEnum,
  ): Promise<void> {
    // If the user pressed the enter key, submit the form.
    if (event.key === 'Enter') {
      await this.submit();
      return;
    }

    // If the user pressed the backspace/delete key, clear the previous and
    // current input and focus the previous input.
    if (event.key === 'Backspace' || event.key === 'Delete') {
      // Clear the current input.
      this.signMePinForm.controls[signMePinDigitEnum].setValue(null);

      switch (signMePinDigitEnum) {
        case SignMePinDigitEnum.DIGIT1: {
          // Refocus the current input after clearing it above.
          this.digit1.nativeElement.focus();
          break;
        }
        case SignMePinDigitEnum.DIGIT2: {
          // Clear the previous input and focus it.
          this.signMePinForm.controls[SignMePinDigitEnum.DIGIT1].setValue(null);
          this.digit1.nativeElement.focus();
          break;
        }
        case SignMePinDigitEnum.DIGIT3: {
          // Clear the previous input and focus it.
          this.signMePinForm.controls[SignMePinDigitEnum.DIGIT2].setValue(null);
          this.digit2.nativeElement.focus();
          break;
        }
        case SignMePinDigitEnum.DIGIT4: {
          // Clear the previous input and focus it.
          this.signMePinForm.controls[SignMePinDigitEnum.DIGIT3].setValue(null);
          this.digit3.nativeElement.focus();
          break;
        }
      }
      return;
    }

    const input = event.target as HTMLInputElement;
    const inputStringValue = input.value;
    const inputValue = parseInt(inputStringValue, 10);

    if (isNaN(inputValue) || inputValue < 0 || inputValue > 9) {
      // User entered an invalid digit, clear the input.
      input.value = '';
    } else {
      // User entered a valid digit, focus the next input.
      switch (signMePinDigitEnum) {
        case SignMePinDigitEnum.DIGIT1:
          this.digit2.nativeElement.focus();
          break;
        case SignMePinDigitEnum.DIGIT2:
          this.digit3.nativeElement.focus();
          break;
        case SignMePinDigitEnum.DIGIT3:
          this.digit4.nativeElement.focus();
          break;
        case SignMePinDigitEnum.DIGIT4:
          this.digit4.nativeElement.blur();
          await this.submit();
          break;
      }
    }
  }

  /**
   * Submit the form and return the signature from the server.
   */
  private async submit(): Promise<void> {
    // If the user entered a value that is not 0-9, do not submit the form.
    if (
      this.signMePinForm.invalid ||
      (this.signMePinForm.controls.digit1.value ?? 0) < 0 ||
      (this.signMePinForm.controls.digit1.value ?? 0) > 9 ||
      (this.signMePinForm.controls.digit2.value ?? 0) < 0 ||
      (this.signMePinForm.controls.digit2.value ?? 0) > 9 ||
      (this.signMePinForm.controls.digit3.value ?? 0) < 0 ||
      (this.signMePinForm.controls.digit3.value ?? 0) > 9 ||
      (this.signMePinForm.controls.digit4.value ?? 0) < 0 ||
      (this.signMePinForm.controls.digit4.value ?? 0) > 9
    ) {
      return;
    }

    this.isSumbitting = true;

    const pin = Number(
      `${this.signMePinForm.controls.digit1.value}` +
        `${this.signMePinForm.controls.digit2.value}` +
        `${this.signMePinForm.controls.digit3.value}` +
        `${this.signMePinForm.controls.digit4.value}`,
    );
    if (Number.isNaN(pin)) {
      throw new Error('Unable to parse PIN as a number.');
    }

    const signature =
      (await firstValueFrom(this.signatureService.getByPin(pin))) ?? null;

    this.isSumbitting = false;

    if (signature === null) {
      this.alertService.error({
        message: 'Invalid PIN, please try again.',
      });
      return;
    }

    this.signatureFromPINUpdate.emit(signature);
  }
}

interface SignMePinFormGroup {
  [SignMePinDigitEnum.DIGIT1]: FormControl<number | null>;
  [SignMePinDigitEnum.DIGIT2]: FormControl<number | null>;
  [SignMePinDigitEnum.DIGIT3]: FormControl<number | null>;
  [SignMePinDigitEnum.DIGIT4]: FormControl<number | null>;
}
