export function getCentimetersFrom({
  feet,
  inches,
}: {
  feet?: number;
  inches?: number;
} = {}): number {
  if (feet !== undefined) {
    return feet * 30.48;
  }
  if (inches !== undefined) {
    return inches * 2.54;
  }
  throw new Error('No length value provided.');
}

export function getMetersFrom({
  centimeters,
  feet,
  inches,
}: {
  centimeters?: number;
  feet?: number;
  inches?: number;
} = {}): number {
  if (centimeters !== undefined) {
    return centimeters / 100;
  }
  if (feet !== undefined) {
    return feet * 0.3048;
  }
  if (inches !== undefined) {
    return inches * 0.0254;
  }
  throw new Error('No length value provided.');
}

export function getFeetFrom({
  centimeters,
  inches,
}: {
  centimeters?: number;
  inches?: number;
} = {}): number {
  if (centimeters !== undefined) {
    return centimeters / 30.48;
  }
  if (inches !== undefined) {
    return inches / 12;
  }
  throw new Error('No length value provided.');
}

export function getInchesFrom({
  centimeters,
  feet,
}: {
  centimeters?: number;
  feet?: number;
} = {}): number {
  if (centimeters !== undefined) {
    return centimeters / 2.54;
  }
  if (feet !== undefined) {
    return feet * 12;
  }
  throw new Error('No length value provided.');
}

export function getFeetInchesFrom({
  centimeters,
  inches,
}: {
  centimeters?: number;
  inches?: number;
} = {}): { feet: number; inches: number } {
  let totalInches: number;

  if (centimeters !== undefined) {
    totalInches = getInchesFrom({ centimeters });
  } else if (inches !== undefined) {
    totalInches = inches;
  } else {
    throw new Error('No length value provided.');
  }

  const feet = Math.floor(totalInches / 12);
  const remainingInches = Math.round(totalInches % 12);

  return {
    feet,
    inches: remainingInches,
  };
}
