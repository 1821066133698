import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { ContainerModule } from 'src/app/components/container/container.module';
import { InputDropdownModule } from 'src/app/components/forms/input-dropdown/input-dropdown.module';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingIndicatorModule } from 'src/app/components/loading-indicator/loading-indicator.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { PersonSearchModule } from 'src/app/components/person-search/person-search.module';

import { DirectivesModule } from 'src/app/directives/directives.module';

import { LayoutFooterComponent } from 'src/app/layout/footer/layout-footer.component';
import { LayoutHeaderSwitchFacilityComponent } from 'src/app/layout/header/layout-header-switch-facility.component';
import { LayoutHeaderComponent } from 'src/app/layout/header/layout-header.component';
import { LayoutNavbarUserActionsComponent } from 'src/app/layout/navbar/layout-navbar-user-actions.component';
import { LayoutNavbarComponent } from 'src/app/layout/navbar/layout-navbar.component';
import { LayoutPageContentComponent } from 'src/app/layout/page-content/layout-page-content.component';
import { LayoutQuickLaunchMenuComponent } from 'src/app/layout/quick-launch-menu/layout-quick-launch-menu.component';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    LayoutFooterComponent,
    LayoutHeaderComponent,
    LayoutHeaderSwitchFacilityComponent,
    LayoutNavbarComponent,
    LayoutNavbarUserActionsComponent,
    LayoutPageContentComponent,
    LayoutQuickLaunchMenuComponent,
  ],
  exports: [
    LayoutFooterComponent,
    LayoutHeaderComponent,
    LayoutNavbarComponent,
    LayoutPageContentComponent,
    LayoutQuickLaunchMenuComponent,
  ],
  imports: [
    ButtonComponent,
    CommonModule,
    ContainerModule,
    DirectivesModule,
    IconModule,
    InputDropdownModule,
    LoadingIndicatorModule,
    MatBadgeModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    MenuModule,
    PersonSearchModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [DatePipe],
})
export class LayoutModule {}
