import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AlertService {
  public constructor(private readonly matSnackBar: MatSnackBar) {}

  public error({
    actionLabel = 'Dismiss',
    durationSeconds = 5,
    keepOpen = false,
    message = 'An unknown issue occurred, please try again or contact support.',
  }: Partial<AlertData>): void {
    this.matSnackBar.open(message, actionLabel, {
      duration: keepOpen ? undefined : getMillisecondsFrom(durationSeconds),
      panelClass: 'alert-error',
    });
  }

  public success({
    actionLabel = 'Dismiss',
    durationSeconds = 5,
    message = 'Success!',
  }: Partial<AlertData>): void {
    this.matSnackBar.open(message, actionLabel, {
      duration: getMillisecondsFrom(durationSeconds),
      panelClass: 'alert-success',
    });
  }
}

function getMillisecondsFrom(seconds: number): number {
  return seconds * 1000;
}

interface AlertData {
  /**
   * The label to display on the button that closes the alert.
   *
   * @default 'Dismiss'
   */
  actionLabel: string;
  /**
   * The duration that the alert message with display for in seconds.
   *
   * @default 5 Seconds.
   */
  durationSeconds: number;
  /**
   * Whether or not to keep the alert open indefinitely until dismissed.
   *
   * @default false
   */
  keepOpen: boolean;
  /**
   * The message to display in the alert.
   *
   * @default 'Error: An unknown issue occurred.'
   */
  message: string;
}
