import { ClientRecentAppointmentsApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientRecentAppointmentDeserializationArgs,
  ClientRecentAppointments,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AppointmentsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return last and upcoming appointment for a single client by ID.
   *
   * @param clientId The client ID to fetch.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns Full model on success, null on not found, undefined on error.
   */
  public getRecent(
    clientId: Client['id'],
    deserializationArgs: ClientRecentAppointmentDeserializationArgs,
  ): Observable<ClientRecentAppointments | null | undefined> {
    return this.httpClient
      .get<
        ClientRecentAppointmentsApi | undefined
      >(`${config.api}/clients/${clientId}/appointments/recent`)
      .pipe(
        map((response) =>
          response
            ? ClientRecentAppointments.deserialize(
                response,
                deserializationArgs,
              )
            : null,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
