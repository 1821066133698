import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mask a string with a given character.
 */
@Pipe({ name: 'mask' })
export class MaskPipe implements PipeTransform {
  public transform(
    value: string | null | undefined,
    shouldMask = true,
    maskWith = '*',
  ): string {
    return (shouldMask ? value?.replace(/./g, maskWith) : value) ?? '';
  }
}
