import { ClientMedicationDoctorsOrderApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientMedicationDoctorsOrderApi>();

export class ClientMedicationDoctorsOrder {
  public constructor(props: ClassProperties<ClientMedicationDoctorsOrder>) {
    this.id = props.id;
    this.pending = props.pending;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Medication
   * Doctors Order API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      pending: io.boolean,
    },
    'ClientMedicationDoctorsOrderApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'pending' }) public readonly pending: boolean;

  /**
   * Deserializes a Client Medication Doctors Order object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Medication Doctors Order object Signed
   * object.
   * @throws An error if the value is not a valid Client Medication Doctors
   * Order object.
   */
  public static deserialize(
    value: NonNullable<ClientMedicationDoctorsOrderApi>,
  ): ClientMedicationDoctorsOrder {
    const decoded = decode(ClientMedicationDoctorsOrder.Codec, value);
    return new ClientMedicationDoctorsOrder({
      id: decoded.id,
      pending: decoded.pending,
    });
  }

  /**
   * Deserializes a list of Client Medication Doctors Order objects from an
   * API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Client Medication Doctors Order objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Medication
   * Doctors Order objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientMedicationDoctorsOrderApi>>,
  ): readonly ClientMedicationDoctorsOrder[] {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Client Medication Doctors Order objects.',
      );
    }
    return values.map(ClientMedicationDoctorsOrder.deserialize);
  }
}
