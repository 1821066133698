import { firstValueFrom, timer } from 'rxjs';
import { isNonEmptyString } from 'src/app/utilities';

import { ElementRef, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * A print service for printing various types of objects and elements.
 */
@Injectable({ providedIn: 'root' })
export class PrintService {
  public constructor(private readonly domSanitizer: DomSanitizer) {}

  public async print(
    content?: PrintContent,
    args: PrintArgs = DEFAULT_PRINT_ARGS,
  ): Promise<void> {
    // If no specific item is provided, print the entire window.
    if (!content) {
      window.print();
      return;
    }

    // Get the content to print/display.
    let unsafeContent: string | null = null;
    if (content instanceof ElementRef) {
      unsafeContent = content.nativeElement.outerHTML;
    } else if (content instanceof HTMLElement) {
      unsafeContent = content.outerHTML;
    } else if (isNonEmptyString(content)) {
      unsafeContent = content;
    }

    // If the content is null, throw an error.
    if (unsafeContent === null) {
      throw new Error('Could not get content to print.');
    }

    // Sanitize the incoming markup to be printed/displayed.
    const safeContent = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      unsafeContent,
    );
    if (!safeContent) {
      throw new Error(
        'There was a security issue processing the content to print.',
      );
    }

    // Create a new window and reference it for use.
    const newWindow = window.open(
      '',
      '_blank',
      `height=${args.height},width=${args.width}`,
    );
    if (!newWindow) {
      throw new Error('Could not open a new print window.');
    }

    // Hydrate the document for print.
    const safeHtmlPrintDocument = getNewPrintDocument(safeContent, args);
    // Add css styles to the document.

    // Write the document to the new window.
    newWindow.document.write(safeHtmlPrintDocument.toString());
    // Close the document itself.
    newWindow.document.close();
    // Focus the new window.
    newWindow.focus();
    // Await some time to allow the document to hydrate and images to render.
    await firstValueFrom(timer(250));
    // Print the document.
    newWindow.print();
    // Close the window when the print is completed.
    newWindow.close();
  }
}

export interface PrintArgs {
  /** Height in pixels. */
  height?: number;
  /** The style to apply to the print preview. */
  style?: PrintStyle;
  /** Title of the print document. */
  title?: string;
  /** Width in pixels. */
  width?: number;
}

export const DEFAULT_PRINT_ARGS: PrintArgs = {
  /** The default height of the print window. */
  height: 600,
  /** The default print style. */
  style: undefined,
  /** The default print window title. */
  title: 'Print',
  /** The default width of the print window. */
  width: 800,
};

function getNewPrintDocument(safeContent: SafeHtml, args: PrintArgs): SafeHtml {
  return `
    <html>
      <head>
        <title>${args.title}</title>
        <style>
          @page {
            size: auto;
            margin: 0;
          }
          @media print {
            html, body {
              width: ${args.width}px;
              height: ${args.height}px;
            }
          }
          ${
            args.style
              ? Object.keys(args.style)
                  .map((key) => `\n${key} { ${args.style?.[key]}; }`)
                  .join('')
              : ''
          }
        </style>
      </head>
      <body>
        ${safeContent}
      </body>
    </html>
  `;
}
