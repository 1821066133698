import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validators,
} from '@angular/forms';

/**
 * A directive for custom Angular Material form field inputcontrols.
 *
 * @example `InputCustomComponent extends CustomInputDirective<InputValueType>`
 */
@UntilDestroy()
@Directive()
export class CustomInputDirective<T> implements ControlValueAccessor, OnInit {
  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
  ) {
    if (!this.ngControl) {
      throw new Error(
        '"CustomInputDirective" must be provided a form control.',
      );
    }

    this.ngControl.valueAccessor = this;
    this.nativeElement = this.elementRef.nativeElement;
  }

  public readonly nativeElement: HTMLElement;

  public baseControl!: FormControl<T | null>;
  public value: T | null = null;

  @HostBinding('class.disabled')
  @Input()
  public set disabled(value: BooleanInput) {
    this.#disabled = coerceBooleanProperty(value);
    this.disabledSubject.next(this.#disabled);
  }
  public get disabled(): boolean {
    return this.#disabled;
  }
  #disabled = false;

  public onChange?: (value: T) => void;
  public onTouched?: () => void;

  /**
   * The `hint` attribute controlling the hint of the values field.
   */
  @Input() public hint?: string;

  /**
   * The `hide-hint-subscript` attribute controlling whether the hint is shown
   * as a subscript.
   *
   * Warning: This may also hide error message "hints" depending.
   */
  @HostBinding('class.hide-hint-subscript')
  @Input()
  public set hideHint(value: BooleanInput) {
    this.#hideHint = coerceBooleanProperty(value);
  }
  public get hideHint(): boolean {
    return this.#hideHint;
  }
  #hideHint = false;

  /**
   * The `label` attribute controlling the label of the value.
   */
  @Input() public label?: string;

  /**
   * The `placeholder` attribute controlling the placeholder of the value.
   */
  @Input() public placeholder?: string;

  /**
   * The `max` attribute controlling the maximum value of the value.
   *
   * Supports dynamic type `T` for cases when `DateTime` objects or such are
   * used.
   */
  @Input() public max?: number | T;

  /**
   * The `min` attribute controlling the minimum value of the value.
   */
  @Input() public min?: T;

  /**
   * The `maxlength` attribute controlling the maximum length of the value.
   */
  @Input() public maxLength: string | number | null = null;

  /**
   * The `minlength` attribute controlling the minimum length of the value.
   */
  @Input() public minLength: string | number | null = null;

  /**
   * Whether or not the value is required.
   */
  public get required(): boolean {
    return this.baseControl.hasValidator(Validators.required) ?? false;
  }

  private readonly disabledSubject = new BehaviorSubject<boolean>(false);
  public readonly disabledChanges = this.disabledSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public onContainerClick = (_event: MouseEvent): void => {
    // Empty, for now. Used to appease implementation.
  };
  public setDescribedByIds = (_ids: string[]): void => {
    // Empty, for now. Used to appease implementation.
  };

  public ngOnInit(): void {
    if (!(this.ngControl.control instanceof FormControl)) {
      throw new Error(
        '"CustomInputDirective" must be provided a form control.',
      );
    }
    this.baseControl = this.ngControl.control;

    this.baseControl.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.disabled = this.baseControl.disabled;
      this.disabledSubject.next(this.disabled);
    });
  }

  public registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public scrollIntoView(options?: ScrollIntoViewOptions): void {
    const defaultOptions: ScrollIntoViewOptions = { behavior: 'smooth' };
    this.nativeElement.scrollIntoView({ ...defaultOptions, ...options });
  }

  public writeValue(value: T | null): void {
    this.value = value;
  }
}
