import { ClientContactReferralContactApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientContactPhone } from 'src/app/models/client/client-contact-phone.model';

const api = apiDecorator<ClientContactReferralContactApi>();

export class ClientContactReferralContact {
  public constructor(props: ClassProperties<ClientContactReferralContact>) {
    this.email = props.email;
    this.id = props.id;
    this.image = props.image;
    this.name = props.name;
    this.phone = props.phone;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Contact Referral
   * Contact API model.
   */
  public static readonly Codec = io.type(
    {
      email: io.union([io.string, io.null]),
      id: io.number,
      image: io.union([io.string, io.null]),
      name: io.union([io.string, io.null]),
      phone: ClientContactPhone.Codec,
    },
    'ClientContactReferralContactApi',
  );

  @api({ key: 'email' }) public readonly email: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'phone' }) public readonly phone: ClientContactPhone;

  /**
   * Deserializes a Client Contact Referral Contact object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Contact Referral Contact object.
   * @throws An error if the value is not a valid Client Contact Referral
   * Contact object.
   */
  public static deserialize(
    value: NonNullable<ClientContactReferralContactApi>,
  ): ClientContactReferralContact {
    const decoded = decode(ClientContactReferralContact.Codec, value);
    return new ClientContactReferralContact({
      ...decoded,
      phone: ClientContactPhone.deserialize(decoded.phone),
    });
  }
}
