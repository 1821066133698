import { BillingRuleDurationApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingRuleDurationApi>();

export class BillingRuleDuration {
  public constructor(props: ClassProperties<BillingRuleDuration>) {
    this.max = props.max;
    this.min = props.min;
    this.minutesPerUnit = props.minutesPerUnit;
  }

  /** The io-ts codec for runtime type checking of the Min Max API model. */
  public static readonly Codec = io.type(
    {
      max: io.union([io.number, io.null]),
      min: io.union([io.number, io.null]),
      minutesPerUnit: io.union([io.number, io.null]),
    },
    'BillingRuleDurationApi',
  );

  @api({ key: 'max' }) public readonly max: number | null;
  @api({ key: 'min' }) public readonly min: number | null;
  @api({ key: 'minutesPerUnit' }) public readonly minutesPerUnit: number | null;

  /**
   * Deserializes a Min Max object from the API.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Min Max object.
   * @throws An error if the value is not a valid Min Max object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleDurationApi>,
  ): BillingRuleDuration {
    const decoded = decode(BillingRuleDuration.Codec, value);
    return new BillingRuleDuration({
      max: decoded.max,
      min: decoded.min,
      minutesPerUnit: decoded.minutesPerUnit,
    });
  }

  /**
   * Serializes the Min Max object to the API model.
   *
   * @returns The serialized Min Max object.
   */
  public serialize(): BillingRuleDurationApi {
    return {
      max: this.max,
      min: this.min,
      minutesPerUnit: this.minutesPerUnit,
    };
  }
}
