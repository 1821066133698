import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, delay, firstValueFrom, startWith } from 'rxjs';

import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { TabComponent } from 'src/app/components/tabs/tab.component';

@UntilDestroy()
@Component({
  selector: 'alleva-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  /**
   * Whether or not to stretch the tabs (horizontally) to fill the available
   * space.
   *
   * @default false
   */
  @Input() public isStretched: boolean = false;

  /**
   * Whether or not to display a loader in the tab content area when switching
   * tabs. This is useful for lazy loaded components that fetch data on tab
   * selection to keep animations smooth during transition.
   */
  @Input() public shouldDisplayLoader: boolean = false;

  /**
   * Whether or not the tabs should stack vertically or horizontally.
   *
   * @default true
   */
  @Input() @HostBinding('class.vertical') public isVertical: boolean = true;

  @ContentChildren(TabComponent)
  private readonly tabComponents?: QueryList<TabComponent>;
  private readonly tabComponentsSubject = new ReplaySubject<
    readonly TabComponent[]
  >(1);
  public readonly tabComponentsChanges =
    this.tabComponentsSubject.asObservable();

  @ViewChild('tabGroup') private readonly tabGroup!: MatTabGroup;

  /** Output the index of the selected tab. */
  @Output() public readonly selectedIndexChange = new EventEmitter<number>();

  protected isSwitchingTabsCompleted = false;

  private selectTabTriggerNextEvent = true;

  public selectTab(
    index: number,
    { triggerEvent }: { triggerEvent?: boolean } = {},
  ): void {
    this.tabGroup.selectedIndex = index;
    this.selectTabTriggerNextEvent = triggerEvent ?? true;
  }

  public async ngAfterContentInit(): Promise<void> {
    const tabs = this.tabComponents?.toArray();
    // Ensure the tabs/tab components are used properly.
    if (!tabs?.length) {
      throw new Error(
        '"TabsComponent" must contain at least one "TabComponent".',
      );
    } else if (tabs.some((tab) => !tab.title)) {
      throw new Error('All "TabComponent" instances must have a "title".');
    }

    this.tabComponentsSubject.next(tabs || []);
    await firstValueFrom(this.tabComponentsChanges);

    // When the user chooses a tab, let's make it active along with its content.
    // This is necessary for lazy loading components further up the tree.
    this.tabGroup.selectedIndexChange
      .pipe(
        startWith(0), // Start with the first tab in the list as the default.
        // Display content once the tab switch animation is complete. This limits
        // jumpiness when switching tabs and loading content simultaneously.
        delay(this.shouldDisplayLoader ? 750 : 0),
        untilDestroyed(this),
      )
      .subscribe((index) => {
        if (this.tabComponents) {
          this.tabComponents.forEach((tab, i) => {
            tab.isActive = i === index;

            if (tab.isActive) {
              tab.tabSelected.emit();
            }
          });
        }
        this.isSwitchingTabsCompleted = true;
      });

    // When the user chooses a tab, emit the index of the tab.
    this.tabGroup.selectedIndexChange
      .pipe(untilDestroyed(this))
      .subscribe((index) => {
        this.isSwitchingTabsCompleted = false;

        if (this.selectTabTriggerNextEvent) {
          this.selectedIndexChange.emit(index);
        } else {
          this.selectTabTriggerNextEvent = true;
        }
      });
  }
}
