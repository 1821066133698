import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NAV_MENU_ITEMS } from 'src/app/constants';
import { MenuService } from 'src/app/services';

import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'alleva-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styleUrls: ['./layout-navbar.component.scss'],
})
export class LayoutNavbarComponent implements OnInit {
  public constructor(
    private readonly elementRef: ElementRef,
    private readonly menuService: MenuService,
  ) {}

  @HostBinding('class.menu-collapsed') public isCollapsed = false;

  public readonly menuItems = NAV_MENU_ITEMS;

  private startY: number | undefined;

  @HostListener('document:touchmove', ['$event'])
  protected handleTouchMove(event: TouchEvent): void {
    const currentY = event.touches[0].clientY;

    if (this.startY !== undefined) {
      const deltaY = currentY - this.startY;
      this.elementRef.nativeElement.scrollTop -= deltaY;
    }

    this.startY = currentY;
  }

  @HostListener('document:touchstart', ['$event'])
  protected handleTouchStart(event: TouchEvent): void {
    this.startY = event.touches[0].clientY;
  }

  @HostListener('document:touchend', ['$event'])
  protected handleTouchEnd(): void {
    this.startY = undefined;
  }

  public ngOnInit(): void {
    this.menuService.isCollapsedChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isCollapsed = value;
      });
  }
}
