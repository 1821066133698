import { SignatureService } from 'src/app/services';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { InputSignMeCanvasComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-canvas.component';
import { InputSignMeControlsComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-controls.component';
import { InputSignMePinComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-pin.component';
import { InputSignMeComponent } from 'src/app/components/forms/input-sign-me/input-sign-me.component';

@NgModule({
  declarations: [
    InputSignMeCanvasComponent,
    InputSignMeComponent,
    InputSignMeControlsComponent,
    InputSignMePinComponent,
  ],
  exports: [InputSignMeComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  providers: [SignatureService],
})
export class InputSignMeModule {}
