import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom, shareReplay } from 'rxjs';

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'alleva-input-sign-me-canvas',
  templateUrl: './input-sign-me-canvas.component.html',
  styleUrls: ['./input-sign-me-canvas.component.scss'],
})
export class InputSignMeCanvasComponent implements AfterViewInit {
  public constructor(private readonly elementRef: ElementRef) {}

  /**
   * Whether or not the signature canvas is disabled.
   */
  @Input() @HostBinding('class.disabled') public disabled = false;

  /**
   * The placeholder text to display when the signature is empty.
   */
  @Input() public placeholder?: string;

  /**
   * The appearance variant of the sign me component.
   */
  @Input()
  @HostBinding('class')
  public appearance: InputSignMeAppearance | undefined;

  /** The canvas element. */
  @ViewChild('canvas', { static: false })
  private readonly canvas!: ElementRef<HTMLCanvasElement>;

  private readonly valueSubject = new BehaviorSubject<Base64<'png'> | null>(
    null,
  );
  public readonly valueChanges = this.valueSubject
    .asObservable()
    .pipe(shareReplay(1));

  private canvasContext: CanvasRenderingContext2D | null = null;
  private currentMousePosition: { x: number; y: number } = { x: 0, y: 0 };
  private isCurrentlySigning = false;
  private previousMousePosition: { x: number; y: number } = { x: 0, y: 0 };
  private showPlaceholder = false;
  private windowWidth: null | number = null;

  public set value(value: Base64<'png'>) {
    this.valueSubject.next(value);
    const image = new Image();
    image.onload = () => {
      this.canvasContext?.drawImage(image, 0, 0);
    };
    image.src = value;
  }

  public async ngAfterViewInit(): Promise<void> {
    // Ensure that the value is loaded before setting the canvas.
    await firstValueFrom(this.valueChanges);

    // Set the initial window width.
    this.windowWidth = this.elementRef.nativeElement.offsetWidth;

    // Set the initial canvas size and context.
    this.canvasContext = this.setCanvasSize().getContext('2d');

    // Handle the placeholder text within the canvas.
    this.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (!value) {
        this.drawPlaceholder();
        this.showPlaceholder = true;
      } else if (this.showPlaceholder) {
        this.showPlaceholder = false;
        this.clear({ emitEvent: false });
      }
    });
  }

  /** Called when the mouse is pressed down. */
  protected onMouseDown(event: MouseEvent | TouchEvent): void {
    if (this.disabled) {
      return;
    }

    // Prevent the default action of selecting text.
    event.preventDefault();

    this.isCurrentlySigning = true;
    this.previousMousePosition = this.getMousePosition(event);
  }

  /** Called when the mouse is moved. */
  protected onMouseMove(event: MouseEvent | TouchEvent): void {
    if (this.isCurrentlySigning && !this.disabled) {
      // Prevent the default action of scrolling on drag (mobile).
      event.preventDefault();

      this.currentMousePosition = this.getMousePosition(event);
      this.draw();
    }
  }

  /** Called when the mouse is released. */
  @HostListener('document:mouseup', ['$event'])
  protected onMouseUp(_: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    this.isCurrentlySigning = false;
  }

  /** Called when the mouse leaves the canvas. */
  protected onMouseLeave(): void {
    if (this.disabled) {
      return;
    }

    this.isCurrentlySigning = false;
  }

  /** Called when the window size is updated. */
  @HostListener('window:resize') protected onWindowResize(): void {
    // Update the canvas size dynamically if the window width changes.
    if (this.windowWidth !== this.elementRef.nativeElement.offsetWidth) {
      this.setCanvasSize();
      if (this.showPlaceholder) {
        this.drawPlaceholder();
      }
    }
    // Keep track of the previous window width for the next resize event.
    this.windowWidth = this.elementRef.nativeElement.offsetWidth;
  }

  /** Clears the canvas. */
  public clear(
    { emitEvent }: { emitEvent: boolean } = { emitEvent: true },
  ): void {
    if (!this.canvasContext) {
      return;
    }
    this.canvasContext.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height,
    );
    if (emitEvent) {
      this.valueSubject.next(null);
    }
  }

  /** Draws a line from the previous mouse position to the current mouse position. */
  private draw(): void {
    if (!this.canvasContext || this.disabled) {
      return;
    }
    this.canvasContext.beginPath();
    this.canvasContext.strokeStyle = '#000';
    this.canvasContext.lineWidth = 2;
    this.canvasContext.moveTo(
      this.previousMousePosition.x,
      this.previousMousePosition.y,
    );
    this.canvasContext.lineTo(
      this.currentMousePosition.x,
      this.currentMousePosition.y,
    );
    this.canvasContext.stroke();
    this.canvasContext.closePath();
    this.previousMousePosition = this.currentMousePosition;

    // Update the base64 image value.
    this.valueSubject.next(
      this.canvas.nativeElement.toDataURL('image/png') as Base64<'png'>,
    );
  }

  private drawPlaceholder(): void {
    if (!this.canvasContext || !this.placeholder) {
      return;
    }

    // The current page "rem" value in pixels.
    const currentFontRem = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    // Half of the canvas width and height.
    const halfParentHeight = this.canvas.nativeElement.height / 2;
    // Half of the canvas width and height.
    const halfParentWidth = this.canvas.nativeElement.width / 2;

    this.canvasContext.font = '16px "Metric-Regular", "Roboto", sans-serif';
    this.canvasContext.fillStyle = 'rgb(0, 0, 0)';

    // Draw placeholder in the center of the canvas.
    this.canvasContext.textAlign = 'center';
    this.canvasContext.fillText(
      this.placeholder,
      halfParentWidth,
      halfParentHeight + 0.35 * currentFontRem,
    );
  }

  /** Returns the current mouse position. */
  private getMousePosition(event: MouseEvent | TouchEvent): {
    x: number;
    y: number;
  } {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    return {
      x:
        event instanceof MouseEvent
          ? event.clientX - rect.left
          : event.changedTouches[0].clientX - rect.left,
      y:
        event instanceof MouseEvent
          ? event.clientY - rect.top
          : event.changedTouches[0].clientY - rect.top,
    };
  }

  /** Set the canvas size. */
  private setCanvasSize(): HTMLCanvasElement {
    const canvas = this.canvas.nativeElement;
    if (!canvas) {
      throw new Error('The canvas element is not defined.');
    }

    canvas.height = 150;
    canvas.width = this.elementRef.nativeElement.offsetWidth;

    return canvas;
  }
}
