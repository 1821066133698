import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { VALIDATION } from 'src/app/constants';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * A custom form field component for currency.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-currency[formControl][label]',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent
  extends CustomInputDirective<number>
  implements OnInit
{
  /**
   * The id of the form control, automatically generated if not provided.
   */
  protected id = `alleva-input-currency-${++uniqueCurrencyFormId}`;

  /**
   * The _required_ label for this input.
   */
  @Input() public override label!: string;

  /**
   * Whether the currency icon should be hidden or not.
   */
  @Input() public set hidePrefixIcon(value: BooleanInput) {
    this.#hidePrefixIcon = coerceBooleanProperty(value);
  }
  public get hidePrefixIcon(): boolean {
    return this.#hidePrefixIcon;
  }
  #hidePrefixIcon = false;

  /**
   * Whether 0 is a valid value or not.
   * Default is `true` and allows 0 as a valid value.
   */
  @Input() public allowZeroValue = true;

  /**
   * Name of the form control.
   */
  @Input() public name?: string;

  protected readonly options: NgxCurrencyConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    inputMode: NgxCurrencyInputMode.Financial,
    nullable: true,
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: ',',
  };

  public override ngOnInit(): void {
    super.ngOnInit();

    const maxLength = getMaxLength(this.baseControl);

    this.baseControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        const currentErrors = this.baseControl.errors;
        // If the value is greater than the maxLength, set the error.
        if (value && maxLength && value.toString().length > maxLength) {
          const newErrors = {
            ...currentErrors,
            maxlength: {
              requiredLength: maxLength,
              actualLength: value.toString().length,
            },
          };
          this.baseControl.setErrors(newErrors);
        }
        // If the value is less than the maxLength, remove the error.
        else if (currentErrors && currentErrors['maxlength']) {
          delete currentErrors['maxlength'];
          this.baseControl.setErrors(currentErrors);
        }

        // If the value is 0 and zero is not allowed, set the error.
        if (!this.allowZeroValue && value === 0) {
          const newErrors = {
            ...currentErrors,
            zeroNotAllowed: true,
          };
          this.baseControl.setErrors(newErrors);
        }
      });
  }
}

let uniqueCurrencyFormId = 0;

function getMaxLength(control: AbstractControl): number | null {
  const validatorFn = control.validator;
  if (!validatorFn) return null;

  const mockValue = 'X'.repeat(VALIDATION.currency.maxlength + 1);
  const mockControl: AbstractControl = new FormControl(mockValue);
  const validationResult = validatorFn(mockControl);

  // Check if maxLength error exists and return the requiredLength if it does.
  if (validationResult && validationResult['maxlength']) {
    return validationResult['maxlength'].requiredLength;
  }

  return null;
}
