import { ClientCIWAVitalsApi, ClientCIWAVitalsUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';
import {
  VitalsBloodPressure,
  VitalsBloodPressureUpdate,
} from 'src/app/models/vitals/vitals-blood-pressure.model';
import {
  VitalsBloodSugar,
  VitalsBloodSugarUpdate,
} from 'src/app/models/vitals/vitals-blood-sugar.model';
import {
  VitalsBMI,
  VitalsBMIUpdate,
} from 'src/app/models/vitals/vitals-bmi.model';
import {
  VitalsHeight,
  VitalsHeightUpdate,
} from 'src/app/models/vitals/vitals-height.model';
import {
  VitalsTemperature,
  VitalsTemperatureUpdate,
} from 'src/app/models/vitals/vitals-temperature.model';

const api = apiDecorator<ClientCIWAVitalsApi>();

abstract class ClientCIWAVitalsBase {
  public constructor(props: ClassProperties<ClientCIWAVitalsBase>) {
    this.dateTime = props.dateTime;
    this.oxygenSat = props.oxygenSat;
    this.pain = props.pain;
    this.pulse = props.pulse;
    this.respirations = props.respirations;
    this.weightLbs = props.weightLbs;
  }

  @api({ key: 'date' }) public readonly dateTime: DateTime;
  @api({ key: 'oxygenSat' }) public readonly oxygenSat: number | null;
  @api({ key: 'pain' }) public readonly pain: number | null;
  @api({ key: 'pulse' }) public readonly pulse: number | null;
  @api({ key: 'respirations' }) public readonly respirations: number | null;
  @api({ key: 'weight' }) public readonly weightLbs: number | null;
}

export class ClientCIWAVitals extends ClientCIWAVitalsBase {
  public constructor(props: ClassProperties<ClientCIWAVitals>) {
    super(props);

    this.bloodPressure = props.bloodPressure;
    this.bloodSugar = props.bloodSugar;
    this.bmi = props.bmi;
    this.height = props.height;
    this.id = props.id;
    this.temperature = props.temperature;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA Vitals API
   * model.
   */
  public static readonly Codec = io.type(
    {
      bloodPressure: io.union([VitalsBloodPressure.Codec, io.null]),
      bloodSugar: io.union([VitalsBloodSugar.Codec, io.null]),
      bmi: io.union([VitalsBMI.Codec, io.null]),
      date: io.string,
      height: io.union([VitalsHeight.Codec, io.null]),
      id: io.number,
      oxygenSat: io.union([io.number, io.null]),
      pain: io.union([io.number, io.null]),
      pulse: io.union([io.number, io.null]),
      respirations: io.union([io.number, io.null]),
      temperature: io.union([VitalsTemperature.Codec, io.null]),
      weight: io.union([io.number, io.null]),
    },
    'ClientCIWAVitalsApi',
  );

  @api({ key: 'bloodPressure' })
  public readonly bloodPressure: VitalsBloodPressure | null;
  @api({ key: 'bloodSugar' })
  public readonly bloodSugar: VitalsBloodSugar | null;
  @api({ key: 'bmi' }) public readonly bmi: VitalsBMI | null;
  @api({ key: 'height' }) public readonly height: VitalsHeight | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'temperature' })
  public readonly temperature: VitalsTemperature | null;

  /**
   * Deserializes a Client CIWA Vitals object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA Vitals object.
   * @throws An error if the value is not a valid Client CIWA Vitals object.
   */
  public static deserialize(
    value: NonNullable<ClientCIWAVitalsApi>,
    deserializationArgs: ClientCIWAVitalsDeserializationArgs,
  ): ClientCIWAVitals {
    const decoded = decode(ClientCIWAVitals.Codec, value);
    return new ClientCIWAVitals({
      ...decoded,
      bloodPressure: decoded.bloodPressure
        ? VitalsBloodPressure.deserialize(decoded.bloodPressure)
        : null,
      bloodSugar: decoded.bloodSugar
        ? VitalsBloodSugar.deserialize(decoded.bloodSugar)
        : null,
      bmi: decoded.bmi ? VitalsBMI.deserialize(decoded.bmi) : null,
      dateTime: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      height: decoded.height ? VitalsHeight.deserialize(decoded.height) : null,
      temperature: decoded.temperature
        ? VitalsTemperature.deserialize(decoded.temperature)
        : null,
      weightLbs: decoded.weight,
    });
  }

  /**
   * Deserializes a list of Client CIWA Vitals objects from an API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA Vitals objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA Vitals
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWAVitalsApi>>,
    deserializationArgs: ClientCIWAVitalsDeserializationArgs,
  ): readonly ClientCIWAVitals[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA Vitals objects.');
    }
    return values.map((clientCIWAVitals) =>
      ClientCIWAVitals.deserialize(clientCIWAVitals, deserializationArgs),
    );
  }
}

export class ClientCIWAVitalsUpdate extends ClientCIWAVitalsBase {
  public constructor(props: ClassProperties<ClientCIWAVitalsUpdate>) {
    super(props);

    this.bloodPressure = props.bloodPressure;
    this.bloodSugar = props.bloodSugar;
    this.bmi = props.bmi;
    this.height = props.height;
    this.temperature = props.temperature;
  }

  @api({ key: 'bloodPressure' })
  public readonly bloodPressure: VitalsBloodPressureUpdate | null;
  @api({ key: 'bloodSugar' })
  public readonly bloodSugar: VitalsBloodSugarUpdate | null;
  @api({ key: 'bmi' }) public readonly bmi: VitalsBMIUpdate | null;
  @api({ key: 'height' }) public readonly height: VitalsHeightUpdate | null;
  @api({ key: 'temperature' })
  public readonly temperature: VitalsTemperatureUpdate | null;

  public serialize(): ClientCIWAVitalsUpdateApi {
    return {
      bloodPressure: this.bloodPressure ? this.bloodPressure.serialize() : null,
      bloodSugar: this.bloodSugar ? this.bloodSugar.serialize() : null,
      bmi: this.bmi ? this.bmi.serialize() : null,
      date: this.dateTime.toISO()!,
      height: this.height ? this.height.serialize() : null,
      oxygenSat: this.oxygenSat,
      pain: this.pain,
      pulse: this.pulse,
      respirations: this.respirations,
      temperature: this.temperature ? this.temperature.serialize() : null,
      weight: this.weightLbs,
    };
  }
}

export interface ClientCIWAVitalsDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
