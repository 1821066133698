<form [formGroup]="formGroup">
  <!-- DATE -->
  <alleva-input-pick-date
    [formControl]="formGroup.controls['date']"
    label="Date"
  ></alleva-input-pick-date>
  <!-- TIME -->
  <alleva-input-pick-time
    [formControl]="formGroup.controls['time']"
    label="Time"
  ></alleva-input-pick-time>
  <!-- CLIENT -->
  <alleva-input-autocomplete
    [displayWith]="getDisplayValue"
    [formControl]="formGroup.controls['client']"
    [list]="clientListChanges | async"
    label="Client"
    placeholder="Search Clients"
  ></alleva-input-autocomplete>
  <!-- NOTE TYPE -->
  <alleva-input-dropdown
    [formControl]="formGroup.controls['noteType']"
    label="Note Type"
  >
    <alleva-input-dropdown-option
      *ngFor="let clientNoteType of clientNoteTypesChanges | async"
      [value]="clientNoteType"
    >
      {{ clientNoteType.name }}
    </alleva-input-dropdown-option>
  </alleva-input-dropdown>
  <!-- PRIORITY -->
  <alleva-input-dropdown
    [formControl]="formGroup.controls['priority']"
    label="Priority"
  >
    <alleva-input-dropdown-option
      *ngFor="let clientPriority of clientPrioritiesChanges | async"
      [value]="clientPriority"
    >
      {{ clientPriority.name }}
    </alleva-input-dropdown-option>
  </alleva-input-dropdown>
  <!-- NOTES (BODY) -->
  <alleva-input-editor
    [formControl]="formGroup.controls['body']"
    class="note-body"
    height="300"
    label="Notes (optional)"
    placeholder="Notes (optional)"
  ></alleva-input-editor>
  <!-- SIGNATURE -->
  <alleva-input-sign-me
    [formControl]="formGroup.controls['signature']"
    appearance="staff"
    class="staff-signature"
    enablePin
    label="Staff Signature"
  ></alleva-input-sign-me>
</form>

<div class="actions">
  <alleva-button
    (click)="close()"
    [disabled]="isSubmitting"
    label="Cancel"
    styling="stroked"
  >
    Cancel
  </alleva-button>
  <alleva-button
    (click)="save()"
    [disabled]="formGroup.disabled || isSubmitting"
    color="accent"
    label="Save & Close"
    styling="flat"
  >
    Save & Close
  </alleva-button>
</div>
