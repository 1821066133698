import { Client, ReferralContact, UserBase } from 'src/app/models';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

/**
 * A component for displaying a list of client, referrals, and user cards in a
 * responsive grid layout.
 */
@Component({
  selector: 'alleva-cards-grid[items]',
  templateUrl: './cards-grid.component.html',
  // Static styles.
  styleUrls: ['./cards-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardsGridComponent<T extends GridItem> {
  /**
   * Whether the items in the grid are clickable. If enabled, emit an event
   * when clicked.
   */
  @Input() public isClickable = true;

  /** The list of items to display in the grid. */
  @Input() public items!: readonly T[];

  protected readonly Client = Client;
  protected readonly ReferralContact = ReferralContact;
  protected readonly UserBase = UserBase;

  /** Emits when an item in the grid is clicked if `isClickable` is enabled. */
  @Output() public readonly itemClicked = new EventEmitter<T>();

  protected click(item: T): void {
    if (!this.isClickable) {
      return;
    }

    this.itemClicked.emit(item);
  }

  protected trackById(
    _index: number,
    item: Client | ReferralContact | UserBase,
  ): number {
    return item.id;
  }
}

type GridItem = Client | ReferralContact | UserBase;
