import { CaseManagerApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';
import { Name } from 'src/app/models/user/name.model';
import { UserLicense } from 'src/app/models/user/user-license.model';

const api = apiDecorator<CaseManagerApi>();

export class CaseManager {
  public constructor(props: ClassProperties<CaseManager>) {
    this.active = props.active;
    this.id = props.id;
    this.image = props.image;
    this.isSupervisor = props.isSupervisor;
    this.licenses = props.licenses;
    this.npi = props.npi;
    this.phone = props.phone;
    this.userName = props.userName;
  }

  /**
   * The io-ts codec for runtime type checking of the Case Manager model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      id: io.number,
      image: io.union([io.string, io.null]),
      licenses: io.union([io.array(UserLicense.Codec), io.null]),
      name: io.union([Name.Codec, io.null]),
      npi: io.union([io.string, io.null]),
      phone: io.union([io.string, io.null]),
      supervisor: io.boolean,
    },
    'CaseManagerApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'supervisor' }) public readonly isSupervisor: boolean;
  @api({ key: 'licenses' }) public readonly licenses:
    | readonly UserLicense[]
    | null;
  @api({ key: 'npi' }) public readonly npi: string | null;
  @api({ key: 'phone' }) public readonly phone: string | null;
  @api({ key: 'name' }) public readonly userName: Name | null;

  /**
   * Deserializes a Case Manager object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Case Manager object.
   * @throws An error if the value is not a valid Case Manager object.
   */
  public static deserialize(
    value: NonNullable<CaseManagerApi>,
    deserializationArgs: CaseManagerDeserializationArgs,
  ): CaseManager {
    const decoded = decode(CaseManager.Codec, value);
    return new CaseManager({
      ...decoded,
      isSupervisor: decoded.supervisor,
      licenses: decoded.licenses
        ? UserLicense.deserializeList(decoded.licenses, deserializationArgs)
        : null,
      userName: decoded.name ? Name.deserialize(decoded.name) : null,
    });
  }

  /**
   * Deserializes a list of Case Manager objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Case Manager objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Case Manager objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<CaseManagerApi>>,
    deserializationArgs: CaseManagerDeserializationArgs,
  ): readonly CaseManager[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Case Manager objects.');
    }
    return values.map((caseManager) =>
      CaseManager.deserialize(caseManager, deserializationArgs),
    );
  }
}

export interface CaseManagerDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
