import { AlertService } from 'src/app/services';

import { Component } from '@angular/core';

import {
  AddNoteFormDialogComponent,
  AddNoteFormInput,
} from 'src/app/components/client/communication-log/add-note-form-dialog.component';
import { DialogService } from 'src/app/components/dialogs/dialog.service';

@Component({
  selector: 'alleva-layout-quick-launch-menu',
  templateUrl: './layout-quick-launch-menu.component.html',
  styleUrls: ['./layout-quick-launch-menu.component.scss'],
})
export class LayoutQuickLaunchMenuComponent {
  public constructor(
    private readonly alertService: AlertService,
    private readonly dialogService: DialogService,
  ) {}

  protected isMenuOpen = false;

  /**
   * Perform an action when the user clicks the "Master UDS/BA" button.
   *
   * @todo Figure out if we're going to keep this. See ACP-56.
   */
  protected onClickMasterUDSBA(): void {
    this.alertService.error({
      message:
        'Master UDS/BA action not yet implemented, please contact support ' +
        'for more information.',
    });
  }

  /**
   * Perform an action when the user clicks the "Process Payment" button.
   *
   * @todo Figure out if we're going to keep this. See ACP-56.
   */
  protected onClickProcessPayment(): void {
    this.alertService.error({
      message:
        'Process Payment action not yet implemented, please contact support ' +
        'for more information.',
    });
  }

  /**
   * Perform an action when the user clicks the "Alleva AI" button.
   *
   * @todo Integrate "Alleva AI" chatbot. See ACP-56.
   */
  protected onClickAllevaAI(): void {
    this.alertService.error({
      message:
        'Alleva AI action not yet implemented, please contact support for ' +
        'more information.',
    });
  }

  /** Open the add note dialog. */
  protected openAddNoteDialog(): void {
    this.dialogService.open<
      AddNoteFormDialogComponent,
      AddNoteFormInput | undefined,
      undefined
    >(AddNoteFormDialogComponent, {
      options: {
        closeOnBackdropClick: false,
        dialogTitle: 'Add Note',
        introSectionText: `
          <span>
            Use the form below to add a note for a client.
          </span>
        `.trim(),
      },
    });
  }
}
