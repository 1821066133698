/**
 * Enumerator of standard values (px) for use with Alleva dialog `max-width`.
 */
export enum DialogMaxWidthEnum {
  SMALL = '400px',
  MEDIUM = '600px',
  LARGE = '900px',
  XLARGE = '1200px',
  FULL = '100%',
}
