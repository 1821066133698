import { ValueCodeApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ValueCodeApi>();

export abstract class ValueCode {
  public constructor(props: ClassProperties<ValueCodeApi>) {
    this.id = props.id;
    this.description = props.description;
  }

  /**
   * The io-ts codec for runtime type checking of the Value Code API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      description: io.string,
    },
    'ValueCodeApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'description' }) public readonly description: string;

  /**
   * Deserializes a Value Code object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Value Code object.
   * @throws An error if the value is not a valid Value Code object.
   */
  public static deserialize(value: NonNullable<ValueCodeApi>): ValueCode {
    const decoded = decode(ValueCode.Codec, value);
    return {
      id: decoded.id,
      description: decoded.description,
    };
  }

  /**
   * Deserializes a list of Value Code objects from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized list of Value Code objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not Value Code objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ValueCodeApi>>,
  ): readonly ValueCode[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected an array of Billing Payor Rules objects');
    }
    return values.map(ValueCode.deserialize);
  }
}
