import { MedicationTypeApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<MedicationTypeApi>();

export class MedicationType {
  public constructor(props: ClassProperties<MedicationType>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Medication Type API
   * model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'MedicationTypeApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Client Medication Type object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Medication Type object.
   * @throws An error if the value is not a valid Client Medication Type
   * object.
   */
  public static deserialize(
    value: NonNullable<MedicationTypeApi>,
  ): MedicationType {
    const decoded = decode(MedicationType.Codec, value);
    return new MedicationType({
      ...decoded,
    });
  }

  /**
   * Deserializes a list of Client Medication Type objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Medication Type objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Medication Type
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<MedicationTypeApi>>,
  ): readonly MedicationType[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Medication Type objects.');
    }
    return values.map(MedicationType.deserialize);
  }
}
