/**
 * Function to smoothly scroll to top of the page of the current window.
 */
export function scrollTop(): void {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}
