import { BehaviorSubject, shareReplay } from 'rxjs';

import { Injectable } from '@angular/core';

/**
 * Service for getting current collapse status of the left-side navigation menu.
 */
@Injectable({ providedIn: 'root' })
export class MenuService {
  private readonly isCollapsedSubject = new BehaviorSubject<boolean>(false);
  public readonly isCollapsedChanges = this.isCollapsedSubject.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public set isCollapsed(value: boolean) {
    this.isCollapsedSubject.next(value);
  }
}
