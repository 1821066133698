<mat-tab-group
  #tabGroup
  *ngIf="tabComponentsChanges | async as tabs"
  [class.is-loading]="!isSwitchingTabsCompleted"
  [mat-stretch-tabs]="isStretched"
  mat-align-tabs="start"
>
  <mat-tab *ngFor="let tab of tabs">
    <ng-template mat-tab-label>
      <alleva-icon
        *ngIf="tab.iconName as iconName"
        [name]="iconName"
        [type]="tab.iconType"
      ></alleva-icon>
      <span
        [innerText]="tab.title"
        [matBadgeColor]="tab.titleBadgeColor"
        [matBadgeHidden]="tab.titleBadgeCount === undefined"
        [matBadge]="tab.titleBadgeCount"
        class="tab-title-label"
        matBadgeOverlap="false"
        matBadgePosition="after"
        matBadgeSize="medium"
      ></span>
    </ng-template>
    <ng-template matTabContent>
      <ng-container *ngIf="!tab.isTitleTemplateHidden">
        <div *ngIf="!tab.titleTemplate" class="tab-title">
          <h5>{{ tab.title }}</h5>
        </div>
        <ng-template
          *ngIf="tab.titleTemplate as tabTitleTemplate"
          [ngTemplateOutlet]="tabTitleTemplate"
        ></ng-template>
      </ng-container>
      <ng-container *ngIf="isSwitchingTabsCompleted; else loadingTemplate">
        <ng-container *ngTemplateOutlet="tab.template"></ng-container>
      </ng-container>
      <ng-template #loadingTemplate>
        <alleva-loading-indicator
          [isCentered]="true"
        ></alleva-loading-indicator>
      </ng-template>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<ng-content select="alleva-tab"></ng-content>
