import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';

import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingIndicatorModule } from 'src/app/components/loading-indicator/loading-indicator.module';
import {
  TabComponent,
  TabTitleTemplateDirective,
} from 'src/app/components/tabs/tab.component';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';

@NgModule({
  declarations: [TabComponent, TabTitleTemplateDirective, TabsComponent],
  imports: [
    CommonModule,
    IconModule,
    LoadingIndicatorModule,
    MatBadgeModule,
    MatTabsModule,
  ],
  exports: [TabComponent, TabTitleTemplateDirective, TabsComponent],
})
export class TabsModule {}
