import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a boolean'ish value to a 'Yes' or 'No' string.
 */
@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
  public transform(value: object | Primitive, lowercase?: boolean): string {
    const yesNo = coerceBooleanProperty(value) ? 'Yes' : 'No';
    return lowercase ? yesNo.toLowerCase() : yesNo;
  }
}
