import {
  Observable,
  ReplaySubject,
  combineLatest,
  filter,
  map,
  shareReplay,
} from 'rxjs';
import { AuthenticatedUser, Client, Facility } from 'src/app/models';
import { isNonEmptyValue } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector:
    'alleva-menu-category-item[currentFacility][currentUser][categoryMenuItem][isCollapsed][selectedClient]',
  templateUrl: './menu-category-item.component.html',
  styleUrls: ['./menu-category-item.component.scss'],
})
export class MenuCategoryItemComponent {
  /** The currently selected facility. Required. */
  @Input() public set currentFacility(value: Facility | null) {
    this.currentFacilitySubject.next(value);
  }
  private readonly currentFacilitySubject = new ReplaySubject<Facility | null>(
    1,
  );
  protected readonly currentFacilityChanges =
    this.currentFacilitySubject.asObservable();

  /** The current logged in user. Required. */
  @Input() public set currentUser(value: AuthenticatedUser | null) {
    this.currentUserSubject.next(value);
  }
  private readonly currentUserSubject =
    new ReplaySubject<AuthenticatedUser | null>(1);
  protected readonly currentUserChanges = this.currentUserSubject
    .asObservable()
    .pipe(shareReplay(1));

  /** The menu item to display. Required. */
  @Input() public categoryMenuItem!: CategoryMenuItem;

  /** Whether the menu item should use a collapsed (mobile) view. */
  @Input() public set isCollapsed(value: BooleanInput) {
    this.isCollapsedSubject.next(coerceBooleanProperty(value));
  }
  private readonly isCollapsedSubject = new ReplaySubject<boolean>(1);
  protected readonly isCollapsedChanges =
    this.isCollapsedSubject.asObservable();

  /** The currently selected client, if any. */
  @Input() public set selectedClient(value: Client | null) {
    this.selectedClientSubject.next(value);
  }
  private readonly selectedClientSubject = new ReplaySubject<Client | null>(1);
  protected readonly selectedClientChanges = this.selectedClientSubject
    .asObservable()
    .pipe(shareReplay(1));

  protected readonly topLevelMenuItemsChanges: Observable<
    ReadonlyArray<TopLevelMenuItem & { isDisplayed: boolean }>
  > = combineLatest([
    this.currentFacilityChanges.pipe(filter(isNonEmptyValue)),
    this.currentUserChanges.pipe(filter(isNonEmptyValue)),
    this.selectedClientChanges,
  ]).pipe(
    map(([currentFacility, currentUser, selectedClient]) =>
      this.categoryMenuItem.topLevelMenuItems
        .map((topLevelMenuItem) => {
          const isBillingEnabled = currentFacility.features.isBillingEnabled;
          const isInsightsEnabled = currentUser.settings.isInsightsEnabled;
          const isSandboxEnabled = config.features.isSandboxEnabled;

          // Handle client menu item.
          if (topLevelMenuItem.isClientMenu && selectedClient === null) {
            return {
              ...topLevelMenuItem,
              isDisplayed: false,
            };
          }

          const isTopLevelSandboxMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/sandbox/buttons' ||
                subLevelMenuItem.path === '/sandbox/editor' ||
                subLevelMenuItem.path === '/sandbox/forms' ||
                subLevelMenuItem.path === '/sandbox/icons' ||
                subLevelMenuItem.path === '/sandbox/loading-indicators' ||
                subLevelMenuItem.path === '/sandbox/messages' ||
                subLevelMenuItem.path === '/sandbox/print' ||
                subLevelMenuItem.path === '/sandbox/signatures' ||
                subLevelMenuItem.path === '/sandbox/tables',
            );

          // Handle sandbox menu items.
          // These are locked out by an environment feature flag.
          if (isTopLevelSandboxMenuItem) {
            return {
              ...topLevelMenuItem,
              isDisplayed: isSandboxEnabled,
            };
          }

          // Handle admin menu item.
          if (currentUser.role.isAdmin) {
            return {
              ...topLevelMenuItem,
              isDisplayed: true,
            };
          }

          const hasPermission = currentUser.hasPermission(
            topLevelMenuItem.permissions,
          );

          // Handle insights menu item.
          const isTopLevelInsightsMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/reports/insights',
            );
          if (isTopLevelInsightsMenuItem && isInsightsEnabled) {
            return {
              ...topLevelMenuItem,
              isDisplayed: true,
            };
          }

          // Handle billing menu item.
          const isTopLevelBillingMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/billing-offsite',
            );
          if (isTopLevelBillingMenuItem && isBillingEnabled) {
            return {
              ...topLevelMenuItem,
              isDisplayed: true,
            };
          }

          return {
            ...topLevelMenuItem,
            isDisplayed: hasPermission,
          };
        })
        .filter((topLevelMenuItems) => topLevelMenuItems.isDisplayed),
    ),

    shareReplay(1),
  );
}
