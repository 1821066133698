<mat-form-field *ngIf="type" [appearance]="'outline'">
  <mat-label>{{ label }}</mat-label>
  <div *ngIf="prefixIcon" class="prefix-icon-container">
    <alleva-icon
      [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
      [color]="
        baseControl.touched && baseControl.invalid ? 'warn' : prefixIcon.color
      "
      [name]="prefixIcon.name"
      [scale]="prefixIcon.scale"
      [type]="prefixIcon.type"
    ></alleva-icon>
  </div>
  <ng-container [ngSwitch]="type">
    <textarea
      *ngSwitchCase="'textarea'"
      [attr.maxlength]="maxLength"
      [attr.minlength]="minLength"
      [autocomplete]="autocomplete"
      [formControl]="baseControl"
      [id]="id"
      [name]="name || id"
      [placeholder]="placeholder || ''"
      matInput
    ></textarea>
    <span *ngSwitchDefault class="input-container">
      <input
        [attr.max]="max"
        [attr.maxlength]="maxLength"
        [attr.min]="min"
        [attr.minlength]="minLength"
        [attr.step]="step"
        [autocomplete]="autocomplete"
        [formControl]="baseControl"
        [id]="id"
        [name]="name || id"
        [placeholder]="placeholder || ''"
        [type]="type"
        matInput
      />
      <alleva-icon
        (click)="onSuffixIconClick()"
        *ngIf="suffixIcon || (isClearable && baseControl.value)"
        [class.is-clickable]="isClearable || typeCustom === 'password-showable'"
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="
          baseControl.touched && baseControl.invalid ? 'warn' : suffixIconColor
        "
        [name]="suffixIconName"
        [scale]="suffixIcon?.scale || 0.85"
        [type]="suffixIcon?.type"
        class="suffix-icon"
        matSuffix
      ></alleva-icon>
      <span
        *ngIf="suffixText"
        [innerHTML]="suffixText"
        class="suffix-text"
        matSuffix
      ></span>
    </span>
  </ng-container>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    @if (baseControl.hasError('required')) {
      {{ label }} is required.
    } @else if (baseControl.hasError('min')) {
      {{ label }} must be at least {{ baseControl.errors?.['min'].min }}.
    } @else if (baseControl.hasError('max')) {
      {{ label }} must be at most {{ baseControl.errors?.['max'].max }}.
    } @else if (baseControl.hasError('minlength')) {
      {{ label }} must be at least
      {{ baseControl.errors?.['minlength'].requiredLength }} characters.
    } @else if (baseControl.hasError('maxlength')) {
      {{ label }} must be at most
      {{ baseControl.errors?.['maxlength'].requiredLength }} characters.
    } @else if (baseControl.hasError('email')) {
      {{ label }} must be a valid email address.
    } @else if (baseControl.hasError('nonNumber')) {
      {{ label }} must be a number.
    } @else if (baseControl.hasError('wholeNumber')) {
      {{ label }} must be a whole number.
    }
  </mat-error>
</mat-form-field>
