export enum RaceEnum {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'American Indian or Alaska Native',
  ASIAN = 'Asian',
  BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
  DECLINED_TO_STATE = 'Declined to State',
  HISPANIC_OR_LATINO = 'Hispanic or Latino',
  MULTIRACIAL_TWO_OR_MORE_RACES = 'Multiracial (Two or More Races)',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'Native Hawaiian or Other Pacific Islander',
  SOME_OTHER_RACE = 'Some Other Race',
  WHITE = 'White',
}
