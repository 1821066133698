<ng-container *ngIf="baseControl; else loadingEditor">
  <editor
    *ngIf="editorOptions"
    [class.has-error]="baseControl.invalid && baseControl.touched"
    [formControl]="baseControl"
    [init]="editorOptions"
    [licenseKey]="license_key"
  ></editor>
  <!-- Form Hints and Errors -->
  <mat-hint
    *ngIf="!hideHint && hint && (baseControl.untouched || baseControl.valid)"
    [title]="hint"
    >{{ hint }}</mat-hint
  >
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('minlength')">
      {{ label }} must be at least
      {{ baseControl.errors?.['minlength'].requiredLength }} characters.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('maxlength')">
      {{ label }} must be at most
      {{ baseControl.errors?.['maxlength'].requiredLength }} characters.
    </ng-container>
  </mat-error>
</ng-container>

<ng-template #loadingEditor> Loading editor... </ng-template>
