<!-- 
  @todo Find an alternative to using multiple buttons instances (dynamic 
  attribute binding for instance).
  @see https://github.com/angular/components/issues/15367#issuecomment-1529737909
-->
<ng-container [ngSwitch]="styling">
  <button
    *ngSwitchCase="'basic'"
    [attributes]="attributes"
    [color]="color"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    mat-button
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="'stroked'"
    [attributes]="attributes"
    [color]="color"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    mat-stroked-button
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="'flat'"
    [attributes]="attributes"
    [color]="color"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    mat-flat-button
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="'icon'"
    [attributes]="attributes"
    [color]="color"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    class="icon-button"
    mat-icon-button
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="'fab'"
    [attributes]="attributes"
    [color]="color || 'primary'"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    mat-fab
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="'mini-fab'"
    [attributes]="attributes"
    [color]="color || 'primary'"
    [disabled]="disabled"
    [style.background-color]="colorHex"
    class="alleva-button"
    mat-mini-fab
  >
    <ng-container [ngTemplateOutlet]="innerContent"></ng-container>
  </button>
</ng-container>

<ng-template #innerContent>
  <ng-container *ngIf="areAttributesSet">
    <ng-container
      *ngIf="!href || disabled; else linkTemplate"
      [ngTemplateOutlet]="passedContent"
    ></ng-container>
    <ng-template #linkTemplate>
      <a [href]="href" [target]="target" [title]="label">
        <ng-container [ngTemplateOutlet]="passedContent"></ng-container>
      </a>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #passedContent>
  <ng-container *ngIf="iconName; else labelTemplate">
    <alleva-icon
      [color]="iconColor"
      [colorHex]="iconColorHex"
      [inline]="iconInline"
      [name]="iconName"
      [scale]="iconScale"
      [type]="iconType"
    ></alleva-icon>
  </ng-container>
  <ng-template #labelTemplate>
    <ng-content></ng-content>
  </ng-template>
</ng-template>
