import { Route } from '@angular/router';

/** The unique resource identifier for a route resource used globally in routing. */
const id: RouteResourceIdentifier = ':id';

export const ALLEVA_ROUTES: AllevaRoutes = {
  authenticated: {
    '/alumni': {
      loadChildren: () =>
        import('src/app/pages/alumni/alumni.module').then(
          (module) => module.AlumniModule,
        ),
      path: 'alumni',
      pathMatch: 'full',
    },
    '/billing-offsite': {
      loadChildren: () =>
        import(
          'src/app/pages/billing/billing-offsite/billing-offsite.module'
        ).then((module) => module.BillingOffsiteModule),
      path: 'billing-offsite',
      pathMatch: 'full',
    },
    '/calendar/historical-events': {
      loadChildren: () =>
        import(
          'src/app/pages/calendar/historical-events/historical-events.module'
        ).then((module) => module.HistoricalEventsModule),
      path: 'calendar/historical-events',
      pathMatch: 'full',
    },
    '/calendar/medical-provider-scheduler': {
      loadChildren: () =>
        import(
          'src/app/pages/calendar/medical-provider-scheduler/medical-provider-scheduler.module'
        ).then((module) => module.MedicalProviderSchedulerModule),
      path: 'calendar/medical-provider-scheduler',
      pathMatch: 'full',
    },
    '/calendar/scheduler': {
      loadChildren: () =>
        import('src/app/pages/calendar/scheduler/scheduler.module').then(
          (module) => module.SchedulerModule,
        ),
      path: 'calendar/scheduler',
      pathMatch: 'full',
    },
    '/chef-dashboard': {
      loadChildren: () =>
        import('src/app/pages/chef-dashboard/chef-dashboard.module').then(
          (module) => module.ChefDashboardModule,
        ),
      path: 'chef-dashboard',
      pathMatch: 'full',
    },
    '/clients/list': {
      loadChildren: () =>
        import('src/app/pages/client-list/client-list.module').then(
          (module) => module.ClientListModule,
        ),
      path: 'clients/list',
      pathMatch: 'full',
    },
    '/clients/:id': {
      loadChildren: () =>
        import('src/app/pages/clients/chart/client-chart.module').then(
          (module) => module.ClientChartModule,
        ),
      path: `clients/${id}`,
      pathMatch: 'full',
    },
    '/clients/:id/admission-screening': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/admission-screening/client-admission-screening.module'
        ).then((module) => module.ClientAdmissionScreeningModule),
      path: 'clients/:id/admission-screening',
      pathMatch: 'full',
    },
    '/clients/:id/billing-intake': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/billing-intake/client-billing-intake.module'
        ).then((module) => module.ClientBillingIntakeModule),
      path: 'clients/:id/billing-intake',
      pathMatch: 'full',
    },
    '/clients/:id/biopsychosocial-intake': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/biopsychosocial-intake/client-biopsychosocial-intake.module'
        ).then((module) => module.ClientBiopsychosocialIntakeModule),
      path: 'clients/:id/biopsychosocial-intake',
      pathMatch: 'full',
    },
    '/clients/:id/calendar': {
      loadChildren: () =>
        import('src/app/pages/clients/calendar/client-calendar.module').then(
          (module) => module.ClientCalendarModule,
        ),
      path: 'clients/:id/calendar',
      pathMatch: 'full',
    },
    '/clients/:id/discharge-planning': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/discharge-planning/client-discharge-planning.module'
        ).then((module) => module.ClientDischargePlanningModule),
      path: 'clients/:id/discharge-planning',
      pathMatch: 'full',
    },
    '/clients/:id/doctors-orders': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/doctors-orders/client-doctors-orders.module'
        ).then((module) => module.ClientDoctorsOrdersModule),
      path: 'clients/:id/doctors-orders',
      pathMatch: 'full',
    },
    '/clients/:id/document-manager': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/document-manager/client-document-manager.module'
        ).then((module) => module.ClientDocumentManagerModule),
      path: 'clients/:id/document-manager',
      pathMatch: 'full',
    },
    '/clients/:id/edit': {
      loadChildren: () =>
        import('src/app/pages/clients/edit/client-edit.module').then(
          (module) => module.ClientEditModule,
        ),
      path: 'clients/:id/edit',
      pathMatch: 'full',
    },
    '/clients/:id/face-to-face': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/face-to-face/client-face-to-face.module'
        ).then((module) => module.ClientFaceToFaceModule),
      path: 'clients/:id/face-to-face',
      pathMatch: 'full',
    },
    '/clients/:id/homework': {
      loadChildren: () =>
        import('src/app/pages/clients/homework/client-homework.module').then(
          (module) => module.ClientHomeworkModule,
        ),
      path: 'clients/:id/homework',
      pathMatch: 'full',
    },
    '/clients/:id/meal-plan': {
      loadChildren: () =>
        import('src/app/pages/clients/meal-plan/client-meal-plan.module').then(
          (module) => module.ClientMealPlanModule,
        ),
      path: 'clients/:id/meal-plan',
      pathMatch: 'full',
    },
    '/clients/:id/medical-intake': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/medical-intake/client-medical-intake.module'
        ).then((module) => module.ClientMedicalIntakeModule),
      path: 'clients/:id/medical-intake',
      pathMatch: 'full',
    },
    '/clients/:id/medication-administration-record': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/medication-administration-record/client-medication-administration-record.module'
        ).then((module) => module.ClientMedicationAdministrationRecordModule),
      path: 'clients/:id/medication-administration-record',
      pathMatch: 'full',
    },
    '/clients/:id/medication': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/medication/client-medication.module'
        ).then((module) => module.ClientMedicationModule),
      path: 'clients/:id/medication',
      pathMatch: 'full',
    },
    '/clients/:id/notes': {
      loadChildren: () =>
        import('src/app/pages/clients/notes/client-notes.module').then(
          (module) => module.ClientNotesModule,
        ),
      path: 'clients/:id/notes',
      pathMatch: 'full',
    },
    '/clients/:id/rec-therapy': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/rec-therapy/client-rec-therapy.module'
        ).then((module) => module.ClientRecTherapyModule),
      path: 'clients/:id/rec-therapy',
      pathMatch: 'full',
    },
    '/clients/:id/treatment-plan': {
      loadChildren: () =>
        import(
          'src/app/pages/clients/treatment-plan/client-treatment-plan.module'
        ).then((module) => module.ClientTreatmentPlanModule),
      path: 'clients/:id/treatment-plan',
      pathMatch: 'full',
    },
    '/dashboard': {
      loadChildren: () =>
        import('src/app/pages/dashboard/dashboard.module').then(
          (module) => module.DashboardModule,
        ),
      path: 'dashboard',
      pathMatch: 'full',
    },
    '/doctors-orders': {
      loadChildren: () =>
        import('src/app/pages/doctors-orders/doctors-orders.module').then(
          (module) => module.DoctorsOrdersModule,
        ),
      path: 'doctors-orders',
      pathMatch: 'full',
    },
    '/encounter-transmission': {
      loadChildren: () =>
        import(
          'src/app/pages/billing/encounter-transmission/encounter-transmission.module'
        ).then((module) => module.EncounterTransmissionModule),
      path: 'encounter-transmission',
      pathMatch: 'full',
    },
    '/incidents': {
      loadChildren: () =>
        import('src/app/pages/incidents/incidents.module').then(
          (module) => module.IncidentsModule,
        ),
      path: 'incidents',
      pathMatch: 'full',
    },
    '/lab-orders': {
      loadChildren: () =>
        import('src/app/pages/lab-orders/lab-orders.module').then(
          (module) => module.LabOrdersModule,
        ),
      path: 'lab-orders',
      pathMatch: 'full',
    },
    '/medications/count': {
      loadChildren: () =>
        import('src/app/pages/medications/meds-count/meds-count.module').then(
          (module) => module.MedsCountModule,
        ),
      path: 'medications/count',
      pathMatch: 'full',
    },
    '/medications/destruction': {
      loadChildren: () =>
        import(
          'src/app/pages/medications/medication-destruction/medication-destruction.module'
        ).then((module) => module.MedicationDestructionModule),
      path: 'medications/destruction',
      pathMatch: 'full',
    },
    '/medications/management': {
      loadChildren: () =>
        import(
          'src/app/pages/medications/meds-management/meds-management.module'
        ).then((module) => module.MedsManagementModule),
      path: 'medications/management',
      pathMatch: 'full',
    },
    '/notifications': {
      loadChildren: () =>
        import('src/app/pages/notifications/notifications.module').then(
          (module) => module.NotificationsModule,
        ),
      path: 'notifications',
      pathMatch: 'full',
    },
    '/occupancy': {
      loadChildren: () =>
        import('src/app/pages/occupancy/occupancy.module').then(
          (module) => module.OccupancyModule,
        ),
      path: 'occupancy',
      pathMatch: 'full',
    },
    '/progress-notes': {
      loadChildren: () =>
        import('src/app/pages/progress-notes/progress-notes.module').then(
          (module) => module.ProgressNotesModule,
        ),
      path: 'progress-notes',
      pathMatch: 'full',
    },
    '/prospective/add': {
      loadChildren: () =>
        import(
          'src/app/pages/prospective/prospect-add/prospect-add.module'
        ).then((module) => module.ProspectAddModule),
      path: 'prospective/add',
      pathMatch: 'full',
    },
    '/prospective/list': {
      loadChildren: () =>
        import(
          'src/app/pages/prospective/prospect-list/prospect-list.module'
        ).then((module) => module.ProspectListModule),
      path: 'prospective/list',
      pathMatch: 'full',
    },
    '/referrals': {
      loadChildren: () =>
        import('src/app/pages/referrals/referrals.module').then(
          (module) => module.ReferralsModule,
        ),
      path: 'referrals',
      pathMatch: 'full',
    },
    '/reports/discharge-reports': {
      loadChildren: () =>
        import(
          'src/app/pages/reports/discharge-reports/discharge-reports.module'
        ).then((module) => module.DischargeReportsModule),
      path: 'reports/discharge-reports',
      pathMatch: 'full',
    },
    '/reports/insights': {
      loadChildren: () =>
        import('src/app/pages/reports/insights/insights.module').then(
          (module) => module.InsightsModule,
        ),
      path: 'reports/insights',
      pathMatch: 'full',
    },
    '/reports/meds-count-reports': {
      loadChildren: () =>
        import(
          'src/app/pages/reports/meds-count-reports/meds-count-reports.module'
        ).then((module) => module.MedsCountReportsModule),
      path: 'reports/meds-count-reports',
      pathMatch: 'full',
    },
    '/restricted-access': {
      loadChildren: () =>
        import('src/app/pages/restricted-access/restricted-access.module').then(
          (module) => module.RestrictedAccessModule,
        ),
      path: 'restricted-access',
      pathMatch: 'full',
    },
    '/review-screen/notes-audit': {
      loadChildren: () =>
        import(
          'src/app/pages/review-screen/notes-audit/notes-audit.module'
        ).then((module) => module.NotesAuditModule),
      path: 'review-screen/notes-audit',
      pathMatch: 'full',
    },
    '/review-screen/notes-review': {
      loadChildren: () =>
        import(
          'src/app/pages/review-screen/notes-review/notes-review.module'
        ).then((module) => module.NotesReviewModule),
      path: 'review-screen/notes-review',
      pathMatch: 'full',
    },
    '/review-screen/signature-review': {
      loadChildren: () =>
        import(
          'src/app/pages/review-screen/signature-review/signature-review.module'
        ).then((module) => module.SignatureReviewModule),
      path: 'review-screen/signature-review',
      pathMatch: 'full',
    },
    '/sandbox/buttons': {
      loadChildren: () =>
        import('src/app/pages/sandbox/buttons/sandbox-buttons.module').then(
          (module) => module.SandboxButtonsModule,
        ),
      path: 'sandbox/buttons',
      pathMatch: 'full',
    },
    '/sandbox/editor': {
      loadChildren: () =>
        import('src/app/pages/sandbox/editor/sandbox-editor.module').then(
          (module) => module.SandboxEditorModule,
        ),
      path: 'sandbox/editor',
      pathMatch: 'full',
    },
    '/sandbox/forms': {
      loadChildren: () =>
        import('src/app/pages/sandbox/forms/sandbox-forms.module').then(
          (module) => module.SandboxFormsModule,
        ),
      path: 'sandbox/forms',
      pathMatch: 'full',
    },
    '/sandbox/icons': {
      loadChildren: () =>
        import('src/app/pages/sandbox/icons/sandbox-icons.module').then(
          (module) => module.SandboxIconsModule,
        ),
      path: 'sandbox/icons',
      pathMatch: 'full',
    },
    '/sandbox/loading-indicators': {
      loadChildren: () =>
        import(
          'src/app/pages/sandbox/loading-indicators/sandbox-loading-indicators.module'
        ).then((module) => module.SandboxLoadingIndicatorsModule),
      path: 'sandbox/loading-indicators',
      pathMatch: 'full',
    },
    '/sandbox/messages': {
      loadChildren: () =>
        import('src/app/pages/sandbox/messages/sandbox-messages.module').then(
          (module) => module.SandboxMessagesModule,
        ),
      path: 'sandbox/messages',
      pathMatch: 'full',
    },
    '/sandbox/print': {
      loadChildren: () =>
        import('src/app/pages/sandbox/print/sandbox-print.module').then(
          (module) => module.SandboxPrintModule,
        ),
      path: 'sandbox/print',
      pathMatch: 'full',
    },
    '/sandbox/signatures': {
      loadChildren: () =>
        import(
          'src/app/pages/sandbox/signatures/sandbox-signatures.module'
        ).then((module) => module.SandboxSignaturesModule),
      path: 'sandbox/signatures',
      pathMatch: 'full',
    },
    '/sandbox/tables': {
      loadChildren: () =>
        import('src/app/pages/sandbox/tables/sandbox-tables.module').then(
          (module) => module.SandboxTablesModule,
        ),
      path: 'sandbox/tables',
      pathMatch: 'full',
    },
    '/settings/admission-checklist': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/admission-checklist/admission-checklist.module'
        ).then((module) => module.AdmissionChecklistModule),
      path: 'settings/admission-checklist',
      pathMatch: 'full',
    },
    '/settings/advanced-forms': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/forms-three-manager/forms-three-manager.module'
        ).then((module) => module.FormsThreeManagerModule),
      path: 'settings/advanced-forms',
      pathMatch: 'full',
    },
    '/settings/alerts-frequency': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/alerts-frequency/alerts-frequency.module'
        ).then((module) => module.AlertsFrequencyModule),
      path: 'settings/alerts-frequency',
      pathMatch: 'full',
    },
    '/settings/alerts-permission': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/alerts-permission/alerts-permission.module'
        ).then((module) => module.AlertsPermissionModule),
      path: 'settings/alerts-permission',
      pathMatch: 'full',
    },
    '/settings/alerts-template': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/alerts-template/alerts-template.module'
        ).then((module) => module.AlertsTemplateModule),
      path: 'settings/alerts-template',
      pathMatch: 'full',
    },
    '/settings/beds': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/bed-management/bed-management.module'
        ).then((module) => module.BedManagementModule),
      path: 'settings/beds',
      pathMatch: 'full',
    },
    '/settings/billing': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: 'settings/billing',
      pathMatch: 'full',
    },
    '/settings/billing/billing-codes': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: `settings/billing/billing-codes`,
      pathMatch: 'full',
    },
    '/settings/billing/insurance-companies': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: `settings/billing/insurance-companies`,
      pathMatch: 'full',
    },
    '/settings/billing/modifiers': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: `settings/billing/modifiers`,
      pathMatch: 'full',
    },
    '/settings/billing/rules': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: `settings/billing/rules`,
      pathMatch: 'full',
    },
    '/settings/billing/rules/add': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/billing/rules/add/billing-rule-add.module'
        ).then((module) => module.BillingRuleAddModule),
      path: `settings/billing/rules/add`,
      pathMatch: 'full',
    },
    '/settings/billing/rules/:id/copy': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/billing/rules/copy/billing-rule-copy.module'
        ).then((module) => module.BillingRuleCopyModule),
      path: 'settings/billing/rules/:id/copy',
      pathMatch: 'full',
    },
    '/settings/billing/rules/:id/edit': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/billing/rules/edit/billing-rule-edit.module'
        ).then((module) => module.BillingRuleEditModule),
      path: 'settings/billing/rules/:id/edit',
      pathMatch: 'full',
    },
    '/settings/billing/rev-codes': {
      loadChildren: () =>
        import('src/app/pages/settings/billing/settings-billing.module').then(
          (module) => module.SettingsBillingModule,
        ),
      path: `settings/billing/rev-codes`,
      pathMatch: 'full',
    },
    '/settings/client-transfer': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/client-transfer/client-transfer.module'
        ).then((module) => module.ClientTransferModule),
      path: 'settings/client-transfer',
      pathMatch: 'full',
    },
    '/settings/company-information': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/company-information/company-information.module'
        ).then((module) => module.CompanyInformationModule),
      path: 'settings/company-information',
      pathMatch: 'full',
    },
    '/settings/daily-questionnaire': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/daily-questionnaire/daily-questionnaire.module'
        ).then((module) => module.DailyQuestionnaireModule),
      path: 'settings/daily-questionnaire',
      pathMatch: 'full',
    },
    '/settings/dietary': {
      loadChildren: () =>
        import('src/app/pages/settings/dietary/dietary.module').then(
          (module) => module.DietaryModule,
        ),
      path: 'settings/dietary',
      pathMatch: 'full',
    },
    '/settings/digital-dashboard': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/digital-dashboard/digital-dashboard.module'
        ).then((module) => module.DigitalDashboardModule),
      path: 'settings/digital-dashboard',
      pathMatch: 'full',
    },
    '/settings/discharge': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/manage-discharge/manage-discharge.module'
        ).then((module) => module.ManageDischargeModule),
      path: 'settings/discharge',
      pathMatch: 'full',
    },
    '/settings/family-portal': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/family-portal/family-portal.module'
        ).then((module) => module.FamilyPortalModule),
      path: 'settings/family-portal',
      pathMatch: 'full',
    },
    '/settings/forms': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/forms-manager/forms-manager.module'
        ).then((module) => module.FormsManagerModule),
      path: 'settings/forms',
      pathMatch: 'full',
    },
    '/settings/forms-two': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/forms-two-manager/forms-two-manager.module'
        ).then((module) => module.FormsTwoManagerModule),
      path: 'settings/forms-two',
      pathMatch: 'full',
    },
    '/settings/groups': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/manage-groups/manage-groups.module'
        ).then((module) => module.ManageGroupsModule),
      path: 'settings/groups',
      pathMatch: 'full',
    },
    '/settings/intakes': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/manage-intake/manage-intake.module'
        ).then((module) => module.ManageIntakeModule),
      path: 'settings/intakes',
      pathMatch: 'full',
    },
    '/settings/labs': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/lab-management/lab-management.module'
        ).then((module) => module.LabManagementModule),
      path: 'settings/labs',
      pathMatch: 'full',
    },
    '/settings/master-dropdowns': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/master-drop-downs/master-drop-downs.module'
        ).then((module) => module.MasterDropDownsModule),
      path: 'settings/master-dropdowns',
      pathMatch: 'full',
    },
    '/settings/medical-providers': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/medical-provider/medical-provider.module'
        ).then((module) => module.MedicalProviderModule),
      path: 'settings/medical-providers',
      pathMatch: 'full',
    },
    '/settings/orders': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/order-settings/order-settings.module'
        ).then((module) => module.OrderSettingsModule),
      path: 'settings/orders',
      pathMatch: 'full',
    },
    '/settings/office-templates': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/office-templates/office-templates.module'
        ).then((module) => module.OfficeTemplatesModule),
      path: 'settings/office-templates',
      pathMatch: 'full',
    },
    '/settings/pharmacies': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/pharmacy-management/pharmacy-management.module'
        ).then((module) => module.PharmacyManagementModule),
      path: 'settings/pharmacies',
      pathMatch: 'full',
    },
    '/settings/profile': {
      loadChildren: () =>
        import('src/app/pages/settings/profile/profile.module').then(
          (module) => module.ProfileModule,
        ),
      path: `settings/profile`,
      pathMatch: 'full',
    },
    '/settings/roles': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/role-management/role-management.module'
        ).then((module) => module.RoleManagementModule),
      path: 'settings/roles',
      pathMatch: 'full',
    },
    '/settings/surveys': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/manage-survey/manage-survey.module'
        ).then((module) => module.ManageSurveyModule),
      path: 'settings/surveys',
      pathMatch: 'full',
    },
    '/settings/templates': {
      loadChildren: () =>
        import('src/app/pages/settings/templates/templates.module').then(
          (module) => module.TemplatesModule,
        ),
      path: 'settings/templates',
      pathMatch: 'full',
    },
    '/settings/treatment-plan-review': {
      loadChildren: () =>
        import('src/app/pages/settings/manage-tpr/manage-tpr.module').then(
          (module) => module.ManageTPRModule,
        ),
      path: 'settings/treatment-plan-review',
      pathMatch: 'full',
    },
    '/settings/users': {
      loadChildren: () =>
        import(
          'src/app/pages/settings/user-management/user-management.module'
        ).then((module) => module.UserManagementModule),
      path: 'settings/users',
      pathMatch: 'full',
    },
    '/shifts/shift-notes': {
      loadChildren: () =>
        import('src/app/pages/shifts/shift-notes/shift-notes.module').then(
          (module) => module.ShiftNotesModule,
        ),
      path: 'shifts/shift-notes',
      pathMatch: 'full',
    },
    '/shifts/shift-rounds': {
      loadChildren: () =>
        import('src/app/pages/shifts/shift-rounds/shift-rounds.module').then(
          (module) => module.ShiftRoundsModule,
        ),
      path: 'shifts/shift-rounds',
      pathMatch: 'full',
    },
    '/utilization-review': {
      loadChildren: () =>
        import(
          'src/app/pages/utilization-review/utilization-review.module'
        ).then((module) => module.UtilizationReviewModule),
      path: 'utilization-review',
      pathMatch: 'full',
    },
  },
  rewrites: [
    {
      path: 'facility/:id/calendar',
      redirectTo: '/calendar/scheduler',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/clients',
      redirectTo: '/clients/list',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/medications',
      redirectTo: '/medications/management',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/prospective',
      redirectTo: '/prospective/list',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/reports',
      redirectTo: '/reports/discharge-reports',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/review-screen',
      redirectTo: '/review-screen/signature-review',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/sandbox',
      redirectTo: '/sandbox/buttons',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/settings',
      redirectTo: '/settings/company-information',
      pathMatch: 'full',
    },
    {
      path: 'facility/:id/shifts',
      redirectTo: '/shifts/shift-rounds',
      pathMatch: 'full',
    },
  ],
  unauthenticated: {
    '/forbidden': {
      loadChildren: () =>
        import('src/app/pages/forbidden/forbidden.module').then(
          (module) => module.ForbiddenModule,
        ),
      path: 'forbidden',
      pathMatch: 'full',
    },
    '/login': {
      loadChildren: () =>
        import('src/app/pages/login/login.module').then(
          (module) => module.LoginModule,
        ),
      pathMatch: 'full',
      path: 'login',
    },
    '/not-found': {
      loadChildren: () =>
        import('src/app/pages/not-found/not-found.module').then(
          (module) => module.NotFoundModule,
        ),
      path: 'not-found',
      pathMatch: 'full',
    },
  },
};

/**
 * All of the Alleva EMR routes in the application.
 */
interface AllevaRoutes {
  /**
   * The routes that require authentication.
   */
  authenticated: AuthenticatedRoutes;
  /**
   * Route redirects. Redirects are used to redirect users to a new route.
   */
  rewrites: readonly RouteRedirect[];
  /**
   * The routes that do not require authentication.
   */
  unauthenticated: UnauthenticatedRoutes;
}

/**
 * The routes that require authentication.
 */
type AuthenticatedRoutes = Omit<
  {
    [key in PageRoute]: Route;
  },
  '/forbidden' | '/login' | '/not-found'
>;

/**
 * The routes that do not require authentication.
 */
type UnauthenticatedRoutes = Pick<
  {
    [key in PageRoute]: Route;
  },
  '/forbidden' | '/login' | '/not-found'
>;
