<ng-container *ngIf="baseControl as formControl; else loadingControl">
  <mat-form-field
    (click)="openFileInput()"
    [appearance]="'outline'"
    subscriptSizing="dynamic"
  >
    <mat-label>{{ inputLabel }}</mat-label>
    <input class="placeholder" disabled matInput />
    <input
      #fileInput
      (change)="files = getInputFiles($event)"
      [multiple]="this.maxFiles > 1"
      [accept]="accept.join(', ')"
      hidden
      type="file"
    />
    <alleva-icon
      [colorHex]="disabled ? 'var(--gray-mid-lighter)' : undefined"
      color="accent"
      matSuffix
      name="cloud_upload"
    ></alleva-icon>
  </mat-form-field>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <div *ngIf="!hideFileList && files" class="file-list">
    <div *ngFor="let file of files" class="file">
      <alleva-icon class="file-icon" name="insert_drive_file"></alleva-icon>
      <div class="file-info">
        {{ file.name }}&nbsp;({{ getFileSizeString(file.size) }})
      </div>
      <alleva-button
        (click)="viewFile(file)"
        (mouseout)="closeImageHoverPreview()"
        (mouseover)="openImageHoverPreview($event, file)"
        [icon]="{ colorHex: 'var(--white)', name: 'visibility', scale: 0.75 }"
        class="action-button"
        color="primary"
        label="View File"
        styling="mini-fab"
      ></alleva-button>
      <alleva-button
        *ngIf="!disabled"
        (click)="removeFile(file)"
        [icon]="{ name: 'delete', colorHex: 'var(--white)', scale: 0.75 }"
        class="action-button"
        color="warn"
        label="Remove file"
        styling="mini-fab"
      ></alleva-button>
    </div>
  </div>
</ng-container>

<ng-template #loadingControl> Loading... </ng-template>
