import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, map, startWith } from 'rxjs';
import { getNestedObjectData, isEmptyValue } from 'src/app/utilities';

import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * A component that allows users to select items from one list (a column of
 * rows that display an array of "Unselected" values) to be added to another
 * list (the secondary column of rows, the "Selected" values).
 *
 * @requires `data` attribute to be set.
 * @requires `displayKey` attribute to be set.
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@Component({
  selector: 'alleva-input-pick-list[data][displayKey][formControl][label]',
  templateUrl: './input-pick-list.component.html',
  styleUrls: ['./input-pick-list.component.scss'],
})
export class InputPickListComponent<T extends object>
  extends CustomInputDirective<readonly T[]>
  implements OnInit
{
  /**
   * The data to display in the list.
   *
   * @required This is a required input.
   */
  @Input() public data!: readonly T[];

  /**
   * The key of the property to display in the list. Supports nested keys.
   *
   * Example keys: 'name' or 'name.first' using dot notation.
   *
   * @required This is a required input.
   */
  @Input() public displayKey!: NestedKeysOfString<T>;

  /**
   * The label to display above the selected list.
   */
  @Input() public set labelSelected(value: string) {
    this.#labelSelected = value;
  }
  public get labelSelected(): string {
    return this.baseControl.hasValidator(Validators.required)
      ? `${this.#labelSelected}*`
      : this.#labelSelected;
  }
  #labelSelected = 'Selected';

  /**
   * The label to display above the unselected list.
   */
  @Input() public set labelUnselected(value: string) {
    this.#labelUnselected = value;
  }
  public get labelUnselected(): string {
    return this.baseControl.hasValidator(Validators.required)
      ? `${this.#labelUnselected}*`
      : this.#labelUnselected;
  }
  #labelUnselected = 'Unselected';

  /**
   * The text to display when a value is missing.
   */
  @Input() public missingValueText = 'N/A';

  /**
   * Default `placeholder` is undefined and unused, please use
   * `emptyListPlaceholder` inputs to set the "empty" placeholder.
   */
  public override placeholder: undefined;

  /**
   * The placeholder to display when the list is empty.
   *
   * @future Potentially seperate placeholders for each list.
   */
  @Input() public emptyListPlaceholder = 'Empty';

  /**
   * The amount of rows to display in the list before scrolling.
   *
   * @default 12 Rows
   */
  @Input() public size = 12;

  /**
   * The values that are currently selected in the list.
   */
  private readonly selectedValuesSubject = new BehaviorSubject<
    readonly T[] | null
  >(null);
  public readonly selectedValuesChanges =
    this.selectedValuesSubject.asObservable();

  /**
   * The values that are currently not selected in the list.
   */
  private readonly unselectedValuesSubject = new BehaviorSubject<
    readonly T[] | null
  >(null);
  public readonly unselectedValuesChanges = combineLatest([
    this.unselectedValuesSubject.asObservable(),
    this.selectedValuesChanges,
  ]).pipe(
    map(([unselectedValues, selectedValues]) =>
      // Compare using JSON.stringify to avoid issues with objects.
      unselectedValues?.filter((value) =>
        selectedValues
          ? !selectedValues
              .map((selectedValue) => JSON.stringify(selectedValue))
              .includes(JSON.stringify(value))
          : true,
      ),
    ),
  );

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    // Check to makes sure an appropriate row height was provided.
    if (this.size <= 0) {
      throw new Error('The row height must be greater than 0.');
    }

    // Verify `displayKey` key exists in the provided `data` array on init.
    if (this.displayKey in this.data[0] === false) {
      throw new Error(
        `The provided display key '${this.displayKey}' does not exist in the provided data.`,
      );
    }

    // Set the initial unselected values.
    this.unselectedValuesSubject.next(this.data);

    // Clear the selected values when the base control is set to null/undefined.
    // For example, this will clear the selected values when the form is reset.
    this.baseControl.valueChanges
      .pipe(startWith(this.baseControl.value), untilDestroyed(this))
      .subscribe((values) => {
        if (isEmptyValue(values)) {
          this.selectedValuesSubject.next(null);
        } else {
          for (const value of values) {
            this.onSelectValue(value);
          }
        }
      });
  }

  /**
   * Gets the display value for the provided item.
   *
   * @param item The item to get the display value for.
   * @returns The display value for the provided item.
   */
  public getDisplayValue(item: T): string {
    return (
      getNestedObjectData<T>(item, this.displayKey) || this.missingValueText
    ).toString();
  }

  /**
   * Handles when a value is selected from the list.
   *
   * @param selectedValue The value that was selected.
   */
  public onSelectValue(selectedValue: T): void {
    if (this.disabled) {
      return;
    }

    const updatedValues = [
      ...(this.selectedValuesSubject.value ?? []).filter(
        (previousValue) =>
          JSON.stringify(previousValue) !== JSON.stringify(selectedValue),
      ),
      selectedValue,
    ];
    this.selectedValuesSubject.next(updatedValues);
    this.baseControl.setValue(updatedValues, { emitEvent: false });
  }

  /**
   * Handles when a value is deselected from the list.
   *
   * @param deselectedValue The value that was deselected.
   */
  public onDeselectValue(deselectedValue: T): void {
    if (this.disabled) {
      return;
    }

    const updatedValues =
      this.selectedValuesSubject.value?.filter(
        (previousValue) =>
          JSON.stringify(previousValue) !== JSON.stringify(deselectedValue),
      ) ?? null;
    this.selectedValuesSubject.next(updatedValues);
    this.baseControl.setValue(updatedValues, { emitEvent: false });
  }
}
