import {
  ClientCIWAAssessmentApi,
  ClientCIWAAssessmentUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientCIWAAssessmentApi>();

type ClientCIWAAssessmentArgs = Omit<
  ClassProperties<ClientCIWAAssessment>,
  // Omit computed properties set on construct from outside data.
  'score'
>;

export class ClientCIWAAssessment {
  public constructor(props: ClassProperties<ClientCIWAAssessmentArgs>) {
    this.agitation = props.agitation;
    this.anxiety = props.anxiety;
    this.auditoryDisturbances = props.auditoryDisturbances;
    this.headache = props.headache;
    this.nausea = props.nausea;
    this.orientation = props.orientation;
    this.sweats = props.sweats;
    this.tactileDisturbances = props.tactileDisturbances;
    this.tremors = props.tremors;
    this.visualDisturbances = props.visualDisturbances;
  }

  /**
   * Union of possible 0-4 literal values, nullable. For use in runtime type
   * checking of the number literals in the Client CIWA Assessment API model.
   */
  private static readonly ZERO_TO_FOUR_NULLABLE_UNION = io.union([
    io.literal(0),
    io.literal(1),
    io.literal(2),
    io.literal(3),
    io.literal(4),
    io.null,
  ]);

  /**
   * Union of possible 0-7 literal values, nullable. For use in runtime type
   * checking of the number literals in the Client CIWA Assessment API model.
   */
  private static readonly ZERO_TO_SEVEN_NULLABLE_UNION = io.union([
    io.literal(0),
    io.literal(1),
    io.literal(2),
    io.literal(3),
    io.literal(4),
    io.literal(5),
    io.literal(6),
    io.literal(7),
    io.null,
  ]);

  /**
   * The io-ts codec for runtime type checking of the Client CIWA Assessment API
   * model.
   */
  public static readonly Codec = io.type(
    {
      agitation: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      anxiety: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      auditoryDisturbances: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      headache: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      nausea: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      orientation: this.ZERO_TO_FOUR_NULLABLE_UNION,
      sweats: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      tactileDisturbances: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      tremors: this.ZERO_TO_SEVEN_NULLABLE_UNION,
      visualDisturbances: this.ZERO_TO_SEVEN_NULLABLE_UNION,
    },
    'ClientCIWAAssessmentApi',
  );

  @api({ key: 'agitation' })
  public readonly agitation: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'anxiety' })
  public readonly anxiety: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'auditoryDisturbances' })
  public readonly auditoryDisturbances: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'headache' })
  public readonly headache: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'nausea' })
  public readonly nausea: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'orientation' })
  public readonly orientation: 0 | 1 | 2 | 3 | 4;
  @api({ key: 'sweats' })
  public readonly sweats: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'tactileDisturbances' })
  public readonly tactileDisturbances: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'tremors' })
  public readonly tremors: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @api({ key: 'visualDisturbances' })
  public readonly visualDisturbances: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

  /**
   * Get the total CIWA assessment score.
   *
   * @returns The total CIWA assessment score.
   */
  public get score(): number {
    return (
      this.agitation +
      this.anxiety +
      this.auditoryDisturbances +
      this.headache +
      this.nausea +
      this.orientation +
      this.sweats +
      this.tactileDisturbances +
      this.tremors +
      this.visualDisturbances
    );
  }

  /**
   * Deserializes a Client CIWA Assessment object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client CIWA Assessment object.
   * @throws An error if the value is not a valid Client CIWA Assessment object.
   */
  public static deserialize(
    value: NonNullable<ClientCIWAAssessmentApi>,
  ): ClientCIWAAssessment {
    const decoded = decode(ClientCIWAAssessment.Codec, value);
    return new ClientCIWAAssessment({
      agitation: decoded.agitation ?? 0,
      anxiety: decoded.anxiety ?? 0,
      auditoryDisturbances: decoded.auditoryDisturbances ?? 0,
      headache: decoded.headache ?? 0,
      nausea: decoded.nausea ?? 0,
      orientation: decoded.orientation ?? 0,
      sweats: decoded.sweats ?? 0,
      tactileDisturbances: decoded.tactileDisturbances ?? 0,
      tremors: decoded.tremors ?? 0,
      visualDisturbances: decoded.visualDisturbances ?? 0,
    });
  }

  /**
   * Deserializes a list of Client CIWA Assessment objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client CIWA Assessment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA Assessment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWAAssessmentApi>>,
  ): readonly ClientCIWAAssessment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA Assessment objects.');
    }
    return values.map(ClientCIWAAssessment.deserialize);
  }
}

export class ClientCIWAAssessmentUpdate extends ClientCIWAAssessment {
  public constructor(props: ClassProperties<ClientCIWAAssessmentUpdate>) {
    super(props);
  }

  /**
   * Serializes a Client CIWA Score object to an API model.
   *
   * @returns The serialized Client CIWA Score object.
   */
  public serialize(): ClientCIWAAssessmentUpdateApi {
    return this;
  }
}
