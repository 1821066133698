import { ClientStatusEnum } from 'src/app/enumerators';
import { Client } from 'src/app/models';

import { Component, Input } from '@angular/core';

/** A component that displays a clients status badge. */
@Component({
  selector: 'alleva-client-status-badge[client]',
  templateUrl: './client-status-badge.component.html',
  styleUrls: ['../status-badge.scss'],
})
export class ClientStatusBadgeComponent {
  @Input() public client!: Client;

  protected readonly ClientStatusEnum = ClientStatusEnum;
}
