import { AuthenticationService, FacilityService } from 'src/app/services';
import { getFirstNonEmptyValueFrom } from 'src/app/utilities';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

export function facilityGuard(): CanActivateFn {
  return async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> => {
    const authenticationService = inject(AuthenticationService);
    const facilityService = inject(FacilityService);
    const router = inject(Router);

    const user = await getFirstNonEmptyValueFrom(
      authenticationService.userChanges,
    );

    const routeFacilityId = route.paramMap.get('facilityId')
      ? Number(route.paramMap.get('facilityId'))
      : null;

    if (!routeFacilityId || isNaN(routeFacilityId)) {
      return router.parseUrl('/not-found' satisfies PageRoute);
    }

    if (!facilityService.initialized) {
      facilityService.currentFacility = user.defaultFacility;
    }

    const activeFacility = await getFirstNonEmptyValueFrom(
      facilityService.currentFacilityChanges,
    );
    if (activeFacility.id !== routeFacilityId) {
      const setFacilitySuccess =
        await facilityService.setFacilityById(routeFacilityId);

      if (!setFacilitySuccess) {
        return router.parseUrl('/');
      }

      const newFacility = await getFirstNonEmptyValueFrom(
        facilityService.currentFacilityChanges,
      );

      const currentUrl = state.url;
      const facilityAndId = new RegExp('^/facility/\\d+/');
      const newFacilityRoute = `/facility/${newFacility.id}/`;
      const updatedUrl = currentUrl.replace(facilityAndId, newFacilityRoute);

      if (currentUrl === `/facility/${newFacility.id}`) {
        return navigateToFacilityDashboard(newFacility.id, router);
      } else if (currentUrl !== updatedUrl) {
        return router.parseUrl(updatedUrl);
      }
    }

    // If the user visits the base facility page (ie `/facility/1` or
    // `/facility/1/`) then redirect them to the home/dashboard page.
    if (state.url === `/facility/${activeFacility.id}`) {
      return navigateToFacilityDashboard(activeFacility.id, router);
    }

    return true;
  };
}

function navigateToFacilityDashboard(
  facilityId: number,
  router: Router,
): UrlTree {
  const dashboardPage: PageRoute = '/dashboard';
  const dashboardPageRoute = `/facility/${facilityId}${dashboardPage}`;
  return router.parseUrl(dashboardPageRoute);
}
