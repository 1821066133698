import { ClientIntakesCompletedApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientIntakesCompletedApi>();

export class ClientIntakesCompleted {
  public constructor(props: ClassProperties<ClientIntakesCompleted>) {
    this.admissions = props.admissions;
    this.billing = props.billing;
    this.faceToFace = props.faceToFace;
    this.historyAndPhysical = props.historyAndPhysical;
    this.medical = props.medical;
    this.nursing = props.nursing;
    this.psychiatricEval = props.psychiatricEval;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Intakes Completed
   * API model.
   */
  public static readonly Codec = io.type(
    {
      admissions: io.boolean,
      billing: io.boolean,
      faceToFace: io.boolean,
      historyAndPhysical: io.boolean,
      medical: io.boolean,
      nursing: io.boolean,
      psychiatricEval: io.boolean,
    },
    'ClientIntakesCompletedApi',
  );

  @api({ key: 'admissions' }) public readonly admissions: boolean;
  @api({ key: 'billing' }) public readonly billing: boolean;
  @api({ key: 'faceToFace' }) public readonly faceToFace: boolean;
  @api({ key: 'historyAndPhysical' })
  public readonly historyAndPhysical: boolean;
  @api({ key: 'medical' }) public readonly medical: boolean;
  @api({ key: 'nursing' }) public readonly nursing: boolean;
  @api({ key: 'psychiatricEval' }) public readonly psychiatricEval: boolean;

  /**
   * Deserializes a Client Intakes Completed object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Intakes Completed object.
   * @throws An error if the value is not a valid Client Intakes Completed
   * object.
   */
  public static deserialize(
    value: NonNullable<ClientIntakesCompletedApi>,
  ): ClientIntakesCompleted {
    const decoded = decode(ClientIntakesCompleted.Codec, value);
    return new ClientIntakesCompleted(decoded);
  }

  /**
   * Deserializes a list of Client Intakes Completed objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Intakes Completed objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Intakes
   * Completed objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientIntakesCompletedApi>>,
  ): readonly ClientIntakesCompleted[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Intakes Completed objects.');
    }
    return values.map(ClientIntakesCompleted.deserialize);
  }
}
