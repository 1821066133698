import { LevelOfCareApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<LevelOfCareApi>();

export abstract class LevelOfCareBase {
  public constructor(props: ClassProperties<LevelOfCareBase>) {
    this.id = props.id;
  }

  @api({ key: 'id' }) public readonly id: number;
}

export class LevelOfCare extends LevelOfCareBase {
  public constructor(props: ClassProperties<LevelOfCare>) {
    super(props);

    this.active = props.active;
    this.description = props.description;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Level of Care API model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      description: io.union([io.string, io.null]),
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'LevelOfCareApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Level of Care object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Level of Care object.
   * @throws An error if the value is not a valid Level of Care object.
   */
  public static deserialize(value: NonNullable<LevelOfCareApi>): LevelOfCare {
    const decoded = decode(LevelOfCare.Codec, value);
    return new LevelOfCare(decoded);
  }

  /**
   * Deserializes a list of Level of Care objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Level of Care objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Level of Care objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<LevelOfCareApi>>,
  ): readonly LevelOfCare[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Level of Care objects.');
    }
    return values.map(LevelOfCare.deserialize);
  }
}
