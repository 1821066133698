import {
  RoleApi,
  UserApi,
  UserBaseApi,
  UserNotificationPagedListApi,
  UserNotificationsCountApi,
} from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import {
  AuthenticatedUser,
  Facility,
  Role,
  User,
  UserBase,
  UserNotification,
  UserNotificationUpdate,
} from 'src/app/models';
import {
  PagedListCodec,
  decode,
  isNumber,
  parseHttpParams,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the User API.
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return paged user notifications data.
   *
   * @param user The authenticated user to fetch notifications for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @param requestParameters The request parameters to use.
   * @returns The paged user notifications data, undefined on error.
   */
  public getPagedNotificationList(
    user: AuthenticatedUser,
    deserializationArgs: DeserializationArgs,
    requestParameters: readonly NotificationsRequestParam[],
  ): Observable<PagedList<UserNotification> | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<
        UserNotificationPagedListApi | undefined
      >(`${config.api}/users/${user.id}/notifications`, { params })
      .pipe(
        map((response) =>
          response
            ? ({
                ...decode(UserNotificationPagedListCodec, response),
                results: UserNotification.deserializeList(
                  response.results,
                  deserializationArgs,
                ),
              } satisfies PagedList<UserNotification>)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all user information by user id.
   *
   * @param id The user id to fetch the user information for.
   * @param accessToken The access token to use for the request.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The user information, null if not found, undefined on error.
   */
  public getEmrUser({
    id,
    accessToken,
  }: {
    id: AuthenticatedUser['id'];
    accessToken: AuthenticatedUser['accessToken'];
  }): Observable<{
    result: User | null | undefined;
    httpErrorResponse: HttpErrorResponse | null;
  }> {
    return this.httpClient
      .get<UserApi>(`${config.api}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          httpErrorResponse: null,
          result: response.body ? User.deserialize(response.body) : null,
        })),
        catchError((error: unknown) => {
          console.error(error);

          if (error instanceof HttpErrorResponse) {
            // Error occurred on the server.
            return of({
              httpErrorResponse: error,
              result: undefined,
            });
          } else {
            // Error occurred during deserialization or other client-side errors.
            return of({
              httpErrorResponse: null,
              result: undefined,
            });
          }
        }),
      );
  }

  /**
   * Fetch and return all roles by subdomain.
   *
   * @param requestParameters The request parameters to use.
   * @returns The roles, undefined on error.
   */
  public getRoles(
    requestParameters?: readonly RolesRequestParameters[],
  ): Observable<readonly Role[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly RoleApi[]>(`${config.api}/auth/roles`, { params })
      .pipe(
        map((response) => (response ? Role.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all users by facility id.
   *
   * @param facilityId The facility id to fetch the users for.
   * @returns The users, undefined on error.
   */
  public getUsers(
    facilityId: Facility['id'],
  ): Observable<readonly UserBase[] | undefined> {
    return this.httpClient
      .get<
        readonly UserBaseApi[]
      >(`${config.api}/users`, { params: { facilityId } })
      .pipe(
        map((response) => (response ? UserBase.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return the users notification count.
   *
   * @param user The authenticated user to fetch the notifications count for.
   * @returns Notifications count, 0 on error (non-disruptive, silent error).
   */
  public getUnreadNotificationsCount(
    user: AuthenticatedUser,
    requestParameters: readonly NotificationsRequestParam[],
  ): Observable<number> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<
        UserNotificationsCountApi | undefined
      >(`${config.api}/users/${user.id}/notifications/count`, { params })
      .pipe(
        map((response) =>
          response?.unread && isNumber(response.unread) ? response.unread : 0,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(0);
        }),
      );
  }

  /**
   * Update (PATCH) the a user notification resource.
   *
   * @param user The authenticated user to patch the notification for.
   * @param notificationId The notification id to patch.
   * @returns True if the notification was patched, false otherwise.
   */
  public updateNotification(
    user: AuthenticatedUser,
    notificationId: UserNotification['id'],
    notificationUpdate: UserNotificationUpdate,
  ): Observable<boolean> {
    return this.httpClient
      .patch<
        boolean | undefined
      >(`${config.api}/users/${user.id}/notifications/${notificationId}`, notificationUpdate.serialize())
      .pipe(
        map((response) => response ?? false),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}

const UserNotificationPagedListCodec = PagedListCodec(
  UserNotification.Codec,
  'UserNotificationPagedListApi',
);

export interface DeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}

/**
 * Request parameter interface for use with the User Notifications API.
 */
export interface NotificationsRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'facilityId' | 'isRead' | 'order' | 'pageNumber' | 'pageSize' | 'sort';
  /** The values to use for the query parameters. */
  value: SortOrderEnum | boolean | number | keyof UserNotification;
}

/**
 * Request parameter interface for use with the Roles API.
 */
export interface RolesRequestParameters extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'subDomain';
  /** The value to use for the query parameter(s). */
  value: string;
}
