import { UntilDestroy } from '@ngneat/until-destroy';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';

import { IconComponent } from 'src/app/components/icon/icon.component';
import { IconModule } from 'src/app/components/icon/icon.module';

import { DirectivesModule } from 'src/app/directives/directives.module';

/**
 * A dynamic button container that wraps and uses the Angular Material Button
 * functionality while enforcing standard styling, accessibility and attributes.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 * @see https://material.angular.io/components/button/overview
 */
@UntilDestroy()
@Component({
  selector: 'alleva-button[label]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  /** Standalone Options */
  standalone: true,
  imports: [CommonModule, DirectivesModule, IconModule, MatButtonModule],
})
export class ButtonComponent implements AfterViewInit {
  /**
   * Whether or not the button attributes have been set yet.
   */
  public areAttributesSet = false;

  /**
   * The _static_ attributes to add to the button.
   */
  public get attributes(): { [key: string]: string | undefined } {
    try {
      return {
        'aria-label': this.label,
        'aria-disabled': this.disabled ? 'true' : undefined,
        name: this.name,
        type: this.type,
        value: this.value,
      };
    } finally {
      this.areAttributesSet = true;
    }
  }

  /**
   * The theme palette color to use for this button.
   * Can be `primary`, `accent`, or `warn`.
   */
  @Input() public color?: ThemePalette;

  /**
   * Override default button background color with a custom hex color.
   */
  @Input() public colorHex?: string;

  /**
   * Whether the button is disabled.
   */
  @HostBinding('class.disabled')
  @Input()
  public set disabled(value: BooleanInput) {
    this.#disabled = coerceBooleanProperty(value);
  }
  public get disabled(): boolean {
    return this.#disabled;
  }
  #disabled = false;

  /**
   * The label of the button for accessibility attributes.
   */
  @Input() public label!: string; // Required

  /**
   * The link to navigate to when the button is clicked.
   */
  @Input() public href?: string;

  /**
   * The icon to display in the button.
   *
   * - Pass in an `Icon` object to use a custom icon with options.
   * - Pass in an `IconComponent` name to use an icon with default options.
   */
  @Input() public icon?: Icon | IconComponent['name'];

  /**
   * The color of the icon in the button when an icon is used.
   */
  protected get iconColor(): IconComponent['color'] | undefined {
    return this.icon === undefined || typeof this.icon === 'string'
      ? undefined
      : this.icon.color;
  }

  /**
   * Override default icon color with a custom hex color.
   */
  protected get iconColorHex(): IconComponent['colorHex'] | undefined {
    return this.icon === undefined || typeof this.icon === 'string'
      ? undefined
      : this.icon.colorHex;
  }

  /**
   * Whether or not the button and its icon are inline.
   */
  @HostBinding('class.icon-inline')
  protected get iconInline(): boolean | undefined {
    return this.icon === undefined || typeof this.icon === 'string'
      ? undefined
      : this.icon.inline;
  }

  /**
   * The name of the icon to use.
   */
  protected get iconName(): IconComponent['name'] | undefined {
    if (this.icon === undefined) {
      return undefined;
    } else if (typeof this.icon === 'string') {
      return this.icon;
    } else {
      return this.icon.name;
    }
  }

  /**
   * The scale of the icon in the button when an icon is used.
   */
  @HostBinding('class.custom-icon-size')
  protected get iconScale(): number | undefined {
    return this.icon === undefined || typeof this.icon === 'string'
      ? undefined
      : this.icon.scale;
  }

  /**
   * The type of icon to use.
   */
  protected get iconType(): IconComponent['type'] | undefined {
    return this.icon === undefined || typeof this.icon === 'string'
      ? undefined
      : this.icon.type;
  }

  /**
   * The name of the button used to reference form-data after the form has
   * been submitted, or to reference the element.
   */
  @Input() public name?: string;

  /**
   * The target of the link when `href` is set.
   */
  @Input() public target: '_self' | '_blank' = '_self';

  /**
   * The Anuglar material button type styling.
   */
  @Input() public styling: ButtonStyle = 'flat';

  /**
   * The type of button. This is used to determine the button type attribute.
   */
  @Input() public type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * The value of the button used for reference.
   */
  @Input() public value?: string;

  public ngAfterViewInit(): void {
    // If this button is an "icon" button, check to make sure an icon is chosen.
    if (
      (this.styling === 'icon' ||
        this.styling === 'fab' ||
        this.styling === 'mini-fab') &&
      !this.icon
    ) {
      throw new Error('Icon button must have an icon defined.');
    }
  }
}

// Angular Material Button Styles
type ButtonStyle = 'basic' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab';
