import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  interval,
  map,
  startWith,
} from 'rxjs';
import { FacilityBase } from 'src/app/models';
import {
  AlertService,
  AuthenticationService,
  FacilityService,
} from 'src/app/services';
import { getFirstNonEmptyValueFrom, isNonEmptyValue } from 'src/app/utilities';

import { AfterContentInit, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

/**
 * The number of seconds to wait before refreshing the displayed time.
 */
const refreshDisplayTimeSeconds = 5;

/**
 * Component that allows the user to switch between facilities and displays the
 * related facility information.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-layout-header-switch-facility',
  templateUrl: './layout-header-switch-facility.component.html',
  styleUrls: ['./layout-header-switch-facility.component.scss'],
})
export class LayoutHeaderSwitchFacilityComponent implements AfterContentInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly facilityService: FacilityService,
    private readonly router: Router,
  ) {}

  protected readonly activeFacilityChanges =
    this.facilityService.currentFacilityChanges;

  protected readonly availableFacilityBaseChanges =
    this.authenticationService.userChanges.pipe(
      filter(isNonEmptyValue),
      map((user) => user.facilities.filter((facility) => facility.active)),
    );

  protected readonly facilityBaseControl = new FormControl<FacilityBase | null>(
    null,
  );

  // A clock that shows the current time formatted to the facility's timezone.
  // Update every X second(s) to keep the display in sync real-time.
  private readonly timeSubject = new BehaviorSubject<DateTime | null>(null);
  protected readonly timeChanges = this.timeSubject.asObservable();

  public async ngAfterContentInit(): Promise<void> {
    let currentFacility = await getFirstNonEmptyValueFrom(
      this.facilityService.currentFacilityChanges,
    );
    const availableBaseFacilities = await getFirstNonEmptyValueFrom(
      this.availableFacilityBaseChanges,
    );
    const facilityBaseMatch = availableBaseFacilities?.find(
      (facility) => facility.id === currentFacility.id,
    );

    if (!facilityBaseMatch) {
      this.alertService.error({
        message:
          'User Facility cannot be found, please try again or contact support.',
      });
      await this.authenticationService.logout();
      return;
    }

    // Initially set the facility control to the facility that matches the
    // user's facility.
    this.facilityBaseControl.setValue(facilityBaseMatch);

    // Subscribe to changes to the facility and route the user to the
    // facility's dashboard on update.
    this.facilityBaseControl.valueChanges
      .pipe(
        filter(isNonEmptyValue),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(async (facilityBase) => {
        if (currentFacility !== facilityBase) {
          const switchSuccess = await this.facilityService.setFacilityById(
            facilityBase.id,
          );

          if (!switchSuccess) {
            this.alertService.error({
              message:
                'Failed to switch facilities, please try again or contact support.',
            });
            this.facilityBaseControl.setValue(currentFacility, {
              emitEvent: false,
            });
            return;
          }

          currentFacility = await getFirstNonEmptyValueFrom(
            this.facilityService.currentFacilityChanges,
          );
          // Route to the facility's dashboard.
          const dashboardUrl: PageRoute = '/dashboard';
          this.router.navigate([dashboardUrl]);
        }
      });

    // Subscribe to the applications current facility changes and update the
    // facility control to the new facility when necessary.
    this.facilityService.currentFacilityChanges
      .pipe(
        filter(isNonEmptyValue),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((facility) => {
        if (currentFacility !== facility) {
          this.facilityBaseControl.setValue(
            {
              active: facility.active,
              id: facility.id,
              name: facility.name,
            },
            { emitEvent: false },
          );
          currentFacility = facility;
        }
      });

    // Check & update the currently displayed facility time.
    combineLatest([
      // Update the displayed time every X seconds. Start with null to update
      // immediately on initialize.
      interval(refreshDisplayTimeSeconds * 1000).pipe(startWith(null)),
      // Also run when the facility changes.
      this.facilityService.currentFacilityChanges,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([_interval, facility]) => {
        if (!facility) {
          // Facility has not yet been auto-selected, do nothing.
          return;
        }

        const displayedTime = this.timeSubject.value;
        const currentTime = DateTime.local().setZone(facility.timeZone);
        if (
          // Update the current time if the displayed time isn't yet set.
          !displayedTime ||
          // Update the current time if the displayed time is out of sync with
          // the current time.
          displayedTime.minute !== currentTime.minute ||
          displayedTime.hour !== currentTime.hour
        ) {
          this.timeSubject.next(currentTime);
        }
      });
  }
}
