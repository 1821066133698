import {
  ClientTestingAnalysisApi,
  ClientTestingAnalysisTypeApi,
} from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientTestingAnalysis,
  ClientTestingAnalysisDeserializationArgs,
  ClientTestingAnalysisType,
  ClientTestingAnalysisUpdate,
} from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the client testing analysis API.
 */
@Injectable()
export class ClientTestingAnalysisService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return the testing analysis data for the given client.
   *
   * @param clientId The client id to get the testing analysis for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @param requestParameters The request parameters to use for the query.
   * @returns The testing analysis list on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
    deserializationArgs: ClientTestingAnalysisDeserializationArgs,
    requestParameters?: readonly ClientTestingAnalysisRequestParam[],
  ): Observable<readonly ClientTestingAnalysis[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientTestingAnalysisApi[] | undefined
      >(`${config.api}/clients/${clientId}/testing-analysis`, { params: parseHttpParams(requestParameters) })
      .pipe(
        map((response) =>
          response
            ? ClientTestingAnalysis.deserializeList(
                response,
                deserializationArgs,
              )
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return the testing analysis types.
   *
   * @returns The testing analysis types on success, undefined on error.
   */
  public getTypes(): Observable<
    readonly ClientTestingAnalysisType[] | undefined
  > {
    return this.httpClient
      .get<
        readonly ClientTestingAnalysisTypeApi[] | undefined
      >(`${config.api}/testing-analysis/types`)
      .pipe(
        map((response) =>
          response
            ? ClientTestingAnalysisType.deserializeList(response)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new testing analysis for the given client.
   *
   * @param clientId The client id to add the testing analysis for.
   * @param clientTestingAnalysisUpdate The testing analysis data to add.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns
   */
  public create(
    clientId: Client['id'],
    clientTestingAnalysisUpdate: ClientTestingAnalysisUpdate,
    deserializationArgs: ClientTestingAnalysisDeserializationArgs,
  ): Observable<ClientTestingAnalysis | undefined> {
    return this.httpClient
      .post<
        ClientTestingAnalysisApi | undefined
      >(`${config.api}/clients/${clientId}/testing-analysis`, clientTestingAnalysisUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientTestingAnalysis.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update the testing analysis for the given client.
   *
   * @param clientId The client ID to patch the testing analysis for.
   * @param clientTestingAnalysisId The ID of the client testing analysis to
   * update.
   * @param clientTestingAnalysisUpdate The client testing analysis update
   * data.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns
   */
  public update(
    clientId: Client['id'],
    clientTestingAnalysisId: ClientTestingAnalysis['id'],
    clientTestingAnalysisUpdate: ClientTestingAnalysisUpdate,
    deserializationArgs: ClientTestingAnalysisDeserializationArgs,
  ): Observable<ClientTestingAnalysis | undefined> {
    return this.httpClient
      .patch<
        ClientTestingAnalysisApi | undefined
      >(`${config.api}/clients/${clientId}/testing-analysis/${clientTestingAnalysisId}`, clientTestingAnalysisUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientTestingAnalysis.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Client Testing Analysis API.
 */
export interface ClientTestingAnalysisRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'type';
  /** The value to use for the query parameter. */
  value: ClientTestingAnalysis['type']['name'];
}
