declare global {
  // eslint-disable-next-line id-blacklist
  interface Number {
    /**
     * Checks if a number value is between two other values.
     *
     * Note: The `min` and `max` values are inclusive (`>=` and `<=` checks).
     *
     * @param min The minimum value.
     * @param max The maximum value.
     * @returns True if the value is between the min and max values.
     */
    isBetween(min: number, max: number): boolean;
  }
}

/**
 * Extends the `Number` prototype with additional `isBetween` method.
 */
Number.prototype.isBetween = function (min: number, max: number): boolean {
  return this.valueOf() >= min && this.valueOf() <= max;
};

export {};
