export function isSettingsBillingTabUrl(
  value: unknown,
): value is SettingsBillingTabUrl {
  return (
    value === ('billing-codes' satisfies SettingsBillingTabUrl) ||
    value === ('rules' satisfies SettingsBillingTabUrl) ||
    value === ('insurance-companies' satisfies SettingsBillingTabUrl) ||
    value === ('modifiers' satisfies SettingsBillingTabUrl) ||
    value === ('rev-codes' satisfies SettingsBillingTabUrl)
  );
}
