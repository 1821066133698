import { BillingRuleServiceDetailApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { ServiceTypeEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { FacilityBase } from 'src/app/models/facility/facility.model';

const api = apiDecorator<BillingRuleServiceDetailApi>();

export class BillingRuleServiceDetail {
  public constructor(props: ClassProperties<BillingRuleServiceDetail>) {
    this.description = props.description;
    this.facility = props.facility;
    this.id = props.id;
    this.isActive = props.isActive;
    this.name = props.name;
    this.type = props.type;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Rule Service
   * Detail API model.
   */
  public static readonly Codec = io.type(
    {
      description: io.union([io.string, io.null]),
      facility: io.union([FacilityBase.BaseCodec, io.null]),
      id: io.number,
      isActive: io.boolean,
      name: io.union([io.string, io.null]),
      type: io.union([
        io.literal(ServiceTypeEnum.GROUP),
        io.literal(ServiceTypeEnum.INDIVIDUAL),
        io.literal(ServiceTypeEnum.INTAKE),
      ]),
    },
    'BillingRuleServiceDetailApi',
  );

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'facility' }) public readonly facility: FacilityBase | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'type' }) public readonly type: ServiceTypeEnum;

  /**
   * Deserializes a Billing Rule Service Detail object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Rule Service object.
   * @throws An error if the value is not a valid Billing Rule Service Detail
   * object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleServiceDetailApi>,
  ): BillingRuleServiceDetail {
    const decoded = decode(BillingRuleServiceDetail.Codec, value);
    return new BillingRuleServiceDetail({
      description: decoded.description,
      facility: decoded.facility
        ? FacilityBase.deserialize(decoded.facility)
        : null,
      id: decoded.id,
      isActive: decoded.isActive,
      name: decoded.name,
      type: decoded.type,
    });
  }

  /**
   * Deserializes a list of Billing Rule Service Detail objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Billing Rule Service Detail objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Billing Rule Service
   * Detail objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<BillingRuleServiceDetailApi>>,
  ): readonly BillingRuleServiceDetail[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Billing Rule Service Detail objects.');
    }
    return values.map(BillingRuleServiceDetail.deserialize);
  }
}
