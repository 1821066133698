import { BillingRuleUpdateApi } from 'api/models';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { DailyDropCriteriaEnum } from 'src/app/enumerators';

import { BillingCode } from 'src/app/models/billing/billing-code.model';
import { BillingRuleClaim } from 'src/app/models/billing/billing-rule-claim.model';
import { BillingRuleDuration } from 'src/app/models/billing/billing-rule-duration.model';
import { BillingRuleService } from 'src/app/models/billing/billing-rule-service.model';
import { RevenueCode } from 'src/app/models/billing/revenue-code.model';
import { BillingRuleRateCreate } from 'src/app/models/billing/rule-rate/billing-rule-rate-create.model';
import { BillingRuleRateUpdate } from 'src/app/models/billing/rule-rate/billing-rule-rate-update.model';
import { FacilityBase } from 'src/app/models/facility/facility.model';
import { InsuranceCompany } from 'src/app/models/insurance/insurance-company.model';
import { LevelOfCare } from 'src/app/models/level-of-care.model';
import { License } from 'src/app/models/license.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<BillingRuleUpdateApi>();

/** The Billing Payor Rule update (PUT) model. */
export class BillingRuleUpdate {
  public constructor(props: ClassProperties<BillingRuleUpdate>) {
    this.billingCode = props.billingCode;
    this.billingCodeSuffix = props.billingCodeSuffix;
    this.claim = props.claim;
    this.dailyDropCriteria = props.dailyDropCriteria;
    this.duration = props.duration;
    this.effectiveFrom = props.effectiveFrom;
    this.effectiveTo = props.effectiveTo;
    this.facilities = props.facilities;
    this.id = props.id;
    this.isActive = props.isActive;
    this.isDailyDrop = props.isDailyDrop;
    this.levelOfCare = props.levelOfCare;
    this.name = props.name;
    this.payor = props.payor;
    this.providerCredentials = props.providerCredentials;
    this.providers = props.providers;
    this.rates = props.rates;
    this.renderingProvider = props.renderingProvider;
    this.revenueCode = props.revenueCode;
    this.services = props.services;
  }

  @api({ key: 'billingCode' }) public readonly billingCode: BillingCode | null;
  @api({ key: 'billingCodeSuffix' }) public readonly billingCodeSuffix:
    | string
    | null;
  @api({ key: 'claim' }) public readonly claim: BillingRuleClaim;
  @api({ key: 'dailyDropCriteria' })
  public readonly dailyDropCriteria: DailyDropCriteriaEnum | null;
  @api({ key: 'duration' })
  public readonly duration: BillingRuleDuration | null;
  @api({ key: 'effectiveFrom' }) public readonly effectiveFrom: DateTime;
  @api({ key: 'effectiveTo' }) public readonly effectiveTo: DateTime | null;
  @api({ key: 'facilities' }) public readonly facilities:
    | readonly FacilityBase[]
    | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isDailyDrop' }) public readonly isDailyDrop: boolean;
  @api({ key: 'levelOfCare' }) public readonly levelOfCare: LevelOfCare;
  @api({ key: 'name' }) public readonly name: string;
  @api({ key: 'payor' }) public readonly payor: InsuranceCompany | null;
  @api({ key: 'providerCredentials' }) public readonly providerCredentials:
    | readonly License[]
    | null;
  @api({ key: 'providers' }) public readonly providers:
    | readonly UserBase[]
    | null;
  @api({ key: 'rates' })
  public readonly rates: ReadonlyArray<
    BillingRuleRateCreate | BillingRuleRateUpdate
  >;
  @api({ key: 'renderingProvider' })
  public readonly renderingProvider: UserBase | null;
  @api({ key: 'revenueCode' }) public readonly revenueCode: RevenueCode | null;
  @api({ key: 'services' })
  public readonly services: BillingRuleService | null;

  public serialize(): BillingRuleUpdateApi {
    const effectiveFrom = this.effectiveFrom.toISO();
    if (!effectiveFrom) {
      throw new Error('Invalid Effective From date.');
    }

    return {
      billingCode: this.billingCode ? { id: this.billingCode.id } : null,
      billingCodeSuffix: this.billingCodeSuffix,
      claim: this.claim,
      dailyDropCriteria: this.dailyDropCriteria,
      duration: this.duration?.serialize() ?? null,
      effectiveFrom,
      effectiveTo: this.effectiveTo ? this.effectiveTo.toISO() : null,
      facilities: this.facilities
        ? this.facilities.map((facility) => ({ id: facility.id }))
        : null,
      id: this.id,
      isActive: this.isActive,
      isDailyDrop: this.isDailyDrop,
      levelOfCare: { id: this.levelOfCare.id },
      name: this.name,
      payor: this.payor ? { id: this.payor.id } : null,
      providerCredentials: this.providerCredentials
        ? this.providerCredentials.map((providerCredentials) => ({
            id: providerCredentials.id,
          }))
        : null,
      providers: this.providers
        ? this.providers.map((provider) => ({ id: provider.id }))
        : null,
      rates: this.rates.map((rate) => rate.serialize()),
      renderingProvider: this.renderingProvider
        ? { id: this.renderingProvider.id }
        : null,
      revenueCode: this.revenueCode,
      services: this.services
        ? {
            details: this.services.details.map((detail) => ({ id: detail.id })),
            type: this.services.type,
          }
        : null,
    };
  }
}
