import { EditorComponent as TinyMceEditorComponent } from '@tinymce/tinymce-angular';
import { config } from 'src/configs/config';

import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';
import { InputEditorMenuBarOption } from 'src/app/components/forms/input-editor/input-editor-menu-bar-option';
import { InputEditorPluginOption } from 'src/app/components/forms/input-editor/input-editor-plugins';
import { InputEditorToolBarOption } from 'src/app/components/forms/input-editor/input-editor-tool-bar-option';

/**
 * A custom Alleva form component which wraps around the TinyMCE editor
 * component with pre-configured values and an easier to use interface.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@Component({
  selector: 'alleva-input-editor[formControl][label]',
  templateUrl: './input-editor.component.html',
  styleUrls: ['./input-editor.component.scss'],
})
export class InputEditorComponent
  extends CustomInputDirective<string>
  implements OnInit
{
  /**
   * The options to be passed to the TinyMCE editor.
   */
  protected editorOptions: EditorOptions | null = null;

  protected readonly license_key = config.publicKeys.tinymce;

  /**
   * Sets the height of the entire editor, including the menu bar, toolbars,
   * and status bar.
   *
   * @see https://www.tiny.cloud/docs/tinymce/6/editor-size-options/#height
   */
  @Input() public height?: string | number;

  /**
   * The inline option allows you to specify whether TinyMCE should run in
   * inline mode.
   *
   * @see https://www.tiny.cloud/docs/tinymce/6/inline-editor-options/#inline
   */
  @Input() @HostBinding('class.inline') public inline = false;

  /**
   * Sets the menu bar to be displayed at the top of the editor.
   *
   * @see https://www.tiny.cloud/docs/tinymce/6/available-menu-items/
   */
  @Input() public menuBarOptions: readonly InputEditorMenuBarOption[] = [];

  /**
   * Sets the plugins to be used by the editor.
   *
   * @see https://www.tiny.cloud/docs/tinymce/6/plugins/
   */
  @Input() public pluginOptions: readonly InputEditorPluginOption[] = [];

  /**
   * Sets the tool bar to be displayed at the top of the editor.
   *
   * @see https://www.tiny.cloud/docs/tinymce/6/available-toolbar-buttons/
   */
  @Input() public toolBar: keyof ToolBars = 'default';
  private get toolBarOptions(): readonly InputEditorToolBarOption[] {
    return toolbars[this.toolBar];
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    // Set the TinyMCE editor options on initialization.
    const { height, inline, placeholder } = this;
    this.editorOptions = {
      height,
      inline,
      license_key: this.license_key,
      menubar:
        this.menuBarOptions.length > 0 ? this.menuBarOptions.join(' ') : false,
      placeholder,
      plugins:
        this.pluginOptions.length > 0
          ? this.pluginOptions.join(' ')
          : undefined,
      promotion: false,
      toolbar:
        this.toolBarOptions.length > 0 ? this.toolBarOptions.join(' ') : false,
      // Set to "scrolling" when inline, otherwise set to "wrap". This prevents
      // the toolbar from ever overlapping the editor content when inline.
      toolbar_mode: inline ? 'scrolling' : 'wrap',
    };
  }
}

/** The options to be passed to the TinyMCE editor. */
type EditorOptions = NonNullable<TinyMceEditorComponent['init']>;

type ToolBars = Record<'default' | 'full', readonly InputEditorToolBarOption[]>;

const toolbars: ToolBars = {
  default: [
    'undo',
    'redo',
    '|',
    'blocks',
    '|',
    'bold',
    'italic',
    '|',
    'alignleft',
    'aligncenter',
    'alignright',
    'alignjustify',
    '|',
    'outdent',
    'indent',
    '|',
    'bullist',
    'numlist',
  ],
  full: [
    'newdocument',
    'undo',
    'redo',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'subscript',
    'superscript',
    'code',
    'codesample',
    'emoticons',
    '|',
    'fontfamily',
    'fontsize',
    'blocks',
    '|',
    'alignleft',
    'aligncenter',
    'alignright',
    'alignjustify',
    '|',
    'lineheight',
    'bullist',
    'numlist',
    '|',
    'outdent',
    'indent',
    '|',
    'forecolor',
    'backcolor',
    'removeformat',
    '|',
    'blockquote',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    '|',
    'selectall',
    'copy',
    'cut',
    'paste',
    'pastetext',
    '|',
    'fullscreen',
    'visualaid',
    'print',
  ],
};
