import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

/**
 * A directive that adds attributes to an element. Only use for static
 * attributes that do not change as Angular will ignore these attributes
 * at run time.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[attributes]',
})
export class AttributesDirective implements OnChanges {
  public constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  @Input() public attributes: { [key: string]: string | undefined } | undefined;

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['attributes']) {
      for (const attributeName in this.attributes) {
        if (
          Object.prototype.hasOwnProperty.call(this.attributes, attributeName)
        ) {
          const attributeValue = this.attributes[attributeName];
          if (attributeValue) {
            this.renderer.setAttribute(
              this.elementRef.nativeElement,
              attributeName,
              attributeValue,
            );
          } else {
            this.renderer.removeAttribute(
              this.elementRef.nativeElement,
              attributeName,
            );
          }
        }
      }
    }
  }
}
