import { PrivatePaymentApi, PrivatePaymentUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';
import { PrivatePaymentPayer } from 'src/app/models/private-payment/private-payment-payer.model';

const api = apiDecorator<PrivatePaymentApi>();

type PrivatePaymentArgs = Omit<
  ClassProperties<PrivatePayment>,
  // Omit computed properties based on core model data.
  'displayAmount'
>;

abstract class PrivatePaymentBase {
  public constructor(props: ClassProperties<PrivatePaymentBase>) {
    this.addedDate = props.addedDate;
    this.amount = props.amount;
    this.payer = props.payer;
    this.total = props.total;
  }

  @api({ key: 'addedAt' }) public readonly addedDate: DateTime;
  @api({ key: 'amount' }) public readonly amount: number;
  @api({ key: 'payer' }) public readonly payer: PrivatePaymentPayer;
  @api({ key: 'total' }) public readonly total: number;
}

export class PrivatePayment extends PrivatePaymentBase {
  public constructor(props: ClassProperties<PrivatePaymentArgs>) {
    super(props);

    this.id = props.id;

    // Get the supported locale for currency formatting.
    const currencyFormatter = new Intl.NumberFormat('en-us', {
      // Future: Make dynamic based on the facility unit of measurement.
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    });
    // Computed values
    this.displayAmount = currencyFormatter.format(this.amount);
  }

  /**
   * The io-ts codec for runtime type checking of the Private Payment API
   * model.
   */
  public static readonly Codec = io.type(
    {
      addedAt: io.string,
      amount: io.number,
      id: io.number,
      payer: PrivatePaymentPayer.Codec,
      total: io.number,
    },
    'PrivatePaymentApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  // Computed values
  public readonly displayAmount: string;

  /**
   * Deserializes a Private Payment object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Private Payment object.
   * @throws An error if the value is not a valid Private Payment object.
   */
  public static deserialize(
    value: NonNullable<PrivatePaymentApi>,
    deserializationArgs: PrivatePaymentDeserializationArgs,
  ): PrivatePayment {
    const decoded = decode(PrivatePayment.Codec, value);
    return new PrivatePayment({
      ...decoded,
      addedDate: DateTime.fromISO(decoded.addedAt, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      payer: PrivatePaymentPayer.deserialize(decoded.payer),
    });
  }

  /**
   * Deserializes a list of Private Payment objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Private Payment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Private Payment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<PrivatePaymentApi>>,
    deserializationArgs: PrivatePaymentDeserializationArgs,
  ): readonly PrivatePayment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Private Payment objects.');
    }
    return values.map((privatePayment) =>
      PrivatePayment.deserialize(privatePayment, deserializationArgs),
    );
  }
}

export class PrivatePaymentUpdate extends PrivatePaymentBase {
  public constructor(props: ClassProperties<PrivatePaymentUpdate>) {
    super(props);
  }

  public serialize(): PrivatePaymentUpdateApi {
    const addedAt = this.addedDate.toISO();
    if (!addedAt) {
      throw new Error('Invalid added date.');
    }

    return {
      ...this,
      addedAt,
    };
  }
}

export interface PrivatePaymentDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
