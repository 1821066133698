import { LogLevel } from 'angular-auth-oidc-client';

const developmentRestApi = 'https://app-emr-rest-api-001-01.allevasoft.com';

export const config: EnvironmentConfig = {
  api: `${developmentRestApi}/v2`,
  apiDocuments: `${developmentRestApi}/v2/documents`,
  billingAppUrl: 'https://brave-sky-01a518010.1.azurestaticapps.net/',
  features: {
    isQuickLaunchMenuEnabled: true,
    isSandboxEnabled: true,
    isScreenAutoLockEnabled: false,
    isServiceWorkersEnabled: false,
    isStorageObfuscationEnabled: false,
  },
  isLegacyRoutingEnabled: false,
  isProduction: false,
  oidc: {
    authAuthority: 'https://alleva-identity-int.helloalleva.com',
    authClientId: 'alleva.emr',
    logLevel: LogLevel.Debug,
  },
  publicKeys: {
    tinymce: 'gpl',
  },
  verifyPasswordUrl: `${developmentRestApi}/api/v1/auth/verify-password`,
};
