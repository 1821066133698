import { VitalsBloodSugarApi, VitalsBloodSugarUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<VitalsBloodSugarApi>();

export class VitalsBloodSugar {
  public constructor(props: ClassProperties<VitalsBloodSugar>) {
    this.value = props.value;
    this.witness = props.witness;
  }

  /**
   * The io-ts codec for runtime type checking of the Vitals Blood Sugar API
   * model.
   */
  public static readonly Codec = io.type(
    {
      value: io.number,
      witness: io.union([io.string, io.null]),
    },
    'VitalsBloodSugarApi',
  );

  @api({ key: 'value' }) public readonly value: number;
  @api({ key: 'witness' }) public readonly witness: string | null;

  /**
   * Deserializes a Vitals Blood Sugar object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Vitals Blood Sugar object.
   * @throws An error if the value is not a valid Vitals Blood Sugar object.
   */
  public static deserialize(
    value: NonNullable<VitalsBloodSugarApi>,
  ): VitalsBloodSugar {
    const decoded = decode(VitalsBloodSugar.Codec, value);
    return new VitalsBloodSugar(decoded);
  }

  /**
   * Deserializes a list of Vitals Blood Sugar objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Vitals Blood Sugar objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Vitals Blood Sugar
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<VitalsBloodSugarApi>>,
  ): readonly VitalsBloodSugar[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Vitals Blood Sugar objects.');
    }
    return values.map(VitalsBloodSugar.deserialize);
  }
}

export class VitalsBloodSugarUpdate extends VitalsBloodSugar {
  public constructor(props: ClassProperties<VitalsBloodSugarUpdate>) {
    super(props);
  }

  public serialize(): VitalsBloodSugarUpdateApi {
    return {
      value: this.value,
      witness: this.witness,
    };
  }
}
