import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { customIconNames } from 'src/app/components/icon/icon-name';

/**
 * Service for setting the custom icons in the Material icon registry.
 */
@Injectable({ providedIn: 'root' })
export class CustomIconsRegistryService {
  public constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
  ) {}

  private isInitialized = false;

  private readonly customIcons: readonly IconName[] = customIconNames;

  public initialize(): void {
    if (this.isInitialized) {
      return;
    }

    this.registerCustomAllevaIcons();

    this.isInitialized = true;
  }

  /**
   * Add and register new svg icons to the Material icon registry.
   */
  private registerCustomAllevaIcons(): void {
    for (const iconName of this.customIcons) {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${iconName}.svg`,
        ),
      );
    }
  }
}
