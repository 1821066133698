import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that returns the first letter of every word in a string up to X words.
 *
 * @param value The string to transform.
 * @param maxWords The maximum number of a words' first letters to return.
 * @returns The first letter of every word in a string up to X words.
 */
@Pipe({ name: 'firstLetterEveryWord' })
export class FirstLetterEveryWordPipe implements PipeTransform {
  public transform(value: string, maxWords = 1): string {
    const words = value.split(' ');
    return words.length < maxWords
      ? // The number of words in the string is less than the number of words requested.
        words
          .slice(0, words.length)
          .map((word) => word[0])
          .join('')
      : // Everything is fine. Return as intended.
        words
          .slice(0, maxWords)
          .map((word) => word[0])
          .join('');
  }
}
