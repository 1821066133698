import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VALIDATION } from 'src/app/constants';
import { VitalsBloodPressure } from 'src/app/models';
import { isNonEmptyValue } from 'src/app/utilities';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomFormGroupDirective } from 'src/app/components/forms/custom-form-group.directive';

/**
 * An input component for a blood pressure reading.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-blood-pressure[formControl][label]',
  templateUrl: './input-blood-pressure.component.html',
  styleUrls: ['./input-blood-pressure.component.scss'],
})
export class InputBloodPressureComponent
  extends CustomFormGroupDirective<BloodPressure>
  implements OnInit
{
  /**
   * The id of the systolic form control, automatically generated if not provided.
   */
  @Input()
  public systolicId =
    `alleva-input-blood-pressure-systolic${++uniqueSystolicFormId}`;

  /**
   * The id of the diastolic form control, automatically generated if not provided.
   */
  @Input()
  public diastolicId =
    `alleva-input-blood-pressure-diastolic${++uniqueDiastolicFormId}`;

  // Override the following defaults to undefined to show they're unused here.
  public override placeholder = undefined;

  protected override readonly formGroup = new FormGroup<BloodPressureFormGroup>(
    {
      // Sub control for tracking the state of the users systolic reading.
      systolic: new FormControl<VitalsBloodPressure['systolic'] | null>(null, [
        Validators.required,
        Validators.min(VALIDATION.vitals.systolic.min),
        Validators.max(VALIDATION.vitals.systolic.max),
      ]),
      // Sub control for tracking the state of the users diastolic reading.
      diastolic: new FormControl<VitalsBloodPressure['diastolic'] | null>(
        null,
        [
          Validators.required,
          Validators.min(VALIDATION.vitals.diastolic.min),
          Validators.max(VALIDATION.vitals.diastolic.max),
        ],
      ),
    },
  );

  protected readonly ctrlSystolic = this.formGroup.controls.systolic;
  protected readonly ctrlDiastolic = this.formGroup.controls.diastolic;

  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputBloodPressureComponent requires a formControl attribute to be set.',
      );
    }

    // Set initial values.
    this.formGroup.setValue({
      diastolic: this.baseControl.value?.diastolic ?? null,
      systolic: this.baseControl.value?.systolic ?? null,
    });

    // If the base control is not required, then the sub controls should not be required.
    if (!this.baseControl.hasValidator(Validators.required)) {
      this.ctrlSystolic.removeValidators(Validators.required);
      this.ctrlDiastolic.removeValidators(Validators.required);
    }

    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(({ systolic, diastolic }) => {
        if (
          isNonEmptyValue(systolic) &&
          isNonEmptyValue(diastolic) &&
          this.formGroup.valid
        ) {
          this.baseControl.patchValue({ systolic, diastolic });
          this.baseControl.markAsDirty();
        } else if (!isNonEmptyValue(systolic) && !isNonEmptyValue(diastolic)) {
          this.baseControl.patchValue(null);
          this.baseControl.markAsPristine();
        } else {
          this.baseControl.patchValue(null, { emitEvent: false });
          this.baseControl.markAsPristine();
        }
      });
  }
}

interface BloodPressureFormGroup {
  systolic: FormControl<VitalsBloodPressure['systolic'] | null>;
  diastolic: FormControl<VitalsBloodPressure['diastolic'] | null>;
}

/** A unique ID for each form field systolic input. */
let uniqueSystolicFormId = 0;

/** A unique ID for each form field diastolic input. */
let uniqueDiastolicFormId = 0;
