import { ClientLevelOfCareApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { LevelOfCareBase } from 'src/app/models/level-of-care.model';

const api = apiDecorator<ClientLevelOfCareApi>();

export class ClientLevelOfCare extends LevelOfCareBase {
  public constructor(props: ClassProperties<ClientLevelOfCare>) {
    super(props);

    this.active = props.active;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Level of Care API
   * model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      id: io.number,
      name: io.string,
    },
    'ClientLevelOfCareApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Client Level of Care object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Level of Care object.
   * @throws An error if the value is not a valid Client Level of Care object.
   */
  public static deserialize(
    value: NonNullable<ClientLevelOfCareApi>,
  ): ClientLevelOfCare {
    const decoded = decode(ClientLevelOfCare.Codec, value);
    return new ClientLevelOfCare(decoded);
  }

  /**
   * Deserializes a list of Client Level of Care objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Level of Care objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Level of Care
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientLevelOfCareApi>>,
  ): readonly ClientLevelOfCare[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Level of Care objects.');
    }
    return values.map(ClientLevelOfCare.deserialize);
  }
}
