import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { InputPickDateComponent } from 'src/app/components/forms/input-pick-date/input-pick-date.component';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [InputPickDateComponent],
  exports: [InputPickDateComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatLuxonDateModule,
    PipesModule,
    ReactiveFormsModule,
  ],
})
export class InputPickDateModule {}
