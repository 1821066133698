import { DateTime } from 'luxon';
import { isNonEmptyValue } from 'src/app/utilities';

import { Pipe, PipeTransform } from '@angular/core';

const defaultFormat: LuxonDateTimeFormat = 'MM/dd/yyyy';

@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
  /**
   * Transforms a DateTime object into a string using the specified format.
   *
   * @param value The value to transform.
   * @param format The format to use when transforming the value. Optional
   * parameter that otherwise defaults to `MM/dd/yyy`.
   * @returns The transformed value.
   */
  public transform(
    value: DateTime | null | undefined | unknown,
    format: LuxonDateTimeFormat | AllevaDateTimeFormat = defaultFormat,
  ): string {
    if (!isNonEmptyValue(value) || !(value instanceof DateTime)) {
      return '';
    }

    if (format === 'relative-date') {
      return getRelativeDate(value);
    } else if (format === 'relative-datetime') {
      return getRelativeDateTime(value);
    }

    return value.toFormat(format);
  }
}

/**
 * Predefined date/time formats for use with the DateTimePipe ready out of the
 * box from the Luxon library.
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
type LuxonDateTimeFormat =
  | 'ccc, LLLL dd, yyyy hh:mm a' // Wed, November 12, 2023 06:00 AM
  | 'MM/dd/yyyy, hh:mm a' // 01/01/2025, 01:00 AM
  | 'MM/dd/yyyy, hh:mm a (ZZZZ)' // 01/01/2025, 01:00 AM (CDT)
  | 'MM/dd/yyyy' // 01/01/2025
  | 'h:mm a (ZZZZ)'; // 1:00 AM (CDT)

/**
 * Custom Alleva specific date/time formats for use with the DateTimePipe.
 */
type AllevaDateTimeFormat =
  // Relative date ("Today", "in 2 days", "2 days ago", etc.). If the relative
  // date is greater than a week, return full date as default format 'MM/dd/yyyy'.
  | 'relative-date'
  // Relative date and time ("Today, 1:00 PM", "04/29/1990, 12:00 AM", etc.).
  | 'relative-datetime';

function getRelativeDate(value: DateTime): string {
  const relativeTime = value.toRelative();
  if (!relativeTime) {
    throw new Error(
      `There was an issue converting the datetime object "${value.toString()}" to a relative time.`,
    );
  }

  const now = DateTime.local();
  const hoursDiff = now.diff(value).as('hours');

  if (hoursDiff < 24) {
    return 'Today';
  } else if (hoursDiff < 48) {
    return 'Yesterday';
  } else if (hoursDiff > 168) {
    // If the relative time is greater than one week, return full date using
    // the default format.
    return value.toFormat(defaultFormat);
  }

  return relativeTime;
}

function getRelativeDateTime(value: DateTime): string {
  const time = value.toFormat('h:mm a');
  return `${getRelativeDate(value)}, ${time}`;
}
