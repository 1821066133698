import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';
import { DialogMaxWidthEnum } from 'src/app/enumerators';
import { ScreenLockService } from 'src/app/services';

import { Component, OnInit } from '@angular/core';

import { DialogOptions } from 'src/app/components/dialogs/dialog-config';
import { DialogService } from 'src/app/components/dialogs/dialog.service';
import {
  LockScreenDialogOutput,
  ScreenLockDialogComponent,
} from 'src/app/components/screen-lock/screen-lock-dialog.component';

/**
 * A component that displays a lock screen preventing user interaction until
 * the user enters their password.
 *
 * It holds the state of the lock screen dialog and re-opens it if it's closed
 * nefariously.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-screen-lock',
  // No template, this component is only used for its state and side effects.
  template: '',
})
export class ScreenLockComponent implements OnInit {
  public constructor(
    private readonly dialogService: DialogService,
    private readonly screenLockService: ScreenLockService,
  ) {}

  /**
   * The dialog reference. This is stored so that we can subscribe to the
   * dialog close changes. If the dialog is closed nefariously, we re-open it
   * by refreshing this reference and re-subscribing to the close changes.
   *
   * We also set this on initialize so that the dialog opens immediately.
   */
  protected dialogRef = this.dialogService.open<
    ScreenLockDialogComponent,
    undefined,
    LockScreenDialogOutput
  >(ScreenLockDialogComponent, { options });

  public ngOnInit(): void {
    // Subscribe to the dialog close changes. If the dialog is closed
    // nefariously, re-open it.
    this.dialogRef.afterClosedChanges
      .pipe(take(1), untilDestroyed(this))
      .subscribe((result) => {
        if (!result || result.wasClosedNefariously) {
          // Closed nefariously by the user, re-open. No garbage collection is
          // needed for the dialog reference because the previous dialog will
          // self-close after it detects it was closed nefariously to be
          // re-initialized here.
          this.dialogRef = this.dialogService.open<
            ScreenLockDialogComponent,
            undefined,
            LockScreenDialogOutput
          >(ScreenLockDialogComponent, { options });
          // Recursively call ngOnInit to re-subscribe to the dialog close
          // changes.
          this.ngOnInit();
          return;
        }
        // Properly closed by the user, unlock the screen.
        this.screenLockService.isLocked = false;
      });
  }
}

/** The options to pass to the lock screen dialog. */
const options: DialogOptions = {
  blurBackground: true,
  closeOnBackdropClick: false,
  closeOnNavigation: false,
  dialogTitle: 'Screen Locked',
  isCloseIconDisplayed: false,
  maxWidth: DialogMaxWidthEnum.MEDIUM,
};
