import { DailyQuestionnaireScoreApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<DailyQuestionnaireScoreApi>();

export class DailyQuestionnaireScore {
  public constructor(props: ClassProperties<DailyQuestionnaireScore>) {
    this.date = props.date;
    this.score = props.score;
  }

  /**
   * The io-ts codec for runtime type checking of the Daily Questionnaire Score
   * API model.
   */
  public static readonly Codec = io.type(
    {
      date: io.string,
      score: io.number,
    },
    'DailyQuestionnaireScoreApi',
  );

  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'score' }) public readonly score: number;

  /**
   * Deserializes a Daily Questionnaire Score object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Daily Questionnaire Score object.
   * @throws An error if the value is not a valid Daily Questionnaire Score
   * object.
   */
  public static deserialize(
    value: NonNullable<DailyQuestionnaireScoreApi>,
    deserializationArgs: DailyQuestionnaireDeserializationArgs,
  ): DailyQuestionnaireScore {
    const decoded = decode(DailyQuestionnaireScore.Codec, value);
    return new DailyQuestionnaireScore({
      ...decoded,
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
    });
  }

  /**
   * Deserializes a list of Daily Questionnaire Score objects from the API
   * model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Daily Questionnaire Score objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Daily Questionnaire
   * Score objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DailyQuestionnaireScoreApi>>,
    deserializationArgs: DailyQuestionnaireDeserializationArgs,
  ): readonly DailyQuestionnaireScore[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Daily Questionnaire Score objects.');
    }
    return values.map((dailyQuestionnaireScore) =>
      DailyQuestionnaireScore.deserialize(
        dailyQuestionnaireScore,
        deserializationArgs,
      ),
    );
  }
}

export interface DailyQuestionnaireDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
