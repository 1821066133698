import { PrivatePaymentPayerApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { Address } from 'src/app/models/user/address.model';

const api = apiDecorator<PrivatePaymentPayerApi>();

type PrivatePaymentPayerArgs = Omit<PrivatePaymentPayer, 'relationshipName'>;

export class PrivatePaymentPayer {
  public constructor(props: ClassProperties<PrivatePaymentPayerArgs>) {
    this.address = props.address;
    this.name = props.name;
    this.relationship = props.relationship;

    // Computed properties.
    this.relationshipName = this.relationship?.name ?? null;
  }

  /**
   * The io-ts codec for runtime type checking of the Private Payment Payer API
   * model.
   */
  public static readonly Codec = io.type(
    {
      address: io.union([Address.Codec, io.null]),
      name: io.union([io.string, io.null]),
      relationship: io.union([NameId.Codec, io.null]),
    },
    'PrivatePaymentPayerApi',
  );

  @api({ key: 'address' }) public readonly address: Address | null;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'relationship' })
  public readonly relationship: NameId | null;

  // Computed properties.

  public readonly relationshipName: string | null;

  /**
   * Deserializes a Private Payment Payer object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Private Payment Payer object.
   * @throws An error if the value is not a valid Private Payment Payer object.
   */
  public static deserialize(
    value: NonNullable<PrivatePaymentPayerApi>,
  ): PrivatePaymentPayer {
    const decoded = decode(PrivatePaymentPayer.Codec, value);
    return new PrivatePaymentPayer({
      ...decoded,
      address: decoded.address ? Address.deserialize(decoded.address) : null,
      relationship: decoded.relationship
        ? NameId.deserialize(decoded.relationship)
        : null,
    });
  }

  /**
   * Deserializes a list of Private Payment Payer objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Private Payment Payer objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Private Payment Payer
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<PrivatePaymentPayerApi>>,
  ): readonly PrivatePaymentPayer[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Private Payment Payer objects.');
    }
    return values.map(PrivatePaymentPayer.deserialize);
  }
}

export class PrivatePaymentPayerUpdate extends PrivatePaymentPayer {
  public serialize(): PrivatePaymentPayerApi {
    return this;
  }
}
