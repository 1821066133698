import { AddressApi, AddressUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode, isNonEmptyString } from 'src/app/utilities';

import { Country } from 'src/app/models/country.model';
import { State } from 'src/app/models/state.model';

const api = apiDecorator<AddressApi>();

type AddressArgs = Omit<
  ClassProperties<Address>,
  // Omit computed properties set on construct from outside data.
  'countryName' | 'full' | 'stateName'
>;

export class Address {
  public constructor(props: ClassProperties<AddressArgs>) {
    this.city = props.city;
    this.country = props.country;
    this.line1 = props.line1;
    this.line2 = props.line2;
    this.postal = props.postal;
    this.state = props.state;

    // Computed properties
    this.countryName = props.country?.name ?? null;
    this.full = getFullAddress(props);
    this.stateName = props.state?.name ?? null;
  }

  /**
   * The io-ts codec for runtime type checking of the Address API model.
   */
  public static readonly Codec = io.type(
    {
      city: io.union([io.string, io.null]),
      country: io.union([Country.Codec, io.null]),
      line1: io.union([io.string, io.null]),
      line2: io.union([io.string, io.null]),
      postal: io.union([io.string, io.null]),
      state: io.union([State.Codec, io.null]),
    },
    'AddressApi',
  );

  @api({ key: 'city' }) public readonly city: string | null;
  @api({ key: 'country' }) public readonly country: Country | null;
  @api({ key: 'line1' }) public readonly line1: string | null;
  @api({ key: 'line2' }) public readonly line2: string | null;
  @api({ key: 'postal' }) public readonly postal: string | null;
  @api({ key: 'state' }) public readonly state: State | null;

  // Computed properties
  public readonly countryName: string | null;
  public readonly full: string | null;
  public readonly stateName: string | null;

  /**
   * Deserializes a Address object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Address object.
   * @throws An error if the value is not a valid Address object.
   */
  public static deserialize(value: NonNullable<AddressApi>): Address {
    const decoded = decode(Address.Codec, value);
    return new Address({
      ...decoded,
      country: decoded.country ? Country.deserialize(decoded.country) : null,
      state: decoded.state ? State.deserialize(decoded.state) : null,
    });
  }

  /**
   * Deserializes a list of Address objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Address objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Address objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<AddressApi>>,
  ): readonly Address[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Address objects.');
    }
    return values.map(Address.deserialize);
  }
}

export class AddressUpdate extends Address {
  public serialize(): AddressUpdateApi {
    return {
      city: this.city,
      country: this.country,
      line1: this.line1,
      line2: this.line2,
      postal: this.postal,
      state: this.state,
    };
  }
}

function getFullAddress(address: AddressArgs): string | null {
  const { city, line1, line2, postal, state } = address;
  if (
    !isNonEmptyString(city) &&
    !isNonEmptyString(line1) &&
    !isNonEmptyString(line2) &&
    !isNonEmptyString(postal) &&
    !isNonEmptyString(state)
  ) {
    return null;
  }

  return (
    `${line1 ?? ''}${line2 ?? ''}` +
      `${(line1 || line2) && (city || state?.name || postal) ? ', ' : ''}` +
      `${city ?? ''}${city && (state || postal) ? ', ' : ''}` +
      `${state?.name ?? ''}${state?.name && postal ? ' ' : ''}` +
      `${postal ?? ''}` || null
  );
}
