import { AlertService } from 'src/app/services';
import {
  isNonEmptyValue,
  isNumber,
  mergeArrays,
  removeFromArray,
} from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';
import { HoverPreviewService } from 'src/app/components/hover-preview/hover-preview.service';
import {
  ImageHoverPreviewComponent,
  ImageHoverPreviewData,
} from 'src/app/components/hover-preview/template/image-hover-preview.component';

/**
 * A component that allows the user to pick files to be uploaded via a file
 * input. The component will display the file name and size, and allow the user
 * to remove and preview the file from the list. This component will also
 * validate file types, sizes and counts based on the optional parameters
 * passed in.
 *
 * @requires `formControl` attribute to be set.
 */
@Component({
  selector: 'alleva-input-pick-files[formControl]',
  templateUrl: './input-pick-files.component.html',
  styleUrls: ['./input-pick-files.component.scss'],
})
export class InputPickFilesComponent extends CustomInputDirective<
  readonly File[]
> {
  public constructor(
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    private readonly alertService: AlertService,
    private readonly hoverPreviewService: HoverPreviewService,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  @ViewChild('fileInput', { static: false })
  public fileInput!: ElementRef<HTMLInputElement>;

  /**
   * The accepted and supported file type extensions.
   *
   * @default ['.bmp','.gif','.jpeg','.jpg','.png'] (images).
   */
  @Input() public accept: SupportedFileExtensions[] = [
    '.bmp',
    '.gif',
    '.jpeg',
    '.jpg',
    '.png',
  ];

  @Input() public set hideFileList(value: BooleanInput) {
    this.#hideFileList = coerceBooleanProperty(value);
  }
  public get hideFileList(): boolean {
    return this.#hideFileList;
  }
  #hideFileList = true;

  /**
   * The maximum files allowed for upload.
   *
   * @default 1
   */
  @Input() public maxFiles = 1;

  /**
   * The maximum file size allowed per file.
   *
   * @default 5242880 (bytes), or 5MB per file.
   */
  @Input() public maxFileSizeBytes = 5242880;

  private get width(): number {
    return isNumber(Number(this.nativeElement.offsetWidth))
      ? Number(this.nativeElement.offsetWidth)
      : 0;
  }

  protected set files(values: readonly File[] | null) {
    if ((!values || values.length === 0) && (this.value?.length || 0) > 0) {
      // User previously added files and went to add more, but then cancelled.
      // Do nothing.
      return;
    }

    if (values) {
      const previousValues = this.value || [];
      const updatedValues = Array.from(values ?? []);
      const newValues = mergeArrays(previousValues, updatedValues, ['name']);

      if (newValues.length > this.maxFiles) {
        // User tried to add more files than allowed.
        this.alertService.error({
          message: `You can only upload up to ${this.maxFiles} file${
            this.maxFiles > 1 ? 's' : ''
          }.`,
        });
        // Cancel the upload and do nothing.
        return;
      }

      for (const file of newValues) {
        // Validate each files' type.
        if (!isFileTypeSupported(file.type, this.accept)) {
          // User tried to add a file that is not supported.
          this.alertService.error({
            message: `The file type "${file.type}" is not supported.`,
          });
          // Cancel the upload and do nothing.
          return;
        }

        // Validate each files' size.
        if (file.size > this.maxFileSizeBytes) {
          // User tried to add a file that is too large.
          this.alertService.error({
            message:
              `The file "${file.name}" is too large. The maximum file size ` +
              `is ${this.getFileSizeString(this.maxFileSizeBytes)}.`,
          });
          // Cancel the upload and do nothing.
          return;
        }
      }

      // Finally, set the value.
      this.baseControl.setValue(newValues);
    }
  }
  protected get files(): readonly File[] | null {
    return this.baseControl.value || null;
  }

  protected get inputLabel(): string {
    let label: string;

    if (!this.files || this.files.length === 0) {
      // Default labels for when nothing is selected.
      label = this.label
        ? this.label
        : `No file${this.maxFiles > 1 ? '(s)' : ''} selected.`;
    } else if (this.files.length === 1) {
      // Only one file selected, show the file name.
      label = this.files[0].name;
    } else {
      // Multiple files selected, get all their names.
      const fileNames = this.files.map((file) => file.name).join(', ');
      // Get font width, which is about half of default `16px` font size.
      const fontWidth = 16 / 2;
      // Check if the file names can fit in the input preview.
      if (fileNames.length <= this.width / fontWidth) {
        // All file names can fit in the input preview, show them all.
        label = fileNames;
      } else {
        // Otherwise, show the number of files selected.
        label = `${this.files.length} files selected`;
      }
    }

    return this.baseControl.hasValidator(Validators.required)
      ? `${label}*` // Add asterisk if field is required.
      : label;
  }

  protected closeImageHoverPreview(): void {
    this.hoverPreviewService.closePreview();
  }

  protected getFileSizeString(bytes: File['size']): string {
    if (bytes < 1000000) {
      return `${Math.floor(bytes / 1000)} KB`;
    } else {
      return `${Math.floor(bytes / 1000000)} MB`;
    }
  }

  protected getInputFiles(event: Event): readonly File[] {
    const input = event.target as HTMLInputElement;
    return input.files ? Array.from(input.files) : [];
  }

  protected openFileInput(): void {
    if (this.disabled) {
      // This field is disabled, do nothing.
      return;
    }
    // Clear out the previous value so we can force the (change) event to fire
    // even if the user selects the same file(s) again.
    this.fileInput.nativeElement.value = '';
    // Open the native file input browser dialog window.
    this.fileInput.nativeElement.click();
  }

  protected openImageHoverPreview(event: MouseEvent, file: File): void {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          const imageSrc = e.target.result as string;

          this.hoverPreviewService.open<ImageHoverPreviewData>({
            content: ImageHoverPreviewComponent,
            data: { src: imageSrc }, // Use the image data URL as the src attribute
            maxHeight: '500px',
            maxWidth: '500px',
            origin: this.calculateOrigin(event),
          });
        }
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  }

  protected removeFile(value: File): void {
    const updatedFiles = removeFromArray(this.files ?? [], [value]);
    this.baseControl.setValue(updatedFiles.length > 0 ? updatedFiles : null);
  }

  protected viewFile(value: File): void {
    const url = URL.createObjectURL(value);
    window.open(url, '_blank');
  }

  private calculateOrigin(event: MouseEvent): { x: number; y: number } {
    const bodyHeight = (event.view?.innerHeight ?? 0) / 2;
    const xOffset = bodyHeight > event.x ? 0 : -5;
    const yOffset = bodyHeight > event.y ? 15 : -15;
    return {
      x: event.x + xOffset,
      y: event.y + yOffset,
    };
  }
}

/**
 * All the currently supported file extensions that can be uploaded to
 * the Alleva servers.
 */
type SupportedFileExtensions =
  | '.AVCHD'
  | '.DivX'
  | '.H.264'
  | '.WMV'
  | '.asf'
  | '.avi'
  | '.bmp'
  | '.csv'
  | '.doc'
  | '.docx'
  | '.flv'
  | '.gif'
  | '.jpeg'
  | '.jpg'
  | '.mkv'
  | '.mov'
  | '.mp4'
  | '.mpeg'
  | '.mpg'
  | '.pdf'
  | '.png'
  | '.txt'
  | '.xls'
  | '.xlsx';

type MimeTypeName = File['type'];

/**
 * Supported file extension/mime mapping for the file upload input.
 */
const supportedFileExtensionsMimesMap = new Map<
  SupportedFileExtensions,
  MimeTypeName
>()
  .set('.asf', 'video/x-ms-asf')
  .set('.AVCHD', 'video/avchd-stream')
  .set('.avi', 'video/x-msvideo')
  .set('.bmp', 'image/bmp')
  .set('.csv', 'text/csv')
  .set('.doc', 'application/msword')
  .set(
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  )
  .set('.DivX', 'video/divx')
  .set('.flv', 'video/x-flv')
  .set('.gif', 'image/gif')
  .set('.H.264', 'video/h264')
  .set('.jpeg', 'image/jpeg')
  .set('.jpg', 'image/jpeg')
  .set('.mkv', 'video/x-matroska')
  .set('.mov', 'video/quicktime')
  .set('.mp4', 'video/mp4')
  .set('.mpeg', 'video/mpeg')
  .set('.mpg', 'video/mpeg')
  .set('.pdf', 'application/pdf')
  .set('.png', 'image/png')
  .set('.txt', 'text/plain')
  .set('.WMV', 'video/wmv')
  .set('.xls', 'application/vnd.ms-excel')
  .set(
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );

function isFileTypeSupported(
  fileType: File['type'],
  supportedFileExtensions: SupportedFileExtensions[],
): boolean {
  const supportedMimes = supportedFileExtensions
    .map((fileExtension) => supportedFileExtensionsMimesMap.get(fileExtension))
    .filter(isNonEmptyValue);
  return supportedMimes.includes(fileType);
}
