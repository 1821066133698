import { MessageCountsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<MessageCountsApi>();

export class MessageCounts {
  public constructor(props: ClassProperties<MessageCounts>) {
    this.read = props.read;
    this.sent = props.sent;
    this.unread = props.unread;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Message Counts API
   * model.
   */
  public static readonly Codec = io.type(
    {
      read: io.number,
      sent: io.number,
      unread: io.number,
    },
    'MessageCountsApi',
  );

  @api({ key: 'read' }) public readonly read: number;
  @api({ key: 'sent' }) public readonly sent: number;
  @api({ key: 'unread' }) public readonly unread: number;

  /**
   * Deserializes a Client Message Counts object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Message Counts object.
   * @throws An error if the value is not a valid Client Message Counts object.
   */
  public static deserialize(
    value: NonNullable<MessageCountsApi>,
  ): MessageCounts {
    const decoded = decode(MessageCounts.Codec, value);
    return new MessageCounts(decoded);
  }
}
