import { InsuranceAuthorizationDiagnosisApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<InsuranceAuthorizationDiagnosisApi>();

export class InsuranceAuthorizationDiagnosis {
  public constructor(props: ClassProperties<InsuranceAuthorizationDiagnosis>) {
    this.diagnosisId = props.diagnosisId;
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Authorization
   * Diagnosis API model.
   */
  public static readonly Codec = io.type(
    {
      diagnosisId: io.number,
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'InsuranceAuthorizationDiagnosisApi',
  );

  @api({ key: 'diagnosisId' }) public diagnosisId: number;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes an Insurance Authorization Diagnosis object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Authorization Diagnosis object.
   * @throws An error if the value is not a valid Insurance Authorization
   * Diagnosis object.
   */
  public static deserialize(
    value: NonNullable<InsuranceAuthorizationDiagnosisApi>,
  ): InsuranceAuthorizationDiagnosis {
    const decoded = decode(InsuranceAuthorizationDiagnosis.Codec, value);
    return new InsuranceAuthorizationDiagnosis(decoded);
  }

  /**
   * Deserializes a list of Insurance Authorization Diagnosis objects from the
   * API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Authorization Diagnosis objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance
   * Authorization Diagnosis objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceAuthorizationDiagnosisApi>>,
  ): readonly InsuranceAuthorizationDiagnosis[] {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Insurance Authorization Diagnosis objects.',
      );
    }
    return values.map(InsuranceAuthorizationDiagnosis.deserialize);
  }
}
