<mat-form-field
  *ngIf="filteredListChanges | async as filteredList; else loadingTemplate"
  [appearance]="'outline'"
  [subscriptSizing]="hideHint ? 'dynamic' : 'fixed'"
>
  <mat-label>{{ label }}</mat-label>
  <input
    [formControl]="baseControl"
    [matAutocomplete]="autocompleteDropdown"
    [placeholder]="placeholder || ''"
    matInput
    type="text"
  />
  <mat-autocomplete
    #autocompleteDropdown="matAutocomplete"
    [displayWith]="displayWith"
  >
    <mat-option *ngFor="let listItem of filteredList" [value]="listItem">
      {{ displayWith ? displayWith(listItem) : listItem }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('mustSelect')">
      {{ label }} must be selected.
    </ng-container>
  </mat-error>
</mat-form-field>

<ng-template #loadingTemplate>
  <alleva-loading-text [label]="label" type="input"></alleva-loading-text>
</ng-template>
