import { AllergenApi, AllergenUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<AllergenApi>();

class AllergenBase {
  public constructor(props: ClassProperties<AllergenBase>) {
    this.name = props.name;
  }

  @api({ key: 'name' }) public readonly name: string;
}

export class Allergen extends AllergenBase {
  public constructor(props: ClassProperties<Allergen>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Allergen API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'AllergenApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Allergen object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Allergen object.
   * @throws An error if the value is not a valid Allergen object.
   */
  public static deserialize(value: NonNullable<AllergenApi>): Allergen {
    const decoded = decode(Allergen.Codec, value);
    return new Allergen(decoded);
  }

  /**
   * Deserializes a list of Allergen objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Allergen objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Allergen objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<AllergenApi>>,
  ): readonly Allergen[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Allergen objects.');
    }
    return values.map(Allergen.deserialize);
  }
}

export class AllergenUpdate extends AllergenBase {
  public constructor(props: ClassProperties<AllergenUpdate>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): AllergenUpdateApi {
    return {
      name: this.name,
    };
  }
}
