import { DateTime } from 'luxon';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import {
  AuthenticationService,
  StorageService,
  StorageTypeEnum,
} from 'src/app/services';

import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * A pipe for sanitizing and returning a URL for an authenticated blob.
 *
 * @future Look into caching the blob responses.
 */
@Pipe({ name: 'authBlob' })
export class AuthBlobPipe implements PipeTransform {
  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly domSanitizer: DomSanitizer,
    private readonly httpClient: HttpClient,
    private readonly storageService: StorageService, // Inject StorageService
  ) {}

  public transform(src: string | null | undefined): Observable<SafeUrl | null> {
    if (!src) {
      return of(null);
    }
    if (isLocalAssetUrl(src)) {
      return of(src);
    }
    return this.authenticationService.userChanges.pipe(
      switchMap((user) => {
        if (!user) {
          this.storageService.clearAll(StorageTypeEnum.LOCAL_FORAGE);
          return of(null);
        }
        // Only include the first 10 char of the users unique token for ref.
        const sliceLength = Math.min(10, user.accessToken.length);
        const cacheKey = `authBlob:${src}:${user.accessToken.slice(
          0,
          sliceLength,
        )}`;
        return from(
          /** Get the stored blob from localforage. If expired, returns null. */
          this.storageService.get<Blob>(cacheKey, StorageTypeEnum.LOCAL_FORAGE),
        ).pipe(
          switchMap((cachedBlob) => {
            if (cachedBlob) {
              return of(cachedBlob);
            } else {
              return this.httpClient
                .get(src, {
                  responseType: 'blob',
                  headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                  },
                })
                .pipe(
                  switchMap((blob) => {
                    // Store for 8 hours (standard business day). This data
                    // will be refreshed after expiration/logout.
                    const expiration = DateTime.local().plus({ hours: 8 });
                    this.storageService.set(
                      cacheKey,
                      blob,
                      StorageTypeEnum.LOCAL_FORAGE,
                      expiration,
                    );
                    return of(blob);
                  }),
                );
            }
          }),
          catchError(() => {
            this.storageService.clearAll(StorageTypeEnum.LOCAL_FORAGE);
            return of(null);
          }),
          map((blob) =>
            blob
              ? this.domSanitizer.bypassSecurityTrustUrl(
                  URL.createObjectURL(blob),
                )
              : null,
          ),
        );
      }),
    );
  }
}

function isLocalAssetUrl(url: string): boolean {
  return url.startsWith('assets/') || url.startsWith('/assets/');
}
