import { config } from 'src/configs/config';

import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';

/**
 * A service for handling application updates.
 */
@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly swUpdate: SwUpdate,
  ) {}

  private initialized = false;

  public async initialize(): Promise<void> {
    if (!config.isProduction || !config.features.isServiceWorkersEnabled) {
      // Unregister any service workers in development mode if they exist.
      await unregisterServiceWorkers();
      // Return early, we don't need to check for updates in development mode.
      return;
    }

    if (this.initialized) {
      console.warn('"AppUpdateService" already initialized, skipping.');
      return;
    }

    const isUpdateFound = await this.swUpdate.checkForUpdate();
    if (isUpdateFound) {
      // eslint-disable-next-line no-console
      console.info(
        'Version check completed. Application update found, applying!',
      );

      await this.swUpdate.activateUpdate();
      this.alertService.success({
        message:
          'Application updated! Please refresh page for the latest version.',
      });
    } else {
      // eslint-disable-next-line no-console
      console.info('Version check completed. Application is up to date!');
    }

    this.initialized = true;
  }
}

/**
 * Unregister any service workers in development mode if they exist. This
 * ensures that any existing service workers don't interfere or cache old
 * versions of the application, which might lead to unexpected behavior
 * during development.
 */
async function unregisterServiceWorkers(): Promise<void> {
  if (navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations.length > 0) {
      for (const registration of registrations) {
        await registration.unregister();
      }
      // eslint-disable-next-line no-console
      console.info(`Unregistered ${registrations.length} service worker(s).`);
    }
  }
}
