import {
  FlexibleConnectedPositionStrategyOrigin,
  OverlayRef,
} from '@angular/cdk/overlay';
import { Component, OnDestroy, Type } from '@angular/core';

@Component({
  selector: 'alleva-hover-preview',
  template: `<ng-container *ngComponentOutlet="content"></ng-container>`,
})
export class HoverPreviewComponent implements OnDestroy {
  public constructor(
    private readonly hoverPreviewRef: HoverPreviewRef<unknown>,
  ) {}

  public readonly content = this.hoverPreviewRef.content;

  public ngOnDestroy(): void {
    this.hoverPreviewRef.closePreview();
  }
}

export class HoverPreviewRef<T> {
  public constructor(
    public readonly overlay: OverlayRef,
    public readonly content: HoverPreviewConfig<T>['content'],
    public readonly data: T,
  ) {}

  public closePreview(): void {
    this.overlay.dispose();
  }
}

export interface HoverPreviewConfig<T> {
  content: Type<unknown> | null;
  data: T;
  height?: string;
  maxHeight?: string;
  maxWidth?: string;
  origin: FlexibleConnectedPositionStrategyOrigin;
  width?: string;
}
