import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter a list of strings by a string query.
 *
 * @param values The list of strings to filter on.
 * @param query The value to filter by.
 * @returns The filtered list of items.
 */
@Pipe({ name: 'filterStrings' })
export class FilterStringsPipe<T extends string> implements PipeTransform {
  public transform(
    values: readonly T[] | null | undefined,
    query: string,
  ): readonly T[] {
    if (!values) {
      return [];
    }

    return values.filter(
      (value) => value === query || new RegExp(query, 'gi').test(value),
    );
  }
}
