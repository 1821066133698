import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuService } from 'src/app/services';

import { Component, HostBinding, Input, OnInit } from '@angular/core';

/**
 * Container component that handles the main __pages__ content.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-layout-page-content',
  template: `
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="loading-container">
        <alleva-loading-indicator
          [isCentered]="true"
        ></alleva-loading-indicator>
      </div>
    </ng-template>
  `,
  styleUrls: ['./layout-page-content.component.scss'],
})
export class LayoutPageContentComponent implements OnInit {
  public constructor(private readonly menuService: MenuService) {}

  @Input() public set isLoading(value: boolean | null) {
    this.#isLoading = value ?? true;
  }
  public get isLoading(): boolean | null {
    return this.#isLoading;
  }
  #isLoading = false;

  @HostBinding('class.menu-collapsed') protected isCollapsed = false;

  public ngOnInit(): void {
    this.menuService.isCollapsedChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isCollapsed = value;
      });
  }
}
