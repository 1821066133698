import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingIndicatorComponent } from 'src/app/components/loading-indicator/loading-indicator.component';
import { LoadingTextModule } from 'src/app/components/loading-text/loading-text.component.module';

@NgModule({
  declarations: [LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent],
  imports: [CommonModule, LoadingTextModule],
  providers: [],
})
export class LoadingIndicatorModule {}
