import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

/**
 * Service for managing the page title and meta tags.
 */
@Injectable({ providedIn: 'root' })
export class MetaService {
  public constructor(
    private readonly title: Title,
    private readonly meta: Meta,
  ) {}

  public getPageTitle(): string {
    return this.title.getTitle();
  }

  public setPageTitle(title: string): void {
    this.title.setTitle(`${title} - Alleva`);
  }

  public setPageDescription(description: string): void {
    this.meta.updateTag({ name: 'description', content: description });
  }

  public setPageAuthor(author: string): void {
    this.meta.updateTag({ name: 'author', content: author });
  }

  public setPageKeywords(keywords: readonly string[]): void {
    this.meta.updateTag({ name: 'keywords', content: keywords.toString() });
  }

  public setNonDynamicMetaTags(): void {
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0]; // YYYY-MM-DD

    this.meta.addTags([
      { name: 'robots', content: 'nofollow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: todayFormatted },
      { charset: 'UTF-8' },
    ]);
  }
}
