<mat-nav-list *ngIf="currentUserChanges | async as currentUser">
  <alleva-menu-item
    *ngFor="let menuItem of menuItems"
    [currentFacility]="currentFacilityChanges | async"
    [currentUser]="currentUser"
    [isCollapsed]="isCollapsedChanges | async"
    [menuItem]="menuItem"
    [selectedClient]="selectedClientChanges | async"
  ></alleva-menu-item>
</mat-nav-list>
