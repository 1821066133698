import { MessageRecipientApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Name } from 'src/app/models/user/name.model';
import { Role } from 'src/app/models/user/role.model';

const api = apiDecorator<MessageRecipientApi>();

/**
 * The Message Attachment model.
 */
export class MessageRecipient {
  public constructor(props: ClassProperties<MessageRecipient>) {
    this.id = props.id;
    this.name = props.name;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Message Recipient API
   * model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: Name.Codec,
      role: io.union([Role.Codec, io.null]),
    },
    'MessageRecipientApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: Name;
  @api({ key: 'role' }) public readonly role: Role | null;

  /**
   * Deserializes a Message Recipient object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Message Recipient object.
   * @throws An error if the value is not a valid Message Recipient object.
   */
  public static deserialize(
    value: NonNullable<MessageRecipientApi>,
  ): MessageRecipient {
    const decoded = decode(MessageRecipient.Codec, value);
    return new MessageRecipient({
      ...decoded,
      name: Name.deserialize(decoded.name),
      role: decoded.role ? Role.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Message Recipient objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Message Recipient objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Message Recipient
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<MessageRecipientApi>>,
  ): readonly MessageRecipient[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Message Recipient objects.');
    }
    return values.map(MessageRecipient.deserialize);
  }
}
