import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { DialogService } from 'src/app/components/dialogs/dialog.service';
import { PersonSearchButtonComponent } from 'src/app/components/person-search/person-search-button.component';

@NgModule({
  declarations: [PersonSearchButtonComponent],
  exports: [PersonSearchButtonComponent],
  imports: [ButtonComponent, MatTooltipModule],
  providers: [DialogService],
})
export class PersonSearchModule {}
