import { ClientStatusEnum } from 'src/app/enumerators';

import { Component } from '@angular/core';

/**
 * A component that displays a visual key mapping of the clients status badges.
 */
@Component({
  selector: 'alleva-client-status-badge-key-display',
  templateUrl: './client-status-badge-key-display.component.html',
  styleUrls: ['../status-badge.scss'],
})
export class ClientStatusBadgeKeyDisplayComponent {
  protected readonly ClientStatusEnum = ClientStatusEnum;
}
