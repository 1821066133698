import {
  InsuranceAuthorizationNoteApi,
  InsuranceAuthorizationNoteUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';
import { Reviewer } from 'src/app/models/reviewer.model';

const api = apiDecorator<InsuranceAuthorizationNoteApi>();

abstract class InsuranceAuthorizationNoteBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationNoteBase>) {
    this.date = props.date;
    this.note = props.note;
  }

  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'note' }) public readonly note: string | null;
}

export class InsuranceAuthorizationNote extends InsuranceAuthorizationNoteBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationNote>) {
    super(props);

    this.by = props.by;
    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Authorization
   * Note API model.
   */
  public static readonly Codec = io.type(
    {
      by: Reviewer.Codec,
      date: io.string,
      id: io.number,
      note: io.union([io.string, io.null]),
    },
    'InsuranceAuthorizationNoteApi',
  );

  @api({ key: 'by' }) public by: Reviewer;
  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes an Insurance Authorization Note object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Authorization Note object.
   * @throws An error if the value is not a valid Insurance Authorization
   * Note object.
   */
  public static deserialize(
    value: NonNullable<InsuranceAuthorizationNoteApi>,
    deserializationArgs: InsuranceAuthorizationNoteDeserializationArgs,
  ): InsuranceAuthorizationNote {
    const decoded = decode(InsuranceAuthorizationNote.Codec, value);
    return new InsuranceAuthorizationNote({
      ...decoded,
      by: Reviewer.deserialize(decoded.by, deserializationArgs),
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
    });
  }

  /**
   * Deserializes a list of Insurance Authorization Note objects from the API
   * model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Authorization Note objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance
   * Authorization Note objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceAuthorizationNoteApi>>,
    deserializationArgs: InsuranceAuthorizationNoteDeserializationArgs,
  ): readonly InsuranceAuthorizationNote[] {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Insurance Authorization Note objects.',
      );
    }
    return values.map((insuranceAuthorizationNote) =>
      InsuranceAuthorizationNote.deserialize(
        insuranceAuthorizationNote,
        deserializationArgs,
      ),
    );
  }
}

export class InsuranceAuthorizationNoteUpdate extends InsuranceAuthorizationNoteBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationNoteUpdate>) {
    super(props);

    this.by = props.by;
  }

  public readonly by: string;

  public serialize(): InsuranceAuthorizationNoteUpdateApi {
    const date = this.date.toISO();
    if (!date) {
      throw new Error('Invalid date.');
    }

    return {
      by: this.by,
      date,
      note: this.note,
    };
  }
}

export interface InsuranceAuthorizationNoteDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
