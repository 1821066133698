import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  concat,
  filter,
  firstValueFrom,
  shareReplay,
} from 'rxjs';
import { Client } from 'src/app/models';
import { isNonEmptyString, isNonEmptyValue } from 'src/app/utilities';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientsService } from '../client/clients.service';
import { FacilityService } from './facility.service';
import { StorageService, StorageTypeEnum } from './storage.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CurrentClientService {
  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly clientService: ClientsService,
    private readonly facilityService: FacilityService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  private isInitialized = false;

  private readonly SELECTED_CLIENT_KEY = 'selectedClient';

  private readonly selectedSubject = new ReplaySubject<Client | null>(1);

  /**
   * Start with the previously viewed client from local storage. This value
   * updates when the user selects a new client.
   *
   * @see alleva-web\src\app\services\client-resolver.service.ts
   */
  public readonly selectedChanges: Observable<Client | null> = concat(
    this.storageService.get<Client>(
      this.SELECTED_CLIENT_KEY,
      StorageTypeEnum.LOCAL_STORAGE,
    ),
    this.selectedSubject,
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /**
   * Set the previously viewed client data in local state and local storage.
   */
  public set selected(client: Client | null) {
    this.selectedSubject.next(client);
    this.storageService.set(
      this.SELECTED_CLIENT_KEY,
      client,
      StorageTypeEnum.LOCAL_STORAGE,
    );
  }

  public async initialize(): Promise<void> {
    // Check if already initialized
    if (this.isInitialized) {
      throw new Error('CurrentClientService is already initialized.');
    }

    this.isInitialized = true;

    // Set up subscription for changes in selected client and current facility
    combineLatest([
      this.selectedChanges.pipe(filter(isNonEmptyValue)),
      this.facilityService.currentFacilityChanges,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([selectedClient, currentFacility]) => {
        // If the selected client facility id doesn't match the current
        // facility, reset the selected client.
        if (selectedClient.facility.id !== currentFacility.id) {
          this.selected = null;
        }
      });

    // Set up subscription for changes in query parameters.
    // When the external client ID query parameter is provided, we need to
    // get the client information based on the external client ID and update
    // the currently selected client in local memory.
    this.activatedRoute.queryParams
      .pipe(
        filter((queryParams) => Object.keys(queryParams).length > 0),
        untilDestroyed(this),
      )
      .subscribe(async (queryParams) => {
        const PARAM_EXT_CLIENT_ID = 'ext_client_id';
        const queryParam: string | undefined = queryParams[PARAM_EXT_CLIENT_ID];

        // Extract external client ID from query parameters if it was provided.
        const externalClientId = isNonEmptyString(queryParam)
          ? Number(queryParam)
          : null;

        if (externalClientId === null) {
          // No external client ID provided so we can return early here skipping
          // the remaining logic that would otherwise handle it.
          return;
        } else if (isNaN(externalClientId)) {
          throw new Error('The provided external client ID is not a number.');
        } else if (externalClientId <= 0) {
          throw new Error(
            'The external client id should be greater than zero.',
          );
        }

        // Clear the specified query parameters
        const currentQueryParams = {
          ...this.activatedRoute.snapshot.queryParams,
        };
        delete currentQueryParams[PARAM_EXT_CLIENT_ID];

        // Navigate to the current route with the modified query parameters
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: currentQueryParams,
          replaceUrl: true, // Replace the current URL in the browser's history
        });

        // Bail if externalClientId is the same as the currently selected client id
        const currentlySelectedClient = await firstValueFrom(
          this.selectedChanges,
        );
        if (externalClientId === currentlySelectedClient?.id) {
          return;
        }

        // Get the new client information based on external client ID.
        const newClientFromExternalId = await firstValueFrom(
          this.clientService.get(externalClientId),
        );

        // Bail if the new client is null or undefined.
        if (!newClientFromExternalId) {
          return;
        }

        // Update selected client
        this.selected = newClientFromExternalId;
      });
  }
}
