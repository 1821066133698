import { ClientDiagnosisApi, ClientDiagnosisUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { DiagnosisPriorityEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { DiagnosisProblem } from 'src/app/models/diagnosis/diagnosis-problem.model';
import { Diagnosis } from 'src/app/models/diagnosis/diagnosis.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { Name } from 'src/app/models/user/name.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientDiagnosisApi>();
const apiUpdate = apiDecorator<ClientDiagnosisUpdateApi>();

type ClientDiagnosisArgs = Omit<
  ClassProperties<ClientDiagnosis>,
  // Omit computed properties set on construct from outside data.
  'diagnosisDescription' | 'diagnosisCode' | 'problemDescription'
>;

abstract class ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisBase>) {
    this.isActive = props.isActive;
    this.resolvedDate = props.resolvedDate;
    this.isRuleOut = props.isRuleOut;
    this.isResolved = props.isResolved;
  }

  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'resolvedDate' }) public readonly resolvedDate: DateTime | null;
  @api({ key: 'isRuleOut' }) public readonly isRuleOut: boolean;
  @api({ key: 'isResolved' }) public readonly isResolved: boolean;
}

export class ClientDiagnosis extends ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisArgs>) {
    super(props);

    // Set properties.
    this.createdAt = props.createdAt;
    this.createdBy = props.createdBy;
    this.diagnosis = props.diagnosis;
    this.id = props.id;
    this.priority = props.priority;
    this.problem = props.problem;

    // Computed values.
    this.diagnosisDescription = this.diagnosis?.description ?? null;
    this.diagnosisCode = this.diagnosis?.code ?? null;
    this.problemDescription = this.problem?.description ?? null;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Diagnosis API
   * model.
   */
  public static readonly Codec = io.type(
    {
      isActive: io.boolean,
      createdAt: io.string,
      createdBy: UserBase.BaseCodec,
      diagnosis: io.union([Diagnosis.Codec, io.null]),
      id: io.number,
      priority: io.union([
        io.literal(DiagnosisPriorityEnum.PRIMARY),
        io.literal(DiagnosisPriorityEnum.SECONDARY),
        io.literal(DiagnosisPriorityEnum.TERTIARY),
        io.literal(DiagnosisPriorityEnum.OTHER),
        io.null,
      ]),
      problem: io.union([DiagnosisProblem.Codec, io.null]),
      resolvedDate: io.union([io.string, io.null]),
      isRuleOut: io.boolean,
      isResolved: io.boolean,
    },
    'ClientDiagnosisApi',
  );

  @api({ key: 'createdAt' }) public readonly createdAt: DateTime;
  @api({ key: 'createdBy' }) public readonly createdBy: UserBase;
  @api({ key: 'diagnosis' }) public readonly diagnosis: Diagnosis | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'priority' })
  public readonly priority: DiagnosisPriorityEnum | null;
  @api({ key: 'problem' }) public readonly problem: DiagnosisProblem | null;

  // Computed properties
  public readonly diagnosisDescription: string | null;
  public readonly diagnosisCode: string | null;
  public readonly problemDescription: string | null;

  /**
   * Deserializes a Client Diagnosis object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Diagnosis object.
   * @throws An error if the value is not a valid Client Diagnosis object.
   */
  public static deserialize(
    value: NonNullable<ClientDiagnosisApi>,
    deserializationArgs: ClientDiagnosisDeserializationArgs,
  ): ClientDiagnosis {
    const decoded = decode(ClientDiagnosis.Codec, value);
    return new ClientDiagnosis({
      ...decoded,
      createdAt: DateTime.fromISO(decoded.createdAt, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      createdBy: UserBase.deserialize(
        {
          ...decoded.createdBy,
          name: decoded.createdBy.name
            ? Name.deserialize(decoded.createdBy.name)
            : Name.deserialize({ first: null, last: null }),
        },
        deserializationArgs,
      ),
      diagnosis: decoded.diagnosis
        ? Diagnosis.deserialize(decoded.diagnosis)
        : null,
      priority: decoded.priority,
      problem: decoded.problem
        ? DiagnosisProblem.deserialize(decoded.problem)
        : null,
      resolvedDate: decoded.resolvedDate
        ? DateTime.fromISO(decoded.resolvedDate, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
    });
  }

  /**
   * Deserializes a list of Client Diagnosis objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Diagnosis objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Diagnosis
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientDiagnosisApi>>,
    deserializationArgs: ClientDiagnosisDeserializationArgs,
  ): readonly ClientDiagnosis[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Diagnosis objects.');
    }
    return values.map((clientDiagnosis) =>
      ClientDiagnosis.deserialize(clientDiagnosis, deserializationArgs),
    );
  }

  /**
   * A pure function that returns the unique identifier for a Client Diagnosis
   * resource. This is specifically used for the `trackBy` function in
   * Angular's `ngFor` directive to improve rendering performance when a list
   * of Clients Diagnosis is rendered.
   */
  public static trackBy(
    _index: number,
    clientDiagnosis: ClientDiagnosis,
  ): number {
    return clientDiagnosis.id;
  }
}

export class ClientDiagnosisUpdate extends ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisUpdate>) {
    super(props);

    this.diagnosis = props.diagnosis;
    this.priority = props.priority;
    this.problem = props.problem;
  }

  @apiUpdate({ key: 'diagnosis' }) public readonly diagnosis: Diagnosis;
  @apiUpdate({ key: 'priority' })
  public readonly priority: DiagnosisPriorityEnum | null;
  @apiUpdate({ key: 'problem' }) public readonly problem: DiagnosisProblem;

  /**
   * Serialize the Client Diagnosis Update object to an API model.
   *
   * @returns The serialized Client Diagnosis Update object.
   */
  public serialize(): ClientDiagnosisUpdateApi {
    return {
      diagnosis: this.diagnosis,
      isActive: this.isActive,
      isResolved: this.isResolved,
      isRuleOut: this.isRuleOut,
      priority: this.priority,
      priorityRank: 0,
      problem: {
        group: this.problem.group,
        id: this.problem.id,
        problemCode: this.problem.code,
        problemDescription: this.problem.description,
      },
      resolvedDate: this.resolvedDate ? this.resolvedDate.toISO() : null,
    };
  }
}

export interface ClientDiagnosisDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
