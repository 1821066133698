<ng-container *ngIf="baseControl as formControl; else loadingControl">
  <mat-label *ngIf="label as inputLabel">
    <ng-container *ngIf="!boldLabel; else boldLabelTemplate">
      {{ inputLabel + (required ? '*' : '') }}
    </ng-container>
    <ng-template #boldLabelTemplate>
      <strong>{{ inputLabel + (required ? '*' : '') }}</strong>
    </ng-template>
  </mat-label>
  <mat-radio-group
    [ariaLabel]="label"
    [color]="color"
    [formControl]="formControl"
  >
    <mat-radio-button
      *ngFor="let option of options"
      [value]="option.value"
      color="accent"
      >{{ option.element.textContent }}</mat-radio-button
    >
  </mat-radio-group>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
  </mat-error>
</ng-container>

<ng-template #loadingControl>
  Loading{{ label ? ' ' + label : '' }}...
</ng-template>
