import { isNonEmptyValue } from 'src/app/utilities/type-check';

export function getEnumMember<T extends object>(
  enumObject: T,
  value?: string | number | null,
  defaultValue?: T[keyof T] | null,
): T[keyof T] | null {
  if (isNonEmptyValue(value) && isEnumMember(enumObject, value)) {
    return value;
  } else if (
    isNonEmptyValue(defaultValue) &&
    isEnumMember(enumObject, defaultValue)
  ) {
    return defaultValue;
  } else {
    throw new Error(
      `Invalid enum value "${value}" and/or default value "${defaultValue}".`,
    );
  }
}

export function getEnumValues<T extends object>(
  enumObject: T,
): Array<T[keyof T]> {
  return Object.values(enumObject);
}

function isEnumMember<T extends object>(
  enumObject: T,
  value: unknown,
): value is T[keyof T] {
  return getEnumValues(enumObject).includes(value as T[keyof T]);
}
