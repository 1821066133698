import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { ContainerModule } from 'src/app/components/container/container.module';
import {
  DialogComponent,
  InjectDialogDirective,
} from 'src/app/components/dialogs/dialog.component';
import { DialogsModule } from 'src/app/components/dialogs/dialogs.module';

@NgModule({
  declarations: [DialogComponent, InjectDialogDirective],
  exports: [DialogComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    ContainerModule,
    DialogsModule,
    MatTooltipModule,
  ],
})
export class DialogModule {}
