/**
 * All possible values for the "autocomplete" attribute.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
export enum AutoCompleteEnum {
  /** The middle name. */
  ADDITIONAL_NAME = 'additional-name',
  /**
   * The first administrative level in the address. This is typically the
   * province in which the address is located. In the United States, this
   * would be the state. In Switzerland, the canton. In the United Kingdom,
   * the post town.
   */
  ADDRESS_LEVEL1 = 'address-level1',
  /**
   * The second administrative level, in addresses with at least two of
   * them. In countries with two administrative levels, this would typically
   * be the city, town, village, or other locality in which the address is
   * located.
   */
  ADDRESS_LEVEL2 = 'address-level2',
  /**
   * The third administrative level, in addresses with at least three
   * administrative levels.
   */
  ADDRESS_LEVEL3 = 'address-level3',
  /**
   * The finest-grained administrative level, in addresses which have four
   * levels.
   */
  ADDRESS_LEVEL4 = 'address-level4',
  /**
   * An individual line for "street address". These should only be present if
   * the "street-address" is not present.
   */
  ADDRESS_LINE1 = 'address-line1',
  /**
   * An individual line for "street address". These should only be present if
   * the "street-address" is not present.
   */
  ADDRESS_LINE2 = 'address-line2',
  /**
   * An individual line for "street address". These should only be present if
   * the "street-address" is not present.
   */
  ADDRESS_LINE3 = 'address-line3',
  /** A middle name as given on a payment instrument or credit card. */
  CC_ADDITIONAL_NAME = 'cc-additional-name',
  /**
   * The security code for the payment instrument; on credit cards, this is
   * the 3-digit verification number on the back of the card.
   */
  CC_CSC = 'cc-csc',
  /**
   * A payment method expiration date, typically in the form "MM/YY" or
   * "MM/YYYY".
   */
  CC_EXP = 'cc-exp',
  /** The month in which the payment method expires. */
  CC_EXP_MONTH = 'cc-exp-month',
  /** The year in which the payment method expires. */
  CC_EXP_YEAR = 'cc-exp-year',
  /** A family name, as given on a credit card. */
  CC_FAMILY_NAME = 'cc-family-name',
  /**
   * A given (first) name as given on a payment instrument like a credit card.
   */
  CC_GIVEN_NAME = 'cc-given-name',
  /**
   * The full name as printed on or associated with a payment instrument such
   * as a credit card. Using a full name field is preferred, typically, over
   * breaking the name into pieces.
   */
  CC_NAME = 'cc-name',
  /**
   * A credit card number or other number identifying a payment method, such
   * as an account number.
   */
  CC_NUMBER = 'cc-number',
  /** The type of payment instrument (such as "Visa" or "Master Card"). */
  CC_TYPE = 'cc-type',
  /** A country or territory code. */
  COUNTRY = 'country',
  /** A country or territory name. */
  COUNTRY_NAME = 'country-name',
  /** The user's current password. */
  CURRENT_PASSWORD = 'current-password',
  /** A birth date, as a full date. */
  DOB = 'bday',
  /** The day of the month of a birth date. */
  DOB_DAY = 'bday-day',
  /** The month of the year of a birth date. */
  DOB_MONTH = 'bday-month',
  /** The year of a birth date. */
  DOB_YEAR = 'bday-year',
  /** An email address. */
  EMAIL = 'email',
  /** The family (or "last") name. */
  FAMILY_NAME = 'family-name',
  /** The given (or "first") name. */
  GIVEN_NAME = 'given-name',
  /** The prefix or title, such as "Mrs.", "Mr.", "Miss", "Ms.", "Dr.", etc. */
  HONORIFIC_PREFIX = 'honorific-prefix',
  /** The suffix, such as "Jr.", "B.Sc.", "PhD.", "MBASW", or "IV". */
  HONORIFIC_SUFFIX = 'honorific-suffix',
  /**
   * A URL for an instant messaging protocol endpoint, such as
   * "xmpp:username@example.net".
   */
  IMPP = 'impp',
  /** A preferred language, given as a valid BCP 47 language tag. */
  LANGUAGE = 'language',
  /** The field expects the value to be a person's full name. */
  NAME = 'name',
  /** A new password. */
  NEW_PASSWORD = 'new-password',
  /** A nickname or handle. */
  NICKNAME = 'nickname',
  /** Disallow browser from automatically entering values for this field. */
  OFF = 'off',
  /** The browser is allowed to automatically complete the input. */
  ON = 'on',
  /** A one-time code used for verifying user identity. */
  ONE_TIME_CODE = 'one-time-code',
  /** A company or organization name. */
  ORGANIZATION = 'organization',
  /** A job title, or the title a person has within an organization */
  ORGANIZATION_TITLE = 'organization-title',
  /**
   * The URL of an image representing the person, company, or contact
   * information given in the other fields in the form.
   */
  PHOTO = 'photo',
  /** A postal code (in the United States, this is the ZIP code). */
  POSTAL_CODE = 'postal-code',
  /**
   * A gender identity (such as "Female", "Fa'afafine", "Hijra", "Male",
   * "Nonbinary"), as freeform text without newlines.
   */
  SEX = 'sex',
  /** A street address. */
  STREET_ADDRESS = 'street-address',
  /** A full telephone number, including the country code. */
  TEL = 'tel',
  /**
   * The area code, with any country-internal prefix applied if appropriate.
   */
  TEL_AREA_CODE = 'tel-area-code',
  /**
   * The country code, such as "1" for the United States, Canada, and other
   * areas in North America and parts of the Caribbean.
   */
  TEL_COUNTRY_CODE = 'tel-country-code',
  /**
   * A telephone extension code within the phone number, such as a room or
   * suite number in a hotel or an office extension in a company.
   */
  TEL_EXTENSION = 'tel-extension',
  /**
   * The phone number without the country or area code. This can be split
   * further into two parts, for phone numbers which have an exchange number
   * and then a number within the exchange. For the phone number "555-6502",
   * use "tel-local-prefix" for "555" and "tel-local-suffix" for "6502".
   */
  TEL_LOCAL = 'tel-local',
  /** The prefix "555" portion of the phone number in "123-555-4567". */
  TEL_LOCAL_PREFIX = 'tel-local-prefix',
  /** The suffix "4567" portion of the phone number in "123-555-4567". */
  TEL_LOCAL_SUFFIX = 'tel-local-suffix',
  /**
   * The entire phone number without the country code component, including a
   * country-internal prefix. For the phone number "1-855-555-6502", this
   * field's value would be "855-555-6502".
   */
  TEL_NATIONAL = 'tel-national',
  /**
   * The amount, given in the currency specified by "transaction-currency",
   * of the transaction, for a payment form.
   */
  TRANSACTION_AMOUNT = 'transaction-amount',
  /** The currency in which the transaction is to take place. */
  TRANSACTION_CURRENCY = 'transaction-currency',
  /**
   * A URL, such as a home page or company web site address as appropriate
   * given the context of the other fields in the form.
   */
  URL = 'url',
  /** A username or account name. */
  USERNAME = 'username',
}
