import { Observable, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isNonEmptyValue } from 'src/app/utilities/type-check';

/**
 * Gets async the first non-empty value emitted by the source observable.
 *
 * @param observable The observable to wait for.
 * @returns The first non-empty value emitted by the source observable.
 */
export function getFirstNonEmptyValueFrom<T>(
  observable: Observable<T | null | undefined>,
): Promise<T> {
  return firstValueFrom(observable.pipe(filter(isNonEmptyValue)));
}
