import { HttpParams } from '@angular/common/http';

import { replaceNumberGroups } from 'src/app/utilities/strings';
import { isNonEmptyString, isPageRoute } from 'src/app/utilities/type-check';

/**
 * Navigates to an external URL with the given query parameters either in a new
 * tab or the current tab. Returns a promise that resolves when the new tab is
 * opened.
 *
 * @param url The base URL to navigate to.
 * @param queryParams The query parameters to use.
 * @param openInNewTab Whether to open the URL in a new tab.
 * @returns A promise that resolves when the new tab is opened.
 */
export function navigateOffsiteTo(
  url: string,
  {
    queryParams,
    openInNewTab = false,
  }: {
    queryParams?: { [key: string]: string } | HttpParams;
    openInNewTab?: boolean;
  } = {},
): Promise<Window | null> {
  let finalURL = url;

  if (queryParams) {
    if (queryParams instanceof HttpParams) {
      const paramsObj: { [key: string]: string } = {};
      queryParams.keys().forEach((key) => {
        paramsObj[key] = queryParams.get(key) || '';
      });

      const queryString = Object.keys(paramsObj)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(paramsObj[key])}`,
        )
        .join('&');

      finalURL += `?${queryString}`;
    } else {
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              queryParams[key],
            )}`,
        )
        .join('&');

      finalURL += `?${queryString}`;
    }
  }

  return new Promise((resolve) => {
    const newWindow = window.open(finalURL, openInNewTab ? '_blank' : '_self');
    resolve(newWindow);
  });
}

/**
 * A function that returns whether or not the value matches one of the string
 * literals defined in the `PageRoute` type. Also works with SPA route URL's
 * that contain the base facility relative URL.
 *
 * @param url The URL to check and parse.
 * @returns The page route if the URL is a page route, null otherwise.
 */
export function getPageRoute(url: string): PageRoute | null {
  const baseUrl = getBaseRoute(url);

  if (isPageRoute(baseUrl)) {
    const urlNoFacility = baseUrl.replace(/\/facility\/\d+/, '');
    if (!isNonEmptyString(urlNoFacility)) {
      return null;
    }

    const id: RouteResourceIdentifier = ':id';
    const prePageRoute = replaceNumberGroups(urlNoFacility, id);
    const pageRoute: PageRoute | null = isPageRoute(prePageRoute)
      ? prePageRoute
      : null;

    // Remove any facility from the URL if it exists.
    return pageRoute;
  }
  return null;
}

/**
 * A function that returns the base route of a URL. Ignores query parameters.
 *
 * @param fullUrl The full URL to get the base route from.
 * @returns The base route of the URL.
 */
function getBaseRoute(fullUrl: string): string {
  const index = fullUrl.indexOf('?');
  if (index !== -1) {
    return fullUrl.substr(0, index);
  } else {
    return fullUrl;
  }
}
