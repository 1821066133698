import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatTab } from '@angular/material/tabs';

@Directive({ selector: '[allevaTabTitleTemplate]' })
export class TabTitleTemplateDirective {}

@Component({
  selector: 'alleva-tab[title]',
  template: `
    <ng-template #template>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class TabComponent extends MatTab {
  /**
   * The name of the icon to display in the tab label.
   *
   * @default null
   */
  @Input() public iconName: IconName | null = null;

  /**
   * The type of icon to use. Can be `filled`, `outlined`, `rounded`, `sharp`,
   * or `two-tone`.
   *
   * @default "filled"
   */
  @Input() public iconType: IconType | undefined = 'filled';

  /**
   * Whether to hide the tab title template in the tab content body.
   *
   * @default False
   */
  @Input() public isTitleTemplateHidden = false;

  /**
   * The title of the tab. Also display at the top of the tab content body
   * unless a custom template is provided as `allevaTabTitleTemplate`.
   *
   * @required This input is required.
   */
  @Input() public title!: string;

  /**
   * The number to display in a badge that displays with the title of the tab.
   */
  @Input() public titleBadgeCount?: number;

  /** The color of the badge that displays with the title of the tab. */
  @Input() public titleBadgeColor?: ThemePalette;

  /**
   * The custom template to display for the tab title in the tab content body.
   *
   * @note A default tab title template is provided if this input is not
   * provided & the `hideTabTitle` input is false.
   */
  @ContentChild(TabTitleTemplateDirective, { read: TemplateRef })
  public readonly titleTemplate?: TemplateRef<void>;

  /**
   * The template to display in the tab content body.
   */
  @ViewChild('template', { static: false })
  public template!: TemplateRef<unknown>;

  /**
   * An event that is emitted when the tab is selected.
   */
  @Output() public readonly tabSelected: EventEmitter<void> =
    new EventEmitter();
}
