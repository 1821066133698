export function getPoundsFrom({ kilograms }: { kilograms?: number }): number {
  if (kilograms === undefined) {
    throw new Error('No weight value provided.');
  }

  return kilograms * 2.20462;
}

export function getKilogramsFrom({ pounds }: { pounds?: number }): number {
  if (pounds === undefined) {
    throw new Error('No weight value provided.');
  }

  return pounds / 2.20462;
}
