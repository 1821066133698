import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { InputDropdownOptionGroupComponent } from 'src/app/components/forms/input-dropdown/input-dropdown-option-group.component';
import { InputDropdownOptionComponent } from 'src/app/components/forms/input-dropdown/input-dropdown-option.component';
import { InputDropdownComponent } from 'src/app/components/forms/input-dropdown/input-dropdown.component';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingTextModule } from 'src/app/components/loading-text/loading-text.component.module';

@NgModule({
  declarations: [
    InputDropdownComponent,
    InputDropdownOptionComponent,
    InputDropdownOptionGroupComponent,
  ],
  exports: [
    InputDropdownComponent,
    InputDropdownOptionComponent,
    InputDropdownOptionGroupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    LoadingTextModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class InputDropdownModule {}
