import {
  ReplaySubject,
  combineLatest,
  firstValueFrom,
  map,
  shareReplay,
  startWith,
  withLatestFrom,
} from 'rxjs';
import { arrayFilterAll, getNestedObjectData } from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

@Component({
  selector:
    'alleva-input-dropdown-autocomplete[displayKey][formControl][label][list]',
  templateUrl: './input-dropdown-autocomplete.component.html',
  styleUrls: ['./input-dropdown-autocomplete.component.scss'],
})
export class InputDropdownAutocompleteComponent<T extends object>
  extends CustomInputDirective<T>
  implements OnInit
{
  /**
   * Whether the filtering should be done locally. When `true` the filter in the
   * dropdown will filter the list locally. When `false` the filter will emit
   * the value to the parent component to handle the filtering.
   */
  @Input() public isLocalFilter = true;

  /**
   * Whether the dropdown should retain the initial value even when it is not
   * existent in the list.
   *
   * Notes: This can be used to check if the value still exists in the list on
   * initialize and if it doesn't, add it back to the list. This is useful for
   * handling cases where the value was deleted and the dropdown can no longer
   * find the value matching the selected value. Essentially handling bad data.
   */
  @Input() public set retainInitialValue(value: BooleanInput) {
    this.#retainInitialValue = coerceBooleanProperty(value);
  }
  public get retainInitialValue(): boolean {
    return this.#retainInitialValue;
  }
  #retainInitialValue = false;

  private readonly initialValueSubject = new ReplaySubject<T | null>(1);

  /** Emits the filter value when it changes. */
  @Output() public readonly filterValueChange = new EventEmitter<
    string | null
  >();

  /**
   * The list of items to display in the dropdown.
   *
   * @required This is a required input.
   */
  @Input() public set list(values: readonly T[] | null | undefined) {
    if (!values) {
      return;
    }
    this.listSubject.next(values);
  }

  /**
   * Whether the user should be allowed to select multiple options.
   */
  @Input() public set multiple(value: BooleanInput) {
    this.#multiple = coerceBooleanProperty(value);
  }
  public get multiple(): boolean {
    return this.#multiple;
  }
  #multiple = false;

  private readonly listSubject = new ReplaySubject<readonly T[]>(1);
  protected readonly listChanges = combineLatest([
    this.listSubject,
    this.initialValueSubject,
  ]).pipe(
    map(([list, initialValue]): readonly T[] => {
      const currentValue = this.baseControl.value;

      if (this.multiple && Array.isArray(currentValue)) {
        // Multi-value handling
        const missingValues = currentValue.filter(
          (value) => !list.some((item) => this.compareWith(item, value)),
        );
        if (missingValues.length > 0) {
          return [...missingValues, ...list];
        }
      } else {
        // Single value handling
        const isCurrentValueInitialValue = initialValue
          ? initialValue === currentValue
          : false;

        if (!this.retainInitialValue && isCurrentValueInitialValue) {
          return list;
        }

        const listHasCurrentValue =
          currentValue &&
          list.some((item) => this.compareWith(item, currentValue));

        if (!listHasCurrentValue && currentValue) {
          return [currentValue, ...list];
        }
      }

      return list;
    }),
    shareReplay(1),
  );

  private readonly localFilteredListSubject = new ReplaySubject<
    readonly T[] | null
  >(1);
  protected readonly filteredListChanges = combineLatest([
    this.listChanges,
    this.localFilteredListSubject.pipe(startWith(null)),
  ]).pipe(
    withLatestFrom(this.initialValueSubject),
    map(([[list, localFilteredList], initialValue]) => {
      const currentValue = this.baseControl.value;

      if (this.multiple && Array.isArray(currentValue)) {
        // Multi-value handling
        const missingValues = currentValue.filter(
          (value) => !list.some((item) => this.compareWith(item, value)),
        );
        if (missingValues.length > 0) {
          return [...missingValues, ...(localFilteredList || list)];
        }
      } else {
        // Single value handling
        if (localFilteredList === null) {
          if (currentValue) {
            return [currentValue, ...list];
          } else if (initialValue) {
            return [initialValue, ...list];
          }
          return list;
        }
      }

      return localFilteredList === null ? list : localFilteredList;
    }),
    shareReplay(1),
  );

  @ViewChild(MatSelect) public readonly matSelect?: MatSelect;

  @Input() public displayKey!: NestedKeysOfString<T>;

  /** The label to display for the clear button. */
  @Input() public clearLabel?: string;

  @Input() public set isClearable(value: BooleanInput) {
    this.#isClearable = coerceBooleanProperty(value);
  }
  public get isClearable(): boolean {
    return this.#isClearable;
  }
  #isClearable = false;

  /**
   * Emit an event when the input dropdown was cleared with the internal clear
   * function and button.
   */
  @Output() public readonly cleared = new EventEmitter<void>();

  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error('The base control is required.');
    }

    this.initialValueSubject.next(this.baseControl.value);
  }

  /** Clear the dropdown selection. */
  protected clear(): void {
    if (this.isClearable && this.matSelect) {
      if (Array.isArray(this.matSelect.selected)) {
        this.matSelect.selected.forEach((selected) => selected.deselect(false));
      } else {
        this.matSelect.selected.deselect(false);
      }
      this.baseControl.setValue(null);
      this.cleared.emit();
    }
  }

  /**
   * Function to compare the option values with the selected values. The first argument
   * is a value from an option. The second is a value from the selection. A boolean
   * should be returned.
   */
  @Input() public compareWith: MatSelect['compareWith'] = (
    optionValue,
    selectedValue,
  ) => optionValue === selectedValue;

  protected async filter(value: string): Promise<void> {
    if (!this.isLocalFilter) {
      this.filterValueChange.emit(value || null);
      return;
    }

    const currentList: readonly T[] = await firstValueFrom(this.listChanges);
    const localFilteredList = arrayFilterAll(currentList, value, {
      searchNestedKeys: true,
    });

    this.localFilteredListSubject.next(localFilteredList);
  }

  protected handleFilterKeyDown(event: KeyboardEvent): void {
    // Stop all events from propogating up to the mat-select.
    event.stopPropagation();
  }

  /**
   * Gets the display value for the provided item.
   *
   * @param item The item to get the display value for.
   * @returns The display value for the provided item.
   */

  protected getDisplayValue(item: T): string {
    return (getNestedObjectData<T>(item, this.displayKey) || '—').toString();
  }
}
