import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a boolean'ish value to a 'True' or 'False' string.
 */
@Pipe({ name: 'trueFalse' })
export class TrueFalsePipe implements PipeTransform {
  public transform(value: object | Primitive, lowercase?: boolean): string {
    const yesNo = coerceBooleanProperty(value) ? 'True' : 'False';
    return lowercase ? yesNo.toLowerCase() : yesNo;
  }
}
