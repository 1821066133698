import { BillingRuleClaimApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { ClaimTypeEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { BillingCodeModifier } from './billing-code-modifier.model';
import { BillingRuleClaimTypeOfBill } from './billing-rule-claim-type-of-bill.model';
import { BillingRuleClaimValueCode } from './billing-rule-claim-value-code.model';
import { BillingRulePlaceOfService } from './billing-rule-place-of-service.model';

const api = apiDecorator<BillingRuleClaimApi>();

export abstract class BillingRuleClaim {
  public constructor(props: ClassProperties<BillingRuleClaim>) {
    this.modifiers = props.modifiers;
    this.placeOfService = props.placeOfService;
    this.type = props.type;
    this.typeOfBill = props.typeOfBill;
    this.units = props.units;
    this.valueCodes = props.valueCodes;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Claim
   * API model.
   */
  public static readonly Codec = io.type(
    {
      modifiers: io.union([io.array(BillingCodeModifier.Codec), io.null]),
      placeOfService: io.union([BillingRulePlaceOfService.Codec, io.null]),
      type: io.union([
        io.literal(ClaimTypeEnum.FACILITY),
        io.literal(ClaimTypeEnum.PROFESSIONAL),
        io.null,
      ]),
      typeOfBill: io.union([BillingRuleClaimTypeOfBill.Codec, io.null]),
      units: io.union([io.number, io.null]),
      valueCodes: io.union([
        io.array(BillingRuleClaimValueCode.Codec),
        io.null,
      ]),
    },
    'BillingRuleClaimApi',
  );

  // todo: Potentially update to be `BillingCodeModifier`?
  @api({ key: 'modifiers' }) public readonly modifiers:
    | readonly BillingCodeModifier[]
    | null;
  @api({ key: 'placeOfService' })
  public readonly placeOfService: BillingRulePlaceOfService | null;
  @api({ key: 'type' }) public readonly type: ClaimTypeEnum | null;
  @api({ key: 'typeOfBill' })
  public readonly typeOfBill: BillingRuleClaimTypeOfBill | null;
  @api({ key: 'units' }) public readonly units: number | null;
  @api({ key: 'valueCodes' })
  public readonly valueCodes: readonly BillingRuleClaimValueCode[] | null;

  /**
   * Deserializes a Billing Payor Rule Claim object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Claim object.
   * @throws An error if the value is not a valid Billing Payor Rule Claim
   * object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleClaimApi>,
  ): BillingRuleClaim {
    const decoded = decode(BillingRuleClaim.Codec, value);
    return {
      modifiers: decoded.modifiers
        ? BillingCodeModifier.deserializeList(decoded.modifiers)
        : null,
      placeOfService: decoded.placeOfService
        ? BillingRulePlaceOfService.deserialize(decoded.placeOfService)
        : null,
      type: decoded.type,
      typeOfBill: decoded.typeOfBill
        ? BillingRuleClaimTypeOfBill.deserialize(decoded.typeOfBill)
        : null,
      units: decoded.units,
      valueCodes: decoded.valueCodes
        ? decoded.valueCodes.map(BillingRuleClaimValueCode.deserialize)
        : null,
    };
  }
}
