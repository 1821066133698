import { LicenseApi, LicenseCreateApi, LicenseUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<LicenseApi>();

export abstract class LicenseBase {
  public constructor(props: ClassProperties<License>) {
    this.active = props.active;
    this.description = props.description;
    this.name = props.name;
  }

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'name' }) public readonly name: string | null;
}

export class License extends LicenseBase {
  public constructor(props: ClassProperties<License>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the License model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      description: io.union([io.string, io.null]),
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'LicenseApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a License object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized License object.
   * @throws An error if the value is not a valid License object.
   */
  public static deserialize(value: NonNullable<LicenseApi>): License {
    const decoded = decode(License.Codec, value);
    return new License({
      active: decoded.active,
      description: decoded.description,
      id: decoded.id,
      name: decoded.name,
    });
  }

  /**
   * Deserializes a list of License objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized License objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid License
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<LicenseApi>>,
  ): readonly License[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of License objects.');
    }
    return values.map(License.deserialize);
  }
}

export class LicenseCreate extends LicenseBase {
  public serialize(): LicenseCreateApi {
    return {
      active: this.active,
      description: this.description,
      name: this.name,
    };
  }
}

export class LicenseUpdate extends License {
  public serialize(): LicenseUpdateApi {
    return {
      active: this.active,
      description: this.description,
      id: this.id,
      name: this.name,
    };
  }
}
