import { DiagnosisApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<DiagnosisApi>();

export class Diagnosis {
  public constructor(props: ClassProperties<Diagnosis>) {
    this.code = props.code;
    this.description = props.description;
    this.diagnosisNumber = props.diagnosisNumber;
    this.id = props.id;
    this.name = props.name;
    this.problemCode = props.problemCode;
  }

  /**
   * The io-ts codec for runtime type checking of the Diagnosis API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.union([io.string, io.null]),
      description: io.union([io.string, io.null]),
      diagnosisNumber: io.number,
      id: io.number,
      name: io.union([io.string, io.null]),
      problemCode: io.number,
    },
    'DiagnosisApi',
  );

  @api({ key: 'code' }) public readonly code: string | null;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'diagnosisNumber' }) public readonly diagnosisNumber: number;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'problemCode' }) public readonly problemCode: number;

  /**
   * Deserializes a Diagnosis object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Diagnosis object.
   * @throws An error if the value is not a valid Diagnosis object.
   */
  public static deserialize(value: NonNullable<DiagnosisApi>): Diagnosis {
    const decoded = decode(Diagnosis.Codec, value);
    return new Diagnosis(decoded);
  }

  /**
   * Deserializes a list of Diagnosis objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Diagnosis objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Diagnosis objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DiagnosisApi>>,
  ): readonly Diagnosis[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Diagnosis objects.');
    }
    return values.map(Diagnosis.deserialize);
  }
}
