/**
 * Check if a string contains a number.
 *
 * @param value The string to check.
 * @returns True if the string contains a number, false otherwise.
 */
export function containsNumber(value: string): boolean {
  const containsNumberRegExp = /\d+/;
  return containsNumberRegExp.test(value);
}

/**
 * Encode a string using the provided string and hashing algorithm.
 *
 * @param inputString The input string to be encoded.
 * @param hashAlgorithm The hashing algorithm to use.
 * @returns The generated encoded string as a promise.
 */
export async function encodeString(
  inputString: string,
  hashAlgorithm: 'SHA-256' | 'SHA-384' | 'SHA-512',
): Promise<string> {
  // Encode the input string as UTF-8 bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(inputString);

  // Use the Web Crypto API for encoding/transformation
  const buffer = await window.crypto.subtle.digest(hashAlgorithm, data);

  // Convert the buffer to base64
  const encodedArray = Array.from(new Uint8Array(buffer));
  const encodedString = btoa(String.fromCharCode.apply(null, encodedArray));

  // Replace characters for URL safety
  return encodedString
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

/**
 * Generate a random string of the certain length using the given character set.
 *
 * @param length The length of the string to generate.
 * @param charset The character set to use.
 * @returns The generated random string.
 */
export function generateRandomString(
  length: number,
  charset: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~',
): string {
  return Array.from({ length }, () => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset.charAt(randomIndex);
  }).join('');
}

/**
 * Replace all number instances with a replacement string.
 *
 * @example (`replacement` = `:id`) -> `a/1/b/2` -> `a/:id/b/:id`.
 * @param value The string to replace numbers in.
 * @param replacement The string to replace numbers with.
 * @returns The string with all numbers replaced.
 */
export function replaceNumberGroups(
  value: string,
  replacement: string,
): string {
  return value.replace(/[0-9]+/g, replacement);
}
