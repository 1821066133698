import { ClientCIWAApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientCIWA,
  ClientCIWADeserializationArgs,
  ClientCIWAUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client CIWA API.
 */
@Injectable()
export class ClientCIWAService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Create a new client CIWA resource.
   *
   * @param clientId The client id to create the CIWA resource object for.
   * @param clientCIWA The CIWA resource object to create.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The created CIWA resource object on success, undefined on error.
   */
  public create(
    clientId: Client['id'],
    clientCIWAUpdate: ClientCIWAUpdate,
    deserializationArgs: ClientCIWADeserializationArgs,
  ): Observable<ClientCIWA | undefined> {
    return this.httpClient
      .post<
        ClientCIWAApi | undefined
      >(`${config.api}/clients/${clientId}/ciwa`, clientCIWAUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCIWA.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Delete the given client CIWA by id.
   *
   * @param clientId The associated client id of the CIWA to delete.
   * @param clientCIWAId The id of the client CIWA to delete.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientCIWAId: ClientCIWA['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${config.api}/clients/${clientId}/ciwa/${clientCIWAId}`)
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return all client CIWA history data for the given client.
   *
   * @param clientId The client id to get the CIWA history for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The client CIWA history on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
    deserializationArgs: ClientCIWADeserializationArgs,
  ): Observable<readonly ClientCIWA[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientCIWAApi[] | undefined
      >(`${config.api}/clients/${clientId}/ciwa`)
      .pipe(
        map((response) =>
          response
            ? ClientCIWA.deserializeList(response, deserializationArgs)
            : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a client's CIWA resource record.
   *
   * @param clientId The client id to patch the CIWA resource object for.
   * @param clientCIWAId The client CIWA resource object id to patch.
   * @param clientCIWAUpdate The updated CIWA resource object.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated CIWA resource object on success, undefined on error.
   */
  public update(
    clientId: Client['id'],
    clientCIWAId: ClientCIWA['id'],
    clientCIWAUpdate: ClientCIWAUpdate,
    deserializationArgs: ClientCIWADeserializationArgs,
  ): Observable<ClientCIWA | undefined> {
    return this.httpClient
      .patch<
        ClientCIWAApi | undefined
      >(`${config.api}/clients/${clientId}/ciwa/${clientCIWAId}`, clientCIWAUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCIWA.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
