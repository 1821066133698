import { ReferralContactApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { FacilityBase } from 'src/app/models/facility/facility.model';
import { Name } from 'src/app/models/user/name.model';
import { Phone } from 'src/app/models/user/phone.model';

const api = apiDecorator<ReferralContactApi>();

type ReferralContactArgs = Omit<
  ClassProperties<ReferralContact>,
  // Omit computed properties based on core model data.
  'phoneDisplay'
>;

export class ReferralContact {
  public constructor(props: ClassProperties<ReferralContactArgs>) {
    this.email = props.email;
    this.facilityBase = props.facilityBase;
    this.id = props.id;
    this.image = props.image;
    this.name = props.name;
    this.phone = props.phone;

    // Computed properties based on core model data.
    this.phoneDisplay =
      this.phone.mobile ||
      this.phone.home ||
      this.phone.office ||
      this.phone.other ||
      '—';
  }

  /**
   * The io-ts codec for runtime type checking of the Referral Contact API
   * model.
   */
  public static readonly Codec = io.type(
    {
      email: io.union([io.string, io.null]),
      facility: FacilityBase.BaseCodec,
      id: io.number,
      image: io.union([io.string, io.null]),
      name: Name.Codec,
      phone: Phone.Codec,
    },
    'ReferralContactApi',
  );

  @api({ key: 'email' }) public readonly email: string | null;
  @api({ key: 'facility' }) public readonly facilityBase: FacilityBase;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'name' }) public readonly name: Name;
  @api({ key: 'phone' }) public readonly phone: Phone;

  // Computed properties based on core model data.
  public readonly phoneDisplay: string;

  /**
   * Deserializes a Referral Contact object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Referral Contact object.
   * @throws An error if the value is not a valid Referral Contact object.
   */
  public static deserialize(
    value: NonNullable<ReferralContactApi>,
  ): ReferralContact {
    const decoded = decode(ReferralContact.Codec, value);
    return new ReferralContact({
      ...decoded,
      facilityBase: FacilityBase.deserialize(decoded.facility),
      name: Name.deserialize(decoded.name),
      phone: Phone.deserialize(decoded.phone),
    });
  }

  /**
   * Deserializes an array of Referral Contact objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns An array of Referral Contact objects.
   * @throws An error if any of the values are not valid Referral Contact
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ReferralContactApi>>,
  ): readonly ReferralContact[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Referral Contact objects.');
    }
    return values.map(ReferralContact.deserialize);
  }

  /**
   * Determine if the item is an instance of the model.
   *
   * @param item The item to check the instance of.
   * @returns True if the item is an instance of the model, otherwise false.
   */
  public static isInstanceFrom(item: unknown): item is ReferralContact {
    if (item instanceof ReferralContact) {
      // Item is an instance of the model.
      return true;
    } else if (!item || !(typeof item === 'object')) {
      // Is not an object or is null.
      return false;
    }
    // Check if all keys are present in the item.
    return modelPropertyKeys.every((property) => property in item);
  }

  /**
   * A pure function that returns the unique identifier for a Referral Contact.
   * This is specifically used for the `trackBy` function in Angular's `ngFor`
   * directive to improve rendering performance when a list of Referral
   * Contacts is rendered.
   */
  public static trackBy(_index: number, referral: ReferralContact): number {
    return referral.id;
  }
}

const modelPropertyKeys: ReadonlyArray<keyof ReferralContact> = [
  'email',
  'facilityBase',
  'id',
  'image',
  'name',
  'phone',
  'phoneDisplay',
];
