import { ReplaySubject } from 'rxjs';
import {
  AuthenticationService,
  CurrentClientService,
  FacilityService,
} from 'src/app/services';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

/**
 * A component wrapper that contains and displays navigation items in a
 * vertical, stacked menu nav-list.
 */
@Component({
  selector: 'alleva-menu[isCollapsed][menuItems]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly currentClientService: CurrentClientService,
    private readonly facilityService: FacilityService,
  ) {}

  /** Whether the menu should use the collapsed (mobile) view. */
  @Input() public set isCollapsed(value: BooleanInput) {
    this.isCollapsedSubject.next(coerceBooleanProperty(value));
  }
  private readonly isCollapsedSubject = new ReplaySubject<boolean>(1);
  protected readonly isCollapsedChanges =
    this.isCollapsedSubject.asObservable();

  /** The items to display in the menu. Required. */
  @Input() public menuItems!: readonly MenuItem[];

  /**
   * The currently logged in user. Fetch and pass only one for every
   * `alleva-menu` render and self-update reactively.
   */
  protected readonly currentUserChanges =
    this.authenticationService.userChanges;

  /**
   * The currently selected facility. Fetch and pass only one for every
   * `alleva-menu` render and self-update reactively.
   */
  protected readonly currentFacilityChanges =
    this.facilityService.currentFacilityChanges;

  /**
   * The currently selected client. Fetch and pass only one for every
   * `alleva-menu` render and self-update reactively.
   */
  protected readonly selectedClientChanges =
    this.currentClientService.selectedChanges;
}
