/**
 * These records are used to tie the page name to the route URL.
 */
export const ALLEVA_PAGES: Record<PageName, PageRoute> = {
  'Add Prospect': '/prospective/add',
  Alumni: '/alumni',
  'Billing Application': '/billing-offsite',
  'Billing Settings - Add Billing Rule': `/settings/billing/rules/add`,
  'Billing Settings - Codes': `/settings/billing/billing-codes`,
  'Billing Settings - Copy Billing Rule': `/settings/billing/rules/:id/copy`,
  'Billing Settings - Edit Billing Rule': `/settings/billing/rules/:id/edit`,
  'Billing Settings - Insurance Companies': `/settings/billing/insurance-companies`,
  'Billing Settings - Modifiers': `/settings/billing/modifiers`,
  'Billing Settings - Rev Codes': `/settings/billing/rev-codes`,
  'Billing Settings - Billing Rules': `/settings/billing/rules`,
  'Billing Settings': '/settings/billing',
  'Chef Dashboard': '/chef-dashboard',
  'Client Admission Screening': `/clients/:id/admission-screening`,
  'Client Billing Intake': `/clients/:id/billing-intake`,
  'Client Biopsychosocial Intake': `/clients/:id/biopsychosocial-intake`,
  'Client Calendar': `/clients/:id/calendar`,
  'Client Chart': `/clients/:id`,
  'Client Discharge Planning': `/clients/:id/discharge-planning`,
  'Client Doctors Orders': `/clients/:id/doctors-orders`,
  'Client Document Manager': `/clients/:id/document-manager`,
  'Client Edit': `/clients/:id/edit`,
  'Client Face to Face': `/clients/:id/face-to-face`,
  'Client Homework': `/clients/:id/homework`,
  'Client List': '/clients/list',
  'Client Meal Plan': `/clients/:id/meal-plan`,
  'Client Medical Intake': `/clients/:id/medical-intake`,
  'Client Medication Administration Record': `/clients/:id/medication-administration-record`,
  'Client Medication': `/clients/:id/medication`,
  'Client Notes': `/clients/:id/notes`,
  'Client Rec Therapy': `/clients/:id/rec-therapy`,
  'Client Treatment Plan': `/clients/:id/treatment-plan`,
  Dashboard: '/dashboard',
  'Discharge Reports': '/reports/discharge-reports',
  'Doctors Orders': '/doctors-orders',
  'Encounter Transmission': '/encounter-transmission',
  Forbidden: '/forbidden',
  'Historical Events': '/calendar/historical-events',
  Incidents: '/incidents',
  Insights: '/reports/insights',
  'Lab Orders': '/lab-orders',
  Login: '/login',
  'Medical Provider Scheduler': '/calendar/medical-provider-scheduler',
  'Medication Destruction': '/medications/destruction',
  'Medications Count': '/medications/count',
  'Medications Management': '/medications/management',
  'Meds Count Reports': '/reports/meds-count-reports',
  'Notes Audit': '/review-screen/notes-audit',
  'Notes Review': '/review-screen/notes-review',
  Notifications: '/notifications',
  Occupancy: '/occupancy',
  'Page Not Found (404)': '/not-found',
  'Progress Notes': '/progress-notes',
  'Prospect Client List': '/prospective/list',
  Referrals: '/referrals',
  'Restricted Access': '/restricted-access',
  'Sandbox Buttons': '/sandbox/buttons',
  'Sandbox Editor': '/sandbox/editor',
  'Sandbox Forms': '/sandbox/forms',
  'Sandbox Icons': '/sandbox/icons',
  'Sandbox Loading Indicators': '/sandbox/loading-indicators',
  'Sandbox Messages': '/sandbox/messages',
  'Sandbox Print': '/sandbox/print',
  'Sandbox Signatures': '/sandbox/signatures',
  'Sandbox Tables': '/sandbox/tables',
  Scheduler: '/calendar/scheduler',
  'Settings Admission Checklist': '/settings/admission-checklist',
  'Settings Alerts Frequency': '/settings/alerts-frequency',
  'Settings Alerts Permission': '/settings/alerts-permission',
  'Settings Alerts Template': '/settings/alerts-template',
  'Settings Bed Management': '/settings/beds',
  'Settings Client Transfer': '/settings/client-transfer',
  'Settings Company Information': '/settings/company-information',
  'Settings Daily Questionnaire': '/settings/daily-questionnaire',
  'Settings Dietary': '/settings/dietary',
  'Settings Digital Dashboard': '/settings/digital-dashboard',
  'Settings Family Portal': '/settings/family-portal',
  'Settings Forms 2.0 Manager': '/settings/forms-two',
  'Settings Forms 3.0 Manager': '/settings/advanced-forms',
  'Settings Forms Manager': '/settings/forms',
  'Settings Lab Management': '/settings/labs',
  'Settings Manage Discharge': '/settings/discharge',
  'Settings Manage Groups': '/settings/groups',
  'Settings Manage Intake': '/settings/intakes',
  'Settings Manage Survey': '/settings/surveys',
  'Settings Manage Treatment Plan Review': '/settings/treatment-plan-review',
  'Settings Master Drop-Downs': '/settings/master-dropdowns',
  'Settings Medical Provider': '/settings/medical-providers',
  'Settings Office Templates': '/settings/office-templates',
  'Settings Order': '/settings/orders',
  'Settings Pharmacy Management': '/settings/pharmacies',
  'Settings Profile': '/settings/profile',
  'Settings Role Management': '/settings/roles',
  'Settings Templates': '/settings/templates',
  'Settings User Management': '/settings/users',
  'Shift Notes': `/shifts/shift-notes`,
  'Shift Rounds': `/shifts/shift-rounds`,
  'Signature Review': '/review-screen/signature-review',
  'Utilization Review': '/utilization-review',
};
