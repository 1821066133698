import { DateTime } from 'luxon';

import { AfterContentChecked, Component, Input } from '@angular/core';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * An input component input for selecting and/or entering a date.
 *
 * @requires `formControl` attribute to be set.
 */
@Component({
  selector: 'alleva-input-pick-date[formControl]',
  templateUrl: './input-pick-date.component.html',
  styleUrls: ['./input-pick-date.component.scss'],
})
export class InputPickDateComponent
  extends CustomInputDirective<DateTime>
  implements AfterContentChecked
{
  /**
   * The hint to display for the form field.
   *
   * Defaults to `undefined`.
   */
  @Input() public override hint?: string;

  /**
   * The label to display for the form field.
   *
   * Defaults is `"Date"`. Pass `undefined` to hide the label.
   */
  @Input() public override label?: string = 'Date';

  /**
   * The maximum `DateTime` value allowed for the input.
   */
  @Input() public override max?: DateTime;

  /**
   * The minimum `DateTime` value allowed for the input.
   */
  @Input() public override min?: DateTime;

  /**
   * The placeholder to display for the form field.
   */
  @Input() public override placeholder = 'MM/DD/YYYY';

  /**
   * The date to start the calendar at on open.
   */
  @Input() public startAt?: DateTime;

  public ngAfterContentChecked(): void {
    // Add custom change detection to this component to ensure that the form
    // field validation is updated when the min/max validation values are
    // updated dynamically. We have to do this for min/max DateTime values
    // because they override the default `formControl` min/max number typing.
    this.changeDetectorRef.detectChanges();
  }
}
