import { VitalsHeightApi, VitalsHeightUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { HeightUnitsEnum } from 'src/app/enumerators';
import {
  decode,
  getCentimetersFrom,
  getFeetInchesFrom,
  isNonEmptyString,
} from 'src/app/utilities';

const api = apiDecorator<VitalsHeightApi>();

type VitalsHeightArgs = Omit<
  VitalsHeight,
  // Exclude computed properties
  'centimeters' | 'displayValue' | 'feet' | 'inches'
>;

export class VitalsHeight {
  public constructor(props: ClassProperties<VitalsHeightArgs>) {
    this.units = props.units;
    this.value = props.value;

    if (isNonEmptyString(this.value !== null) && this.units === null) {
      throw new Error(
        `The height value "${this.value}" was provided without a unit of measurement.`,
      );
    }

    switch (this.units) {
      case HeightUnitsEnum.CENTIMETERS: {
        this.displayValue = this.value
          ? `${this.value} ${HeightUnitsEnum.CENTIMETERS}`
          : null;
        this.centimeters = this.value;
        const ftInches = this.centimeters
          ? getFeetInchesFrom({ centimeters: this.centimeters })
          : null;
        this.feet = ftInches?.feet ?? null;
        this.inches = ftInches?.inches ?? null;
        break;
      }
      case HeightUnitsEnum.INCHES: {
        const ftInches = this.value
          ? getFeetInchesFrom({ inches: this.value })
          : null;
        this.centimeters = this.value
          ? getCentimetersFrom({ inches: this.value })
          : null;
        this.feet = ftInches?.feet ?? null;
        this.inches = ftInches?.inches ?? null;
        this.displayValue = this.value
          ? `${this.feet}' ${this.inches}" (${this.value} ${HeightUnitsEnum.INCHES})`
          : null;
        break;
      }
      case null: {
        break;
      }
      default: {
        throw new Error(`Invalid height units: ${this.units}`);
      }
    }
  }

  /**
   * The io-ts codec for runtime type checking of the Vitals Height API model.
   */
  public static readonly Codec = io.type(
    {
      units: io.union([
        io.literal(HeightUnitsEnum.CENTIMETERS),
        io.literal(HeightUnitsEnum.INCHES),
        io.null,
      ]),
      value: io.union([io.number, io.null]),
    },
    'VitalsHeightApi',
  );

  @api({ key: 'units' }) public readonly units: HeightUnitsEnum | null;
  @api({ key: 'value' }) public readonly value: number | null;

  public readonly centimeters: number | null = null;
  public readonly feet: number | null = null;
  public readonly inches: number | null = null;

  // Computed values
  public readonly displayValue: string | null = null;

  /**
   * Deserializes a Vitals Height object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Vitals Height object.
   * @throws An error if the value is not a valid Vitals Height object.
   */
  public static deserialize(value: NonNullable<VitalsHeightApi>): VitalsHeight {
    const decoded = decode(VitalsHeight.Codec, value);
    return new VitalsHeight(decoded);
  }

  /**
   * Deserializes a list of Vitals Height objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Vitals Height objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Vitals Height objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<VitalsHeightApi>>,
  ): readonly VitalsHeight[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Vitals Height objects.');
    }
    return values.map(VitalsHeight.deserialize);
  }
}

export class VitalsHeightUpdate extends VitalsHeight {
  public constructor(props: ClassProperties<VitalsHeightArgs>) {
    super(props);
  }

  public serialize(): VitalsHeightUpdateApi {
    if (this.value === null) {
      throw new Error('You must provide a value for the height.');
    }

    return {
      units: this.units,
      value: this.value,
    };
  }
}
