import { ClientContactPhoneApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientContactPhoneApi>();

export class ClientContactPhone {
  public constructor(props: ClassProperties<ClientContactPhone>) {
    this.mobile = props.mobile;
    this.office = props.office;
    this.other = props.other;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Contact Phone
   * API model.
   */
  public static readonly Codec = io.type(
    {
      mobile: io.union([io.string, io.null]),
      office: io.union([io.string, io.null]),
      other: io.union([io.string, io.null]),
    },
    'ClientContactPhoneApi',
  );

  @api({ key: 'mobile' }) public readonly mobile: string | null;
  @api({ key: 'office' }) public readonly office: string | null;
  @api({ key: 'other' }) public readonly other: string | null;

  /**
   * Deserializes a Client Contact Phone object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Contact Phone object.
   * @throws An error if the value is not a valid Client Contact Phone object.
   */
  public static deserialize(
    value: NonNullable<ClientContactPhoneApi>,
  ): ClientContactPhone {
    const decoded = decode(ClientContactPhone.Codec, value);
    return new ClientContactPhone({
      ...decoded,
    });
  }
}
