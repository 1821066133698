<mat-form-field [appearance]="'outline'">
  <mat-label *ngIf="label as inputLabel">{{ inputLabel }}</mat-label>
  <mat-select
    #matSelect
    [ariaLabel]="label"
    [compareWith]="compareWith"
    [formControl]="baseControl"
    [multiple]="multiple"
  >
    <!-- SEARCH CONTAINER -->
    <div class="input-container">
      <input
        #searchInput
        (input)="filter(searchInput.value)"
        (keydown)="handleFilterKeyDown($event)"
        [placeholder]="placeholder || ''"
        type="text"
      />
    </div>
    <!-- Dispaly Value(s) -->
    <mat-select-trigger>
      @if (!multiple && matSelect.value) {
        {{ getDisplayValue(matSelect.value) || '—' }}
      } @else if (matSelect.value?.length === 1) {
        {{ getDisplayValue(matSelect.value[0]) }}
      } @else if (matSelect.value?.length > 1) {
        {{ matSelect.value.length }} selected
      } @else {
        No values selected
      }
    </mat-select-trigger>
    <!-- CLEAR ALL OPTION -->
    <div class="clear-select-all-container">
      <button
        (click)="clear()"
        *ngIf="isClearable"
        [disabled]="!matSelect.value?.length || baseControl.disabled"
        class="clear-dropdown-option"
        label="Clear Dropdown"
        mat-button
      >
        <alleva-icon color="warn" name="close"></alleva-icon>
        {{ clearLabel || 'Clear' }}
      </button>
    </div>
    <mat-option
      *ngFor="let option of listChanges | async"
      [hidden]="!(filteredListChanges | async)?.includes(option)"
      [value]="option"
    >
      <span class="label">
        {{ getDisplayValue(option) }}
      </span>
    </mat-option>
    <mat-option
      *ngIf="
        searchInput.value.length > 0 &&
        (filteredListChanges | async)?.length === 0
      "
      [value]="null"
      disabled
    >
      No results found.
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
  </mat-error>
</mat-form-field>

<ng-template #loadingTemplate>
  <alleva-loading-text [label]="label" type="input"></alleva-loading-text>
</ng-template>
