import { Permission } from 'src/app/constants';
import { AuthenticatedUser } from 'src/app/models';
import { AlertService, AuthenticationService } from 'src/app/services';
import { getFirstNonEmptyValueFrom } from 'src/app/utilities';

import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/**
 * A directive that shows or hides an element based on the user's role and
 * permissions.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
  ) {}

  private elseTemplateRef: TemplateRef<unknown> | undefined;
  private permission: Permission | undefined;
  private user: AuthenticatedUser | undefined;

  /**
   * The permission to check for. If the user has this permission, the element
   * will be rendered. If the user does not have this permission, the element
   * will not be rendered.
   */
  @Input() public set hasPermission(permission: Permission) {
    this.permission = permission;
  }

  /**
   * The template to render if the user does not have the proper permissions.
   */
  @Input() public set hasPermissionElse(elseTemplateRef: TemplateRef<unknown>) {
    this.elseTemplateRef = elseTemplateRef;
  }

  public async ngOnInit(): Promise<void> {
    // Clear the view container initially.
    this.viewContainer.clear();

    // Get the user data asynchonously.
    this.user = await getFirstNonEmptyValueFrom(
      this.authenticationService.userChanges,
    );

    // Ensure the user's permissions are available for us to check.
    const arePermissionsAvailable =
      this.user?.permissions !== undefined && this.permission;

    if (arePermissionsAvailable) {
      // User permissions are available, render the element based on them.
      this.render();
    } else {
      // User permissions are not available, show an error.
      this.alertService.error({
        message: `Failed to check the user's permissions for the "${
          this.permission || 'not-found'
        }" permission.`,
      });
    }
  }

  /** The method that renders the element based on the user's permissions. */
  private render(): void {
    if (this.permission === undefined || this.user === undefined) {
      return;
    }

    if (this.user.hasPermission(this.permission)) {
      // Has permission or is admin, allow the element to be rendered.
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseTemplateRef) {
      // Does not have permission but there's an else template available,
      // render the else template.
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    } else {
      // Does not have permission and there's no else template available,
      // clear the view container (do not render anything).
      this.viewContainer.clear();
    }
  }
}
