import { NameIdApi, NameIdUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<NameIdApi>();

export class NameId {
  public constructor(props: ClassProperties<NameId>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the ID/Name API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.union([io.string, io.null]),
    },
    'NameIdApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a ID/Name object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized ID/Name object.
   * @throws An error if the value is not a valid ID/Name object.
   */
  public static deserialize(value: NonNullable<NameIdApi>): NameId {
    const decoded = decode(NameId.Codec, value);
    return new NameId(decoded);
  }

  /**
   * Deserializes a list of ID/Name objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized ID/Name objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid ID/Name objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<NameIdApi>>,
  ): readonly NameId[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of ID/Name objects.');
    }
    return values.map(NameId.deserialize);
  }
}

export class NameIdUpdate extends NameId {
  public serialize(): NameIdUpdateApi {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
