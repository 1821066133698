import {
  BooleanInput,
  coerceBooleanProperty,
  coerceElement,
} from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'alleva-input-dropdown-option[value]',
  template: `<ng-content></ng-content>`,
})
export class InputDropdownOptionComponent<T> {
  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @Input() public class?: string;

  @HostBinding('class.disabled')
  @Input()
  public set disabled(value: BooleanInput) {
    this.#disabled = coerceBooleanProperty(value);
  }
  public get disabled(): boolean {
    return this.#disabled;
  }
  #disabled = false;

  @Input() public fontColor?: string;

  @Input() public iconName?: IconName;

  @Input() public set value(value: T) {
    this.#value = value;
  }
  public get value(): T {
    return this.#value;
  }
  #value!: T;

  public get element(): HTMLElement {
    return coerceElement(this.elementRef);
  }
}
