import { ClientVitalsApi, ClientVitalsUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Time } from 'src/app/models/core/time.model';
import { Facility } from 'src/app/models/facility/facility.model';
import {
  VitalsBloodPressure,
  VitalsBloodPressureUpdate,
} from 'src/app/models/vitals/vitals-blood-pressure.model';
import {
  VitalsBloodSugar,
  VitalsBloodSugarUpdate,
} from 'src/app/models/vitals/vitals-blood-sugar.model';
import {
  VitalsBMI,
  VitalsBMIUpdate,
} from 'src/app/models/vitals/vitals-bmi.model';
import {
  VitalsHeight,
  VitalsHeightUpdate,
} from 'src/app/models/vitals/vitals-height.model';
import {
  VitalsTemperature,
  VitalsTemperatureUpdate,
} from 'src/app/models/vitals/vitals-temperature.model';

const api = apiDecorator<ClientVitalsApi>();

type ClientVitalsArgs = Omit<
  ClientVitals,
  // Exclude computed properties
  | 'bloodSugarDisplay'
  | 'bloodSugarWitnessDisplay'
  | 'bmiDisplay'
  | 'bodyTempDisplay'
  | 'oxygenSatDisplay'
  | 'pulseDisplay'
  | 'respirationsDisplay'
  | 'supplementalOxygenFlowRateDisplay'
  | 'systolicDiastolicDisplay'
  | 'time'
  | 'weightDisplay'
>;

abstract class ClientVitalsBase {
  public constructor(props: ClassProperties<ClientVitalsBase>) {
    this.dateTime = props.dateTime;
    this.oxygenSat = props.oxygenSat;
    this.pain = props.pain;
    this.pulse = props.pulse;
    this.respirations = props.respirations;
    this.signature = props.signature;
    this.signedBy = props.signedBy;
    this.supplementalOxygenFlowRate = props.supplementalOxygenFlowRate;
    this.weightLbs = props.weightLbs;
  }

  @api({ key: 'date' }) public readonly dateTime: DateTime;
  @api({ key: 'oxygenSat' }) public readonly oxygenSat: number | null;
  @api({ key: 'pain' }) public readonly pain: number | null;
  @api({ key: 'pulse' }) public readonly pulse: number | null;
  @api({ key: 'respirations' }) public readonly respirations: number | null;
  @api({ key: 'signature' }) public readonly signature: Base64<'png'> | null;
  @api({ key: 'signedBy' }) public readonly signedBy: string | null;
  @api({ key: 'supplementalOxygenFlowRate' })
  public readonly supplementalOxygenFlowRate: number | null;
  @api({ key: 'weight' }) public readonly weightLbs: number | null;
}

export class ClientVitals extends ClientVitalsBase {
  public constructor(props: ClassProperties<ClientVitalsArgs>) {
    super(props);

    this.bloodPressure = props.bloodPressure;
    this.bloodSugar = props.bloodSugar;
    this.bmi = props.bmi;
    this.height = props.height;
    this.id = props.id;
    this.temperature = props.temperature;

    // Computed properties
    this.bloodSugarDisplay = props.bloodSugar
      ? `${props.bloodSugar.value} mg/dL`
      : '— mg/dL';
    this.bloodSugarWitnessDisplay =
      props.bloodSugar && props.bloodSugar.witness
        ? props.bloodSugar.witness
        : '—';
    this.bmiDisplay = props.bmi ? props.bmi.displayValue.toString() : '—';
    this.bodyTempDisplay =
      props.temperature && props.temperature.value && props.temperature.units
        ? `${props.temperature.value} °${props.temperature.units}`
        : `—`;
    this.oxygenSatDisplay = props.oxygenSat ? `${props.oxygenSat}%` : '—%';
    this.pulseDisplay = props.pulse ? `${props.pulse} bpm` : '— bpm';
    this.respirationsDisplay = props.respirations
      ? `${props.respirations} /min`
      : '— /min';
    this.supplementalOxygenFlowRateDisplay = props.supplementalOxygenFlowRate
      ? `${props.supplementalOxygenFlowRate} L/min`
      : '— L/min';
    this.systolicDiastolicDisplay = this.bloodPressure
      ? `${this.bloodPressure.systolic}/${this.bloodPressure.diastolic}`
      : '—/—';
    this.time = Time.fromDateTime(props.dateTime);
    // @future - Make units dynamic (lbs/kg).
    this.weightDisplay = props.weightLbs ? `${props.weightLbs} lbs` : '— lbs';
  }

  /**
   * The io-ts codec for runtime type checking of the Client Vitals API model.
   */
  public static readonly Codec = io.type(
    {
      bloodPressure: io.union([VitalsBloodPressure.Codec, io.null]),
      bloodSugar: io.union([VitalsBloodSugar.Codec, io.null]),
      bmi: io.union([VitalsBMI.Codec, io.null]),
      date: io.string,
      height: io.union([VitalsHeight.Codec, io.null]),
      id: io.number,
      oxygenSat: io.union([io.number, io.null]),
      pain: io.union([io.number, io.null]),
      pulse: io.union([io.number, io.null]),
      respirations: io.union([io.number, io.null]),
      signature: io.union([io.string, io.null]),
      signedBy: io.union([io.string, io.null]),
      supplementalOxygenFlowRate: io.union([io.number, io.null]),
      temperature: io.union([VitalsTemperature.Codec, io.null]),
      weight: io.union([io.number, io.null]),
    },
    'ClientVitalsApi',
  );

  @api({ key: 'bloodPressure' })
  public readonly bloodPressure: VitalsBloodPressure | null;
  @api({ key: 'bloodSugar' })
  public readonly bloodSugar: VitalsBloodSugar | null;
  @api({ key: 'bmi' }) public readonly bmi: VitalsBMI | null;
  @api({ key: 'height' }) public readonly height: VitalsHeight | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'temperature' })
  public readonly temperature: VitalsTemperature | null;

  // Computed properties
  public readonly bloodSugarDisplay: string;
  public readonly bloodSugarWitnessDisplay: string;
  public readonly bmiDisplay: string;
  public readonly bodyTempDisplay: string;
  public readonly oxygenSatDisplay: string;
  public readonly pulseDisplay: string;
  public readonly respirationsDisplay: string;
  public readonly supplementalOxygenFlowRateDisplay: string;
  public readonly systolicDiastolicDisplay: string;
  public readonly time: Time;
  public readonly weightDisplay: string;

  /**
   * Deserializes a Client Vitals object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Vitals object.
   * @throws An error if the value is not a valid Client Vitals object.
   */
  public static deserialize(
    value: NonNullable<ClientVitalsApi>,
    deserializationArgs: ClientVitalsDeserializationArgs,
  ): ClientVitals {
    const decoded = decode(ClientVitals.Codec, value);
    return new ClientVitals({
      bloodPressure: decoded.bloodPressure
        ? VitalsBloodPressure.deserialize(decoded.bloodPressure)
        : null,
      bloodSugar: decoded.bloodSugar
        ? VitalsBloodSugar.deserialize(decoded.bloodSugar)
        : null,
      bmi: decoded.bmi ? VitalsBMI.deserialize(decoded.bmi) : null,
      dateTime: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.timeZone,
      }),
      height: decoded.height ? VitalsHeight.deserialize(decoded.height) : null,
      id: decoded.id,
      oxygenSat: decoded.oxygenSat,
      pain: decoded.pain,
      pulse: decoded.pulse,
      respirations: decoded.respirations,
      signature: decoded.signature
        ? (decoded.signature as Base64<'png'>)
        : null,
      signedBy: decoded.signedBy,
      supplementalOxygenFlowRate: decoded.supplementalOxygenFlowRate,
      temperature: decoded.temperature
        ? VitalsTemperature.deserialize(decoded.temperature)
        : null,
      weightLbs: decoded.weight,
    });
  }

  /**
   * Deserializes a list of Client Vitals objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Vitals objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Vitals objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientVitalsApi>>,
    deserializationArgs: ClientVitalsDeserializationArgs,
  ): readonly ClientVitals[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Vitals objects.');
    }
    return values.map((value) =>
      ClientVitals.deserialize(value, deserializationArgs),
    );
  }
}

export interface ClientVitalsDeserializationArgs {
  timeZone: Facility['timeZone'];
}

export class ClientVitalsUpdate extends ClientVitalsBase {
  public constructor(props: ClassProperties<ClientVitalsUpdate>) {
    super(props);

    this.bloodPressure = props.bloodPressure;
    this.bloodSugar = props.bloodSugar;
    this.bmi = props.bmi;
    this.height = props.height;
    this.temperature = props.temperature;
  }

  @api({ key: 'bloodPressure' })
  public readonly bloodPressure: VitalsBloodPressureUpdate | null;
  @api({ key: 'bloodSugar' })
  public readonly bloodSugar: VitalsBloodSugarUpdate | null;
  @api({ key: 'bmi' }) public readonly bmi: VitalsBMIUpdate | null;
  @api({ key: 'height' }) public readonly height: VitalsHeightUpdate | null;
  @api({ key: 'temperature' })
  public readonly temperature: VitalsTemperatureUpdate | null;

  public serialize(): ClientVitalsUpdateApi {
    return {
      bloodPressure: this.bloodPressure ? this.bloodPressure.serialize() : null,
      bloodSugar: this.bloodSugar ? this.bloodSugar.serialize() : null,
      bmi: this.bmi ? this.bmi.serialize() : null,
      date: this.dateTime.toISO()!,
      height: this.height ? this.height.serialize() : null,
      oxygenSat: this.oxygenSat,
      pain: this.pain,
      pulse: this.pulse,
      respirations: this.respirations,
      signature: this.signature,
      signedBy: this.signedBy,
      supplementalOxygenFlowRate: this.supplementalOxygenFlowRate,
      temperature: this.temperature ? this.temperature.serialize() : null,
      weight: this.weightLbs,
    };
  }
}
