import { DateTime } from 'luxon';
import { ReplaySubject, map, shareReplay } from 'rxjs';
import { Time } from 'src/app/models';
import { AlertService } from 'src/app/services';
import {
  getFirstNonEmptyValueFrom,
  getNestedObjectData,
  isNonEmptyString,
} from 'src/app/utilities';

import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

/** The collapse/expand animation duration for expandable/collapsible rows. */
const animationDuration = 100;

/**
 * A dynamic table component that displays a configurable list of items.
 *
 * @requires `columns` attribute to be set.
 * @requires `data` attribute to be set.
 */
@Component({
  selector: 'alleva-table[columns][data]',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('collapseAnimation', [
      state('expanded', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('collapsed', style({ height: '0', visibility: 'hidden' })),
      transition(
        'expanded => collapsed',
        animate(`${animationDuration}ms ease-in`),
      ),
      transition(
        'collapsed => expanded',
        animate(`${animationDuration}ms ease-out`),
      ),
    ]),
    trigger('exitAnimation', [
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(
          `${animationDuration}ms`,
          style({ transform: 'translateX(100%)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class TableComponent<T extends object> implements OnInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly clipboard: Clipboard,
    private readonly elementRef: ElementRef,
  ) {}
  /**
   * The theme palette color to use for this button.
   * Can be `primary`, `accent`, or `warn`, `neutral`, or `none`.
   *
   * Note: `neutral` is a gray color, and `none` is no color.
   */
  @Input()
  @HostBinding('class')
  public color: ThemePalette | 'neutral' | 'none' = 'primary';

  /**
   * The list of columns to display in the table.
   */
  @Input() public set columns(value: ReadonlyArray<TableColumn<T>>) {
    this.columnsSubject.next(value);
  }
  private readonly columnsSubject = new ReplaySubject<
    ReadonlyArray<TableColumn<T>>
  >(1);
  protected readonly columnsChanges = this.columnsSubject.asObservable();
  protected readonly columnKeysChanges = this.columnsSubject.pipe(
    map((columns) => columns.map((column) => column.key)),
  );

  /**
   * The list of items to display in the table.
   */
  @Input() public set data(values: readonly T[] | null | undefined) {
    const data =
      values === null || values === undefined
        ? values
        : new MatTableDataSource([...values]);
    this.dataSubject.next(data);
  }
  private readonly dataSubject = new ReplaySubject<
    MatTableDataSource<T> | null | undefined
  >(1);
  protected readonly dataChanges = this.dataSubject
    .asObservable()
    .pipe(shareReplay(1));

  /**
   * The placeholder to display when the table item is empty.
   */
  @Input() public emptyCellValuePlaceholder = '';

  /**
   * The placeholder to display when the provided list (`data`) for the table
   * is empty.
   */
  @Input() public emptyListPlaceholder = 'No items to display.';

  /**
   * Whether or not the table header should stick to the top of the parent
   * element when scrolling.
   */
  @Input() public hasStickyHeader = false;

  /**
   * Whether or not every nth row (even) should have a background color.
   */
  @Input() @HostBinding('class.nth-row-highlight') public isNthRowHighlighted =
    false;

  /**
   * Whether or not the table should emit a row click event and highlight the
   * row on hover.
   */
  @Input() @HostBinding('class.enable-row-click') public isRowClickEnabled =
    false;

  /**
   * Whether or not the table should be slim.
   */
  @Input() @HostBinding('class.slim') public isSlim = false;

  /**
   * Emits the row data that was clicked.
   */
  @Output() public readonly rowClick = new EventEmitter<T>();

  /**
   * The currently expanded table row element.
   */
  protected set expandableObject(
    value: TableTemplateRefExpandableObject | null,
  ) {
    if (value !== null && !this.isTableTemplateRefExpandableObject(value)) {
      throw new Error(
        'The expandable value must be of type TableTemplateRefExpandableObject.',
      );
    }
    this.#expandableObject = value;
  }
  protected get expandableObject(): TableTemplateRefExpandableObject | null {
    return this.#expandableObject;
  }
  #expandableObject: TableTemplateRefExpandableObject | null = null;

  @HostBinding('class.expandable')
  private isRowExpansionEnabled = false;

  public async ngOnInit(): Promise<void> {
    // Get reference to the table data source.
    const matTableDataSource = await getFirstNonEmptyValueFrom(
      this.dataChanges,
    );
    // Determine if the table is expandable or not based on if
    // expandable row content is provided in the data.
    this.isRowExpansionEnabled = matTableDataSource?.data.some((row) => {
      for (const key in row) {
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          if (this.isTableTemplateRefExpandableObject(row[key])) {
            return true;
          }
        }
      }
      return false;
    });
  }

  public scrollTop({ offset = 0 }: { offset?: number } = {}): void {
    const topOfTableWithOffset =
      this.elementRef.nativeElement.getBoundingClientRect().top +
      window.scrollY -
      offset;
    window.scrollTo({ top: topOfTableWithOffset, behavior: 'smooth' });
  }

  /**
   * Retrieves and formats data for display in a table cell. This method
   * ensures that data values which are naturally falsy in JavaScript (like 0
   * and false) are handled in such a way that they are displayed correctly
   * when using Angular's *ngIf directive, which does not render for falsy
   * values.
   *
   * @param dataSourceItem The data item or date object from the data source.
   * @param columnKey The key of the column to check in the data item.
   * @returns The formatted data suitable for display, including handling for
   * zero, booleans, and null values.
   */
  protected getData(
    dataSourceItem: T,
    columnKey: NestedKeysOfString<T>,
  ): DefinedPrimitive | object | Date | DateTime | Time {
    const data = getNestedObjectData<T>(dataSourceItem, columnKey);

    if (data === 0) {
      // Return '0' as a string to ensure it gets displayed with ngIf.
      return '0';
    }

    if (typeof data === 'boolean') {
      // Return 'True' or 'False' strings for boolean values. This ensure that
      // `false` is displayed with ngIf.
      return data ? 'True' : 'False';
    }

    if (data === null) {
      // Data is null, return the empty cell value.
      return this.emptyCellValuePlaceholder;
    }

    return data;
  }

  protected getDate(value: unknown): Date | null {
    return value instanceof Date ? value : null;
  }

  protected getNestedObjectData(
    data: T,
    stringKeys: NestedKeysOfString<T>,
  ): NonNullable<Primitive | object> | null {
    return (
      getNestedObjectData<T>(data, stringKeys) ?? this.emptyCellValuePlaceholder
    );
  }

  protected getTime(value: unknown): Time | null {
    return value instanceof Time ? value : null;
  }

  protected getTypeOf(value: unknown): 'date' | 'datetime' | 'time' | string {
    if (value instanceof Date) {
      return 'date';
    } else if (value instanceof DateTime) {
      return 'datetime';
    } else if (value instanceof Time) {
      return 'time';
    } else {
      return typeof value;
    }
  }

  protected isNonEmptyObject(value: unknown): value is object {
    return value instanceof Object && Object.keys(value).length > 0;
  }

  protected isRowExpanded(
    rowObjectData: T,
    expandableObject: TableTemplateRefExpandableObject | null,
  ): boolean {
    if (!this.isRowExpansionEnabled || expandableObject === null) {
      return false;
    }
    for (const key in rowObjectData) {
      if (Object.prototype.hasOwnProperty.call(rowObjectData, key)) {
        const data = rowObjectData[key];
        if (data === expandableObject) {
          return true;
        }
      }
    }
    return false;
  }

  protected isTableTemplateRefObject(
    value: unknown,
  ): value is TableTemplateRefObject {
    return (
      value instanceof Object &&
      'context' in value &&
      'templateRef' in value &&
      !('isExpanded' in value)
    );
  }

  protected isTableTemplateRefExpandableObject(
    value: unknown,
  ): value is TableTemplateRefExpandableObject {
    return (
      value instanceof Object &&
      'context' in value &&
      'templateRef' in value &&
      'isExpanded' in value
    );
  }

  protected onRowClick(
    rowObjectData: T,
    event: MouseEvent,
    column?: TableColumn<T>,
    item?: HTMLTableCellElement,
  ): void {
    event.stopPropagation();

    if (column?.isClickToCopyEnabled && !!item) {
      this.copyToClipboard(item);
      return;
    }

    if (!this.isRowClickEnabled) {
      return;
    }

    this.rowClick.emit(rowObjectData);
  }

  private copyToClipboard(item: HTMLTableCellElement): void {
    const content = item.innerHTML
      // Remove all `<alleva-icon>...</alleva-icon>` elements from the inner
      // HTML and everything in between. We don't want to copy icons or their
      // names to the clipboard since they're not useful to the user.
      .replace(/<alleva-icon[^>]*>.*<\/alleva-icon>/g, '')
      // Strip out all remaining html, keep inner text.
      .replace(/<[^>]*>/g, '')
      .trim();

    if (!isNonEmptyString(content)) {
      throw new Error('Failed to copy empty string to the clipboard!');
    }

    const isCopied = this.clipboard.copy(content);

    if (!isCopied) {
      this.alertService.error({
        message: `Failed to copy "${content}" to clipboard!`,
      });
      return;
    }

    this.alertService.success({
      message: `Copied "${content}" to clipboard!`,
    });
  }
}

export interface TableColumn<T extends object> {
  /**
   * The key to access the column data from the row object.
   */
  key: NestedKeysOfString<T>;
  /**
   * The label to display for the column.
   */
  label: string;
  /**
   * Whether or not the displayed column data is copyable to the clipboard.
   */
  isClickToCopyEnabled?: boolean;
  /**
   * The minimum width of the column in pixels.
   */
  minWidthPx?: number;
}
