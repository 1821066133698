<form [formGroup]="signMePinForm" *ngIf="!isSumbitting; else loading">
  <mat-form-field [appearance]="'outline'" subscriptSizing="dynamic">
    <input
      #digit1
      (keyup)="onPinKeyUp($event, SignMePinDigitEnum.DIGIT1)"
      [formControl]="signMePinForm.controls['digit1']"
      autofocus
      matInput
      max="9"
      maxlength="1"
      min="0"
      minlength="1"
      placeholder="X"
      type="number"
    />
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" subscriptSizing="dynamic">
    <input
      #digit2
      (keyup)="onPinKeyUp($event, SignMePinDigitEnum.DIGIT2)"
      [formControl]="signMePinForm.controls['digit2']"
      matInput
      max="9"
      maxlength="1"
      min="0"
      minlength="1"
      placeholder="X"
      type="number"
    />
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" subscriptSizing="dynamic">
    <input
      #digit3
      (keyup)="onPinKeyUp($event, SignMePinDigitEnum.DIGIT3)"
      [formControl]="signMePinForm.controls['digit3']"
      matInput
      max="9"
      maxlength="1"
      min="0"
      minlength="1"
      placeholder="X"
      type="number"
    />
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" subscriptSizing="dynamic">
    <input
      #digit4
      (keyup)="onPinKeyUp($event, SignMePinDigitEnum.DIGIT4)"
      [formControl]="signMePinForm.controls['digit4']"
      matInput
      max="9"
      maxlength="1"
      min="0"
      minlength="1"
      placeholder="X"
      type="number"
    />
  </mat-form-field>
</form>

<ng-template #loading>
  <div class="loading">Loading...</div>
</ng-template>
