import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';
import { InputSignMeCanvasComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-canvas.component';
import { InputSignMeControlsComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-controls.component';
import { InputSignMePinComponent } from 'src/app/components/forms/input-sign-me/input-sign-me-pin.component';

/**
 * A component that allows a user to sign their name digitally.
 *
 * @output - The base64 encoded image (png or jpeg) of the signature.
 * @supports - All browsers that support the canvas element.
 * @supports - Mouse signatures + touch screen signatures.
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-sign-me[formControl][label]',
  templateUrl: './input-sign-me.component.html',
  styleUrls: ['./input-sign-me.component.scss'],
})
export class InputSignMeComponent
  extends CustomInputDirective<Base64<'png'>>
  implements AfterViewInit
{
  protected showPin = false;
  protected readonly Validators = Validators;

  @ViewChild(InputSignMeCanvasComponent, { static: false })
  private readonly signMeCanvasComponent?: InputSignMeCanvasComponent;

  @ViewChild(InputSignMeControlsComponent, { static: false })
  private readonly signMeControlsComponent?: InputSignMeControlsComponent;

  @ViewChild(InputSignMePinComponent, { static: false })
  private readonly signMePinComponent?: InputSignMePinComponent;

  /**
   * The appearance variant of the sign me component.
   *
   * @default undefined Defaults to `undefined`.
   */
  @Input()
  @HostBinding('class')
  public appearance: InputSignMeAppearance | undefined;

  /**
   * The placeholder text to display when the signature is empty.
   */
  @Input() public override placeholder? = 'Please sign here...';

  @Input()
  public set enablePin(value: BooleanInput) {
    this.#enablePin = coerceBooleanProperty(value);
  }
  public get enablePin(): boolean {
    return this.#enablePin;
  }
  #enablePin = false;

  public ngAfterViewInit(): void {
    // When the form control value changes, update the canvas value.
    this.baseControl.valueChanges
      .pipe(startWith(this.baseControl.value), untilDestroyed(this))
      .subscribe((value) => {
        if (!this.signMeCanvasComponent) {
          throw new Error('Missing InputSignMeCanvasComponent');
        } else if (value) {
          // When the form control is set, set the canvas value.
          this.value = value;
          this.signMeCanvasComponent.value = value;
        } else {
          // When the form control is cleared, clear the canvas.
          this.signMeCanvasComponent.clear();
          this.value = null;
        }
      });

    // When the canvas value changes, update the form control value.
    this.signMeCanvasComponent?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        // Update the form control value.
        this.baseControl.setValue(value, { emitEvent: false });
        this.value = value;

        if (value) {
          this.baseControl.markAsDirty();
          this.baseControl.markAsTouched();
        }
      });
  }

  /**
   * Clear the signature.
   */
  protected clear(): void {
    this.signMeCanvasComponent?.clear();
    this.signMePinComponent?.clearAndCancel();
    this.baseControl.setValue(null);
  }

  /**
   * When the signature is updated from the PIN component, update the form control value.
   *
   * @param value
   */
  protected onSignatureFromPINUpdate(value: Base64<'png'> | null): void {
    // These should always be defined, but just in case we will check.
    if (!this.signMeControlsComponent) {
      throw new Error('Missing InputSignMeControlsComponent');
    } else if (!this.signMeCanvasComponent) {
      throw new Error('Missing InputSignMeCanvasComponent');
    }

    if (value !== null) {
      // Update the form control value with the saved user signature retrieved
      // from the PIN component.
      this.value = value;
      this.baseControl.setValue(value, { emitEvent: false });
      this.signMeCanvasComponent.clear();
      this.signMeCanvasComponent.value = value;
    }

    this.signMeControlsComponent.isEnteringPin = false;
    this.showPin = false;
  }
}
