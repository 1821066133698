import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { InputAutocompleteComponent } from 'src/app/components/forms/input-autocomplete/input-autocomplete.component';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingTextModule } from 'src/app/components/loading-text/loading-text.component.module';

import { DirectivesModule } from 'src/app/directives/directives.module';

@NgModule({
  declarations: [InputAutocompleteComponent],
  exports: [InputAutocompleteComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    DirectivesModule,
    FormsModule,
    IconModule,
    LoadingTextModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class InputAutocompleteModule {}
