import { PhoneApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<PhoneApi>();

export class Phone {
  public constructor(props: ClassProperties<Phone>) {
    this.fax = props.fax;
    this.home = props.home;
    this.mobile = props.mobile;
    this.office = props.office;
    this.other = props.other;
  }

  public static readonly Codec = io.type(
    {
      fax: io.union([io.string, io.null, io.undefined]),
      home: io.union([io.string, io.null]),
      mobile: io.union([io.string, io.null, io.undefined]),
      office: io.union([io.string, io.null]),
      other: io.union([io.string, io.null]),
    },
    'PhoneApi',
  );

  private static readonly api = apiDecorator<PhoneApi>();

  @api({ key: 'fax' }) public readonly fax: string | null;
  @api({ key: 'home' }) public readonly home: string | null;
  @api({ key: 'mobile' }) public readonly mobile: string | null;
  @api({ key: 'office' }) public readonly office: string | null;
  @api({ key: 'other' }) public readonly other: string | null;

  /**
   * Deserializes a Phone object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Phone object.
   * @throws An error if the value is not a valid Phone object.
   */
  public static deserialize(value: NonNullable<PhoneApi>): Phone {
    const decoded = decode(Phone.Codec, value);
    return new Phone({
      ...decoded,
      fax: decoded.fax ?? null,
      mobile: decoded.mobile ?? null,
    });
  }

  /**
   * Deserializes a list of Phone objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Phone objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Phone objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<PhoneApi>>,
  ): readonly Phone[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Phone objects.');
    }
    return values.map(Phone.deserialize);
  }
}
