import { DateTime } from 'luxon';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isNumber } from 'src/app/utilities/type-check';

export class CustomValidators {
  public static maxDateTime(value: DateTime): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control.value &&
      control.value instanceof DateTime &&
      control.value > value
        ? { max: { max: value, actual: control.value } }
        : null;
  }

  public static minDateTime(value: DateTime): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control.value &&
      control.value instanceof DateTime &&
      control.value < value
        ? { min: { min: value, actual: control.value } }
        : null;
  }

  public static nonString(control: AbstractControl): ValidationErrors | null {
    return control.value && typeof control.value !== 'string'
      ? null
      : { nonString: true };
  }

  public static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = !isNaN(Number(control.value));
      return valid ? null : { nonNumber: true };
    };
  }

  public static wholeNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!isNumber(value)) {
        return null;
      }
      const isWholeNumber = Number.isInteger(Number(value));
      return isWholeNumber ? null : { wholeNumber: true };
    };
  }
}
