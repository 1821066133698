import { DateTime } from 'luxon';
import { Time } from 'src/app/models';

import { isNonEmptyPrimitive } from 'src/app/utilities/type-check';

/**
 * Validate that the value is an object.
 *
 * @param firstObject The first object to compare.
 * @param secondObject The second object to compare.
 * @returns True if the objects are equal, false otherwise.
 */
export function areObjectsEqual<T extends object>(
  firstObject: T | null | undefined,
  secondObject: T | null | undefined,
): boolean {
  if (firstObject === secondObject) {
    // If both objects are the same object, they are equal.
    return true;
  } else if (!firstObject || !secondObject) {
    // If either object is null or undefined, they are not equal.
    return false;
  }

  const keys1 = Object.keys(firstObject) as Array<keyof T>;
  const keys2 = Object.keys(secondObject) as Array<keyof T>;

  // Make sure both objects have the same number of keys.
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || firstObject[key] !== secondObject[key]) {
      // If the second object does not have the same key or the values are not
      // equal, the objects are not equal.
      return false;
    }
  }

  return true;
}

/**
 * Extract nested primitive/object data from an `object` using a `string` key.
 *
 * @param data The object to extract data from.
 * @param stringKeys The key of the property to extract data from. Supports
 * nested keys. Example keys: 'name' or 'name.first' using dot notation.
 * @future Improve return typing to be specific to the data type returned by key.
 * @returns The value of the property or null if the property does not exist.
 */
export function getNestedObjectData<T>(
  data: T,
  stringKeys: NestedKeysOfString<T>,
): DefinedPrimitive | object | Date | DateTime | Time | null {
  const keys = stringKeys.split('.');
  let value: unknown = data;

  for (const key of keys) {
    if (
      value instanceof Object &&
      Object.prototype.hasOwnProperty.call(value, key)
    ) {
      value = value[key as keyof typeof value];
    }
  }

  if (isNonEmptyPrimitive(value) || typeof value === 'object') {
    return value;
  }

  return null;
}

/**
 * Compares two objects by a specified key.
 *
 * @param object The object to compare.
 * @param otherObject The other value to compare against.
 * @returns `true` if the specified key on the object matches the same key's
 * value on the currentValue.
 */
export function keyComparator<K extends string>(
  object: Record<K, unknown>,
  otherObject: Record<K, unknown> | null,
  key: K,
): boolean {
  if (object[key] === undefined) {
    throw new Error(
      `"${key}" is undefined in current object: ${JSON.stringify(object)}`,
    );
  } else if (otherObject && otherObject[key] === undefined) {
    throw new Error(
      `"${key}" is undefined in other object: ${JSON.stringify(otherObject)}`,
    );
  }
  return object[key] === otherObject?.[key];
}
