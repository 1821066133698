<div
  (click)="onDialogClicked($event)"
  [style.height]="options.height"
  [style.maxHeight]="options.maxHeight"
  [style.maxWidth]="options.maxWidth"
  [style.minHeight]="options.minHeight"
  [style.minWidth]="options.minWidth"
  [style.width]="options.width"
>
  <div
    *ngIf="options.dialogTitle || options.isCloseIconDisplayed"
    [style.maxWidth]="options.maxWidth"
    [style.width]="options.width"
    class="dialog-title"
  >
    <h4
      *ngIf="options.dialogTitle as dialogTitle"
      [innerHTML]="dialogTitle || ''"
    ></h4>
    <alleva-button
      (click)="close()"
      *ngIf="options.isCloseIconDisplayed"
      [icon]="{ colorHex: 'var(--black)', inline: true, name: 'close' }"
      color="accent"
      label="Close Dialog"
      matTooltip="Close"
      matTooltipPosition="after"
      styling="icon"
    ></alleva-button>
  </div>
  <alleva-container
    *ngIf="options.introSectionText as intro"
    [innerHTML]="intro"
    class="intro-shadow-box"
    theme="shadow"
  ></alleva-container>
  <ng-template allevaInjectDialog></ng-template>
</div>
