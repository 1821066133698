import { Component, ContentChildren, Input, QueryList } from '@angular/core';

import { InputDropdownOptionComponent } from 'src/app/components/forms/input-dropdown/input-dropdown-option.component';

@Component({
  selector: 'alleva-input-dropdown-option-group[label]',
  template: `<ng-content></ng-content>`,
})
export class InputDropdownOptionGroupComponent<T> {
  @Input() public label!: string;

  @ContentChildren(InputDropdownOptionComponent)
  public readonly options: QueryList<InputDropdownOptionComponent<T>> =
    new QueryList<InputDropdownOptionComponent<T>>();
}
