import {
  VitalsBloodPressureApi,
  VitalsBloodPressureUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import {
  BloodPressureArmEnum,
  BloodPressurePositionEnum,
  BloodPressureStatusEnum,
} from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<VitalsBloodPressureApi>();

type VitalsBloodPressureArgs = Omit<
  VitalsBloodPressure,
  // Exclude computed properties
  'status'
>;

export class VitalsBloodPressure {
  public constructor(props: ClassProperties<VitalsBloodPressureArgs>) {
    this.arm = props.arm;
    this.diastolic = props.diastolic;
    this.position = props.position;
    this.systolic = props.systolic;

    // Computed values
    this.status = VitalsBloodPressure.getBloodPressureStatus(this);
  }

  /**
   * The io-ts codec for runtime type checking of the Vitals Blood Pressure
   * API model.
   */
  public static readonly Codec = io.type(
    {
      arm: io.union([
        io.literal(BloodPressureArmEnum.LEFT),
        io.literal(BloodPressureArmEnum.RIGHT),
        io.null,
      ]),
      diastolic: io.number,
      position: io.union([
        io.literal(BloodPressurePositionEnum.LYING),
        io.literal(BloodPressurePositionEnum.SITTING),
        io.literal(BloodPressurePositionEnum.STANDING),
        io.null,
      ]),
      systolic: io.number,
    },
    'VitalsBloodPressureApi',
  );

  @api({ key: 'arm' }) public readonly arm: BloodPressureArmEnum | null;
  @api({ key: 'diastolic' }) public readonly diastolic: number;
  @api({ key: 'position' })
  public readonly position: BloodPressurePositionEnum | null;
  @api({ key: 'systolic' }) public readonly systolic: number;

  // Computed values
  public readonly status: BloodPressureStatusEnum | null;

  /**
   * Deserializes a Vitals Blood Pressure object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Vitals Blood Pressure object.
   * @throws An error if the value is not a valid Vitals Blood Pressure object.
   */
  public static deserialize(
    value: NonNullable<VitalsBloodPressureApi>,
  ): VitalsBloodPressure {
    const decoded = decode(VitalsBloodPressure.Codec, value);
    return new VitalsBloodPressure({ ...decoded });
  }

  /**
   * Deserializes a list of Vitals Blood Pressure objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Vitals Blood Pressure objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Vitals Blood Pressure
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<VitalsBloodPressureApi>>,
  ): readonly VitalsBloodPressure[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Vitals Blood Pressure objects.');
    }
    return values.map(VitalsBloodPressure.deserialize);
  }

  /**
   * The calculated status of a blood pressure reading.
   *
   * Duplicates logic from the API, see file:
   * https://github.com/AllevaSoft/rehabweb/blob/b4117d0245030a040f3da95a266933f2688fb82d/AllevaApi/Repositories/v2/ClientVitalsRepository.cs#L382
   *
   * @param { systolic, diastolic } - The systolic and diastolic blood pressure readings.
   * @returns The status of the blood pressure reading.
   */
  public static getBloodPressureStatus({
    systolic,
    diastolic,
  }: {
    systolic: VitalsBloodPressure['systolic'];
    diastolic: VitalsBloodPressure['diastolic'];
  }): BloodPressureStatusEnum {
    if (systolic < 90 || diastolic < 60) {
      return BloodPressureStatusEnum.LOW;
    }
    if (
      systolic >= 90 &&
      systolic <= 120 &&
      diastolic >= 60 &&
      diastolic <= 80
    ) {
      return BloodPressureStatusEnum.NORMAL;
    }
    if (systolic >= 120 && systolic <= 129 && diastolic < 80) {
      return BloodPressureStatusEnum.ELEVATED;
    }
    if (
      (systolic >= 130 && systolic <= 139) ||
      (diastolic >= 80 && diastolic <= 89)
    ) {
      return BloodPressureStatusEnum.HYPERTENSION_STAGE_ONE;
    }
    if (
      (systolic >= 140 && systolic <= 180) ||
      (diastolic >= 90 && diastolic <= 120)
    ) {
      return BloodPressureStatusEnum.HYPERTENSION_STAGE_TWO;
    }
    if (systolic > 180 && diastolic > 120) {
      return BloodPressureStatusEnum.HYPERTENSION_CRISIS;
    }
    return BloodPressureStatusEnum.BAD_READING;
  }
}

export class VitalsBloodPressureUpdate extends VitalsBloodPressure {
  public constructor(props: ClassProperties<VitalsBloodPressureUpdate>) {
    super(props);
  }

  public serialize(): VitalsBloodPressureUpdateApi {
    return {
      arm: this.arm,
      diastolic: this.diastolic,
      position: this.position,
      status: this.status,
      systolic: this.systolic,
    };
  }
}
