import {
  ClientCIWABReviewerApi,
  ClientCIWABReviewerUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Facility } from 'src/app/models/facility/facility.model';
import { Reviewer } from 'src/app/models/reviewer.model';

const api = apiDecorator<ClientCIWABReviewerApi>();

abstract class ClientCIWABReviewerBase {
  public constructor(props: ClassProperties<ClientCIWABReviewerBase>) {
    this.date = props.date;
    this.signature = props.signature;
  }

  @api({ key: 'date' }) public readonly date: DateTime | null;
  @api({ key: 'signature' }) public readonly signature: Base64<'png'> | null;
}

export class ClientCIWABReviewer extends ClientCIWABReviewerBase {
  public constructor(props: ClassProperties<ClientCIWABReviewer>) {
    super(props);

    this.by = props.by;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA-B Reviewer API
   * model.
   */
  public static readonly Codec = io.type(
    {
      by: io.union([Reviewer.Codec, io.undefined]),
      date: io.union([io.string, io.null]),
      signature: io.union([io.string, io.null]),
    },
    'ClientCIWABReviewerApi',
  );

  @api({ key: 'by' }) public readonly by: Reviewer | null;

  /**
   * Deserializes a Client CIWA-B Reviewer object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B Reviewer object.
   * @throws An error if the value is not a valid Client CIWA-B Reviewer
   * object.
   */
  public static deserialize(
    value: NonNullable<ClientCIWABReviewerApi>,
    deserializationArgs: ClientCiwaBReviewerDeserializationArgs,
  ): ClientCIWABReviewer {
    const decoded = decode(ClientCIWABReviewer.Codec, value);
    return new ClientCIWABReviewer({
      by: decoded.by
        ? Reviewer.deserialize(decoded.by, deserializationArgs)
        : null,
      date: decoded.date
        ? DateTime.fromISO(decoded.date, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
      signature: decoded.signature
        ? (decoded.signature as Base64<'png'>)
        : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA-B Reviewer objects from an API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client CIWA-B Reviewer objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA-B Reviewer
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWABReviewerApi>>,
    deserializationArgs: ClientCiwaBReviewerDeserializationArgs,
  ): readonly ClientCIWABReviewer[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA-B Reviewer objects.');
    }
    return values.map((clientCIWABReviewer) =>
      ClientCIWABReviewer.deserialize(clientCIWABReviewer, deserializationArgs),
    );
  }
}

export class ClientCIWABReviewerUpdate extends ClientCIWABReviewerBase {
  public constructor(props: ClassProperties<ClientCIWABReviewerUpdate>) {
    super(props);

    this.by = props.by;
  }

  @api({ key: 'by' }) public readonly by: { id: Reviewer['id'] };

  /**
   * Serializes a Client CIWA-B Reviewer object to an API model.
   *
   * @returns The serialized Client CIWA-B Reviewer object.
   */
  public serialize(): ClientCIWABReviewerUpdateApi {
    return {
      by: this.by,
      date: this.date?.toISO() ?? null,
      signature: this.signature ?? null,
    };
  }
}

export interface ClientCiwaBReviewerDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
