import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { InputPickTimeComponent } from 'src/app/components/forms/input-pick-time/input-pick-time.component';
import { IconModule } from 'src/app/components/icon/icon.module';

@NgModule({
  declarations: [InputPickTimeComponent],
  exports: [InputPickTimeComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    IconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatLuxonDateModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
  ],
})
export class InputPickTimeModule {}
