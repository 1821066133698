<a
  [class.collapsed]="isCollapsedChanges | async"
  [matTooltip]="(tooltipDisplayChanges | async) || ''"
  [routerLinkActiveOptions]="{ exact: true }"
  [routerLink]="subMenuItemLinkChanges | async"
  class="sub-menu-item"
  mat-list-item
  matTooltipPosition="right"
  routerLinkActive="active"
  type="button"
>
  <ng-container
    *ngIf="(isCollapsedChanges | async) === false; else collapsedView"
  >
    <span class="sub-menu-nested-chevron">•</span>
    <span [innerText]="subLevelMenuItem.label"></span>
  </ng-container>
  <ng-template #collapsedView>
    <span class="center">{{
      subLevelMenuItem.label | firstLetterEveryWord: 2
    }}</span>
  </ng-template>
</a>
