<alleva-icon
  [inline]="true"
  color="success"
  name="client_status_active"
></alleva-icon>
<span>{{ ClientStatusEnum.ACTIVE }}</span>
<alleva-icon
  [inline]="true"
  colorHex="var(--orange-dark)"
  name="client_status_discharged"
></alleva-icon>
<span>{{ ClientStatusEnum.DISCHARGED }}</span>
<alleva-icon
  [inline]="true"
  colorHex="var(--gray-dark)"
  name="client_status_inactive"
></alleva-icon>
<span>{{ ClientStatusEnum.INACTIVE }}</span>
<alleva-icon
  [inline]="true"
  color="warn"
  name="client_status_suspended"
></alleva-icon>
<span>{{ ClientStatusEnum.SUSPENDED }}</span>
