import { BillingRuleServiceApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { ServiceTypeEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { BillingRuleServiceDetail } from 'src/app/models/billing/billing-rule-service-detail.model';

const api = apiDecorator<BillingRuleServiceApi>();

export class BillingRuleService {
  public constructor(props: ClassProperties<BillingRuleService>) {
    this.details = props.details;
    this.type = props.type;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Rule Service API
   * model.
   */
  public static readonly Codec = io.type(
    {
      details: io.array(BillingRuleServiceDetail.Codec),
      type: io.union([
        io.literal(ServiceTypeEnum.GROUP),
        io.literal(ServiceTypeEnum.INDIVIDUAL),
        io.literal(ServiceTypeEnum.INTAKE),
      ]),
    },
    'BillingRuleServiceApi',
  );

  @api({ key: 'details' })
  public readonly details: readonly BillingRuleServiceDetail[];
  @api({ key: 'type' }) public readonly type: ServiceTypeEnum;

  /**
   * Deserializes a Billing Rule Service object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Rule Service object.
   * @throws An error if the value is not a valid Billing Rule Service object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleServiceApi>,
  ): BillingRuleService {
    const decoded = decode(BillingRuleService.Codec, value);
    return new BillingRuleService({
      details: BillingRuleServiceDetail.deserializeList(decoded.details),
      type: decoded.type,
    });
  }
}
