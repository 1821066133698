<ng-container *ngIf="baseControl as formControl; else loading">
  <div class="list unselected">
    <label>{{ labelUnselected }}</label>
    <select [size]="size" [disabled]="disabled">
      <option
        *ngFor="let item of unselectedValuesChanges | async"
        [value]="item"
        (click)="onSelectValue(item)"
      >
        {{ getDisplayValue(item) }}
      </option>
      <ng-container
        *ngIf="!(unselectedValuesChanges | async)?.length"
        [ngTemplateOutlet]="emptyListOption"
      ></ng-container>
    </select>
  </div>
  <div class="list selected">
    <label>{{ labelSelected }}</label>
    <select [size]="size" [disabled]="disabled">
      <option
        *ngFor="let item of selectedValuesChanges | async"
        [value]="item"
        (click)="onDeselectValue(item)"
      >
        {{ getDisplayValue(item) }}
      </option>
      <ng-container
        *ngIf="!(selectedValuesChanges | async)?.length"
        [ngTemplateOutlet]="emptyListOption"
      ></ng-container>
    </select>
  </div>
</ng-container>

<ng-template #emptyListOption>
  <option class="empty" [value]="null" disabled>
    {{ emptyListPlaceholder }}
  </option>
</ng-template>

<ng-template #loading>Loading...</ng-template>
