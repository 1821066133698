import { VitalsBMIApi, VitalsBMIUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { BmiCategoryEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<VitalsBMIApi>();

type VitalsBMIArgs = Omit<
  ClassProperties<VitalsBMI>,
  // Omit computed properties based on core model data.
  'displayValue'
>;

export class VitalsBMI {
  public constructor(props: ClassProperties<VitalsBMIArgs>) {
    this.category = props.category;
    this.value = props.value;

    // Computed values
    this.displayValue = Math.round(this.value * 10) / 10;
  }

  /**
   * The io-ts codec for runtime type checking of the Vitals BMI API model.
   */
  public static readonly Codec = io.type(
    {
      category: io.union([
        io.literal(BmiCategoryEnum.UNDERWEIGHT),
        io.literal(BmiCategoryEnum.NORMAL),
        io.literal(BmiCategoryEnum.OVERWEIGHT),
        io.literal(BmiCategoryEnum.OBESE_CLASS_1),
        io.literal(BmiCategoryEnum.OBESE_CLASS_2),
        io.literal(BmiCategoryEnum.OBESE_CLASS_3),
        io.null,
      ]),
      value: io.number,
    },
    'VitalsBMIApi',
  );

  @api({ key: 'category' }) public readonly category: BmiCategoryEnum | null;
  @api({ key: 'value' }) public readonly value: number;

  // Computed values
  public readonly displayValue: number;

  /**
   * Deserializes a Vitals BMI object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Vitals BMI object.
   * @throws An error if the value is not a valid Vitals BMI object.
   */
  public static deserialize(value: NonNullable<VitalsBMIApi>): VitalsBMI {
    const decoded = decode(VitalsBMI.Codec, value);
    return new VitalsBMI(decoded);
  }

  /**
   * Deserializes a list of Vitals BMI objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Vitals BMI objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Vitals BMI objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<VitalsBMIApi>>,
  ): readonly VitalsBMI[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Vitals BMI objects.');
    }
    return values.map(VitalsBMI.deserialize);
  }
}

export class VitalsBMIUpdate extends VitalsBMI {
  public constructor(props: ClassProperties<VitalsBMIArgs>) {
    super(props);
  }

  public serialize(): VitalsBMIUpdateApi {
    return {
      category: this.category,
      value: this.value,
    };
  }
}
