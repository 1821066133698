import { ClientMedicationApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientMedicationCreatedBy } from 'src/app/models/client/client-medication-created-by.model';
import { ClientMedicationDiscontinued } from 'src/app/models/client/client-medication-discontinued.model';
import { ClientMedicationDoctorsOrder } from 'src/app/models/client/client-medication-doctors-order.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { MedicationType } from 'src/app/models/medication/medication-type.model';

const api = apiDecorator<ClientMedicationApi>();

type ClientMedicationArgs = Omit<
  ClassProperties<ClientMedication>,
  // Omit computed properties set on construct from outside data.
  'activeStateName' | 'discontinuedByFullName' | 'discontinuedDate' | 'typeName'
>;

export class ClientMedication {
  public constructor(props: ClassProperties<ClientMedicationArgs>) {
    this.createdBy = props.createdBy;
    this.custom = props.custom;
    this.customId = props.customId;
    this.discontinued = props.discontinued;
    this.dispensableName = props.dispensableName;
    this.doctorsOrder = props.doctorsOrder;
    this.dosageFormDescription = props.dosageFormDescription;
    this.gcnSequenceNum = props.gcnSequenceNum;
    this.id = props.id;
    this.indication = props.indication;
    this.instructions = props.instructions;
    this.maxDosage = props.maxDosage;
    this.maxQuantity = props.maxQuantity;
    this.medsUnit = props.medsUnit;
    this.minDosage = props.minDosage;
    this.minQuantity = props.minQuantity;
    this.name = props.name;
    this.nameId = props.nameId;
    this.prn = props.prn;
    this.route = props.route;
    this.routedNameId = props.routedNameId;
    this.startDate = props.startDate;
    this.stopDate = props.stopDate;
    this.strength = props.strength;
    this.type = props.type;

    // Computed properties.
    this.activeStateName = !this.discontinued?.date ? 'Active' : 'Inactive';
    this.discontinuedDate = this.discontinued?.date ?? null;
    this.discontinuedByFullName = this.discontinued?.by.fullName ?? 'N/A';
    this.typeName = this.type?.name ?? 'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the Client Medication Api
   * model.
   */
  public static readonly Codec = io.type(
    {
      createdBy: ClientMedicationCreatedBy.Codec,
      custom: io.boolean,
      customId: io.union([io.number, io.null]),
      discontinued: io.union([ClientMedicationDiscontinued.Codec, io.null]),
      dispensableName: io.union([io.string, io.null]),
      doctorsOrder: io.union([ClientMedicationDoctorsOrder.Codec, io.null]),
      dosageFormDescription: io.union([io.string, io.null]),
      gcnSequenceNum: io.union([io.string, io.null]),
      id: io.number,
      indication: io.union([io.string, io.null]),
      instructions: io.union([io.string, io.null]),
      maxDosage: io.union([io.string, io.null]),
      maxQuantity: io.union([io.string, io.null]),
      medsUnit: io.union([io.string, io.null]),
      minDosage: io.union([io.string, io.null]),
      minQuantity: io.union([io.string, io.null]),
      name: io.union([io.string, io.null]),
      nameId: io.union([io.string, io.null]),
      prn: io.boolean,
      route: io.union([io.string, io.null]),
      routedNameId: io.union([io.string, io.null]),
      startDate: io.string,
      status: MedicationType.Codec,
      stopDate: io.union([io.string, io.null]),
      strength: io.union([io.string, io.null]),
    },
    'ClientMedicationApi',
  );

  @api({ key: 'createdBy' })
  public readonly createdBy: ClientMedicationCreatedBy;
  @api({ key: 'custom' }) public readonly custom: boolean;
  @api({ key: 'customId' }) public readonly customId: number | null;
  @api({ key: 'discontinued' })
  public readonly discontinued: ClientMedicationDiscontinued | null;
  @api({ key: 'dispensableName' }) public readonly dispensableName:
    | string
    | null;
  @api({ key: 'doctorsOrder' })
  public readonly doctorsOrder: ClientMedicationDoctorsOrder | null;
  @api({ key: 'dosageFormDescription' }) public readonly dosageFormDescription:
    | string
    | null;
  @api({ key: 'gcnSequenceNum' }) public readonly gcnSequenceNum: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'indication' }) public readonly indication: string | null;
  @api({ key: 'instructions' }) public readonly instructions: string | null;
  @api({ key: 'maxDosage' }) public readonly maxDosage: string | null;
  @api({ key: 'maxQuantity' }) public readonly maxQuantity: string | null;
  @api({ key: 'medsUnit' }) public readonly medsUnit: string | null;
  @api({ key: 'minDosage' }) public readonly minDosage: string | null;
  @api({ key: 'minQuantity' }) public readonly minQuantity: string | null;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'nameId' }) public readonly nameId: string | null;
  @api({ key: 'prn' }) public readonly prn: boolean;
  @api({ key: 'route' }) public readonly route: string | null;
  @api({ key: 'routedNameId' }) public readonly routedNameId: string | null;
  @api({ key: 'startDate' }) public readonly startDate: DateTime;
  @api({ key: 'stopDate' }) public readonly stopDate: DateTime | null;
  @api({ key: 'strength' }) public readonly strength: string | null;
  @api({ key: 'status' }) public readonly type: MedicationType;

  // Computed properties below.
  public readonly activeStateName: 'Active' | 'Inactive';
  public readonly discontinuedDate: DateTime | null;
  public readonly discontinuedByFullName: string;
  public readonly typeName: string;

  /**
   * Deserializes a Client Medication object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication object.
   * @throws An error if the value is not a valid Client Medication object.
   */
  public static deserialize(
    value: NonNullable<ClientMedicationApi>,
    deserializationArgs: ClientMedicationDeserializationArgs,
  ): ClientMedication {
    const decoded = decode(ClientMedication.Codec, value);
    return new ClientMedication({
      createdBy: ClientMedicationCreatedBy.deserialize(
        decoded.createdBy,
        deserializationArgs,
      ),
      custom: decoded.custom,
      customId: decoded.customId,
      discontinued: decoded.discontinued
        ? ClientMedicationDiscontinued.deserialize(
            decoded.discontinued,
            deserializationArgs,
          )
        : null,
      dispensableName: decoded.dispensableName,
      doctorsOrder: decoded.doctorsOrder
        ? ClientMedicationDoctorsOrder.deserialize(decoded.doctorsOrder)
        : null,
      dosageFormDescription: decoded.dosageFormDescription,
      gcnSequenceNum: decoded.gcnSequenceNum,
      id: decoded.id,
      indication: decoded.indication,
      instructions: decoded.instructions,
      maxDosage: decoded.maxDosage,
      maxQuantity: decoded.maxQuantity,
      medsUnit: decoded.medsUnit,
      minDosage: decoded.minDosage,
      minQuantity: decoded.minQuantity,
      name: decoded.name,
      nameId: decoded.nameId,
      prn: decoded.prn,
      route: decoded.route,
      routedNameId: decoded.routedNameId,
      startDate: DateTime.fromISO(decoded.startDate, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      stopDate: decoded.stopDate
        ? DateTime.fromISO(decoded.stopDate, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
      strength: decoded.strength,
      type: MedicationType.deserialize(decoded.status),
    });
  }

  /**
   * Deserializes a list of Client Medication objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Medication objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Medication objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientMedicationApi>>,
    deserializationArgs: ClientMedicationDeserializationArgs,
  ): readonly ClientMedication[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Medication objects.');
    }
    return values.map((clientMedication) =>
      ClientMedication.deserialize(clientMedication, deserializationArgs),
    );
  }
}

export interface ClientMedicationDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
