import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';
import { InputRadioOptionComponent } from 'src/app/components/forms/input-radio/input-radio-option.component';

/**
 * A radio component that displays a list of radio buttons allowing the user to
 * select a single value.
 *
 * @requires `formControl` attribute to be set.
 */
@Component({
  selector: 'alleva-input-radio[formControl][label]',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
})
export class InputRadioComponent<T>
  extends CustomInputDirective<T>
  implements OnInit
{
  /**
   * Whether or not the radio group label should be bold.
   *
   * @default false
   */
  @Input()
  public set boldLabel(value: BooleanInput) {
    this.#boldLabel = coerceBooleanProperty(value);
  }
  public get boldLabel(): boolean {
    return this.#boldLabel;
  }
  #boldLabel = false;

  /**
   * The color palette to use for the radio buttons.
   *
   * @default 'primary' Default color palette value.
   */
  @Input() public color: ThemePalette = 'primary';

  @HostBinding('class.inline')
  @Input()
  public set inline(value: BooleanInput) {
    this.#inline = coerceBooleanProperty(value);
  }
  public get inline(): boolean {
    return this.#inline;
  }
  #inline = false;

  @ContentChildren(InputRadioOptionComponent)
  protected readonly options: QueryList<InputRadioOptionComponent<T>> =
    new QueryList<InputRadioOptionComponent<T>>();

  @ViewChildren(MatRadioButton)
  private readonly radioButtons!: QueryList<MatRadioButton>;

  @ViewChild(MatRadioGroup) private readonly radioGroup!: MatRadioGroup;

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.baseControl === undefined) {
      throw new Error('The formControl attribute is required.');
    }
  }
}
