<div
  *ngIf="
    activeFacilityChanges | async as activeFacility;
    else loadingActiveFacilityTemplate
  "
  [matMenuTriggerFor]="facilityMenu"
  class="facility"
>
  <img *ngIf="activeFacility.image | authBlob | async as src" [src]="src" />
  <div class="facility-details">
    <span class="facility-name">{{ activeFacility.name }}</span>
    <span
      *ngIf="timeChanges | async as time; else loadingtimeTemplate"
      class="facility-time"
      >{{ time | dateTime: 'h:mm a (ZZZZ)' }}</span
    >
    <ng-template #loadingtimeTemplate> Loading facility time... </ng-template>
  </div>
</div>

<ng-template #loadingActiveFacilityTemplate> Loading facility... </ng-template>

<mat-menu #facilityMenu="matMenu">
  <button
    (click)="facilityBaseControl.setValue(facilityBase)"
    *ngFor="let facilityBase of availableFacilityBaseChanges | async"
    [class.active]="facilityBase.id === facilityBaseControl.value?.id"
    class="facility-menu-item"
    mat-menu-item
  >
    <span class="facility-name">{{ facilityBase.name }}</span>
    <alleva-icon
      *ngIf="facilityBase.id === facilityBaseControl.value?.id"
      color="primary"
      name="check"
    ></alleva-icon>
  </button>
</mat-menu>
