import { Component } from '@angular/core';

import { DialogConfig } from 'src/app/components/dialogs/dialog-config';
import { DialogRef } from 'src/app/components/dialogs/dialog-ref';

export interface BasicInputData {
  display?: {
    cancel?: boolean;
    confirm?: boolean;
    reject?: boolean;
  };
  labels?: {
    /**
     * The label to display on the cancel button.
     *
     * @default "Cancel" is the default value.
     */
    cancel?: string;
    /**
     * The label to display on the confirm button.
     *
     * @default "Yes" is the default value.
     */
    confirm?: string;
    /**
     * The label to display on the reject button.
     *
     * @default "No" is the default value.
     */
    reject?: string;
  };
  message?: string;
}

export interface BasicOutputData {
  confirmed: boolean;
}

@Component({
  selector: 'alleva-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss'],
})
export class BasicDialogComponent {
  public constructor(
    public readonly config: DialogConfig<BasicInputData>,
    public readonly dialog: DialogRef<BasicOutputData>,
  ) {}

  public readonly data: BasicInputData = {
    ...this.config.data,
    display: {
      cancel: this.config.data?.display?.cancel ?? true,
      confirm: this.config.data?.display?.confirm ?? true,
      reject: this.config.data?.display?.reject ?? true,
    },
  };

  public onClose(value?: boolean): void {
    this.dialog.close(value !== undefined ? { confirmed: value } : undefined);
  }
}
