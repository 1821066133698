import { firstValueFrom } from 'rxjs';
import { FacilityService } from 'src/app/services';
import { getPageRoute } from 'src/app/utilities';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

/**
 * Lock out specific routes based on the current facility's feature flags.
 *
 * @returns True if the user has access to the route and the facility flag
 * allows it if applicable, false otherwise.
 */
export function facilityFeaturesGuard(): CanActivateFn {
  return async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> => {
    const facilityService = inject(FacilityService);

    const currentFacility = await firstValueFrom(
      facilityService.currentFacilityChanges,
    );
    const routeToCheck: PageRoute | null = getPageRoute(state.url);

    if (!routeToCheck) {
      throw new Error(
        'Route is not a SPA route. Unable to check facility features with a route guard.',
      );
    }

    const restrictedFallbackRoute: PageRoute = '/restricted-access';

    switch (routeToCheck) {
      case '/billing-offsite':
      case '/encounter-transmission': {
        const router = inject(Router);
        const hasAccess = currentFacility.features.isBillingEnabled;
        return hasAccess
          ? true
          : router.createUrlTree([restrictedFallbackRoute]);
      }
      default:
        return true;
    }
  };
}
