import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { DialogMaxWidthEnum } from 'src/app/enumerators';
import { ScreenSizeService } from 'src/app/services';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  HostBinding,
  HostListener,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import {
  DialogConfig,
  DialogOptions,
} from 'src/app/components/dialogs/dialog-config';
import { DialogRef } from 'src/app/components/dialogs/dialog-ref';

/**
 * A directive that injects a view container reference into the core dialog
 * component. This is used to dynamically inject the component that is passed
 * in to the dialog service.
 */
@Directive({ selector: '[allevaInjectDialog]' })
export class InjectDialogDirective {
  public constructor(public readonly viewContainerRef: ViewContainerRef) {}
}

const defaultOptions: DialogOptions = {
  backgroundOpacity: 0.5,
  closeOnBackdropClick: true,
  closeOnNavigation: true,
  isCloseIconDisplayed: true,
  maxHeight: 'auto',
  maxWidth: DialogMaxWidthEnum.LARGE,
  minHeight: 'auto',
};

/**
 * A dialog component that handles the main view of the dynamic dialog popup.
 * The dialog service takes this and injects it into the root node of the DOM
 * and then dynamically injects the component that is passed in to the dialog
 * service to display within itself.
 *
 * C = The type of the component to open within.
 * D = The type of the data to pass to the inner component for use.
 * R = The type of the result to return from the dialog on close.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent<C, D, R> implements AfterViewInit, OnDestroy {
  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly config: DialogConfig<D>,
    private readonly dialogRef: DialogRef<R>,
    private readonly router: Router,
    private readonly screenSizeService: ScreenSizeService,
  ) {}

  @HostBinding('style.backdrop-filter') public get blurBackground():
    | string
    | undefined {
    if (!this.options.blurBackground) {
      return;
    }
    return `blur(10px)`;
  }

  @HostBinding('style.background-color') public get backgroundColor(): string {
    return `rgba(33, 33, 33, ${this.options.backgroundOpacity ?? 0.5})`;
  }

  /** The injected component to display within this base dialog component. */
  public component: Type<C> | null = null;

  /** The component reference for the injected component. */
  public componentRef: ComponentRef<C> | null = null;

  /** The options for the dialog. */
  public readonly options: DialogOptions = {
    ...defaultOptions,
    ...this.config.options,
  };

  @ViewChild(InjectDialogDirective)
  public dialogContent!: InjectDialogDirective;

  private readonly onCloseSubject = new Subject<R | undefined>();
  public readonly onCloseChanges = this.onCloseSubject.asObservable();

  /**
   * Handle the on click event for the dialog backdrop.
   */
  @HostListener('click', ['$event'])
  public onClick(mouseEvent: MouseEvent): void {
    this.onDialogClicked(mouseEvent);

    if (this.options?.closeOnBackdropClick === false) {
      return;
    }

    this.dialogRef.close();
  }

  public ngAfterViewInit(): void {
    if (!this.component) {
      throw new Error('A component must be specified.');
    }

    // Load the passed component into the directive view container ref.
    this.loadComponent(this.component);

    // Use manual change detection to ensure the component is rendered.
    this.changeDetectorRef.detectChanges();

    // Act on certain router events.
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd && this.options.closeOnNavigation) {
        // User has changed pages, self close.
        this.close();
      }
    });

    // Update the modal size to 100% width on mobile devices.
    this.screenSizeService.isMobileChanges
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        if (isMobile) {
          // Override the max width and width to 100% on mobile devices.
          this.options.maxWidth = DialogMaxWidthEnum.XLARGE;
          this.options.width = DialogMaxWidthEnum.FULL;
        } else {
          // Reset back to the original values.
          const startingOptions = { ...defaultOptions, ...this.config.options };
          this.options.maxWidth = startingOptions.maxWidth;
          this.options.width = startingOptions.width;
        }

        // Update the modal size.
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    // Clean up the component reference when the dialog is destroyed.
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Handle the click event for the dialog backdrop.
   *
   * @param mouseEvent The mouse event that triggered the click.
   */
  public onDialogClicked(mouseEvent: MouseEvent): void {
    // Prevent click propagation past the backdrop.
    mouseEvent.stopPropagation();
  }

  /**
   * Load the component into the insertion point.
   *
   * @param component The component to load.
   */
  public loadComponent(component: Type<C>): void {
    const viewContainerRef = this.dialogContent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(component);
  }

  public close(value?: R): void {
    const viewContainerRef = this.dialogContent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = null;
    this.onCloseSubject.next(value);
  }
}
