export enum LanguageEnum {
  ARABIC = 'Arabic',
  ASSYRIAN = 'Assyrian',
  CAMBODIAN = 'Cambodian',
  CHINESE_CANTONESE = 'Chinese-Cantonese',
  CHINESE_MANDARIN = 'Chinese-Mandarin',
  ENGLISH = 'English',
  FARSI = 'Farsi',
  FIJIAN = 'Fijian',
  FRENCH = 'French',
  GERMAN = 'German',
  GREEK = 'Greek',
  HEBREW = 'Hebrew',
  HINDI = 'Hindi',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  KOREAN = 'Korean',
  LAOTIAN = 'Laotian',
  MICRONESSIAN = 'Micronessian',
  PASHTUN = 'Pashtun',
  PERSIAN = 'Persian',
  POLISH = 'Polish',
  ROMANIAN = 'Romanian',
  RUSSIAN = 'Russian',
  SAMOAN = 'Samoan',
  SPANISH = 'Spanish',
  TAGALOG = 'Tagalog',
  THAI = 'Thai',
  UKRAINIAN = 'Ukrainian',
  VIETNAMESE = 'Vietnamese',
}
