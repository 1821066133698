import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as io from 'io-ts';
import { failure } from 'io-ts/PathReporter';

/**
 * Run time type checking using io-ts. Explicitly handle strict parameter in
 * overloads to maintain non-nullable output by default.
 *
 * @param codec The codec to use for type checking.
 * @param input The input to check.
 * @param strict Controls the error handling: throws if true, logs and returns
 * null if false.
 * @returns If the value is valid, returns the decoded value. If invalid,
 * behavior depends on `strict`.
 */
export function decode<A, O = A, I = unknown>(
  codec: io.Type<A, O, I>,
  input: I,
  strict: true,
): A;
export function decode<A, O = A, I = unknown>(
  codec: io.Type<A, O, I>,
  input: I,
  strict: false,
): A | null;
export function decode<A, O = A, I = unknown>(
  codec: io.Type<A, O, I>,
  input: I,
  strict?: boolean,
): A;
export function decode<A, O = A, I = unknown>(
  codec: io.Type<A, O, I>,
  input: I,
  strict: boolean = true,
): A | null {
  if (!strict && input === null) {
    return null;
  }
  return pipe(
    codec.decode(input),
    E.getOrElseW((errors) => {
      const message =
        `Failed to deserialize API model(s) to UI model(s). Details below:\n` +
        failure(errors).join('\n\n');
      if (strict) {
        throw new Error(message);
      } else {
        console.warn(message);
        return null;
      }
    }),
  );
}
