import { InsuranceServiceDetailsApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Client, InsuranceServiceDetails } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientInsuranceServiceDetailsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetches a the insurance service details for a client.
   *
   * @param clientId The id of the client to fetch the insurance service
   * details for.
   * @returns A list of insurance service details for the client, or undefined
   * on error.
   */
  public get(
    clientId: Client['id'],
  ): Observable<InsuranceServiceDetails | undefined> {
    return this.httpClient
      .get<
        InsuranceServiceDetailsApi | undefined
      >(`${config.api}/clients/${clientId}/insurance/service-details`)
      .pipe(
        map((response) =>
          response ? InsuranceServiceDetails.deserialize(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
