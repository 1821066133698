import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/services';
import { config } from 'src/configs/config';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A class for intercepting all HTTP requests and responses and applying custom
 * logic to them.
 */
@Injectable()
export class RootHttpInterceptor implements HttpInterceptor {
  public constructor(
    private readonly authenticationService: AuthenticationService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.authenticationService.userChanges.pipe(
      mergeMap((user) => {
        if (user && request.url.startsWith(config.api)) {
          // Auto assign headers on all API requests.
          request = request.clone({
            setHeaders: {
              // Provide the API the user's access token so they can verify
              // access and use the `client_tenant_id` to determine which
              // tenant the user is accessing the API for.
              Authorization: `Bearer ${user.accessToken}`,
            },
          });
        }

        return next.handle(request).pipe(
          // Intercept errors and handle them.
          catchError((error: unknown) => {
            if (!(error instanceof HttpErrorResponse)) {
              // Not an HTTP error, rethrow and let the global error handler
              // take care of it.
              return throwError(() => error);
            }

            // Intercept 401 responses and redirect to login page.
            if (error.status === 401) {
              this.authenticationService.logout(undefined, false);
            }

            // Unhandled HTTP error, rethrow and let the global error handler
            // take care of it.
            return throwError(() => error);
          }),
        );
      }),
    );
  }
}
