import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CardsGridComponent } from 'src/app/components/cards-grid/cards-grid.component';
import { ClientCardComponent } from 'src/app/components/cards/client/client-card.component';
import { ReferralCardComponent } from 'src/app/components/cards/referral/referral-card.component';
import { UserCardComponent } from 'src/app/components/cards/user/user-card.component';

@NgModule({
  declarations: [CardsGridComponent],
  exports: [CardsGridComponent],
  imports: [
    ClientCardComponent,
    CommonModule,
    GridsterComponent,
    GridsterItemComponent,
    ReferralCardComponent,
    RouterModule,
    UserCardComponent,
  ],
  providers: [],
})
export class CardsGridModule {}
