import { firstValueFrom } from 'rxjs';
import { AlertService, ScreenLockService } from 'src/app/services';
import { config } from 'src/configs/config';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';

/**
 * This guard is an extra safeguard to prevent the user from navigating to
 * a page while the screen is locked in the event that the user is able to
 * bypass the screen lock nefariously in an unhandled circumstance.
 */
export function screenLockGuard(): CanActivateFn {
  return async (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<boolean> => {
    if (!config.features.isScreenAutoLockEnabled) {
      return true;
    }

    const screenLockService = inject(ScreenLockService);
    // Check the screen lock state.
    const isScreenLocked = await firstValueFrom(
      screenLockService.isLockedChanges,
    );

    if (isScreenLocked) {
      const alertService = inject(AlertService);

      // Warn the user that they're not allowed to navigate while the screen is locked.
      alertService.error({
        message: 'You are not allowed to navigate while the screen is locked.',
      });
    }

    return isScreenLocked
      ? false // If the screen is locked then don't allow the user to navigate.
      : true; // The screen is not locked so allow the user to navigate.
  };
}
