import { map } from 'rxjs';
import { AuthenticationService } from 'src/app/services';

import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'alleva-input-sign-me-controls',
  templateUrl: './input-sign-me-controls.component.html',
  styleUrls: ['./input-sign-me-controls.component.scss'],
})
export class InputSignMeControlsComponent {
  public constructor(
    private readonly authenticationService: AuthenticationService,
  ) {}

  public isEnteringPin = false;

  /**
   * The appearance variant of the sign me component.
   *
   * @default 'client' Defaults to `client`.
   */
  @Input()
  @HostBinding('class')
  public appearance: InputSignMeAppearance | undefined;

  /**
   * Whether or not the signature is disabled.
   */
  @Input() public disabled = false;

  /**
   * Whether or not to enable the pin feature.
   */
  @Input() public enablePin = false;

  /**
   * Emit a notifying <void> event when the user clicks the clear button.
   */
  @Output() public readonly clear = new EventEmitter<void>();

  /**
   * Emit a notifying <void> event when the user clicks the enter pin button.
   */
  @Output() public readonly enterPIN = new EventEmitter<void>();

  protected readonly userHasPin = this.authenticationService.userChanges.pipe(
    map((user) => user?.hasPin ?? false),
  );

  protected onEnterPIN(): void {
    if (this.disabled || !this.enablePin) {
      return;
    }

    this.isEnteringPin = true;
    this.enterPIN.emit();
  }

  protected onClear(): void {
    if (this.disabled) {
      return;
    }

    this.clear.emit();
  }
}
