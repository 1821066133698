import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { isNonEmptyValue } from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';
import { InputDropdownOptionGroupComponent } from 'src/app/components/forms/input-dropdown/input-dropdown-option-group.component';
import { InputDropdownOptionComponent } from 'src/app/components/forms/input-dropdown/input-dropdown-option.component';
import { AwaitFor } from 'src/app/components/loading-text/loading-text.component';

/**
 * An input component for selecting a single or multiple options from a list.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-dropdown[formControl][label]',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent<T>
  extends CustomInputDirective<T>
  implements OnInit
{
  /**
   * The prefix icon to display on the input.
   */
  @Input() public prefixIcon?: Icon;

  @ViewChild(MatSelect) public readonly matSelect?: MatSelect;

  @ContentChildren(InputDropdownOptionComponent)
  public readonly options: QueryList<InputDropdownOptionComponent<T>> =
    new QueryList<InputDropdownOptionComponent<T>>();

  @ContentChildren(InputDropdownOptionGroupComponent)
  public readonly optionGroups: QueryList<
    InputDropdownOptionGroupComponent<T>
  > = new QueryList<InputDropdownOptionGroupComponent<T>>();

  /**
   * Await for this value before displaying the loading text.
   *
   * @default Do not await, display the loading text immediately.
   */
  @Input() public set awaitFor(value: AwaitFor | null) {
    this.awaitForSubject.next(value);
  }

  /**
   * The `awaitForHint` attribute controlling the hint of which field needs to be selected first.
   */
  @Input() public awaitForHint?: string;

  private readonly awaitForSubject = new BehaviorSubject<
    AwaitFor | null | undefined
  >(undefined);
  protected readonly awaitForChanges = this.awaitForSubject.asObservable();

  /** The label to display for the clear button. */
  @Input() public clearLabel?: string;

  /**
   * Whether the counter should be displayed when multiple options are selected.
   *
   * Examples when `true`:
   * - "My One Selected Value"  // One selected option.
   * - "2 selected"             // Two selected options.
   * - "3 selected"             // Three selected options.
   * - And so on...             // Four or more selected options.
   *
   * Examples when `false`:
   * - "First Value"                // One selected option.
   * - "First Value, Second Value"  // Two selected options.
   * - And so on...                 // Three or more selected options.
   *
   * @default true
   */
  @Input() public set displayCounter(value: BooleanInput) {
    this.#displayCounter = coerceBooleanProperty(value);
  }
  public get displayCounter(): boolean {
    return (
      this.multiple &&
      this.#displayCounter &&
      this.matSelect !== undefined &&
      Array.isArray(this.matSelect.selected) &&
      (this.matSelect.selected as ReadonlyArray<MatOption<T>>).length > 1
    );
  }
  #displayCounter = true;

  /** The maxiumum number of options that can be selected. */
  @Input() public override max: number | undefined;

  protected get isOverSelectionLimit(): boolean {
    if (this.max === undefined) {
      return false;
    }
    return this.selectedOptions.length > this.max;
  }

  /**
   * Whether the user should be allowed to select multiple options.
   */
  @Input() public set multiple(value: BooleanInput) {
    this.#multiple = coerceBooleanProperty(value);
  }
  public get multiple(): boolean {
    return this.#multiple;
  }
  #multiple = false;

  /** Whether or not the dropdown is clearable via the clear button. */
  @Input() public set isClearable(value: BooleanInput) {
    this.#isClearable = coerceBooleanProperty(value);
  }
  public get isClearable(): boolean {
    return this.#isClearable;
  }
  #isClearable = false;

  /**
   * Emit an event when the input dropdown was cleared with the internal clear
   * function and button.
   */
  @Output() public readonly cleared = new EventEmitter<void>();

  /** Whether or not the dropdown should display a select all option. */
  @Input() public set selectAllEnabled(value: BooleanInput) {
    this.#selectAllEnabled = coerceBooleanProperty(value);
  }
  public get selectAllEnabled(): boolean {
    return this.#selectAllEnabled;
  }
  #selectAllEnabled = false;

  public override ngOnInit(): void {
    super.ngOnInit();

    // If `multiple` is `false` and `max` attribute is set, throw an error.
    if (!this.multiple && this.max !== undefined) {
      throw new Error(
        'The `max` attribute can only be set when `multiple` is `true`.',
      );
    }

    // Subscribe to the value changes of the form control to update the error
    // state when the selection limit is exceeded.
    this.baseControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.isOverSelectionLimit) {
        this.baseControl.setErrors(
          {
            ...this.baseControl.errors,
            maxSelection: true,
          },
          { emitEvent: false },
        );
      }
    });
  }

  /** Clear the dropdown selection. */
  protected clear(): void {
    if (this.isClearable && this.matSelect) {
      if (Array.isArray(this.matSelect.selected)) {
        this.matSelect.selected.forEach((selected) => selected.deselect(false));
      } else {
        this.matSelect.selected.deselect(false);
      }
      this.baseControl.setValue(null);
      this.cleared.emit();
    }
  }

  /**
   * Toggles the selection of all options in the dropdown. If all options are
   * already selected, they will be deselected. If any options are not selected,
   * they will be selected.
   */
  protected toggleAll(): void {
    if (this.#selectAllEnabled && this.matSelect) {
      // Get all MatOption instances (including from option groups if they exist)
      const allOptions = this.matSelect.options.toArray();

      // If all options are already selected, deselect them
      if (allOptions.every((option) => option.selected)) {
        allOptions.forEach((option) => option.deselect());
        this.baseControl.setValue(null);
        return;
      }

      // Select each option
      allOptions.forEach((option) => option.select());

      // Update the form control with the selected values
      const selectedValues: T = allOptions.map((option) => option.value) as T;
      this.baseControl.setValue(selectedValues);
    }
  }

  /**
   * The currently selected options.
   */
  protected get selectedOptions(): ReadonlyArray<
    InputDropdownOptionComponent<T>
  > {
    // Component data is stil initializing.
    if (this.matSelect === undefined) {
      return [];
    }

    // Compile grouped options with ungrouped options for use in querying.
    const groupOptions = this.optionGroups.map((optionGroup) =>
      optionGroup.options.toArray(),
    );
    const options = new QueryList<InputDropdownOptionComponent<T>>();
    options.reset([this.options.toArray(), ...groupOptions]);

    // Single option is selected.
    if (this.matSelect.selected instanceof MatOption) {
      const singleSelection = this.matSelect.selected;
      const selected = options.find(
        (option) => singleSelection.value === option.value,
      );
      return selected ? [selected] : [];
    }

    // Multiple options are selected.
    if (
      Array.isArray(this.matSelect.selected) &&
      this.matSelect.selected.every((selected) => selected instanceof MatOption)
    ) {
      const multiSelection: readonly T[] = this.matSelect.selected.map(
        (selected) => selected.value,
      );
      return options.filter((option) => multiSelection.includes(option.value));
    }

    // No option is selected.
    return [];
  }

  /**
   * Function to compare the option values with the selected values. The first argument
   * is a value from an option. The second is a value from the selection. A boolean
   * should be returned.
   */
  @Input() public compareWith: MatSelect['compareWith'] = (
    optionValue,
    selectedValue,
  ) => optionValue === selectedValue;

  public getSelectedOptionsLabels(
    selectedOptions: ReadonlyArray<InputDropdownOptionComponent<T>>,
  ): string {
    return selectedOptions
      .map((selectedOption) => selectedOption.element.textContent)
      .filter(isNonEmptyValue)
      .join(', ');
  }
}
