import { areObjectsEqual } from 'src/app/utilities/objects';

/**
 * Iterate over a list of object values and filter out items that do not match
 * the query.
 *
 * @note Keep function in sync with the one found in `api/utilities/arrays.ts`.
 * @param values The list of objects to filter on.
 * @param query The value to filter by.
 * @param searchNestedKeys If true, search within nested objects as well.
 * @returns The filtered list of items.
 */
export function arrayFilterAll<T extends object>(
  values: readonly T[],
  query: string,
  { searchNestedKeys = false }: { searchNestedKeys?: boolean } = {},
): readonly T[] {
  // Convert query string to lower case to make the search case insensitive.
  const lowerCaseQuery = query.toLowerCase();
  // This regular expression is used to search for the query string in the
  // values. The `gi` means to search globally (find all matches) and to search
  // as case insensitive. We'll new it up here so it doesn't have to be created
  // for every value in our loop below.
  const regExp = new RegExp(lowerCaseQuery, 'gi');

  // Function to check individual values, with support for nested objects
  const checkValue = (value: unknown): boolean => {
    if (value === null || value === undefined) {
      // The value is null or undefined, so it cannot be filtered via the
      // query string. Return false to exclude it from the results.
      return false;
    }

    if (typeof value === 'object' && searchNestedKeys) {
      // Recursively check nested object values if searchNestedKeys is true
      return Object.values(value).some((nestedValue) =>
        checkValue(nestedValue),
      );
    }

    let stringValue: string;
    try {
      // Convert the value to a string and convert it to lower case to make
      // the value to be searched upon case insensitive.
      stringValue = String(value).toLowerCase();
    } catch {
      // Value cannot be converted to a string, so it cannot be filtered via
      // the query string. Return false to exclude it from the results.
      return false;
    }

    return (
      stringValue === lowerCaseQuery ||
      stringValue.includes(lowerCaseQuery) ||
      regExp.test(stringValue)
    );
  };

  // Filter the values to only include items that have a value that matches the
  // query string.
  return values.filter((item) =>
    Object.values(item).some((value) => checkValue(value)),
  );
}

/**
 * Gets a random item from the provided array.
 *
 * @param array The array to get a random item from.
 * @returns A random item from the array.
 */
export function getRandomItemFromArray<T>(array: T[]): T {
  if (!array.length) {
    throw new Error('Array is empty.');
  }
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Compare two arrays to see if they are equal.
 *
 * @param firstArray The first array to compare.
 * @param secondArray The second array to compare.
 * @param ignoreSort Whether or not to ignore the order of the items in the
 * array.
 * @returns True if the arrays are equal, false otherwise.
 */
export function areArraysEqual(
  firstArray: readonly unknown[],
  secondArray: readonly unknown[],
  ignoreSort: boolean = true,
): boolean {
  if (ignoreSort) {
    const firstSortedArray = [...firstArray].sort();
    const secondSortedArray = [...secondArray].sort();
    return areObjectsEqual(firstSortedArray, secondSortedArray);
  } else {
    return areObjectsEqual(firstArray, secondArray);
  }
}

/**
 * Merge two arrays. If an item exists in both arrays, it will only be added once.
 *
 * @param currentItems The array to update.
 * @param updatedItems The items to merge to the current array.
 * @param compareKeys The keys to use to compare the items. If not provided,
 * the items will be compared with `includes`.
 * @returns A new array with the new items added.
 */
export function mergeArrays<T>(
  currentItems: readonly T[],
  updatedItems: readonly T[],
  compareKeys?: ReadonlyArray<KeysOf<T>>,
): readonly T[] {
  return [
    ...currentItems,
    ...updatedItems.filter((newItem) => {
      if (compareKeys) {
        return !currentItems.some((currentItem) =>
          compareKeys.every((key) => newItem[key] === currentItem[key]),
        );
      }
      return !currentItems.includes(newItem);
    }),
  ];
}

/**
 * Remove items from an array. If the item does not exist in the array, it will not be removed.
 *
 * @param currentItems The array to update.
 * @param removedItems The items to remove from the array.
 * @param compareKeys The keys to use to compare the items. If not provided,
 * the items will be compared with `includes`.
 * @returns A new array with the items removed.
 */
export function removeFromArray<T>(
  currentItems: readonly T[],
  removedItems: readonly T[],
  compareKeys?: ReadonlyArray<KeysOf<T>>,
): readonly T[] {
  return currentItems.filter((currentItem) => {
    if (compareKeys) {
      return !removedItems.some((removedItem) =>
        compareKeys.every((key) => removedItem[key] === currentItem[key]),
      );
    }
    return !removedItems.includes(currentItem);
  });
}

/**
 * Sorts an array of objects by the provided property, ascending or descending.
 *
 * @param array The array to sort.
 * @param propertyPath The property to sort by.
 * @param ascending Whether to sort ascending or descending.
 * @returns The sorted array.
 */
export function sortArrayByKey<T>(
  array: T[] | readonly T[],
  key: NestedKeysOfString<T>,
  ascending = true,
): readonly T[] {
  const getNestedPropertyValue = (obj: T, path: string): unknown => {
    return path.split('.').reduce<unknown>((value, key) => {
      if (value === null || value === undefined) {
        return undefined;
      }
      return typeof value === 'object'
        ? (value as Record<string, unknown>)[key]
        : undefined;
    }, obj);
  };

  return [...array].sort((previous, next) => {
    const previousValue = getNestedPropertyValue(previous, key) as
      | number
      | string;
    const nextValue = getNestedPropertyValue(next, key) as number | string;

    if (previousValue === undefined && nextValue === undefined) {
      return 0;
    } else if (previousValue === undefined) {
      return ascending ? 1 : -1;
    } else if (nextValue === undefined) {
      return ascending ? -1 : 1;
    }

    if (typeof previousValue === 'number' && typeof nextValue === 'number') {
      return ascending ? previousValue - nextValue : nextValue - previousValue;
    } else if (
      typeof previousValue === 'string' &&
      typeof nextValue === 'string'
    ) {
      return ascending
        ? previousValue.localeCompare(nextValue)
        : nextValue.localeCompare(previousValue);
    } else {
      // Fallback comparison for other types
      return ascending
        ? String(previousValue).localeCompare(String(nextValue))
        : String(nextValue).localeCompare(String(previousValue));
    }
  });
}
