import { ClientRoomApi, ClientRoomUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientRoomApi>();

class ClientRoomBase {
  public constructor(props: ClassProperties<ClientRoomBase>) {
    this.name = props.name;
  }

  @api({ key: 'name' }) public readonly name: string;
}

export class ClientRoom extends ClientRoomBase {
  public constructor(props: ClassProperties<ClientRoom>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Room API model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'ClientRoomApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Client Room object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Room object.
   * @throws An error if the value is not a valid Client Room object.
   */
  public static deserialize(value: NonNullable<ClientRoomApi>): ClientRoom {
    const decoded = decode(ClientRoom.Codec, value);
    return new ClientRoom({
      id: decoded.id,
      name: decoded.name,
    });
  }

  /**
   * Deserializes a list of Client Room objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Room objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Room objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientRoomApi>>,
  ): readonly ClientRoom[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Room objects.');
    }
    return values.map(ClientRoom.deserialize);
  }
}

export class ClientRoomUpdate extends ClientRoomBase {
  public constructor(props: ClassProperties<ClientRoomUpdate>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): ClientRoomUpdateApi {
    return {
      name: this.name,
    };
  }
}
