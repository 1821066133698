import { NameApi, NameUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode, getFullName } from 'src/app/utilities';

const api = apiDecorator<NameApi>();

type NameArgs = Omit<
  ClassProperties<Name>,
  // Omit computed properties set on construct from outside data.
  'fullName'
>;

export class Name {
  public constructor(props: ClassProperties<NameArgs>) {
    this.first = props.first;
    this.last = props.last;
    this.middle = props.middle;

    // Calculated properties.
    this.fullName = getFullName(this);
  }

  /**
   * The io-ts codec for runtime type checking of the Name model.
   */
  public static readonly Codec = io.type(
    {
      first: io.union([io.string, io.null]),
      last: io.union([io.string, io.null]),
      middle: io.union([io.string, io.null, io.undefined]),
    },
    'NameApi',
  );

  @api({ key: 'first' }) public readonly first: string | null;
  @api({ key: 'last' }) public readonly last: string | null;
  @api({ key: 'middle' }) public readonly middle: string | null;

  // Calculated properties.
  public readonly fullName: string | null;

  /**
   * Deserializes a Name object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Name object.
   * @throws An error if the value is not a valid Name object.
   */
  public static deserialize(value: NonNullable<NameApi>): Name {
    const decoded = decode(Name.Codec, value);
    return new Name({
      ...decoded,
      middle: decoded.middle || null,
    });
  }

  /**
   * Deserializes a list of Name objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Name objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Name objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<NameApi>>,
  ): readonly Name[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Name objects.');
    }
    return values.map(Name.deserialize);
  }
}

export class NameUpdate extends Name {
  public serialize(): NameUpdateApi {
    return {
      first: this.first,
      last: this.last,
    };
  }
}
