import { BillingRuleClaimTypeOfBillApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { BillingRuleClaimTypeOfBillDetails } from './billing-rule-claim-type-of-bill-details.model';

const api = apiDecorator<BillingRuleClaimTypeOfBillApi>();

export abstract class BillingRuleClaimTypeOfBill {
  public constructor(props: ClassProperties<BillingRuleClaimTypeOfBill>) {
    this.care = props.care;
    this.facility = props.facility;
    this.frequency = props.frequency;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Claim
   * Type of Bill API model.
   */
  public static readonly Codec = io.type(
    {
      care: io.union([BillingRuleClaimTypeOfBillDetails.Codec, io.null]),
      facility: io.union([BillingRuleClaimTypeOfBillDetails.Codec, io.null]),
      frequency: io.union([BillingRuleClaimTypeOfBillDetails.Codec, io.null]),
    },
    'BillingRuleClaimTypeOfBillApi',
  );

  @api({ key: 'care' })
  public readonly care: BillingRuleClaimTypeOfBillDetails | null;
  @api({ key: 'facility' })
  public readonly facility: BillingRuleClaimTypeOfBillDetails | null;
  @api({ key: 'frequency' })
  public readonly frequency: BillingRuleClaimTypeOfBillDetails | null;

  /**
   * Deserializes a Billing Payor Rule Claim Type of Bill object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Claim Type fo Bill object.
   * @throws An error if the value is not a valid Billing Payor Rule Claim
   * Type of Bill object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleClaimTypeOfBillApi>,
  ): BillingRuleClaimTypeOfBill {
    const decoded = decode(BillingRuleClaimTypeOfBill.Codec, value);
    return {
      care: decoded.care
        ? BillingRuleClaimTypeOfBillDetails.deserialize(decoded.care)
        : null,
      facility: decoded.facility
        ? BillingRuleClaimTypeOfBillDetails.deserialize(decoded.facility)
        : null,
      frequency: decoded.frequency
        ? BillingRuleClaimTypeOfBillDetails.deserialize(decoded.frequency)
        : null,
    };
  }
}
