export class PageMeta {
  public constructor(props: ClassProperties<PageMeta>) {
    this.author = props.author;
    this.description = props.description;
    this.keywords = props.keywords;
    this.title = props.title;
  }

  public author: string;
  public description: string;
  public keywords: string[];
  public title: PageName;
}
