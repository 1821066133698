<mat-icon
  aria-hidden="false"
  [color]="color"
  [fontSet]="fontSet"
  [inline]="inline"
  [style.color]="colorHex"
  [class.flip-horizontal]="flipHorizontal"
  [class.spin]="spin"
  [class.two-tone-accent]="type === 'two-tone' && color === 'accent'"
  [class.two-tone-primary]="type === 'two-tone' && color === 'primary'"
  [class.two-tone-warn]="type === 'two-tone' && color === 'warn'"
  [svgIcon]="isCustom ? name : ''"
  >{{ name }}</mat-icon
>
