import { ClientInsuranceApi, ClientInsuranceUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientInsurancePlan } from 'src/app/models/client/client-insurance-plan.model';
import { NameId, NameIdUpdate } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';
import {
  InsuranceCompany,
  InsuranceCompanyUpdate,
} from 'src/app/models/insurance/insurance-company.model';
import {
  InsuranceDocument,
  InsuranceDocumentUpdate,
} from 'src/app/models/insurance/insurance-document.model';
import { InsurancePlanPolicyUpdate } from 'src/app/models/insurance/insurance-plan-policy.model';
import {
  InsuranceSubscriber,
  InsuranceSubscriberUpdate,
} from 'src/app/models/insurance/insurance-subscriber.model';
import {
  InsuranceVerification,
  InsuranceVerificationUpdate,
} from 'src/app/models/insurance/insurance-verification.model';
import { Rx } from 'src/app/models/medication/rx.model';

const api = apiDecorator<ClientInsuranceApi>();

type InsuranceArgs = Omit<
  ClassProperties<ClientInsurance>,
  // Omit computed properties set on construct from outside data.
  'insuranceCompanyName' | 'isVerified' | 'subscriberRelationshipName'
>;

abstract class ClientInsuranceBase {
  public constructor(props: ClassProperties<ClientInsuranceBase>) {
    this.active = props.active;
    this.billable = props.billable;
    this.notes = props.notes;
    this.primary = props.primary;
    this.rx = props.rx;
  }

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'billable' }) public readonly billable: boolean;
  @api({ key: 'notes' }) public readonly notes: string | null;
  @api({ key: 'primary' }) public readonly primary: boolean;
  @api({ key: 'rx' }) public readonly rx: Rx | null;
}

export class ClientInsurance extends ClientInsuranceBase {
  public constructor(props: ClassProperties<InsuranceArgs>) {
    super(props);

    this.company = props.company;
    this.documents = props.documents;
    this.id = props.id;
    this.plan = props.plan;
    this.priority = props.priority;
    this.subscriber = props.subscriber;
    this.verification = props.verification;

    // Computed values
    this.insuranceCompanyName = this.company?.name ?? 'N/A';
    this.isVerified = !!this.verification;
    this.subscriberRelationshipName =
      this.subscriber?.relationship?.name ?? null;
  }

  /**
   * The io-ts codec for runtime type checking of the ClientInsurance API model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      billable: io.boolean,
      company: io.union([InsuranceCompany.Codec, io.null]),
      documents: io.union([io.array(InsuranceDocument.Codec), io.null]),
      id: io.number,
      notes: io.union([io.string, io.null]),
      plan: ClientInsurancePlan.Codec,
      primary: io.boolean,
      priority: NameId.Codec,
      rx: io.union([Rx.Codec, io.null]),
      subscriber: io.union([InsuranceSubscriber.Codec, io.null]),
      verification: io.union([InsuranceVerification.Codec, io.null]),
    },
    'ClientInsuranceApi',
  );

  @api({ key: 'company' }) public readonly company: InsuranceCompany | null;
  @api({ key: 'documents' }) public readonly documents:
    | readonly InsuranceDocument[]
    | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'plan' }) public readonly plan: ClientInsurancePlan;
  @api({ key: 'priority' }) public readonly priority: NameId;
  @api({ key: 'subscriber' })
  public readonly subscriber: InsuranceSubscriber | null;
  @api({ key: 'verification' })
  public readonly verification: InsuranceVerification | null;

  // Computed values

  public readonly insuranceCompanyName: string;
  public readonly isVerified: boolean;
  public readonly subscriberRelationshipName: string | null;

  /**
   * Deserializes a ClientInsurance object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized ClientInsurance object.
   * @throws An error if the value is not a valid ClientInsurance object.
   */
  public static deserialize(
    value: NonNullable<ClientInsuranceApi>,
    deserializationArgs: ClientInsuranceDeserializationArgs,
  ): ClientInsurance {
    const decoded = decode(ClientInsurance.Codec, value);
    return new ClientInsurance({
      ...decoded,
      company: decoded.company
        ? InsuranceCompany.deserialize(decoded.company)
        : null,
      documents:
        decoded.documents && decoded.documents.length > 0
          ? InsuranceDocument.deserializeList(
              decoded.documents,
              deserializationArgs,
            )
          : null,
      plan: ClientInsurancePlan.deserialize(decoded.plan, deserializationArgs),
      priority: NameId.deserialize(decoded.priority),
      rx: decoded.rx ? Rx.deserialize(decoded.rx) : null,
      subscriber: decoded.subscriber
        ? InsuranceSubscriber.deserialize(
            decoded.subscriber,
            deserializationArgs,
          )
        : null,
      verification: decoded.verification
        ? InsuranceVerification.deserialize(
            decoded.verification,
            deserializationArgs,
          )
        : null,
    });
  }

  /**
   * Deserializes a list of ClientInsurance objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized ClientInsurance objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid ClientInsurance objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientInsuranceApi>>,
    deserializationArgs: ClientInsuranceDeserializationArgs,
  ): readonly ClientInsurance[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Insurance objects.');
    }
    return values.map((clientInsurance) =>
      ClientInsurance.deserialize(clientInsurance, deserializationArgs),
    );
  }
}

export class ClientInsuranceUpdate extends ClientInsuranceBase {
  public constructor(props: ClassProperties<ClientInsuranceUpdate>) {
    super(props);

    this.company = props.company;
    this.documents = props.documents;
    this.plan = props.plan;
    this.priority = props.priority;
    this.subscriber = props.subscriber;
    this.verification = props.verification;
  }

  @api({ key: 'company' }) public readonly company: InsuranceCompanyUpdate;
  @api({ key: 'documents' }) public readonly documents:
    | readonly InsuranceDocumentUpdate[]
    | null;
  @api({ key: 'plan' }) public readonly plan: {
    id: ClientInsurancePlan['id'];
    policy: InsurancePlanPolicyUpdate;
  };
  @api({ key: 'priority' }) public readonly priority: NameIdUpdate;
  @api({ key: 'subscriber' })
  public readonly subscriber: InsuranceSubscriberUpdate;
  @api({ key: 'verification' })
  public readonly verification: InsuranceVerificationUpdate | null;

  public serialize(): ClientInsuranceUpdateApi {
    return {
      ...this,
      company: this.company ? this.company.serialize() : null,
      documents: this.documents
        ? this.documents.map((document) => document.serialize())
        : null,
      plan: this.plan
        ? {
            id: this.plan.id,
            policy: this.plan.policy.serialize(),
          }
        : null,
      priority: this.priority ? this.priority.serialize() : null,
      subscriber: this.subscriber ? this.subscriber.serialize() : null,
      verification: this.verification ? this.verification.serialize() : null,
    };
  }
}

export interface ClientInsuranceDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
