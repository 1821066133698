export const CLIENT_CIWA_B_SCORES_DROPDOWN_OPTIONS: ClientCIWABScoreDropdownOptions =
  {
    anxiety: [
      { label: 'Not present', value: 0 },
      { label: 'Intermittent anxiety', value: 1 },
      { label: 'Mild persistent anxiety', value: 2 },
      { label: 'Moderate anxiety, requires constant support', value: 3 },
      { label: 'Severe anxiety creating difficulty performing ADLs', value: 4 },
    ],
    appetite: [
      { label: 'Appetite intact', value: 0 },
      { label: 'Mild appetite loss', value: 1 },
      { label: 'Moderate appetite loss', value: 2 },
      { label: 'No appetite, consuming small infrequent meals', value: 3 },
      { label: 'No appetite, unable to eat', value: 4 },
    ],
    concentration: [
      { label: 'Not Present', value: 0 },
      { label: 'Mild intermittent difficulty concentrating', value: 1 },
      { label: 'Moderate intermitent difficulty concentrating', value: 2 },
      { label: 'Moderate persistent difficlulty concentrating', value: 3 },
      {
        label:
          'Extreme difficulty concentrating, Unable to follow conversation',
        value: 4,
      },
    ],
    fatigue: [
      { label: 'No fatigue noted', value: 0 },
      { label: 'Mild fatigue without change in lifestyle', value: 1 },
      { label: 'Mild to moderate fatigue requiring periods of rest', value: 2 },
      {
        label: 'Moderate fatigue requiring frequent naps through out the day',
        value: 3,
      },
      { label: 'Severe Fatigue - unable to perform ADLs', value: 4 },
    ],
    fear: [
      { label: 'Not present', value: 0 },
      { label: 'Mild intermittent periods of fear', value: 1 },
      { label: 'Mild persistent periods of fearfulness', value: 2 },
      { label: 'Moderate persistent periods of fearfulness', value: 3 },
      {
        label: 'Intense periods of fear, difficulty performing ADLs',
        value: 4,
      },
    ],
    headache: [
      { label: 'Not present', value: 0 },
      { label: 'Mild sensitivity', value: 1 },
      { label: 'Moderate intermittent sensitivity', value: 2 },
      { label: 'Moderate persistent senseitivity', value: 3 },
      { label: 'Severe headache, difficulty performing ADLs', value: 4 },
    ],
    insomnia: [
      { label: 'Yes, no difficulties', value: 0 },
      { label: 'Difficulty falling asleep, slept well', value: 1 },
      { label: 'Able to fall asleep but woke often, feel okay', value: 2 },
      {
        label: 'Difficulty falling asleep and staying asleep, feel okay',
        value: 3,
      },
      { label: 'No, I did not sleep well at all', value: 4 },
    ],
    irritability: [
      { label: 'Not Present', value: 0 },
      { label: 'Mild intermittent irritability', value: 1 },
      { label: 'Moderate intermittent irritability', value: 2 },
      { label: 'Moderate persistent irritability', value: 3 },
      { label: 'Severe irritability', value: 4 },
    ],
    mood: [
      { label: 'Not at all', value: 0 },
      { label: 'Fleeting periods of unease', value: 1 },
      { label: 'Intermittent periods of unease', value: 2 },
      { label: 'Persistent periods of unease, self redirects', value: 3 },
      {
        label: 'Severe periods of unease, requires constant redirection',
        value: 4,
      },
    ],
    muscle: [
      { label: 'Not present', value: 0 },
      { label: 'Very mild', value: 1 },
      { label: 'Mild', value: 2 },
      { label: 'Moderate', value: 3 },
      { label: 'Severe stiffness or pain', value: 4 },
    ],
    palpitations: [
      { label: 'Not present', value: 0 },
      { label: 'Brief intermittent periods of heart racing', value: 1 },
      {
        label:
          'Intermittent periods of heart racing, lasting greater than one minute in length',
        value: 2,
      },
      { label: 'Frequent periods of racing heart', value: 3 },
      { label: 'Constant feeling of racing heart', value: 4 },
    ],
    sweats: [
      { label: 'Not present', value: 0 },
      { label: 'Barely perceptible, palms moist', value: 1 },
      { label: 'Palms and forehead moist, reports armpit sweat', value: 2 },
      { label: 'Beads of sweat obvious on forehead', value: 3 },
      { label: 'Drenching sweat', value: 4 },
    ],
    restfulness: [
      { label: 'Rested throughout the night, refreshed', value: 0 },
      { label: 'Brief periods of sleep disturbances, well rested', value: 1 },
      { label: 'Somewhat rested', value: 2 },
      { label: 'Frequent sleep disturbances, reports restlessness', value: 3 },
      { label: 'Insomnia, not at all rested', value: 4 },
    ],
    restlessness: [
      { label: 'None present', value: 0 },
      {
        label: 'Reports difficulty sitting still, but is able to do so',
        value: 1,
      },
      { label: 'Intermittent movements of one or more extremities', value: 2 },
      {
        label: 'Frequent shifting or extraneous movements of the extremities',
        value: 3,
      },
      { label: 'Unable to sit still for more than a few seconds', value: 4 },
    ],
    tactileDisturbances: [
      { label: 'No numbness', value: 0 },
      {
        label: 'Very mild intermittent pins and needles, burning or numbness',
        value: 1,
      },
      {
        label: 'Mild itching, pins and needles, burning or numbness',
        value: 2,
      },
      {
        label: 'Moderate itching, pins and needles, burning or numbness',
        value: 3,
      },
      { label: 'Intense pins and needles, burning or numbness', value: 4 },
    ],
    tense: [
      { label: 'Not Present', value: 0 },
      { label: 'Mild intermittent periods of concern', value: 1 },
      { label: 'Moderate intermitent periods of concern', value: 2 },
      { label: 'Moderate persistent periods of concern', value: 3 },
      { label: 'Severe tension making it difficult to function', value: 4 },
    ],
    tremors: [
      { label: 'Not present', value: 0 },
      { label: 'Not visible but can be felt upon assessment', value: 1 },
      { label: 'Mild visible tremors', value: 2 },
      { label: 'Moderate visible tremors', value: 3 },
      { label: 'Gross tremors', value: 4 },
    ],
    visualDisturbances: [
      { label: 'Not present', value: 0 },
      { label: 'Mild intermittent sensitivity noted', value: 1 },
      { label: 'Mild persistent sensitivity', value: 2 },
      { label: 'Moderate persistent sensitivity', value: 3 },
      { label: 'Severe sensitivity or hallucinations', value: 4 },
    ],
    weakness: [
      { label: 'Not at all', value: 0 },
      { label: 'Intermittently weak', value: 1 },
      { label: 'Mild persistent', value: 2 },
      { label: 'Moderate persistent', value: 3 },
      { label: 'Severe persistent', value: 4 },
    ],
    worry: [
      { label: 'Not present', value: 0 },
      { label: 'Fleeting thoughts of the past or future', value: 1 },
      { label: 'Intermittent worry, self redirects', value: 2 },
      {
        label:
          'Frequent thought of misfortunes, past or future, requiring redirection',
        value: 3,
      },
      { label: 'Overcome with worry, requires constant redirection', value: 4 },
    ],
  };

interface ClientCIWABScoreDropdownOptions {
  anxiety: readonly ClientCIWABScoreDropdownOption[];
  appetite: readonly ClientCIWABScoreDropdownOption[];
  concentration: readonly ClientCIWABScoreDropdownOption[];
  fatigue: readonly ClientCIWABScoreDropdownOption[];
  fear: readonly ClientCIWABScoreDropdownOption[];
  headache: readonly ClientCIWABScoreDropdownOption[];
  insomnia: readonly ClientCIWABScoreDropdownOption[];
  irritability: readonly ClientCIWABScoreDropdownOption[];
  mood: readonly ClientCIWABScoreDropdownOption[];
  muscle: readonly ClientCIWABScoreDropdownOption[];
  palpitations: readonly ClientCIWABScoreDropdownOption[];
  sweats: readonly ClientCIWABScoreDropdownOption[];
  restfulness: readonly ClientCIWABScoreDropdownOption[];
  restlessness: readonly ClientCIWABScoreDropdownOption[];
  tactileDisturbances: readonly ClientCIWABScoreDropdownOption[];
  tense: readonly ClientCIWABScoreDropdownOption[];
  tremors: readonly ClientCIWABScoreDropdownOption[];
  visualDisturbances: readonly ClientCIWABScoreDropdownOption[];
  weakness: readonly ClientCIWABScoreDropdownOption[];
  worry: readonly ClientCIWABScoreDropdownOption[];
}

interface ClientCIWABScoreDropdownOption {
  label: string;
  value: 0 | 1 | 2 | 3 | 4;
}
