<alleva-icon
  *ngIf="client.status.name === ClientStatusEnum.ACTIVE"
  [inline]="true"
  color="success"
  name="client_status_active"
></alleva-icon>
<alleva-icon
  *ngIf="client.status.name === ClientStatusEnum.DISCHARGED"
  [inline]="true"
  colorHex="var(--orange-dark)"
  name="client_status_discharged"
></alleva-icon>
<alleva-icon
  *ngIf="client.status.name === ClientStatusEnum.INACTIVE"
  [inline]="true"
  colorHex="var(--gray-dark)"
  name="client_status_inactive"
></alleva-icon>
<alleva-icon
  *ngIf="client.status.name === ClientStatusEnum.SUSPENDED"
  [inline]="true"
  color="warn"
  name="client_status_suspended"
></alleva-icon>
