import { TreatmentPlanReviewApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientName } from 'src/app/models/client/client-name.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { Reviewer } from 'src/app/models/reviewer.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<TreatmentPlanReviewApi>();

type TreatmentPlanReviewArgs = Omit<
  ClassProperties<TreatmentPlanReview>,
  // Omit "computed properties" set on construct.
  'reviewerNames'
>;

export class TreatmentPlanReview {
  public constructor(props: ClassProperties<TreatmentPlanReviewArgs>) {
    this.clientId = props.clientId;
    this.clientName = props.clientName;
    this.createdBy = props.createdBy;
    this.createdDate = props.createdDate;
    this.id = props.id;
    this.isSignedByClient = props.isSignedByClient;
    this.isSignedByCreator = props.isSignedByCreator;
    this.isSignedByGuardian = props.isSignedByGuardian;
    this.reviewers = props.reviewers;
    this.signatureStatusReviewer = props.signatureStatusReviewer;
    this.status = props.status;
    this.treatmentPlanId = props.treatmentPlanId;

    // Computed properties
    this.reviewerNames = this.reviewers
      ? this.reviewers.map((reviewer) => reviewer.fullName).join(', ')
      : null;
  }

  /**
   * The io-ts codec for runtime type checking of the Treatment Plan Review
   * API model.
   */
  public static readonly Codec = io.type(
    {
      clientId: io.number,
      clientName: ClientName.Codec,
      createdBy: UserBase.BaseCodec,
      createdDate: io.string,
      id: io.number,
      reviewers: io.union([io.array(Reviewer.Codec), io.null]),
      signatureStatusClient: io.boolean,
      signatureStatusCreator: io.boolean,
      signatureStatusGuardian: io.boolean,
      signatureStatusReviewer: io.union([
        io.literal('Pending'),
        io.literal('Completed'),
        io.literal('No Reviewer'),
      ]),
      status: io.union([
        io.literal('Pending'),
        io.literal('Completed'),
        io.literal('In Progress'),
      ]),
      treatmentPlanId: io.number,
    },
    'TreatmentPlanReviewApi',
  );

  @api({ key: 'clientId' }) public readonly clientId: number;
  @api({ key: 'clientName' }) public readonly clientName: ClientName;
  @api({ key: 'createdBy' }) public readonly createdBy: UserBase;
  @api({ key: 'createdDate' }) public readonly createdDate: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'signatureStatusClient' })
  public readonly isSignedByClient: boolean;
  @api({ key: 'signatureStatusCreator' })
  public readonly isSignedByCreator: boolean;
  @api({ key: 'signatureStatusGuardian' })
  public readonly isSignedByGuardian: boolean;
  @api({ key: 'reviewers' }) public readonly reviewers:
    | readonly Reviewer[]
    | null;
  @api({ key: 'signatureStatusReviewer' })
  public readonly signatureStatusReviewer:
    | 'Pending'
    | 'Completed'
    | 'No Reviewer';
  @api({ key: 'status' }) public readonly status:
    | 'Pending'
    | 'Completed'
    | 'In Progress';
  @api({ key: 'treatmentPlanId' }) public readonly treatmentPlanId: number;

  // Computed properties
  public readonly reviewerNames: string | null;

  /**
   * Deserializes a Treatment Plan Review object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Treatment Plan Review object.
   * @throws An error if the value is not a valid Treatment Plan review object.
   */
  public static deserialize(
    value: NonNullable<TreatmentPlanReviewApi>,
    deserializationArgs: TreatmentPlanReviewDeserializationArgs,
  ): TreatmentPlanReview {
    const decoded = decode(TreatmentPlanReview.Codec, value);
    return new TreatmentPlanReview({
      ...decoded,
      clientName: ClientName.deserialize(decoded.clientName),
      createdBy: UserBase.deserialize(decoded.createdBy),
      createdDate: DateTime.fromISO(decoded.createdDate, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      isSignedByClient: decoded.signatureStatusClient,
      isSignedByCreator: decoded.signatureStatusCreator,
      isSignedByGuardian: decoded.signatureStatusGuardian,
      reviewers: decoded.reviewers
        ? Reviewer.deserializeList(decoded.reviewers, deserializationArgs)
        : null,
    });
  }

  /**
   * Deserializes a list of Treatment Plan Review objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Treatment Plan Review objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Treatment Plan review
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<TreatmentPlanReviewApi>>,
    deserializationArgs: TreatmentPlanReviewDeserializationArgs,
  ): readonly TreatmentPlanReview[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Treatment Plan Review objects.');
    }
    return values.map((treatmentPlanReview) =>
      TreatmentPlanReview.deserialize(treatmentPlanReview, deserializationArgs),
    );
  }
}

export interface TreatmentPlanReviewDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
