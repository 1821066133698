<div class="inputs-container">
  <ng-container [ngSwitch]="formGroup.controls['units'].value">
    <!-- CENTIMETERS -->
    <mat-form-field
      *ngSwitchCase="HeightUnitsEnum.CENTIMETERS"
      [appearance]="'outline'"
      class="height-value cm"
    >
      <mat-label>{{ label }} (cm)</mat-label>
      <ng-template
        [ngTemplateOutlet]="prefixIconContainerTemplate"
      ></ng-template>
      <span class="input-container">
        <input
          [formControl]="formGroup.controls['centimeters']"
          [id]="centimetersId"
          [required]="required"
          [step]="0.1"
          autocomplete="off"
          matInput
          name="Height (cm)"
          type="number"
        />
      </span>
    </mat-form-field>
    <!-- FEET/INCHES -->
    <ng-container *ngSwitchCase="HeightUnitsEnum.INCHES">
      <!-- FEET -->
      <mat-form-field [appearance]="'outline'" class="height-value ft">
        <mat-label>Feet</mat-label>
        <ng-template
          [ngTemplateOutlet]="prefixIconContainerTemplate"
        ></ng-template>
        <span class="input-container">
          <input
            [formControl]="formGroup.controls['feet']"
            [id]="feetId"
            [required]="required"
            [step]="1"
            matInput
            name="Feet"
            type="number"
          />
        </span>
      </mat-form-field>
      <!-- INCHES -->
      <mat-form-field [appearance]="'outline'" class="height-value in">
        <mat-label>Inches</mat-label>
        <span class="input-container">
          <input
            [formControl]="formGroup.controls['inches']"
            [id]="inchesId"
            [required]="required"
            [step]="1"
            matInput
            name="Inches"
            type="number"
          />
        </span>
      </mat-form-field>
    </ng-container>
    <!-- EMPTY -->
    <mat-form-field
      *ngSwitchDefault
      [appearance]="'outline'"
      class="height-value empty"
    >
      <mat-label>Height</mat-label>
      <ng-template
        [ngTemplateOutlet]="prefixIconContainerTemplate"
      ></ng-template>
      <span class="input-container">
        <input
          [disabled]="baseControl.disabled"
          [id]="valueId"
          [required]="required"
          [value]="null"
          autocomplete="off"
          matInput
          name="Height"
          type="number"
        />
      </span>
    </mat-form-field>
  </ng-container>
  <!-- UNITS -->
  <mat-form-field
    [appearance]="'outline'"
    [class.cm]="
      formGroup.controls['units'].value === HeightUnitsEnum.CENTIMETERS
    "
    [class.empty]="formGroup.controls['units'].value === null"
    [class.ft-in]="formGroup.controls['units'].value === HeightUnitsEnum.INCHES"
    class="height-units"
  >
    <mat-label>Units</mat-label>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['units']"
        [id]="unitsId"
        [multiple]="false"
        [required]="required"
        name="Units"
      >
        <mat-option [value]="HeightUnitsEnum.INCHES">ft/in</mat-option>
        <mat-option [value]="HeightUnitsEnum.CENTIMETERS">
          {{ HeightUnitsEnum.CENTIMETERS }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>

<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div
    class="errors-container"
    *ngIf="formGroup.invalid && (formGroup.dirty || formGroup.touched)"
  >
    <!-- CENTIMETERS INPUT VALIDATION ERRORS -->
    <ng-container
      *ngIf="
        (formGroup.controls['units'].value === HeightUnitsEnum.CENTIMETERS &&
          ctrls.centimeters.touched) ||
        ctrls.centimeters.dirty
      "
    >
      <mat-error *ngIf="ctrls.centimeters.errors">
        <ng-container *ngIf="ctrls.centimeters.hasError('required')"
          >Centimeter value is required.</ng-container
        >
        <ng-container
          *ngIf="
            !ctrls.centimeters.hasError('required') &&
            ctrls.centimeters.hasError('min') &&
            ctrls.centimeters.errors
          "
          >{{ label }} must be at least
          {{
            ctrls.centimeters.errors['min'].min | number: '1.2-2'
          }}.</ng-container
        >
      </mat-error>
    </ng-container>

    <!-- FEET INPUT VALIDATION ERRORS -->
    <ng-container
      *ngIf="
        (formGroup.controls['units'].value === HeightUnitsEnum.INCHES &&
          ctrls.feet.touched) ||
        ctrls.feet.dirty
      "
    >
      <mat-error *ngIf="ctrls.feet.errors">
        <ng-container *ngIf="ctrls.feet.hasError('required')"
          >Feet value is required.</ng-container
        >
        <ng-container
          *ngIf="!ctrls.feet.hasError('required') && ctrls.feet.hasError('min')"
          >Feet value must be greater than 0.</ng-container
        >
      </mat-error>
    </ng-container>

    <!-- INCHES INPUT VALIDATION ERRORS -->
    <ng-container
      *ngIf="
        (formGroup.controls['units'].value === HeightUnitsEnum.INCHES &&
          ctrls.inches.touched) ||
        ctrls.inches.dirty
      "
    >
      <mat-error *ngIf="ctrls.inches.errors && !ctrls.feet.errors">
        <ng-container *ngIf="ctrls.inches.hasError('required')"
          >Inches value is required.</ng-container
        >
        <ng-container
          *ngIf="
            !ctrls.inches.hasError('required') && ctrls.inches.hasError('min')
          "
          >Inches value must be greater than 0.</ng-container
        >
      </mat-error>
    </ng-container>

    <!-- UNITS INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrls.units.touched || ctrls.units.dirty">
      <mat-error *ngIf="ctrls.units.errors">
        <ng-container *ngIf="ctrls.units.hasError('required')"
          >Units are required.</ng-container
        >
      </mat-error>
    </ng-container>
  </div>
</ng-template>

<ng-template #prefixIconContainerTemplate>
  <div class="prefix-icon-container">
    <alleva-icon
      [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
      [color]="hasErrors ? 'warn' : 'primary'"
      class="prefix-icon"
      name="height"
    ></alleva-icon>
  </div>
</ng-template>
