import { BillingRuleRateApi, BillingRuleRateUpdateApi } from 'api/models';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';

const api = apiDecorator<BillingRuleRateApi>();

export class BillingRuleRateUpdate {
  public constructor(props: ClassProperties<BillingRuleRateUpdate>) {
    this.contractRate = props.contractRate;
    this.endDateTime = props.endDateTime;
    this.id = props.id;
    this.startDate = props.startDate;
    this.unitRate = props.unitRate;
  }

  @api({ key: 'contractRate' }) public readonly contractRate: number | null;
  @api({ key: 'endDate' }) public readonly endDateTime: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'startDate' }) public readonly startDate: DateTime;
  @api({ key: 'unitRate' }) public readonly unitRate: number;

  public serialize(): BillingRuleRateUpdateApi {
    const endDate = this.endDateTime.toISO();
    if (!endDate) {
      throw new Error('Failed to serialize `endDate` in BillingRuleRate.');
    }
    const startDate = this.startDate.toISO();
    if (!startDate) {
      throw new Error('Failed to serialize `startDate` in BillingRuleRate.');
    }

    return {
      contractRate: this.contractRate,
      endDate,
      id: this.id,
      startDate,
      unitRate: this.unitRate,
    };
  }
}
