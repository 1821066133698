import { NoteUserApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode, getFullName } from 'src/app/utilities';

const api = apiDecorator<NoteUserApi>();

type NoteUserArgs = Omit<
  ClassProperties<NoteUser>,
  // Omit computed properties set on construct from outside data.
  'fullName'
>;

export class NoteUser {
  public constructor(props: ClassProperties<NoteUserArgs>) {
    this.firstName = props.firstName;
    this.id = props.id;
    this.lastName = props.lastName;

    // Computed properties.
    this.fullName =
      getFullName({ first: this.firstName, last: this.lastName }) ?? 'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the Note User API model.
   */
  public static readonly Codec = io.type(
    {
      first: io.union([io.string, io.null]),
      id: io.number,
      last: io.union([io.string, io.null]),
    },
    'NoteUserApi',
  );

  @api({ key: 'first' }) public readonly firstName: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'last' }) public readonly lastName: string | null;

  // Computed properties.
  public readonly fullName: string;

  /**
   * Deserializes a Note User object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Note User object.
   * @throws An error if the value is not a valid Note User object.
   */
  public static deserialize(value: NonNullable<NoteUserApi>): NoteUser {
    const decoded = decode(NoteUser.Codec, value);
    return new NoteUser({
      ...decoded,
      firstName: decoded.first,
      lastName: decoded.last,
    });
  }

  /**
   * Deserializes a list of Note User objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Note User objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Note User objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<NoteUserApi>>,
  ): readonly NoteUser[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Note User objects.');
    }
    return values.map(NoteUser.deserialize);
  }
}
