import { ClientAllergyApi, ClientAllergyUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { AllergenSeverityEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { Allergen } from 'src/app/models/allergen.model';
import { AllergyType } from 'src/app/models/allergy-type.model';
import { NameId } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientAllergyApi>();

type ClientAllergyArgs = Omit<
  ClassProperties<ClientAllergy>,
  // Omit computed properties set on construct from outside data.
  'allergenName' | 'reactionName' | 'typeName'
>;

abstract class ClientAllergyBase {
  public constructor(props: ClassProperties<ClientAllergyBase>) {
    this.allergen = props.allergen;
    this.nka = props.nka;
    this.notes = props.notes;
    this.reaction = props.reaction;
    this.severity = props.severity;
    this.type = props.type;
  }

  @api({ key: 'allergen' }) public readonly allergen: Allergen | null;
  @api({ key: 'nka' }) public readonly nka: boolean;
  @api({ key: 'notes' }) public readonly notes: string | null;
  @api({ key: 'reaction' }) public readonly reaction: NameId | null;
  @api({ key: 'severity' })
  public readonly severity: AllergenSeverityEnum | null;
  @api({ key: 'type' }) public readonly type: AllergyType | null;
}

export class ClientAllergy extends ClientAllergyBase {
  public constructor(props: ClassProperties<ClientAllergyArgs>) {
    super(props);

    this.active = props.active;
    this.createdAt = props.createdAt;
    this.id = props.id;

    // Computed values
    this.allergenName = this.allergen?.name ?? '—';
    this.reactionName = this.reaction?.name ?? '—';
    this.typeName = this.type?.name
      ? this.type.name
      : this.nka
        ? 'No Known Allergies'
        : '—';
  }

  /**
   * The io-ts codec for runtime type checking of the Client Allergy API model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      allergen: io.union([Allergen.Codec, io.null]),
      createdAt: io.string,
      id: io.number,
      nka: io.boolean,
      notes: io.union([io.string, io.null]),
      reaction: io.union([NameId.Codec, io.null]),
      severity: io.union([
        io.literal(AllergenSeverityEnum.FATAL),
        io.literal(AllergenSeverityEnum.MILD),
        io.literal(AllergenSeverityEnum.MILD_TO_MODERATE),
        io.literal(AllergenSeverityEnum.MODERATE),
        io.literal(AllergenSeverityEnum.MODERATE_TO_SEVERE),
        io.literal(AllergenSeverityEnum.SEVERE),
        io.null,
      ]),
      type: io.union([AllergyType.Codec, io.null]),
    },
    'ClientAllergyApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'createdAt' }) public readonly createdAt: DateTime;
  @api({ key: 'id' }) public readonly id: number;

  // Computed values
  public readonly allergenName: string;
  public readonly reactionName: string;
  public readonly typeName: string;

  /**
   * Deserializes a Client Allergy object from an API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Allergy object Signed object.
   * @throws An error if the value is not a valid Client Allergy object.
   */
  public static deserialize(
    value: NonNullable<ClientAllergyApi>,
    deserializationArgs: ClientAllergyDeserializationArgs,
  ): ClientAllergy {
    const decoded = decode(ClientAllergy.Codec, value);
    return new ClientAllergy({
      ...decoded,
      allergen: decoded.allergen
        ? Allergen.deserialize(decoded.allergen)
        : null,
      createdAt: DateTime.fromISO(decoded.createdAt, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      reaction: decoded.reaction ? NameId.deserialize(decoded.reaction) : null,
      type: decoded.type ? AllergyType.deserialize(decoded.type) : null,
    });
  }

  /**
   * Deserializes a list of Client Allergy objects from an API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Client Client Allergy objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Allergy
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ClientAllergyApi>>,
    deserializationArgs: ClientAllergyDeserializationArgs,
  ): readonly ClientAllergy[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Allergy objects.');
    }
    return values.map((allergy) =>
      ClientAllergy.deserialize(allergy, deserializationArgs),
    );
  }
}

export class ClientAllergyUpdate extends ClientAllergyBase {
  public constructor(props: ClassProperties<ClientAllergyUpdate>) {
    super(props);
  }

  public serialize(): ClientAllergyUpdateApi {
    return {
      allergen: this.allergen,
      nka: this.nka,
      notes: this.notes,
      reaction: this.reaction,
      severity: this.severity,
      type: this.type,
    };
  }
}

export interface ClientAllergyDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
