import { arrayFilterAll } from 'src/app/utilities';

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Iterate over a list of object values and filter out items that do not match
 * the query.
 *
 * @param values The list of objects to filter on.
 * @param query The value to filter by.
 * @returns The filtered list of items.
 */
@Pipe({ name: 'filterAll' })
export class FilterAllPipe<T extends object> implements PipeTransform {
  public transform(
    values: readonly T[] | null | undefined,
    query: string | undefined,
  ): readonly T[] {
    if (!values || values.length === 0) {
      return [];
    } else if (!query || query.length === 0) {
      return values;
    }

    return arrayFilterAll<T>(values, query);
  }
}
