import { DialogMaxWidthEnum } from 'src/app/enumerators';

/**
 * The configuration for the dialog.
 */
export class DialogConfig<T> {
  public readonly data?: T;
  public options?: DialogOptions;
}

/**
 * The options for the dialog.
 */
export interface DialogOptions {
  /**
   * The opacity of the background.
   *
   * @default 0.5
   */
  backgroundOpacity?: number;
  /**
   * Whether or not to blur the background to obscure what's behind the
   * backdrop.
   *
   * @default false
   */
  blurBackground?: boolean;
  /**
   * Whether or not to close the dialog when the user clicks outside of it.
   *
   * @default true
   */
  closeOnBackdropClick?: boolean;
  /**
   * Whether or not to auto-close the dialog when navigation occurs.
   *
   * @default true
   */
  closeOnNavigation?: boolean;
  /** The title of the dialog itself, displayed in the header. */
  dialogTitle?: string;
  /** The [forced] height of the dialog. */
  height?: string;
  /** The text to display in the "Shadow Box" intro section of the dialog. */
  introSectionText?: string;
  /** Whether or not the "X" close icon is displayed for use in the header. */
  isCloseIconDisplayed?: boolean;
  /**
   * The maximum dialog height.
   *
   * @default 'auto'
   */
  maxHeight?: string;
  /**
   * The maximum dialog width.
   *
   * @default DialogMaxWidthEnum.LARGE
   */
  maxWidth?: DialogMaxWidthEnum;
  /**
   * The minimum dialog height.
   *
   * @default 'auto'
   */
  minHeight?: string;
  /** The minimum dialog width. */
  minWidth?: string;
  /** The [forced] width of the dialog. */
  width?: string;
}
