import { InsuranceSubscriberApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { GenderEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { Address, AddressUpdate } from 'src/app/models/user/address.model';
import { Name } from 'src/app/models/user/name.model';

const api = apiDecorator<InsuranceSubscriberApi>();

export class InsuranceSubscriber {
  public constructor(props: ClassProperties<InsuranceSubscriber>) {
    this.address = props.address;
    this.dateOfBirth = props.dateOfBirth;
    this.employer = props.employer;
    this.gender = props.gender;
    this.name = props.name;
    this.phoneNumber = props.phoneNumber;
    this.relationship = props.relationship;
    this.ssn = props.ssn;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Subscriber model.
   */
  public static readonly Codec = io.type(
    {
      address: io.union([Address.Codec, io.null]),
      dateOfBirth: io.union([io.string, io.null]),
      employer: io.union([io.string, io.null]),
      gender: io.union([
        io.literal(GenderEnum.FEMALE),
        io.literal(GenderEnum.GENDER_VARIANT_NON_CONFORMING),
        io.literal(GenderEnum.MALE),
        io.literal(GenderEnum.NON_BINARY),
        io.literal(GenderEnum.NO_PREFERRENCE),
        io.literal(GenderEnum.PREFFER_NOT_TO_ANSWER),
        io.literal(GenderEnum.TRANSGENDER),
        io.null,
      ]),
      name: Name.Codec,
      phoneNumber: io.union([io.string, io.null]),
      relationship: NameId.Codec,
      ssn: io.union([io.string, io.null]),
    },
    'InsuranceSubscriberApi',
  );

  @api({ key: 'address' }) public readonly address: Address | null;
  @api({ key: 'dateOfBirth' }) public readonly dateOfBirth: DateTime | null;
  @api({ key: 'employer' }) public readonly employer: string | null;
  @api({ key: 'gender' }) public readonly gender: GenderEnum | null;
  @api({ key: 'name' }) public readonly name: Name;
  @api({ key: 'phoneNumber' }) public readonly phoneNumber: string | null;
  @api({ key: 'relationship' }) public readonly relationship: NameId;
  @api({ key: 'ssn' }) public readonly ssn: string | null;

  /**
   * Deserializes a Insurance Subscriber object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Subscriber object.
   * @throws An error if the value is not a valid Insurance Subscriber object.
   */
  public static deserialize(
    value: NonNullable<InsuranceSubscriberApi>,
    deserializationArgs: InsuranceSubscribedrDeserializationArgs,
  ): InsuranceSubscriber {
    const decoded = decode(InsuranceSubscriber.Codec, value);
    return new InsuranceSubscriber({
      ...decoded,
      address: decoded.address ? Address.deserialize(decoded.address) : null,
      dateOfBirth: decoded.dateOfBirth
        ? DateTime.fromISO(decoded.dateOfBirth, {
            zone: deserializationArgs.facilityTimeZone,
          })
        : null,
      gender: decoded.gender,
      name: Name.deserialize(decoded.name),
    });
  }

  /**
   * Deserializes a list of Insurance Subscriber objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Insurance Subscriber objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Subscriber objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceSubscriberApi>>,
    deserializationArgs: InsuranceSubscribedrDeserializationArgs,
  ): readonly InsuranceSubscriber[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Subscriber objects.');
    }
    return values.map((insuranceSubscriber) =>
      InsuranceSubscriber.deserialize(insuranceSubscriber, deserializationArgs),
    );
  }
}

export class InsuranceSubscriberUpdate extends InsuranceSubscriber {
  public constructor(props: ClassProperties<InsuranceSubscriberUpdate>) {
    super(props);

    this.address = props.address;
  }

  @api({ key: 'address' }) public override readonly address: AddressUpdate;

  public serialize(): InsuranceSubscriberApi {
    return {
      ...this,
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toISO() : null,
    };
  }
}

export interface InsuranceSubscribedrDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
