import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, distinctUntilChanged } from 'rxjs';

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
  public constructor(public readonly breakpointObserver: BreakpointObserver) {}

  private readonly isMobileSubject = new ReplaySubject<boolean>(1);
  public readonly isMobileChanges = this.isMobileSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isInitialized = false;

  public initialize(): void {
    if (this.isInitialized) {
      throw new Error('The screen size service is already initialized!');
    }
    this.isInitialized = true;

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Handset,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
      ])
      .pipe(untilDestroyed(this))
      .subscribe((state: BreakpointState) => {
        this.isMobileSubject.next(state.matches);
      });
  }
}
