import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, take } from 'rxjs';
import { DialogMaxWidthEnum } from 'src/app/enumerators';
import { FacilityService } from 'src/app/services';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {
  BasicDialogComponent,
  BasicInputData,
  BasicOutputData,
} from 'src/app/components/dialogs/basic-dialog.component';
import { DialogService } from 'src/app/components/dialogs/dialog.service';
import {
  PersonSearchDialogComponent,
  PersonSearchDialogComponentOutput,
} from 'src/app/components/person-search/person-search-dialog.component';

@UntilDestroy()
@Component({
  selector: 'alleva-person-search-button',
  templateUrl: './person-search-button.component.html',
  styleUrls: ['./person-search-button.component.scss'],
})
export class PersonSearchButtonComponent {
  public constructor(
    private readonly dialogService: DialogService,
    private readonly facilityService: FacilityService,
    private readonly router: Router,
  ) {}

  protected openPersonSearchDialog(): void {
    const personSearchDialog = this.dialogService.open<
      PersonSearchDialogComponent,
      undefined,
      PersonSearchDialogComponentOutput | undefined
    >(PersonSearchDialogComponent, {
      data: undefined,
      options: {
        closeOnBackdropClick: false,
        dialogTitle: 'Person Search',
        maxWidth: DialogMaxWidthEnum.FULL,
        width: '100%',
        minHeight: '320px',
        height: '100%',
        maxHeight: '100%',
      },
    });

    personSearchDialog.afterClosedChanges
      .pipe(take(1), untilDestroyed(this))
      .subscribe(async (result) => {
        if (!result) {
          return;
        }

        await this.navigate(result);
      });
  }

  private async navigate(
    result: PersonSearchDialogComponentOutput,
  ): Promise<void> {
    const client = result.selectedClient;
    if (!client) {
      return;
    }

    const currentFacility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );

    if (currentFacility.id !== client.facility.id) {
      const facilityConfirmDialog = this.dialogService.open<
        BasicDialogComponent,
        BasicInputData,
        BasicOutputData
      >(BasicDialogComponent, {
        data: {
          display: { reject: false },
          labels: { confirm: 'Yes', cancel: 'Cancel' },
          message: `
          If you continue, you will switch to <b>${client.facility.name}</b>. 
          Continue?
        `.trim(),
        },
        options: {
          closeOnBackdropClick: false,
          dialogTitle: 'Warning',
          maxWidth: DialogMaxWidthEnum.SMALL,
        },
      });

      facilityConfirmDialog.afterClosedChanges
        .pipe(take(1), untilDestroyed(this))
        .subscribe(async (isFacilityNavigationConfirmed) => {
          if (!isFacilityNavigationConfirmed) {
            return;
          }
          this.router.navigate([
            'facility',
            client.facility.id,
            'clients',
            client.id,
          ]);
        });
    } else {
      this.router.navigate([`clients`, client.id]);
    }
  }
}
