import { ClientCIWABApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientCIWAB,
  ClientCIWABDeserializationArgs,
  ClientCIWABUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client CIWA-B API.
 */
@Injectable()
export class ClientCIWABService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Create a new client CIWA-B resource.
   *
   * @param clientId The client id to create the CIWA-B resource object for.
   * @param clientCIWAB The CIWA-B resource object to create.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The created CIWA-B resource object on success, undefined on error.
   */
  public create(
    clientId: Client['id'],
    clientCIWAUpdate: ClientCIWABUpdate,
    deserializationArgs: ClientCIWABDeserializationArgs,
  ): Observable<ClientCIWAB | undefined> {
    return this.httpClient
      .post<
        ClientCIWABApi | undefined
      >(`${config.api}/clients/${clientId}/ciwa-b`, clientCIWAUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCIWAB.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Delete the given client CIWA-B by id.
   *
   * @param clientId The associated client id of the CIWA-B to delete.
   * @param clientCIWABId The id of the client CIWA-B to delete.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientCIWABId: ClientCIWAB['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${config.api}/clients/${clientId}/ciwa-b/${clientCIWABId}`,
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return all client CIWA-B history data for the given client.
   *
   * @param clientId The client id to get the CIWA-B history for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The client CIWA-B history on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
    deserializationArgs: ClientCIWABDeserializationArgs,
  ): Observable<readonly ClientCIWAB[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientCIWABApi[] | undefined
      >(`${config.api}/clients/${clientId}/ciwa-b`)
      .pipe(
        map((response) =>
          response
            ? ClientCIWAB.deserializeList(response, deserializationArgs)
            : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a client's CIWA-B resource record.
   *
   * @param clientId The client id to patch the CIWA-B resource object for.
   * @param clientCIWABId The client CIWA-B resource object id to patch.
   * @param clientCIWABUpdate The updated CIWA-B resource object.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated CIWA-B resource object on success, undefined on error.
   */
  public update(
    clientId: Client['id'],
    clientCIWABId: ClientCIWAB['id'],
    clientCIWABUpdate: ClientCIWABUpdate,
    deserializationArgs: ClientCIWABDeserializationArgs,
  ): Observable<ClientCIWAB | undefined> {
    return this.httpClient
      .patch<
        ClientCIWABApi | undefined
      >(`${config.api}/clients/${clientId}/ciwa-b/${clientCIWABId}`, clientCIWABUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCIWAB.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
