<div>
  <div class="group-menu-logo">
    <alleva-icon
      (click)="onToggleMenuClick()"
      class="menu-toggle"
      color="primary"
      name="menu"
    ></alleva-icon>
    <a class="logo" routerLink="/">
      <!-- MOBILE, LOGO ONLY TEMPLATE -->
      <img
        *ngIf="isMobileChanges | async; else fullLogoTemplate"
        src="assets/images/alleva/alleva-256x256.png"
      />
      <!-- DESKTOP, LOGO + TEXT TEMPLATE -->
      <ng-template #fullLogoTemplate>
        <img src="assets/images/alleva/alleva-full-97x23.png" />
      </ng-template>
    </a>
    <div class="logo-divider"></div>
    <alleva-layout-header-switch-facility></alleva-layout-header-switch-facility>
  </div>
</div>
<div>
  <div class="group-actions">
    <alleva-person-search-button></alleva-person-search-button>
    <ng-container [ngTemplateOutlet]="helpMenuButton"></ng-container>
    <ng-container [ngTemplateOutlet]="notificationsMenuButton"></ng-container>
  </div>
</div>

<!-- HELP MENU: ACTION BUTTON -->
<ng-template #helpMenuButton>
  <alleva-button
    (click)="$event.preventDefault()"
    [icon]="{
      name: 'help_outline',
      color: 'primary',
      scale: 1.15
    }"
    [matMenuTriggerFor]="helpMenu"
    colorHex="var(--white)"
    label="Help Toggle"
    name="Help Toggle"
    styling="mini-fab"
  ></alleva-button>
</ng-template>

<!-- NOTIFICATIONS MENU: ACTION BUTTON -->
<ng-template #notificationsMenuButton>
  <alleva-button
    (click)="$event.preventDefault(); getSetUserNotifications()"
    (menuClosed)="isNotificationsMenuOpen = false"
    (menuOpened)="isNotificationsMenuOpen = true"
    [icon]="{
      name:
        (userNotificationsCountChanges | async) || 0 > 0
          ? 'notification_important'
          : 'notifications',
      color:
        (userNotificationsCountChanges | async) || 0 > 0 ? 'warn' : 'primary',
      scale: 1.15
    }"
    [matBadgeDisabled]="false"
    [matBadgeHidden]="((userNotificationsCountChanges | async) || 0) === 0"
    [matBadgeOverlap]="true"
    [matBadge]="
      ((userNotificationsCountChanges | async) || 0) < 100
        ? (userNotificationsCountChanges | async) || 0
        : '99+'
    "
    [matMenuTriggerFor]="notificationsMenu"
    colorHex="var(--white)"
    label="Notifications Toggle"
    matBadgeColor="warn"
    matBadgePosition="after"
    matBadgeSize="medium"
    name="Notifications Toggle"
    styling="mini-fab"
  ></alleva-button>
</ng-template>

<!-- HELP MENU -->
<mat-menu #helpMenu="matMenu" xPosition="before">
  <div class="drop-menu-header">
    <strong>Help</strong>
  </div>
  <div class="drop-menu-content">
    <button
      (click)="navigateOffsiteTo('https://alleva.workramp.io/')"
      mat-menu-item
    >
      <span>Alleva University</span>
    </button>
    <button
      (click)="navigateOffsiteTo('https://support.helloalleva.com/')"
      mat-menu-item
    >
      <span>Help Center</span>
    </button>
    <button
      (click)="navigateOffsiteTo('https://helloalleva.com/referrals/')"
      mat-menu-item
    >
      <span>Referral Program</span>
    </button>
  </div>
</mat-menu>

<!-- NOTIFICATIONS MENU -->
<mat-menu
  #notificationsMenu="matMenu"
  class="notifications-menu"
  xPosition="before"
>
  <div (click)="$event.stopPropagation()" class="drop-menu-header">
    <strong
      [class.has-notifications]="
        (userNotificationsCountChanges | async) || 0 > 0
      "
      [matBadgeDisabled]="false"
      [matBadgeHidden]="((userNotificationsCountChanges | async) || 0) === 0"
      [matBadgeOverlap]="false"
      [matBadge]="
        ((userNotificationsCountChanges | async) || 0) < 100
          ? (userNotificationsCountChanges | async) || 0
          : '99+'
      "
      matBadgeColor="warn"
      matBadgePosition="after"
      matBadgeSize="medium"
      >Notifications</strong
    >
  </div>
  <div
    (click)="$event.stopPropagation()"
    *ngIf="isNotificationsMenuOpen"
    [@fade-out]
    class="drop-menu-content notifications"
  >
    <ng-container
      *ngIf="
        userNotificationsChanges | async as userNotifications;
        else loadingTemplate
      "
    >
      <div *ngFor="let notification of userNotifications" class="notification">
        <alleva-container theme="light">
          <div class="row-info">
            <img
              [src]="
                (notification.client?.profileImage | authBlob | async) ||
                (notification.raisedBy.image | authBlob | async) ||
                'assets/images/clients/temp.png'
              "
            />
            <div class="column-name-date">
              <strong
                *ngIf="
                  notification.client?.fullName ||
                  notification.raisedBy.fullName as fullName
                "
                [title]="fullName"
                class="from-name"
                >{{ fullName }}</strong
              >
              <div>
                {{ notification.date | dateTime: 'relative-datetime' }}
              </div>
            </div>
            <alleva-button
              [disabled]="activeNotificationId !== null"
              (click)="
                $event.stopPropagation();
                markNotificationAsRead(notification['id'])
              "
              label="Mark Read"
              styling="flat"
            >
              Mark Read
            </alleva-button>
          </div>
          <div
            class="row-message"
            [class.one-row]="(notification.message?.length || 0) <= 40"
          >
            {{ notification.message }}
          </div>
        </alleva-container>
      </div>
      <div
        class="no-notifications-message"
        *ngIf="userNotifications.length === 0"
      >
        No new notifications. You're all caught up!
      </div>
    </ng-container>
  </div>
  <div class="drop-menu-footer" *ngIf="userNotificationsChanges | async">
    <a class="view-all-notifications" [routerLink]="notificationsPage">
      View All
      <alleva-icon
        name="arrow_right_alt"
        colorHex="var(--alleva-purple)"
      ></alleva-icon>
    </a>
  </div>
</mat-menu>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <alleva-loading-indicator
      [isCentered]="true"
      [labelHidden]="true"
    ></alleva-loading-indicator>
  </div>
</ng-template>
