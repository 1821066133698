import { NoteApi, NoteUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { Facility, FacilityBase } from 'src/app/models/facility/facility.model';
import { NoteType } from 'src/app/models/note/note-type.model';
import { NoteUser } from 'src/app/models/note/note-user.model';

const api = apiDecorator<NoteApi>();
const apiUpdate = apiDecorator<NoteUpdateApi>();

type NoteArgs = Omit<
  ClassProperties<Note>,
  // Omit computed properties set on construct from outside data.
  'priorityColorHex'
>;

abstract class NoteBase {
  public constructor(props: ClassProperties<NoteBase>) {
    this.body = props.body;
    this.createdDate = props.createdDate;
    this.date = props.date;
    this.facility = props.facility;
    this.isFavorite = props.isFavorite;
    this.isSigned = props.isSigned;
    this.noteFrom = props.noteFrom;
    this.noteTo = props.noteTo;
    this.priority = props.priority;
    this.replies = props.replies;
    this.type = props.type;
  }

  @api({ key: 'body' }) public readonly body: string | null;
  @api({ key: 'createdAt' }) public readonly createdDate: DateTime;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'facility' }) public readonly facility: FacilityBase;
  @api({ key: 'favorited' }) public readonly isFavorite: boolean;
  @api({ key: 'signed' }) public readonly isSigned: boolean;
  @api({ key: 'noteFrom' }) public readonly noteFrom: NoteUser;
  @api({ key: 'noteTo' }) public readonly noteTo: NoteUser;
  @api({ key: 'priority' }) public readonly priority: NameId;
  @api({ key: 'replyCount' }) public readonly replies: number;
  @api({ key: 'type' }) public readonly type: NoteType;
}

export class Note extends NoteBase {
  public constructor(props: ClassProperties<NoteArgs>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Note API model.
   */
  public static readonly Codec = io.type(
    {
      body: io.union([io.string, io.null]),
      createdAt: io.string,
      date: io.string,
      facility: FacilityBase.BaseCodec,
      favorited: io.boolean,
      id: io.number,
      noteFrom: NoteUser.Codec,
      noteTo: NoteUser.Codec,
      priority: NameId.Codec,
      replyCount: io.number,
      signed: io.boolean,
      type: NoteType.Codec,
    },
    'NoteApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Note object from the API model.
   *
   * @param value The value to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Note object.
   * @throws An error if the value is not a valid Note object.
   */
  public static deserialize(
    value: NonNullable<NoteApi>,
    deserializationArgs: NoteDeserializationArgs,
  ): Note {
    const decoded = decode(Note.Codec, value);
    return new Note({
      ...decoded,
      createdDate: DateTime.fromISO(decoded.createdAt, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      date: DateTime.fromISO(decoded.date, {
        zone: deserializationArgs.facilityTimeZone,
      }),
      facility: decoded.facility,
      isFavorite: decoded.favorited,
      isSigned: decoded.signed,
      noteFrom: NoteUser.deserialize(decoded.noteFrom),
      noteTo: NoteUser.deserialize(decoded.noteTo),
      priority: NameId.deserialize(decoded.priority),
      replies: decoded.replyCount,
      type: NoteType.deserialize(decoded.type),
    });
  }

  /**
   * Deserializes a list of Note objects from the API model.
   *
   * @param values The values to deserialize.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object.
   * @returns The deserialized Note objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Note objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<NoteApi>>,
    deserializationArgs: NoteDeserializationArgs,
  ): readonly Note[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Note objects.');
    }
    return values.map((note) => Note.deserialize(note, deserializationArgs));
  }
}

export class NoteUpdate extends NoteBase {
  public constructor(props: ClassProperties<NoteUpdate>) {
    super(props);

    this.signature = props.signature;
  }

  @apiUpdate({ key: 'signature' }) public readonly signature: string | null;

  /**
   * Serialize the data to a format that the API accepts.
   *
   * @returns The model used for updating Note data in the API.
   */
  public serialize(): NoteUpdateApi {
    const createdAt = this.createdDate.toISO();
    if (!createdAt) {
      throw new Error('Invalid created at date.');
    }

    return {
      ...this,
      createdAt,
      date: this.date.toISO(),
      favorited: this.isFavorite,
      noteFrom: {
        first: this.noteFrom.firstName,
        id: this.noteFrom.id,
        last: this.noteFrom.lastName,
      },
      noteTo: {
        first: this.noteTo.firstName,
        id: this.noteTo.id,
        last: this.noteTo.lastName,
      },
      replyCount: this.replies,
      signed: this.isSigned,
    };
  }
}

export interface NoteDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
