import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientInsurance,
  InsuranceVerificationUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientInsuranceVerificationService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Create (post) a new insurance verification record for the provided
   * insurance by id.
   *
   * @param insuranceId The id of the insurance to verify.
   * @param insuranceVerificationUpdate The verification update.
   * @returns A boolean indicating if the verification was created successfully.
   */
  public post(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    insuranceVerificationUpdate: InsuranceVerificationUpdate,
  ): Observable<boolean> {
    return this.httpClient
      .post<
        boolean | undefined
      >(`${config.api}/clients/${clientId}/insurance/${insuranceId}/verify`, insuranceVerificationUpdate.serialize(), { observe: 'response' })
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}
