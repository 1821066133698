import { InsuranceServiceDetailsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { LevelOfCare } from 'src/app/models/level-of-care.model';

const api = apiDecorator<InsuranceServiceDetailsApi>();

export class InsuranceServiceDetails {
  public constructor(props: ClassProperties<InsuranceServiceDetails>) {
    this.icd10CodeNames = props.icd10CodeNames;
    this.id = props.id;
    this.levelOfCare = props.levelOfCare;
    this.treatmentServiceNames = props.treatmentServiceNames;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Service Detail
   * API model.
   */
  public static readonly Codec = io.type(
    {
      icd10CodeNames: io.string,
      id: io.number,
      levelOfCare: LevelOfCare.Codec,
      treatmentServiceNames: io.string,
    },
    'InsuranceServiceDetailApi',
  );

  @api({ key: 'icd10CodeNames' }) public readonly icd10CodeNames: string;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'levelOfCare' }) public readonly levelOfCare: LevelOfCare;
  @api({ key: 'treatmentServiceNames' })
  public readonly treatmentServiceNames: string;

  /**
   * Deserializes a Insurance Service Details object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Service Details object.
   * @throws An error if the value is not a valid Insurance Service Details
   * object.
   */
  public static deserialize(
    value: NonNullable<InsuranceServiceDetailsApi>,
  ): InsuranceServiceDetails {
    const decoded = decode(InsuranceServiceDetails.Codec, value);
    return new InsuranceServiceDetails({
      ...decoded,
      levelOfCare: LevelOfCare.deserialize(decoded.levelOfCare),
    });
  }

  /**
   * Deserializes a list of Insurance Service Details objects from the API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Service Details objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Service
   * Details objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceServiceDetailsApi>>,
  ): readonly InsuranceServiceDetails[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected an array of Insurance Service Detail objects.');
    }
    return values.map(InsuranceServiceDetails.deserialize);
  }
}
