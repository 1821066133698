import { Observable, Subject } from 'rxjs';

/**
 * A reference to a dialog.
 *
 * R = The type of the result to return from the dialog.
 */
export class DialogRef<R> {
  private readonly afterClosedSubject = new Subject<R | undefined>();
  public readonly afterClosedChanges: Observable<R | undefined> =
    this.afterClosedSubject.asObservable();

  // An "early result" data stream that can be used to pass data back to the
  // parent component before the dialog is closed.
  private readonly earlyResultSubject = new Subject<R | undefined>();
  public readonly earlyResultChanges: Observable<R | undefined> =
    this.earlyResultSubject.asObservable();

  public close(result?: R): void {
    this.afterClosedSubject.next(result);
  }

  public emitEarlyResult(result?: R): void {
    this.earlyResultSubject.next(result);
  }
}
