<div class="divider">
  <hr />
  <alleva-icon
    *ngIf="categoryMenuItem.iconName"
    [name]="categoryMenuItem.iconName"
    [scale]="0.85"
    colorHex="var(--alleva-blue-light)"
  ></alleva-icon>
  <span [innerText]="categoryMenuItem.label" *ngIf="!categoryMenuItem.iconName">
  </span>
</div>
<ng-container *ngFor="let topLevelMenuItem of topLevelMenuItemsChanges | async">
  <alleva-menu-top-level-item
    *ngIf="topLevelMenuItem.isDisplayed"
    [currentFacility]="currentFacilityChanges | async"
    [currentUser]="currentUserChanges | async"
    [isCollapsed]="isCollapsedChanges | async"
    [selectedClient]="selectedClientChanges | async"
    [topLevelMenuItem]="topLevelMenuItem"
  ></alleva-menu-top-level-item>
</ng-container>
