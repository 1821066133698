import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Time } from 'src/app/models';

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * An input component that allows users to select a time.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-pick-time[formControl][label]',
  templateUrl: './input-pick-time.component.html',
  styleUrls: ['./input-pick-time.component.scss'],
})
export class InputPickTimeComponent
  extends CustomInputDirective<Time>
  implements OnInit
{
  protected readonly allevaTheme = allevaTheme;

  public override placeholder = 'Select Time';

  protected readonly baseTextControl = new FormControl<string | null>(null);

  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputPickTimeComponent requires a formControl attribute to be set.',
      );
    }

    if (this.baseControl.hasValidator(Validators.required)) {
      this.baseTextControl.setValidators(Validators.required);
    }

    // If the starting value has value, set the text control value.
    const currentValue = this.baseControl.value;
    if (currentValue) {
      this.baseTextControl.setValue(currentValue.toString());
    }

    this.baseControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        // Value checks.
        if (value === null) {
          this.baseTextControl.setValue(null);
        } else {
          this.baseTextControl.setValue(
            `${value.hour}:${value.minute} ${value.meridiem}`,
          );
        }

        // Required state checks.
        if (
          this.baseControl.hasValidator(Validators.required) &&
          !this.baseTextControl.hasValidator(Validators.required)
        ) {
          this.baseTextControl.addValidators(Validators.required);
        } else if (
          !this.baseControl.hasValidator(Validators.required) &&
          this.baseTextControl.hasValidator(Validators.required)
        ) {
          this.baseTextControl.removeValidators(Validators.required);
        }

        // Disabled state checks.
        if (this.baseControl.disabled !== this.baseTextControl.disabled) {
          this.baseTextControl[
            this.baseControl.disabled ? 'disable' : 'enable'
          ]();
        }

        if (this.baseControl.dirty && !this.baseTextControl.dirty) {
          this.baseTextControl.markAsDirty();
        }

        if (this.baseControl.pristine && !this.baseTextControl.pristine) {
          this.baseTextControl.markAsPristine();
        }

        if (this.baseControl.touched && !this.baseTextControl.touched) {
          this.baseTextControl.markAsTouched();
        }
      });

    this.baseTextControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value === null) {
          this.baseControl.setValue(null, { emitEvent: false });
        } else {
          const hour = parseInt(value.split(':')[0], 10) as Time['hour'];
          const minute = parseInt(value.split(':')[1], 10) as Time['minute'];
          const meridiem = value.split(' ')[1] as Time['meridiem'];

          if (isNaN(hour)) {
            throw new Error('Invalid hour value');
          } else if (isNaN(minute)) {
            throw new Error('Invalid minute value');
          } else if (meridiem !== 'AM' && meridiem !== 'PM') {
            throw new Error('Invalid meridiam value');
          }

          this.baseControl.setValue(
            new Time({
              hour,
              minute,
              meridiem,
            }),
            { emitEvent: false },
          );

          if (this.baseTextControl.dirty && !this.baseControl.dirty) {
            this.baseControl.markAsDirty();
          }

          if (this.baseTextControl.pristine && !this.baseControl.pristine) {
            this.baseControl.markAsPristine();
          }

          if (this.baseTextControl.touched && !this.baseControl.touched) {
            this.baseControl.markAsTouched();
          }
        }
      });
  }

  protected clear(): void {
    this.baseControl.setValue(null);
    this.baseTextControl.setValue(null);
  }

  public markAsTouched(): void {
    this.baseControl.markAsTouched();
    this.baseTextControl.markAsTouched();
  }
}

const allevaTheme: NgxMaterialTimepickerTheme = {
  container: { bodyBackgroundColor: 'var(--white)' },
  dial: { dialBackgroundColor: 'var(--alleva-blue)' },
  clockFace: {
    clockFaceBackgroundColor: 'var(--alleva-orange-light)',
    clockHandColor: 'var(--alleva-blue)',
    clockFaceTimeInactiveColor: 'var(--black)',
    clockFaceTimeActiveColor: 'var(--white)',
  },
};
