import { ReligionApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ReligionApi>();

export class Religion {
  public constructor(props: ClassProperties<Religion>) {
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Religion Api model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.string,
    },
    'ReligionApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a Religion object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Religion object.
   * @throws An error if the value is not a valid Religion object.
   */
  public static deserialize(value: NonNullable<ReligionApi>): Religion {
    const decoded = decode(Religion.Codec, value);
    return new Religion({
      ...decoded,
    });
  }

  /**
   * Deserializes a list of Religion objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Religion objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Religion objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ReligionApi>>,
  ): readonly Religion[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Religion objects.');
    }
    return values.map(Religion.deserialize);
  }
}
