import { MessageAttachmentApi, MessageAttachmentPathApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<MessageAttachmentApi>();

export class MessageAttachment {
  public constructor(props: ClassProperties<MessageAttachment>) {
    this.id = props.id;
    this.name = props.name;
    this.path = props.path;
  }

  /**
   * The io-ts codec for runtime type checking of the Message Attachment API
   * model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      name: io.union([io.string, io.null]),
      path: io.type(
        {
          fileName: io.union([io.string, io.null]),
          host: io.union([io.string, io.null]),
          path: io.union([io.string, io.null]),
        },
        'MessageAttachmentPathApi',
      ),
    },
    'MessageAttachmentApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'path' }) public readonly path: MessageAttachmentPath;

  /**
   * Deserializes a Message Attachment object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Message Attachment object.
   * @throws An error if the value is not a valid Message Attachment object.
   */
  public static deserialize(
    value: NonNullable<MessageAttachmentApi>,
  ): MessageAttachment {
    const decoded = decode(MessageAttachment.Codec, value);
    return new MessageAttachment(decoded);
  }

  /**
   * Deserializes a list of Message Attachment objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Message Attachment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Message Attachment
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<MessageAttachmentApi>>,
  ): readonly MessageAttachment[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Message Attachment objects.');
    }
    return values.map(MessageAttachment.deserialize);
  }
}

abstract class MessageAttachmentPath {
  public constructor(props: ClassProperties<MessageAttachmentPath>) {
    this.fileName = props.fileName;
    this.host = props.host;
    this.path = props.path;
  }

  public static readonly api = apiDecorator<MessageAttachmentPathApi>();

  @MessageAttachmentPath.api({ key: 'fileName' }) public readonly fileName:
    | string
    | null;
  @MessageAttachmentPath.api({ key: 'host' }) public readonly host:
    | string
    | null;
  @MessageAttachmentPath.api({ key: 'path' }) public readonly path:
    | string
    | null;
}
