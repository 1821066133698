import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { IconModule } from 'src/app/components/icon/icon.module';
import { TableComponent } from 'src/app/components/table/table.component';

import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [TableComponent],
  exports: [TableComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    IconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
  ],
  providers: [],
})
export class TableModule {}
