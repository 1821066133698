import { MedicationApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<MedicationApi>();

export abstract class MedicationBase {
  public constructor(props: ClassProperties<MedicationBase>) {
    this.id = props.id;
    this.name = props.name;
  }

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;
}

export class Medication extends MedicationBase {
  public constructor(props: ClassProperties<Medication>) {
    super(props);

    this.active = props.active;
    this.custom = props.custom;
    this.dispensableDrugId = props.dispensableDrugId;
    this.dispensableGenericId = props.dispensableGenericId;
    this.dispensableGenericName = props.dispensableGenericName;
    this.dispensableName = props.dispensableName;
    this.doseForm = props.doseForm;
    this.isCustomMeds = props.isCustomMeds;
    this.isGeneric = props.isGeneric;
    this.medicationCustomId = props.medicationCustomId;
    this.route = props.route;
    this.routedDrugId = props.routedDrugId;
    this.routedName = props.routedName;
    this.strength = props.strength;
    this.strengthUnit = props.strengthUnit;
  }

  /** The io-ts codec for runtime type checking of the Medication Api model. */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      custom: io.boolean,
      dispensableDrugId: io.union([io.string, io.null]),
      dispensableGenericId: io.union([io.string, io.null]),
      dispensableGenericName: io.union([io.string, io.null]),
      dispensableName: io.union([io.string, io.null]),
      doseForm: io.union([io.string, io.null]),
      id: io.number,
      isCustomMeds: io.boolean,
      isGeneric: io.boolean,
      medicationCustomId: io.union([io.number, io.null]),
      name: io.string,
      route: io.union([io.string, io.null]),
      routedDrugId: io.union([io.string, io.null]),
      routedName: io.union([io.string, io.null]),
      strength: io.union([io.string, io.null]),
      strengthUnit: io.union([io.string, io.null]),
    },
    'MedicationApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'custom' }) public readonly custom: boolean;
  @api({ key: 'dispensableDrugId' }) public readonly dispensableDrugId:
    | string
    | null;
  @api({ key: 'dispensableGenericId' }) public readonly dispensableGenericId:
    | string
    | null;
  @api({ key: 'dispensableGenericName' })
  public readonly dispensableGenericName: string | null;
  @api({ key: 'dispensableName' }) public readonly dispensableName:
    | string
    | null;
  @api({ key: 'doseForm' }) public readonly doseForm: string | null;
  @api({ key: 'isCustomMeds' }) public readonly isCustomMeds: boolean;
  @api({ key: 'isGeneric' }) public readonly isGeneric: boolean;
  @api({ key: 'medicationCustomId' }) public readonly medicationCustomId:
    | number
    | null;
  @api({ key: 'route' }) public readonly route: string | null;
  @api({ key: 'routedDrugId' }) public readonly routedDrugId: string | null;
  @api({ key: 'routedName' }) public readonly routedName: string | null;
  @api({ key: 'strength' }) public readonly strength: string | null;
  @api({ key: 'strengthUnit' }) public readonly strengthUnit: string | null;

  /**
   * Deserializes a Medication object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Medication object.
   * @throws An error if the value is not a valid Medication object.
   */
  public static deserialize(value: NonNullable<MedicationApi>): Medication {
    const decoded = decode(Medication.Codec, value);
    return new Medication(decoded);
  }

  /**
   * Deserializes a list of Medication objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Medication objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Medication objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<MedicationApi>>,
  ): readonly Medication[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Medication objects.');
    }
    return values.map(Medication.deserialize);
  }
}
