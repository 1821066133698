import { VitalsTemperatureApi, VitalsTemperatureUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { TemperatureUnitsEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<VitalsTemperatureApi>();

export class VitalsTemperature {
  public constructor(props: ClassProperties<VitalsTemperature>) {
    if (props.value !== null && props.units === null) {
      throw new Error(
        'Vitals Temperature "units" must be set when "value" is set.',
      );
    }

    this.units = props.units;
    this.value = props.value;
  }

  /**
   * The io-ts codec for runtime type checking of the Vitals Temperature API model.
   */
  public static readonly Codec = io.type(
    {
      units: io.union([
        io.literal(TemperatureUnitsEnum.CELSIUS),
        io.literal(TemperatureUnitsEnum.FAHRENHEIT),
        io.null,
      ]),
      value: io.union([io.number, io.null]),
    },
    'VitalsTemperatureApi',
  );

  /**
   * The supported temperatures unit of measurement for the given value.
   *
   * 'c' for Celsius
   * 'f' for Fahrenheit
   */
  @api({ key: 'units' }) public readonly units: TemperatureUnitsEnum | null;
  @api({ key: 'value' }) public readonly value: number | null;

  /**
   * Deserializes a Vitals Temperature object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Vitals Temperature object.
   * @throws An error if the value is not a valid Vitals Temperature object.
   */
  public static deserialize(
    value: NonNullable<VitalsTemperatureApi>,
  ): VitalsTemperature {
    const decoded = decode(VitalsTemperature.Codec, value);
    return new VitalsTemperature(decoded);
  }

  /**
   * Deserializes a list of Vitals Temperature objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Vitals Temperature objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Vitals Temperature
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<VitalsTemperatureApi>>,
  ): readonly VitalsTemperature[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Vitals Temperature objects.');
    }
    return values.map(VitalsTemperature.deserialize);
  }
}

export class VitalsTemperatureUpdate extends VitalsTemperature {
  public constructor(props: ClassProperties<VitalsTemperatureUpdate>) {
    super(props);
  }

  public serialize(): VitalsTemperatureUpdateApi {
    return {
      units: this.units,
      value: this.value,
    };
  }
}
