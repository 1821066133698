import {
  CommentApi,
  NoteApi,
  NotePagedListApi,
  NoteTypeApi,
  PriorityApi,
} from 'api/models';
import { Observable, catchError, map, of, withLatestFrom } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import {
  Client,
  Comment,
  CommentUpdate,
  Facility,
  NameId,
  Note,
  NoteDeserializationArgs,
  NoteType,
  NoteUpdate,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityService } from './root/facility.service';

/**
 * A service for interacting with the Notes API.
 */
@Injectable()
export class NotesService {
  public constructor(
    private readonly facilityService: FacilityService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Fetch and return all notes data for the given client id.
   *
   * @param clientId The client ID to fetch.
   * @param facilityId The facility ID to fetch by.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @param requestParameters Optional request parameters.
   * @returns All client notes (list) data on success, undefined on error.
   */
  public getClientNoteList(
    clientId: Client['id'],
    facilityId: Facility['id'],
    deserializationArgs: NoteDeserializationArgs,
    requestParameters?: readonly CommunicationLogsRequestParam[],
  ): Observable<PagedList<Note> | undefined> {
    const params = parseHttpParams(requestParameters)
      ?.append('facility', facilityId)
      .append('noteTo', clientId);

    return this.httpClient
      .get<NotePagedListApi | undefined>(`${config.api}/comm-logs`, {
        params,
      })
      .pipe(
        map((response) => {
          if (response === undefined) return undefined;
          const pagedClientList: PagedList<Note> = {
            ...decode(NotePagedListCodec, response),
            results: Note.deserializeList(
              response.results,
              deserializationArgs,
            ),
          };
          return pagedClientList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all note comments data for the given note id.
   *
   * @param noteId The note ID to fetch the comments for.
   * @returns All comments for the given note ID on success, undefined on error.
   */
  public getNoteComments(
    noteId: Note['id'],
  ): Observable<readonly Comment[] | undefined> {
    return this.httpClient
      .get<
        readonly CommentApi[] | undefined
      >(`${config.api}/comm-logs/${noteId}/comments`)
      .pipe(
        withLatestFrom(this.facilityService.currentFacilityChanges),
        map(([response, currentFacility]) =>
          response
            ? Comment.deserializeList(response, {
                facilityTimeZone: currentFacility.timeZone,
              })
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all note priorities data from the API.
   *
   * @param requestParameters Optional request parameters.
   * @returns All available note priorities on success, undefined on error.
   */
  public getNotePriorities(
    requestParameters?: readonly NoteListRequestParam[],
  ): Observable<readonly NameId[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<
        readonly PriorityApi[] | undefined
      >(`${config.api}/notes/priorities`, { params })
      .pipe(
        map((response) =>
          response ? NameId.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all note types data from the API.
   *
   * @param requestParameters Optional request parameters.
   * @returns All available note types on success, undefined on error.
   */
  public getNoteTypes(
    requestParameters?: readonly NoteListRequestParam[],
  ): Observable<readonly NoteType[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly NoteTypeApi[] | undefined>(`${config.api}/notes/types`, {
        params,
      })
      .pipe(
        map((response) =>
          response ? NoteType.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Post a note to the API.
   *
   * @param noteUpdate The note update to post.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated note on success, undefined on error.
   */
  public postClientNote(
    noteUpdate: NoteUpdate,
    deserializationArgs: NoteDeserializationArgs,
  ): Observable<Note | undefined> {
    return this.httpClient
      .post<
        NoteApi | undefined
      >(`${config.api}/comm-logs`, noteUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? Note.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Post a comment to the API.
   *
   * @param noteId The ID of the note to post the comment to.
   * @param comment The comment to post.
   * @returns The created comment on success, undefined on error.
   */
  public postClientNoteComment(
    noteId: Note['id'],
    comment: CommentUpdate,
  ): Observable<Comment | undefined> {
    return this.httpClient
      .post<
        CommentApi | undefined
      >(`${config.api}/comm-logs/${noteId}/comments`, comment.serialize())
      .pipe(
        withLatestFrom(this.facilityService.currentFacilityChanges),
        map(([response, currentFacility]) =>
          response
            ? Comment.deserialize(response, {
                facilityTimeZone: currentFacility.timeZone,
              })
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a note in the API.
   *
   * @param noteId The ID of the note to patch.
   * @param update The updates to make to the note resource.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated note on success, undefined on error.
   */
  public patchClientNote(
    noteId: Note['id'],
    update: NoteUpdate,
    deserializationArgs: NoteDeserializationArgs,
  ): Observable<Note | undefined> {
    return this.httpClient
      .patch<
        NoteApi | undefined
      >(`${config.api}/comm-logs/${noteId}`, update.serialize())
      .pipe(
        map((response) =>
          response
            ? Note.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const NotePagedListCodec = PagedListCodec(Note.Codec, 'NotePagedListApi');

/**
 * Request parameter interface for use with the Communication Logs API.
 */
export interface CommunicationLogsRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'order' | 'sort' | 'pageNumber' | 'pageSize';
  /** The value to use for the query parameter. */
  value: NestedKeysOfString<Note> | 'asc' | 'desc' | SortOrderEnum | number;
}

/**
 * Request parameter interface for use with the Note Lists API.
 */
interface NoteListRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'order' | 'active';
  /** The value to use for the query parameter. */
  value: NestedKeysOfString<NoteType | NameId> | boolean;
}
