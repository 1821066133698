import { ReferralContact } from 'src/app/models';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from 'src/app/pipes/pipes.module';

/**
 * A component that displays a client contact referral data card.
 *
 * @requires `referral` attribute to be set.
 */
@Component({
  selector: 'alleva-referral-card[referral]',
  templateUrl: './referral-card.component.html',
  styleUrls: ['../card.scss'],
  /** Standalone Options */
  standalone: true,
  imports: [CommonModule, MatBadgeModule, MatIconModule, PipesModule],
})
export class ReferralCardComponent {
  /**
   * The referral data to display.
   */
  @Input() public referral!: ReferralContact;
}
