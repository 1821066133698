import { FacilitySettingsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { MeasurementSystemEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<FacilitySettingsApi>();

export class FacilitySettings {
  public constructor(props: ClassProperties<FacilitySettings>) {
    this.measurementSystem = props.measurementSystem;
  }

  public static readonly Codec = io.type(
    {
      defaultUnits: io.union([
        io.literal(MeasurementSystemEnum.AMERICAN),
        io.literal(MeasurementSystemEnum.IMPERIAL),
        io.literal(MeasurementSystemEnum.METRIC),
        io.null,
      ]),
    },
    'FacilitySettingsApi',
  );

  @api({ key: 'defaultUnits' })
  public readonly measurementSystem: MeasurementSystemEnum;

  public static deserialize(
    value: NonNullable<FacilitySettingsApi>,
  ): FacilitySettings {
    const decoded = decode(FacilitySettings.Codec, value);
    return new FacilitySettings({
      // Default to American if the value is null.
      measurementSystem: decoded.defaultUnits ?? MeasurementSystemEnum.AMERICAN,
    });
  }
}
