import {
  TINYMCE_SCRIPT_SRC,
  EditorModule as TinyMceEditorModule,
} from '@tinymce/tinymce-angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { InputEditorComponent } from 'src/app/components/forms/input-editor/input-editor.component';

// Setup docs: https://tiny.cloud/docs/tinymce/6/angular-pm/

@NgModule({
  declarations: [InputEditorComponent],
  exports: [InputEditorComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TinyMceEditorModule,
  ],
  providers: [
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js',
    },
  ],
})
export class InputEditorModule {}
